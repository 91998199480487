// disable global inspection since this is directly used as the Shepherd tour settings
// noinspection JSUnusedGlobalSymbols

import { BaseTour } from './BaseTour';

export class TrialOnboardingTour extends BaseTour {
    public static tourLocalStorageId: string = 'trialOnboardingTour';
    public useModalOverlay: boolean = true;

    public constructor(protected translate: ng.translate.ITranslateService) {
        super(translate);
        this.steps = [
            this.getWelcomeStep(),
            this.getCreateChecklistStep(),
            this.getStartCheckStep(),
            this.getViewCompletedStep(),
            this.getViewDashboardsStep(),
        ];
    }

    public getTourLocalStorageId(): string {
        return TrialOnboardingTour.tourLocalStorageId;
    }

    private getWelcomeStep(): any {
        const letsGoButton: any = this.getLetsGoButton([
            'itrack--check-trial-onboarding-tour--welcome-lets-go',
            'intouch-data-cy--check-trial-onboarding-tour--welcome-lets-go',
        ]);
        const skipTourButton: any = this.getSkipTourButton([
            'itrack--check-trial-onboarding-tour--welcome-skip',
            'intouch-data-cy--check-trial-onboarding-tour--welcome-skip',
        ]);
        return this.getBaseStep({
            text:
                '<div class="shepherd-content-text--centered"><img src="assets/images/essential/branding/check_icon.svg" style="max-width: 100px;" class="its-margin-top-bot"/>' +
                '<p class="shepherd-content-title">' +
                this.translate.instant('TRIAL_ONBOARDING_TOUR.WELCOME_TITLE') +
                ' IntouchCheck™</p>' +
                '<p class="shepherd-content-text--medium its-padding">IntouchCheck™ ' +
                this.translate.instant('TRIAL_ONBOARDING_TOUR.WELCOME_TEXT') +
                '</p>' +
                '</div>',
            buttons: [skipTourButton, letsGoButton],
            classes: 'shepherd-width-medium',
        });
    }

    private getCreateChecklistStep(): any {
        const skipTourButton: any = this.getSkipTourButton([
            'itrack--check-trial-onboarding-tour--create-checklist-skip',
            'intouch-data-cy--check-trial-onboarding-tour--create-checklist-skip',
        ]);
        const nextButton: any = this.getNextButton([
            'itrack--check-trial-onboarding-tour--create-checklist-next',
            'intouch-data-cy--check-trial-onboarding-tour--create-checklist-next',
        ]);
        return this.getBaseStep({
            title: this.translate.instant('TRIAL_ONBOARDING_TOUR.CREATE_CHECKLIST_TITLE'),
            text: this.translate.instant('TRIAL_ONBOARDING_TOUR.CREATE_CHECKLIST_TEXT'),
            buttons: [skipTourButton, nextButton],
            attachTo: { element: '.itc-tour-checklist-create-button', on: 'bottom' },
            modalOverlayOpeningPadding: 6,
        });
    }

    private getStartCheckStep(): any {
        const skipTourButton: any = this.getSkipTourButton([
            'itrack--check-trial-onboarding-tour--start-check-skip',
            'intouch-data-cy--check-trial-onboarding-tour--start-check-skip',
        ]);
        const nextButton: any = this.getNextButton([
            'itrack--check-trial-onboarding-tour--start-check-next',
            'intouch-data-cy--check-trial-onboarding-tour--start-check-next',
        ]);
        return this.getBaseStep({
            title: this.translate.instant('TRIAL_ONBOARDING_TOUR.START_CHECK_TITLE'),
            text: this.translate.instant('TRIAL_ONBOARDING_TOUR.START_CHECK_TEXT'),
            buttons: [skipTourButton, nextButton],
            attachTo: {
                element: '.itc-tour-checklists-container .itc-tour-checklist-tile:nth-child(1) .its-tour-start-check',
                on: 'bottom',
            },
        });
    }

    private getViewCompletedStep(): any {
        const skipTourButton: any = this.getSkipTourButton([
            'itrack--check-trial-onboarding-tour--view-completed-skip',
            'intouch-data-cy--check-trial-onboarding-tour--view-completed-skip',
        ]);
        const nextButton: any = this.getNextButton([
            'itrack--check-trial-onboarding-tour--view-completed-next',
            'intouch-data-cy--check-trial-onboarding-tour--view-completed-next',
        ]);
        return this.getBaseStep({
            title: this.translate.instant('TRIAL_ONBOARDING_TOUR.VIEW_COMPLETED_TITLE'),
            text: this.translate.instant('TRIAL_ONBOARDING_TOUR.VIEW_COMPLETED_TEXT'),
            buttons: [skipTourButton, nextButton],
            attachTo: { element: '.its-sidebar--menu-item-list div:nth-child(2)', on: 'right' },
        });
    }

    private getViewDashboardsStep(): any {
        const finishButton: any = this.getFinishButton([
            'itrack--check-trial-onboarding-tour--view-dashboards-finish',
            'intouch-data-cy--check-trial-onboarding-tour--view-dashboards-finish',
        ]);
        return this.getBaseStep({
            title: this.translate.instant('TRIAL_ONBOARDING_TOUR.VIEW_DASHBOARDS_TITLE'),
            text: this.translate.instant('TRIAL_ONBOARDING_TOUR.VIEW_DASHBOARDS_TEXT'),
            buttons: [finishButton],
            attachTo: { element: 'its-top-nav-bar md-toolbar div div md-menu:nth-of-type(1)', on: 'bottom' },
        });
    }
}
