export class SubscriptionPlanUtils {
    /**
     * Check to see if current subscription is check trial standard
     *
     * @param subscriptionPlan
     */
    public static isCheckStandardPlan(subscriptionPlan: string): boolean {
        return subscriptionPlan && subscriptionPlan.indexOf('check_trial_standard') === 0;
    }

    /**
     * Check to see if current subscription is check_trial_premium
     *
     * @param subscriptionPlan
     */
    public static isCheckPremium(subscriptionPlan: string): boolean {
        return subscriptionPlan && subscriptionPlan.indexOf('check_trial_premium') === 0;
    }

    /**
     * Return if status is an active one
     *
     * @param status
     */
    public static isActive(status: string): boolean {
        return ['active', 'trialing'].indexOf(status) > -1;
    }

    public static isTrial(status: string): boolean {
        return status === 'trialing';
    }
}
