import {
    IBaseListingItem,
    BaseListingItem,
} from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseListingItem';

export interface IListingItem extends IBaseListingItem {
    approver: string;
}

export class ListingItem extends BaseListingItem implements IListingItem {
    public approver: string = null;
}
