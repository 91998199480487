import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemSettingsComponent } from '../BaseItemSettingsComponent';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { ChecklistService } from '../../../../../services/ChecklistService';
import { IChecklist } from '../../../../../domain/checklists/Checklist';
import { IItemDate, ItemDate } from '../../../../../domain/checklists/ItemDate';
import { IItemInput } from '../../../../../domain/checklists/ItemInput';

@Component('its.check.module.checklists', DatePickerSettings.CID, {
    template: require('/app/modules/checklists/components/items/date-picker/DatePickerSettings.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class DatePickerSettings extends BaseItemSettingsComponent {
    static IID: string = 'DatePickerSettings';
    static CID: string = 'itcDatePickerSettings';

    static $inject: Array<string> = ['itcChecklistService', '$scope'];
    public item: ItemDate;
    public checklist: IChecklist = null;
    public prePopulateQuestions: Array<IItemDate> = [];

    public prePopulateOptions: Array<any> = [{ name: 'CHECKLISTS.PRE_POPULATE.FROM_QUESTION', value: 'question' }];

    public prePopulateInterval: Array<any> = [
        { name: 'CHECKLISTS.PRE_POPULATE.DAYS', value: 'days' },
        { name: 'CHECKLISTS.PRE_POPULATE.WEEKS', value: 'weeks' },
        { name: 'CHECKLISTS.PRE_POPULATE.MONTHS', value: 'months' },
        { name: 'CHECKLISTS.PRE_POPULATE.YEARS', value: 'years' },
    ];

    public constructor(private checklistService: ChecklistService, private scope: ng.IScope) {
        super();
        this.subscribeToChecklist();
    }

    public questionSelected(selection: IItemDate): void {
        if (!selection) {
            this.item.clearPrePopulate();
        }
    }

    private subscribeToChecklist(): void {
        const checklistSubscription: SubjectSubscription = this.checklistService.checklistObserver.subscribe(
            (checklist) => {
                this.checklist = checklist;

                if (this.checklist) {
                    this.prePopulateQuestions = <Array<IItemDate>>this.checklist.getInputItems(
                        null,
                        (i: IItemInput) => {
                            return !i.disabled && i.uuid !== this.item.uuid && i.type === 'date';
                        },
                        true
                    );
                }
            }
        );
        checklistSubscription.unsubscribeOnScopeDestruction(this.scope);
    }
}
