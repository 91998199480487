import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ReportRecord } from '@intouch/its.essential/app/essential/iql/record/ReportRecord';
import { IPageService } from '../../services/PageService';
import { IAuditCounts, IAuditService } from '../../services/AuditService';
import { IAudit } from '../../domain/audits/Audit';
import { IOrganization } from '@intouch/its.essential/app/essential/domain/access/Organization';
import { IListingItem } from '../../domain/audits/ListingItem';
import { IQueryFilter, QueryFilter } from '@intouch/its.essential/app/essential/domain/api/QueryFilter';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import moment = require('moment'); // tslint:disable-line
import { IIql, Iql } from '@intouch/iql-ts-sdk/src/domain/Iql';
import { IFilterGroup, FilterGroup } from '@intouch/iql-ts-sdk/src/domain/filters/FilterGroup';
import { Filter } from '@intouch/iql-ts-sdk/src/domain/filters/Filter';
import { Column } from '@intouch/iql-ts-sdk/src/domain/Column';
import { DatetimeFormatter } from '@intouch/iql-ts-sdk/src/domain/formatters/DatetimeFormatter';
import { NumberFormatter } from '@intouch/iql-ts-sdk/src/domain/formatters/NumberFormatter';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IBaseColumnFormatter } from '@intouch/iql-ts-sdk/src/domain/formatters/BaseColumnFormatter';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { IRecord } from '@intouch/its.essential/app/essential/iql/record/Record';
import { ICheckApi } from '../../api/CheckApi';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';
import { IUpcomingListingItem } from '../../domain/audits/UpcomingListItem';
import { IUser } from '@intouch/its.essential/app/essential/domain/access/User';
import { ICheckSession } from '../../services/CheckSession';
import { EditUpcomingAuditModal } from './modals/EditUpcomingAuditModal';
import { IInProgressListingItem } from '../../domain/audits/InProgressListingItem';

interface IColumnResults {
    columns?: { label: string; value: string };
    synthesisId?: string;
    valuePath?: string;
}

interface IColumns extends Array<IColumnItem> {
    valuePath?: string;
}

interface IColumnItem {
    valuePath: string;
    columnLabel: string;
    type?: string;
}

class DateFilter {
    public type: string = null;
    public fromDate: string = null;
    public toDate: string = null;

    public static fromParams(params: any, defaultType: string): DateFilter {
        let me: DateFilter = new DateFilter();
        me.type = params['dateFilter'] || defaultType;
        me.fromDate = params['from'] || null;
        me.toDate = params['to'] || null;

        return me;
    }
}

/**
 * The users controller
 *
 */
@Controller('its.check.module.audits', DashboardController.IID, DashboardController)
class DashboardController {
    static IID: string = 'DashboardController';
    static $inject: Array<string> = [
        '$state',
        'itcPageService',
        'itcAuditService',
        '$translate',
        '$mdDialog',
        'iteToaster',
        'iteIqlApi',
        'itcCheckSession',
        'iteAccessService',
        '$timeout',
        'itcCheckApi',
        '$q',
        'iteUserLocalTimeService',
        '$mdMedia',
    ];

    public loading: boolean = false;
    public hasError: boolean = false;
    public iqlColumns: IColumns;
    public isAdmin: boolean = false;
    public isAclUser: boolean = false;
    public tabLinks: Array<string> = [
        'approval',
        'in_progress',
        'admin_assigned',
        'reviewable',
        'approval_completed',
        'incomplete',
        'complete',
    ];
    public hasAclAdmin: boolean = false;
    public hasApprovalAcl: boolean = false;
    public deletedRecordUuids: Array<string> = [];
    public dateFilter: DateFilter = null;
    public counts: IAuditCounts = null;

    public navExpanded: boolean = false;

    protected currentTab: string;
    protected organization: IOrganization;
    protected items: Array<IListingItem | IRecord>;
    protected pager: IPager;

    /**
     * The class constructor
     *
     * @param stateService
     * @param pageService
     * @param auditService
     * @param translate
     * @param dialog
     * @param toaster
     * @param iqlApi
     * @param {ICheckSession} session
     * @param {IAccessService} accessService
     * @param timeout
     * @param checkApi
     * @param q
     * @param userLocalTimeService
     * @param media
     */
    constructor(
        private stateService: ng.ui.IStateService,
        private pageService: IPageService,
        private auditService: IAuditService,
        private translate: angular.translate.ITranslateService,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private iqlApi: IIqlApi,
        private session: ICheckSession,
        private accessService: IAccessService,
        private timeout: ng.ITimeoutService,
        private checkApi: ICheckApi,
        private q: ng.IQService,
        private userLocalTimeService: IUserLocalTimeService,
        private media: ng.material.IMedia
    ) {
        this.currentTab = this.stateService.current.data['tab'];

        this.loadCounts();
        this.load();

        this.isAdmin = this.accessService.getToken().isAdmin();

        this.isAclUser = this.accessService.getToken().getUser().hasAcl('iq_delete_records');

        // set permissions
        let user: IUser = this.session.getToken().getUser();
        this.hasAclAdmin = user.hasAcl('check_admin');
        this.hasApprovalAcl = user.hasAcl('check_approval_assignment');
        if (this.isAdmin || this.isAclUser) {
            this.tabLinks.push('deleted');
        }
    }

    public navIsExpanded(): boolean {
        return this.media('gt-sm') || this.navExpanded;
    }

    public loadCounts(): void {
        this.counts = this.auditService.getCounts();

        // update counts
        this.auditService.fetchCounts().then((counts) => {
            this.counts = counts;
        });
    }

    /**
     * Loads listing
     *
     */
    public load(): void {
        this.pager = Pager.make(
            this.stateService.params['page'] || 1,
            this.stateService.params['sortBy'] || 'submitted_at',
            this.stateService.params['order'] || 'desc'
        );
        let promises: Array<any> = [];
        this.items = [];
        this.loading = true;
        this.hasError = false;

        switch (this.currentTab) {
            case 'approval':
                promises.push(
                    this.checkApi.findApprovableAudits(this.pager).then((result: PagedEntities) => {
                        this.items = result.getEntities();
                        this.pager = result.getPager();
                    })
                );
                break;
            case 'in_progress':
                promises.push(
                    this.checkApi.findInProgressAudits(this.pager).then((result: PagedEntities) => {
                        this.items = result.getEntities();
                        this.pager = result.getPager();
                    })
                );
                break;
            case 'reviewable':
                promises.push(
                    this.checkApi.findReviewableAudits(this.pager).then((result: PagedEntities) => {
                        this.items = result.getEntities();
                        this.pager = result.getPager();
                    })
                );
                break;
            case 'incomplete':
                this.pager.sortBy = this.stateService.params['sortBy'] || 'should_have_completed_by';
                this.dateFilter = DateFilter.fromParams(this.stateService.params, 'all_incomplete');
                let queryFilter: IQueryFilter = new QueryFilter();
                if (this.dateFilter.type !== 'all_incomplete') {
                    queryFilter.addParam('filter[older_than]', this.calculate(this.dateFilter.type));
                }
                promises.push(
                    this.checkApi.findIncompleteAudits(this.pager, null, queryFilter).then((result: PagedEntities) => {
                        this.items = result.getEntities();
                        this.pager = result.getPager();
                    })
                );
                break;
            case 'completed':
                promises.push(
                    this.checkApi.findCompletedAudits(this.pager).then((result: PagedEntities) => {
                        this.items = result.getEntities();
                        this.pager = result.getPager();
                    })
                );
                break;
            case 'all_complete':
                this.pager.sortBy = this.stateService.params['sortBy'] || 'meta.date_of_document';
                this.dateFilter = DateFilter.fromParams(this.stateService.params, 'past_30_days');
                promises.push(
                    this.loadAllCompletedData().then((result: PagedEntities) => {
                        if (result && result.getEntities().length > 0) {
                            this.pager = result.getPager();
                            this.items = result.getEntities();
                        }
                    })
                );
                break;
            case 'admin_assigned':
                this.pager.sortBy = this.stateService.params['sortBy'] || 'start_by';

                promises.push(
                    this.checkApi.findUpcomingAudits(this.pager).then((result: PagedEntities) => {
                        this.items = result.getEntities();
                        this.pager = result.getPager();
                    })
                );
                break;
            case 'deleted':
                promises.push(
                    this.checkApi.findTrashedAudits(this.pager).then((result: PagedEntities) => {
                        this.items = result.getEntities();
                        this.pager = result.getPager();
                    })
                );
                break;
            default:
                break;
        }

        this.q
            .all(promises)
            .catch(() => {
                this.hasError = true;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    /**
     * Delete the selected audit from incompletes
     *
     * @param {IAudit} audit
     */
    public deleteMissedAudit(audit: IAudit): void {
        this.translate([
            'AUDITS.MESSAGES.CHECK_CONFIRM_DELETE',
            'AUDITS.MESSAGES.CHECK_CONFIRM_REMOVE_DESC',
            'GENERAL.CANCEL',
            'GENERAL.DELETE',
        ]).then((translations) => {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: translations['AUDITS.MESSAGES.CHECK_CONFIRM_DELETE'],
                            description: translations['AUDITS.MESSAGES.CHECK_CONFIRM_REMOVE_DESC'],
                            confirmText: translations['GENERAL.DELETE'],
                            cancelText: translations['GENERAL.CANCEL'],
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        this.auditService
                            .deleteMissedAudit(audit.uuid)
                            .then((results) => {
                                _.remove(this.items, { uuid: audit.uuid });
                                this.toaster.info('AUDITS.MESSAGES.CHECK_DELETED');
                            })
                            .catch(() => {
                                this.toaster.warn('AUDITS.ERRORS.UNABLE_TO_DELETE');
                            });
                    }
                });
        });
    }

    /**
     * Checks if the audit has been deleted
     *
     * @param {ReportRecord} audit
     * @returns {number}
     */
    public isAuditDeleted(audit: ReportRecord): boolean {
        let auditUuid: any = _.find(audit.columns, (o) => {
            return o.label === 'uuid';
        }).value;
        return this.deletedRecordUuids.indexOf(auditUuid) >= 0;
    }

    /**
     *
     * @param {IAudit} audit
     */
    public deleteAudit(audit: IAudit): void {
        this.translate([
            'AUDITS.MESSAGES.DELETE_RECORD',
            'AUDITS.MESSAGES.CHECK_CONFIRM_DELETE_DESC',
            'GENERAL.CANCEL',
            'GENERAL.DELETE_RECORD',
        ]).then((translations) => {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: translations['AUDITS.MESSAGES.DELETE_RECORD'],
                            description: translations['AUDITS.MESSAGES.CONFIRM_DELETE_DESC'],
                            confirmText: translations['AUDITS.MESSAGES.DELETE_RECORD'],
                            cancelText: translations['GENERAL.CANCEL'],
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        this.auditService
                            .deleteAudit(audit.uuid)
                            .then((results) => {
                                _.remove(this.items, { uuid: audit.uuid });
                                this.toaster.info('AUDITS.MESSAGES.CHECK_DELETED');
                            })
                            .catch(() => {
                                this.toaster.warn('AUDITS.ERRORS.UNABLE_TO_DELETE');
                            });
                    }
                });
        });
    }

    public deleteUpcomingAudit(audit: IAudit): void {
        this.translate([
            'AUDITS.MESSAGES.DELETE_IN_PROGRESS_CHECK_TITLE',
            'AUDITS.MESSAGES.DELETE_ASSIGNED_CHECK',
            'GENERAL.CANCEL',
            'GENERAL.DELETE',
        ]).then((translations) => {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: translations['AUDITS.MESSAGES.DELETE_IN_PROGRESS_CHECK_TITLE'],
                            description: translations['AUDITS.MESSAGES.DELETE_ASSIGNED_CHECK'],
                            confirmText: translations['GENERAL.DELETE'],
                            cancelText: translations['GENERAL.CANCEL'],
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        this.auditService
                            .deleteUpcomingAudit(audit.uuid)
                            .then((results) => {
                                _.remove(this.items, { uuid: audit.uuid });
                                this.loadCounts();
                                this.toaster.info('AUDITS.MESSAGES.CHECK_DELETED');
                            })
                            .catch(() => {
                                this.toaster.warn('AUDITS.ERRORS.UNABLE_TO_DELETE');
                            });
                    }
                });
        });
    }

    public restoreAudit(audit: IAudit): void {
        this.checkApi.restoreAuditByUuid(audit.uuid).then((res) => {
            _.remove(this.items, { uuid: audit.uuid });
            this.toaster.info('AUDITS.MESSAGES.RESTORE_DELETED_CHECK');
            this.loadCounts();
        });
    }

    /**
     *
     * @param {IInProgressListingItem} audit
     */
    public deleteInProgressAudit(audit: IInProgressListingItem): void {
        this.translate([
            'AUDITS.MESSAGES.DELETE_IN_PROGRESS_CHECK_TITLE',
            'AUDITS.MESSAGES.DELETE_IN_PROGRESS_CHECK_DESC',
            'GENERAL.CANCEL',
            'GENERAL.DELETE',
            'GENERAL.CHECKLIST',
            'GENERAL.CHECKED_BY',
            'GENERAL.LOCATION',
        ]).then((translations) => {
            let additionalDescription: string =
                translations['GENERAL.CHECKLIST'] +
                ': ' +
                audit.checklistName +
                ' -- ' +
                translations['GENERAL.CHECKED_BY'] +
                ': ' +
                audit.accessAuditedByName +
                ' -- ' +
                translations['GENERAL.LOCATION'] +
                ': ' +
                audit.hierarchyName;

            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: translations['AUDITS.MESSAGES.DELETE_IN_PROGRESS_CHECK_TITLE'],
                            description: translations['AUDITS.MESSAGES.DELETE_IN_PROGRESS_CHECK_DESC'],
                            additionalDescription: additionalDescription,
                            confirmText: translations['GENERAL.DELETE'],
                            cancelText: translations['GENERAL.CANCEL'],
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        this.auditService
                            .deleteAudit(audit.uuid, true)
                            .then((results) => {
                                _.remove(this.items, { uuid: audit.uuid });
                                this.toaster.info('AUDITS.MESSAGES.DELETED_IN_PROGRESS_CHECK');
                            })
                            .catch(() => {
                                this.toaster.warn('AUDITS.ERRORS.UNABLE_TO_DELETE_CHECK');
                            });

                        this.loadCounts();
                    }
                });
        });
    }

    /**
     * Deletes a completed audit
     *
     * @param {ReportRecord} audit
     */
    public deleteCompletedAudit(audit: ReportRecord): void {
        let auditUuid: any = _.find(audit.columns, (o) => {
            return o.label === 'uuid';
        }).value;

        this.translate([
            'AUDITS.MESSAGES.DELETE_RECORD',
            'AUDITS.MESSAGES.CONFIRM_DELETE_DESC',
            'GENERAL.CANCEL',
            'GENERAL.DELETE_RECORD',
        ]).then((translations) => {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: translations['AUDITS.MESSAGES.DELETE_RECORD'],
                            description: translations['AUDITS.MESSAGES.CONFIRM_DELETE_DESC'],
                            confirmText: translations['AUDITS.MESSAGES.DELETE_RECORD'],
                            cancelText: translations['GENERAL.CANCEL'],
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        this.auditService
                            .deleteAudit(auditUuid, true)
                            .then(() => {
                                this.toaster.info('AUDITS.MESSAGES.CHECK_DELETED');
                                this.deletedRecordUuids.push(auditUuid);
                            })
                            .catch(() => {
                                this.toaster.warn('AUDITS.ERRORS.UNABLE_TO_DELETE');
                            })
                            .finally(() => {
                                this.loadAllCompletedData().then((response) => {
                                    this.pager = response.getPager();
                                    this.items = response.getEntities();
                                });
                            });
                    }
                });
        });
    }

    /**
     * Sets the currently active tab we are using
     *
     * @param tab
     */
    public switchTab(state: string): void {
        this.stateService.go(state);
    }

    /**
     * Indicates if we are online
     *
     * @returns {boolean}
     */
    public isOnline(): boolean {
        return this.pageService.isOnline();
    }

    /**
     * Takes the user to the audit approve or review screen
     *
     * @param audit
     */
    public review(audit: IAudit | IColumnResults): void {
        if ('columns' in audit && this.currentTab === 'all_complete') {
            this.stateService.go('home.audits.all_complete', { auditUuid: audit.columns[0].value });
        } else if ('uuid' in audit) {
            if (this.currentTab === 'reviewable') {
                this.stateService.go('home.audits.review', { auditUuid: audit.uuid });
            } else if (this.currentTab === 'completed') {
                this.stateService.go('home.audits.completed', { auditUuid: audit.uuid });
            } else if (this.currentTab === 'approval') {
                this.stateService.go('home.audits.approve', { auditUuid: audit.uuid });
            }
        }
    }

    /**
     * Returns current tab string
     * @returns {string}
     */
    public getTabString(): string {
        switch (this.currentTab) {
            case 'approval':
                return 'AUDITS.APPROVAL';
            case 'in_progress':
                return 'AUDITS.IN_PROGRESS';
            case 'reviewable':
                return 'AUDITS.REVIEWABLE';
            case 'incomplete':
                return 'AUDITS.INCOMPLETE';
            case 'admin_assigned':
                return 'AUDITS.ADMIN_ASSIGNED';
            case 'completed':
                return 'AUDITS.APPROVAL_COMPLETE';
            default:
                return '';
        }
    }

    /**
     * Allow a user to toggle the sort order of a column
     *
     * @param field
     */
    public toggleSort(field: string): void {
        if (!this.pager) {
            return;
        }
        if (this.pager.isCurrentSort(field)) {
            this.pager.toggleSortOrder();
        }
        this.pager.sortBy = field;
        this.goToSelf(this.pager);
    }

    /**
     * Pass converted dot notation for iql to togglesort
     *
     * @param field
     */
    public iqlToggleSort(field: string): void {
        this.toggleSort(this.convertToDotNotation(field));
    }

    /**
     * Convert arrow notation to dot notation for iql query
     *
     * @param field
     */
    public convertToDotNotation(field: string): string {
        return field.replace('->', '.');
    }

    public getLinkIcon(link: string): string {
        switch (link) {
            case 'approval':
                return 'assignment';
            case 'in_progress':
                return 'autorenew';
            case 'admin_assigned':
                return 'assignment_ind';
            case 'reviewable':
                return 'remove_red_eye';
            case 'approval_completed':
                return 'assignment_turned_in';
            case 'complete':
                return 'check';
            case 'incomplete':
                return 'close';
            case 'deleted':
                return 'delete';
            default:
                return 'assignment';
        }
    }

    /**
     * Determine if the current pager.sortBy is a column in IQL
     *
     */
    public isIqlSortable(): boolean {
        if (this.pager) {
            for (let sortCheck in this.iqlColumns) {
                if (this.convertToDotNotation(this.iqlColumns[sortCheck].valuePath) === this.pager.sortBy) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Allow a user to go to the next page of submissions
     */
    public next(): void {
        if (this.pager.canGoNext()) {
            this.pager.currentPage++;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Allow a user to go to the previous page of submissions
     */
    public prev(): void {
        if (this.pager.canGoPrevious()) {
            this.pager.currentPage--;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Handle change of all complete date filter
     *
     */
    public handleAllCompleteDateChange(): void {
        if (this.dateFilter && this.dateFilter.type !== 'custom') {
            this.stateService.go(this.stateService.current.name, {
                dateFilter: this.dateFilter.type,
                to: null,
                from: null,
                sortBy: this.pager ? this.pager.sortBy : undefined,
                order: this.pager ? this.pager.order : undefined,
            });
        }
    }

    /**
     * Handle incomplete date change
     *
     */
    public handleIncompleteDateChange(): void {
        this.stateService.go(this.stateService.current.name, {
            dateFilter: this.dateFilter.type,
            sortBy: this.pager ? this.pager.sortBy : undefined,
            order: this.pager ? this.pager.order : undefined,
        });
    }

    public isValidAllCompleteDateRange(): boolean {
        return (
            this.dateFilter &&
            this.dateFilter.fromDate &&
            this.dateFilter.toDate &&
            this.dateFilter.fromDate <= this.dateFilter.toDate
        );
    }

    public handleAllCompleteCustomDateChange(): void {
        this.stateService.go(this.stateService.current.name, {
            dateFilter: this.dateFilter.type,
            to: this.dateFilter.toDate,
            from: this.dateFilter.fromDate,
            sortBy: this.pager ? this.pager.sortBy : undefined,
            order: this.pager ? this.pager.order : undefined,
        });
    }

    public resetAllCompleteDates(): void {
        if (this.stateService.params['dateFilter'] === 'custom') {
            this.stateService.go(
                this.stateService.current.name,
                {
                    dateFilter: 'past_30_days',
                    to: null,
                    from: null,
                },
                {
                    reload: true,
                }
            );
        } else {
            this.dateFilter = DateFilter.fromParams(this.stateService.params, 'past_30_days');
        }
    }

    /**
     * Returns full date and time in user's local time
     *
     * @param {string} date
     * @param {string} format
     * @return {string}
     */
    public getDateTime(date: string, format?: string): string {
        return this.userLocalTimeService.fromUtc(date, format);
    }

    public editUpcoming(audit: IUpcomingListingItem, event: any): void {
        this.dialog.show(
            EditUpcomingAuditModal.instantiate({
                locals: {
                    upcomingAudit: audit,
                },
            })
        );
    }

    /**
     * @param pager
     */
    private goToSelf(pager: IPager): void {
        let params: any = angular.copy(this.stateService.params);
        params['page'] = pager.currentPage;
        params['sortBy'] = pager.sortBy;
        params['order'] = pager.order;
        this.stateService.go(this.stateService.current.name, params);
        this.load();
    }

    /**
     * Calculate our current from/to/interval based on the selected range.
     *
     * @param range
     * @returns {any}
     */
    private calculate(range: string): string {
        switch (range) {
            case 'older_than_7_days':
                return moment.utc().subtract(7, 'days').format('YYYY-MM-DD 23:59:59');
            case 'older_than_30_days':
                return moment.utc().subtract(30, 'days').format('YYYY-MM-DD 23:59:59');
            case 'older_than_60_days':
                return moment.utc().subtract(60, 'days').format('YYYY-MM-DD 23:59:59');
            default:
                return '';
        }
    }

    /**
     * Perform IQL Query to return all completed results
     *
     */
    private loadAllCompletedData(): ng.IPromise<any> {
        let iql: IIql = this.buildIql();

        let pager: IPager = Pager.make(
            (this.pager && this.pager.currentPage) || 1,
            (this.pager && this.isIqlSortable() && this.convertToDotNotation(this.pager.sortBy)) ||
                'meta.date_of_document',
            (this.pager && this.pager.order) || 'desc',
            25
        );

        return this.iqlApi.query(iql, pager);
    }

    /**
     * Build IQL
     *
     */
    private buildIql(): IIql {
        let iql: IIql = new Iql();

        let dateOfDocument: IBaseColumnFormatter = new DatetimeFormatter();
        dateOfDocument.options = {
            dateFormat: 'Y-m-d',
            timeFormat: 'H:i',
        };

        let scoreOfDocument: IBaseColumnFormatter = new NumberFormatter();
        scoreOfDocument.options = {
            precision: 2,
        };

        let hierarchyGroup: IFilterGroup = new FilterGroup(Iql.OR, []);

        for (let node in this.accessService.getToken().getUser().nodes) {
            if (node) {
                hierarchyGroup.filters.push(
                    new Filter(
                        'meta.hierarchy->uuid',
                        Iql.EQUAL,
                        this.accessService.getToken().getUser().nodes[node],
                        Iql.HIERARCHY
                    )
                );
            }
        }

        iql.filter(hierarchyGroup);

        let filters: Array<Filter> = [new Filter('meta->product', Iql.EQUAL, 'check', Iql.STRING)];

        if (this.dateFilter.type === 'custom') {
            filters.push(
                new Filter(
                    'meta->date_of_document',
                    Iql.GREATER_THAN_EQUAL,
                    moment(this.dateFilter.fromDate).utc().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                    Iql.DATE
                )
            );
            filters.push(
                new Filter(
                    'meta->date_of_document',
                    Iql.LESS_THAN_EQUAL,
                    moment(this.dateFilter.toDate).utc().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                    Iql.DATE
                )
            );
        } else {
            filters.push(new Filter('meta->date_of_document', Iql.EQUAL, this.dateFilter.type, Iql.DATE));
        }

        iql.filter(new FilterGroup(Iql.AND, filters))
            .select([new Column('meta->document_id', 'uuid', null, 'string')])
            .select([
                new Column(
                    'meta->date_of_document',
                    this.translate.instant('AUDITS.LISTING.DOCUMENT_DATE'),
                    null,
                    'datetime',
                    dateOfDocument
                ),
            ])
            .select([
                new Column(
                    'meta.reference->auditor_name',
                    this.translate.instant('GENERAL.CHECKED_BY'),
                    null,
                    'string'
                ),
            ])
            .select([
                new Column(
                    'meta.reference->outcome_label',
                    this.translate.instant('AUDITS.LISTING.OUTCOME'),
                    null,
                    'string'
                ),
            ])
            .select([
                new Column(
                    'meta.reference->program_name',
                    this.translate.instant('AUDITS.LISTING.PROGRAM_NAME'),
                    null,
                    'string'
                ),
            ])
            .select([
                new Column(
                    'meta.hierarchy->name',
                    this.translate.instant('AUDITS.LISTING.HIERARCHY_NAME'),
                    null,
                    'string'
                ),
            ])
            .select([
                new Column(
                    'meta.score->percentage',
                    this.translate.instant('AUDITS.LISTING.SCORE_PERCENTAGE'),
                    null,
                    'number',
                    scoreOfDocument
                ),
            ]);

        this.iqlColumns = iql.getQuery().columns;
        return iql;
    }
}
