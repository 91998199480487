import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ItemInput } from '../../../../../../domain/checklists/ItemInput';

@Component('its.check.module.checklists', CriticalSetting.CID, {
    template: require('/app/modules/checklists/components/items/shared/critical-setting/CriticalSetting.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class CriticalSetting {
    static IID: string = 'CriticalSetting';
    static CID: string = 'itcCriticalSetting';
    static $inject: string[] = [];

    public item: ItemInput;

    public handleClickAutoFollowupCriticalLabel(): boolean {
        if (this.item.critical) {
            return !this.item.settings.autoFollowupCritical;
        }

        return false;
    }
}
