/// <reference path="tsd.d.ts" />
var tinycolor = require('tinycolor2'); // tslint:disable-line

if (window) {
    window['tinycolor'] = tinycolor;
}
globalThis['tinycolor'] = tinycolor;

import config from './config';
import * as angular from 'angular';

import 'angular-animate';
import 'angular-aria';
import 'angular-messages';
import 'angular-moment';
import 'angular-cookies';
import 'angular-drag-and-drop-lists';
import 'angular-froala';
import 'angular-jwt';
import 'angular-material';
import 'angular-sanitize';
import 'angular-signature';
import 'angular-translate';
import 'angular-ui-router';
import 'md-color-picker';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { SentryHandlerV2 } from '@intouch/its.essential/app/essential/services/logger/SentryHandlerV2';
import * as Sentry from '@sentry/browser';

import '@intouch/its.essential/app/essential/http/_module';
import '@intouch/its.essential/app/essential/panels/_module';
import '@intouch/its.essential/app/essential/components/_module';
import '@intouch/its.essential/app/essential/services/_module';
import '@intouch/its.essential/app/essential/filters/_module';
import '@intouch/its.essential/app/essential/directives/_module';
import '@intouch/its.essential/app/essential/api/_module';

import '@intouch/its.check.essential/app/check-essential/components/_module';
import '@intouch/its.check.essential/app/check-essential/api/_module';
import '@intouch/its.check.essential/app/check-essential/directives/_module';

import './filters/_module';

import './api/_module';
import './services/_module';
import './modules/main/_module';
import './modules/outcomes/_module';
import './modules/checklists/_module';
import './modules/audits/_module';
import './components/_module';

import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ILogger, Logger } from '@intouch/its.essential/app/essential/services/Logger';
import { IStorage } from '@intouch/its.essential/app/essential/services/LocalStorage';
import { IHttpClient } from '@intouch/its.essential/app/essential/http/HttpClient';
import { ISessionService } from '@intouch/its.essential/app/essential/services/SessionService';
import { ICheckSession } from './services/CheckSession';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ICheckApi } from './api/CheckApi';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';
import { ICheckEssentialApi } from '@intouch/its.check.essential/app/check-essential/api/CheckEssentialApi';
import { IConnectivity } from '@intouch/its.essential/app/essential/services/Connectivity';
import { IFileProcessingService } from '@intouch/its.essential/app/essential/services/FileProcessingService';
import { IIntelligenceApi } from '@intouch/its.check.essential/app/check-essential/api/IntelligenceApi';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { ITemplateLibraryApi } from '@intouch/its.essential/app/essential/api/TemplateLibraryApi';
import { INavigationService } from './services/NavigationService';
import { IGoogleTagManager } from '@intouch/its.essential/app/essential/services/GoogleTagManager';
import { IProgramsApi } from '@intouch/its.essential/app/essential/api/ProgramsApi';

import { DataDogHandler } from '@intouch/its.essential/app/essential/services/logger/DataDogHandler';
import { DataDogRumUtils } from '@intouch/its.essential/app/essential/utils/DataDogRumUtils';
import { DataDogLogUtils } from '@intouch/its.essential/app/essential/utils/DataDogLogUtils';
import { IFeatureFlagService } from '@intouch/its.essential/app/essential/services/FeatureFlagService';
import { SecureFileServiceApi } from '@intouch/its.check.essential/app/check-essential/api/SecureFileServiceApi';

// moment language requirements. Add to this list for each language we support (eng is default and doen't need including)
let momentFr = require('moment/locale/fr'); // tslint:disable-line

let intouchCheckModules: Array<string> = [
    'its.check.module.main',
    'its.check.module.checklists',
    'its.check.module.audits',
    'its.check.services',
    'its.check.api',
    'its.check.module.outcomes',
    'its.check.filters',
    'its.check.components',
    'its.check.essential.components',
    'its.check.essential.api',
    'its.check.essential.directives',
];

// todo: these might be moved to app.js in the essential repo as well as the module binding
let intouchEssentialModules: Array<string> = [
    'its.essential.http',
    'its.essential.panels',
    'its.essential.components',
    'its.essential.filters',
    'its.essential.directives',
    'its.essential.services',
    'its.essential.api',
];

let modulesToLoad: Array<string> = intouchEssentialModules.concat(intouchCheckModules),
    thirdPartyModules: Array<string> = [
        'ngMaterial',
        'ngMessages',
        'ngCookies',
        'ngSanitize',
        'ui.router',
        'pascalprecht.translate',
        'mdColorPicker',
        'angularMoment',
        'dndLists',
        'translations',
        'angular-jwt',
        'signature',
        'froala',
        'ngSanitize',
    ];

// set up Sentry logging
let sentryInitialized: boolean = false;
if (config && config.sentry && config.sentry.dsn && config.sentry.release) {
    console.debug('Configuring Sentry');
    Sentry.init({
        dsn: config.sentry.dsn,
        release: config.sentry.release,
        integrations: [],
        environment: config.sentry.environment,
        attachStacktrace: true,
    });

    sentryInitialized = true;
} else {
    console.debug('Sentry config missing, skipping module setup.');
}

let check: any = angular.module('IntouchCheck', thirdPartyModules.concat(modulesToLoad));

/**
 * Configure the application
 */
check.config([
    '$translateProvider',
    '$mdInkRippleProvider',
    '$provide',
    (translateProvider, inkRippleProvider, provide) => {
        inkRippleProvider.disableInkRipple();
        translateProvider.useSanitizeValueStrategy('escapeParameters');
        translateProvider.registerAvailableLanguageKeys(['en', 'fr_CA', 'es_US'], {
            en_US: 'en',
            fr: 'fr_CA',
        });

        translateProvider.fallbackLanguage('en');

        // decorate $mdDialog to support multiple dialogs by default. Need this for color picker in modal
        provide.decorator('$mdDialog', ($delegate) => {
            let originalShow: Function = $delegate.show;

            // this function adds the multiple property to arguments if necessary then returns the original show method
            // this makes mdDialog backwards compatible with the skipHide property used by md-color-picker
            function decoratedShow(): any {
                let args: any = angular.extend({}, arguments[0], {
                    multiple: arguments[0]['multiple'] || arguments[0]['skipHide'],
                });

                return originalShow(args);
            }
            $delegate.show = decoratedShow;
            return $delegate;
        });
    },
]);

/**
 * Critical service bootstrap (we do this first so that any services built during bootstrap will have these configured when injected). Any service
 * which has constructor or configuration that is to be used when creating other services will need to be here -- this should be a very rare need case.
 */
check.run([
    'iteLogger',
    'APPCONFIG',
    function (logger: ILogger, appConfig: any): any {
        logger.configure(appConfig.logger);
    },
]);

/**
 * Our application boostrap (configure any services we need to)
 */
check.run([
    '$rootScope',
    'iteLogger',
    'iteLocalStorage',
    'iteToaster',
    'iteHttpClient',
    'itcCheckApi',
    'amMoment',
    'iteSessionService',
    'itcCheckSession',
    'APPCONFIG',
    '$translate',
    'iteAccessService',
    'iteAccessApi',
    'itcCheckEssentialApi',
    'iteConnectivity',
    'iteFileProcessingService',
    'itcIntelligenceApi',
    'iteIqlApi',
    'iteTemplateLibraryApi',
    'itcNavigationService',
    '$mdDialog',
    'iteGoogleTagManager',
    'iteProgramsApi',
    'itcSecureFileServiceApi',
    'iteFeatureFlagService',
    function (
        $rootScope: ng.IRootScopeService,
        logger: ILogger,
        storage: IStorage,
        toaster: IToaster,
        httpClient: IHttpClient,
        checkApi: ICheckApi,
        amMoment: any,
        session: ISessionService,
        checkSession: ICheckSession,
        appConfig: any,
        translate: angular.translate.ITranslateService,
        accessService: IAccessService,
        accessApi: IAccessApi,
        checkEssentialApi: ICheckEssentialApi,
        connectivityService: IConnectivity,
        fileProcessingService: IFileProcessingService,
        intelligenceApi: IIntelligenceApi,
        iqlApi: IIqlApi,
        templateLibraryApi: ITemplateLibraryApi,
        navigationService: INavigationService,
        mdDialog: ng.material.IDialogService,
        googleTagManager: IGoogleTagManager,
        programsApi: IProgramsApi,
        secureFileServiceApi: SecureFileServiceApi,
        featureFlagService: IFeatureFlagService
    ): any {
        if (appConfig.datadog && appConfig.datadog.clientToken) {
            DataDogLogUtils.init(datadogLogs, appConfig.datadog);
            DataDogRumUtils.init(datadogRum, appConfig.datadog);

            logger.pushHandler(new DataDogHandler(appConfig, Logger.ERROR));
            logger.debug('Loading datadog');
        } else {
            logger.debug('Skipping datadog load');
        }

        logger.debug('Starting the IntouchCheck admin application');

        // determine our default language (fallback to en)
        let language: string = checkSession.getLanguage();
        translate.use(language ? language : 'en');

        if (sentryInitialized) {
            logger.debug('Adding Sentry Handler');
            logger.pushHandler(new SentryHandlerV2(appConfig, Logger.ERROR));
            logger.debug('Sentry Handler V2 added');
        }

        // configure the services
        toaster.configure(appConfig.toaster);
        accessService.configure(appConfig.access);
        intelligenceApi.configure(appConfig.products.apis.iq);
        checkApi.configure(appConfig.api);
        checkEssentialApi.configure(appConfig.api);
        accessApi.configure(appConfig.access);
        amMoment.changeLocale(appConfig.amMoment.locale);
        storage.configure(appConfig.storage);
        session.configure(appConfig.session);
        checkSession.configure(appConfig.checkSession);
        iqlApi.configure(appConfig.products.apis);
        templateLibraryApi.configure(appConfig.products.apis);
        fileProcessingService.configure({
            apiUrl: appConfig.api.endpoint + '/' + appConfig.api.version + '/',
        });
        navigationService.configure(null);
        googleTagManager.configure(appConfig.gtm);
        programsApi.configure(appConfig.products.apis);

        connectivityService.setInternetConnectivity(true);
        connectivityService.setNetworkConnectivity(true);
        secureFileServiceApi.configure(appConfig.secureFileApi);

        featureFlagService.register({
            flag: 'disable_indexed_db',
            name: 'Disabled IndexedDb',
            description: 'Disabled IndexedDB for testing local storage fallback',
        });

        featureFlagService.initalize();

        $rootScope.$on('$stateChangeStart', () => {
            mdDialog.cancel();
        });
    },
]);
