import { IItemInput, ItemInput } from './ItemInput';
import { IWithAdditionalRequirements } from './WithAdditionalRequirements';
import { IItemAdditionalRequirements, ItemAdditionalRequirements } from './ItemAdditionalRequirements';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';

/**
 * The IItemCheck interface
 */
export interface IItemCheck extends IItemInput, IWithAdditionalRequirements {}

/**
 * the ItemCheck class
 *
 */
export class ItemCheck extends ItemInput implements IItemCheck {
    public type: string = 'check';
    public additionalRequirements: IItemAdditionalRequirements = null;

    /**
     * Make a generic instance of this object
     *
     * @param name
     * @returns {IItemCheck}
     */
    public static make(name: string): IItemCheck {
        let me: IItemCheck = new ItemCheck();
        me.name = name;

        return me;
    }

    public setScored(isScored: boolean): void {
        this.points = isScored ? 1 : null;
    }

    /**
     * Returns if object is allowed as a logic subject
     *
     */
    public allowAsLogicSubject(): boolean {
        return true;
    }

    /**
     * Build the object from json
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @return {IItemCheck}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemCheck {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['additional_requirements']) {
            this.additionalRequirements = EntityBuilder.buildOne<IItemAdditionalRequirements>(
                ItemAdditionalRequirements,
                jsonObject['additional_requirements'],
                convertToCamel
            );
        } else if (jsonObject['photo_required']) {
            this.additionalRequirements = new ItemAdditionalRequirements().fromJson(
                {
                    type: 'photo',
                    condition: 'always',
                },
                true
            );
            delete (<any>this).photoRequired;
        }

        return this;
    }
}
