import { IEntity, Entity } from '@intouch/its.essential/app/essential/domain/Entity';
import { IUuidDisplayNamePair } from '@intouch/its.check.essential/app/check-essential/domain/datatypes/UuidDisplayNamePair';
import { INameUuidPair } from '@intouch/its.essential/app/essential/utils/datatypes/NameUuidPair';
import { IUuidNamePair } from '@intouch/its.check.essential/app/check-essential/domain/datatypes/UuidNamePair';

export interface IAssignment extends IEntity {
    checklistUuid: string;
    locations: INameUuidPair[];
    auditedBy: IUuidDisplayNamePair;
    startBy: string;
    completeBy: string;
    notes: string;
    notificationGroup: Array<IUuidNamePair>;
}

export class Assignment extends Entity implements IAssignment {
    public checklistUuid: string = null;
    public locations: INameUuidPair[] = [];
    public auditedBy: IUuidDisplayNamePair = null;
    public startBy: string = null;
    public completeBy: string = null;
    public notes: string = null;
    public notificationGroup: Array<IUuidNamePair> = [];

    /**
     * Builds an instance of Assignment
     *
     * @param {any} properties
     * @return {IAssignment}
     */
    public static make(properties: {
        checklistUuid: string;
        locations: INameUuidPair[];
        auditedBy: IUuidDisplayNamePair;
        startBy?: string;
        completeBy?: string;
        notes?: string;
        notificationGroup?: Array<IUuidNamePair>;
    }): IAssignment {
        let assignment: IAssignment = new Assignment();

        assignment.checklistUuid = properties.checklistUuid;
        assignment.locations = properties.locations;
        assignment.auditedBy = properties.auditedBy;
        assignment.startBy = properties.startBy;
        assignment.completeBy = properties.completeBy;
        assignment.notes = properties.notes || null;
        assignment.notificationGroup = properties.notificationGroup || null;

        return assignment;
    }
}
