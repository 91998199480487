import { ItemModalOptions } from './ItemBaseModal';
import { ItemInputModal } from './ItemInputModal';
import View from './ItemMoveModal.html';

export class ItemMoveModal extends ItemInputModal {
    public static instantiate(config?: ng.material.IDialogOptions, options?: ItemModalOptions): ItemMoveModal {
        config = config || {};
        config.template = View;
        config.controller = ItemMoveModal;

        return super.instantiate(config, options);
    }
}
