import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IAuditCreateProperties } from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseAuditBuilder';
import { ICheckSession } from './CheckSession';
import { IAudit, Audit } from '../domain/audits/Audit';
import { ICheckApi } from '../api/CheckApi';
import { AuditBuilder } from '../domain/audits/AuditBuilder';
import { ItemResultBuilder } from '../domain/audits/ItemResultBuilder';

export interface IAuditProviderService {
    getOnlineAuditFromUuid(uuid: string): ng.IPromise<IAudit>;
}

/**
 * The AuditProviderService class - gets or builds a complete audit/subaudit/checklist construct from just an auditUuid
 *
 */
@Service('its.check.services', AuditProviderService.IID, AuditProviderService)
class AuditProviderService implements IAuditProviderService {
    static IID: string = 'itcAuditProviderService';
    static $inject: Array<string> = ['itcCheckSession', 'itcCheckApi', '$q'];

    /**
     * Instantiates the service
     *
     * @param {ICheckSession} session
     * @param {ICheckApi} checkApi
     * @param {angular.IQService} q
     */
    public constructor(private session: ICheckSession, private checkApi: ICheckApi, private q: ng.IQService) {}

    /**
     * Gets an existing audit from the api
     *
     * @param {string} uuid
     * @return {angular.IPromise<IAudit>}
     */
    public getOnlineAuditFromUuid(uuid: string): ng.IPromise<IAudit> {
        return this.checkApi.findAuditByUuid(uuid).then((result: IAudit) => {
            let sourceAudit: IAudit = EntityBuilder.buildOne<IAudit>(Audit, result, true);

            let properties: IAuditCreateProperties = {
                checklist: result.checklist,
                sourceAudit: sourceAudit,
            };

            let audit: IAudit = <any>new AuditBuilder().create(Audit, properties, new ItemResultBuilder());

            return audit;
        });
    }
}
