import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItem } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItem';

@Component('its.check.module.checklists', OptionalSetting.CID, {
    template: require('/app/modules/checklists/components/items/shared/optional-setting/OptionalSetting.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class OptionalSetting {
    static IID: string = 'OptionalSetting';
    static CID: string = 'itcOptionalSetting';

    static $inject: Array<string> = [];
    public item: BaseItem;
}
