import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IItemInput } from './ItemInput';
import { ItemCheck, IItemCheck } from './ItemCheck';
import { ItemText, IItemText } from './ItemText';
import { ItemDisplayText, IItemDisplayText } from './ItemDisplayText';
import { ItemTime, IItemTime } from './ItemTime';
import { ItemNumber, IItemNumber } from './ItemNumber';
import { IItemMultipleChoice, ItemMultipleChoice } from './ItemMultipleChoice';
import { ItemCalculation } from './ItemCalculation';
import { IItemDate, ItemDate } from './ItemDate';
import { IItemDropdown, ItemDropdown } from './ItemDropdown';
import { IItemDisplayGraphic, ItemDisplayGraphic } from './ItemDisplayGraphic';
import { IItemTemperature, ItemTemperature } from './ItemTemperature';
import { ObjectUtils } from '@intouch/its.essential/app/essential/utils/ObjectUtils';

/**
 * A helper factory to dynamically creating new items/modals, etc based on the given type
 *
 */
export class ItemInputFactory {
    /**
     * Will dynamically create a new item type and return it
     *
     * @param type
     * @param name
     * @returns {IItemInput}
     */
    public static makeItem(type: string, name: string): IItemInput {
        switch (type) {
            case 'radio':
                return ItemMultipleChoice.make(name);
            case 'checkbox':
                return ItemMultipleChoice.make(name, true);
            case 'check':
                return ItemCheck.make(name);
            case 'number':
                return ItemNumber.make(name);
            case 'calculation':
                return <any>ItemCalculation.make(name);
            case 'text':
                return ItemText.make(name);
            case 'date':
                return ItemDate.make(name);
            case 'display_text':
                return ItemDisplayText.make(name);
            case 'dropdown':
                return ItemDropdown.make(name);
            case 'graphic':
                return ItemDisplayGraphic.make(name);
            case 'time':
                return ItemTime.make(name);
            case 'temperature':
                return ItemTemperature.make(name);
            default:
                return null;
        }
    }

    /**
     * Will use an entity builder to build an item with the given data
     *
     * @param data
     * @returns {IItemInput}
     */
    public static buildOne(data: any): IItemInput {
        let snakeData: any = ObjectUtils.camelToSnake(data);
        switch (snakeData['type']) {
            case 'multiplechoice':
            case 'radio':
            case 'checkbox':
                return EntityBuilder.buildOne<IItemMultipleChoice>(ItemMultipleChoice, snakeData, true);
            case 'check':
                return EntityBuilder.buildOne<IItemCheck>(ItemCheck, snakeData, true);
            case 'number':
                return EntityBuilder.buildOne<IItemNumber>(ItemNumber, snakeData, true);
            case 'calculation':
                return EntityBuilder.buildOne<any>(ItemCalculation, snakeData, true);
            case 'text':
                return EntityBuilder.buildOne<IItemText>(ItemText, snakeData, true);
            case 'date':
                return EntityBuilder.buildOne<IItemDate>(ItemDate, snakeData, true);
            case 'display_text':
                return EntityBuilder.buildOne<IItemDisplayText>(ItemDisplayText, snakeData, true);
            case 'time':
                return EntityBuilder.buildOne<IItemTime>(ItemTime, snakeData, true);
            case 'dropdown':
                return EntityBuilder.buildOne<IItemDropdown>(ItemDropdown, snakeData, true);
            case 'graphic':
                return EntityBuilder.buildOne<IItemDisplayGraphic>(ItemDisplayGraphic, snakeData, true);
            case 'temperature':
                return EntityBuilder.buildOne<IItemTemperature>(ItemTemperature, snakeData, true);
            default:
                throw new Error('Tried to build invalid item: ' + snakeData.type);
        }
    }

    /**
     * Builds an array of items based on type
     *
     * @param jsonArray
     */
    public static buildMany(jsonArray: Array<any>): Array<IItemInput> {
        let items: Array<IItemInput> = [];

        for (let item of jsonArray) {
            let builtItem: IItemInput = ItemInputFactory.buildOne(item);
            if (builtItem) {
                items.push(builtItem);
            }
        }

        return items;
    }
}
