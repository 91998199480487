export class TrialLimits {
    /**
     * Check if trial user is over the limit for allowable checklists
     *
     * @param isCheckTrial
     * @param checklistCount
     */
    public static checkTrialLimits(isCheckTrial: boolean, checklistCount: number): boolean {
        let allowedTrialChecklists: number = 10;
        return isCheckTrial && checklistCount >= allowedTrialChecklists;
    }
}
