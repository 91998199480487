import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IItemSection, ItemSection } from '../../domain/checklists/ItemSection';
import { ICheckApi } from '../../api/CheckApi';
import { IChecklist } from '../../domain/checklists/Checklist';
import { IPageService } from '../../services/PageService';

/**
 * Checklist Print Controller
 */
@Controller('its.check.module.checklists', ChecklistPrintController.IID, ChecklistPrintController)
export class ChecklistPrintController {
    static IID: string = 'ChecklistPrintController';
    static $inject: Array<string> = ['itcCheckApi', 'iteToaster', 'itcPageService', '$translate', '$state', '$timeout'];
    public checklist: IChecklist;
    public selectedSection: IItemSection;

    /**
     *
     * @param {ICheckApi} checkApi
     * @param {IToaster} toaster
     * @param {IPageService} pageService
     * @param {angular.translate.ITranslateService} translate
     * @param stateService
     * @param timeout
     */
    constructor(
        protected checkApi: ICheckApi,
        protected toaster: IToaster,
        private pageService: IPageService,
        private translate: angular.translate.ITranslateService,
        private stateService: any,
        private timeout: any
    ) {
        this.checkApi
            .findChecklist(this.stateService.params['uuid'])
            .then((checklist: IChecklist) => {
                this.checklist = checklist;

                if (!this.checklist.sections || this.checklist.sections.length === 0) {
                    this.checklist.sections = [];
                    this.checklist.sections.push(new ItemSection(this.translate.instant('CHECKLISTS.MAIN_SECTION')));
                }

                this.timeout(() => {
                    this.printChecklist();
                });
            })
            .catch(() => {
                this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_LOAD');
                this.stateService.go('home.checklists.edit', { uuid: this.stateService.params['uuid'] });
                return;
            })
            .finally(() => {
                this.pageService.showLoader(false);
            });
    }

    public close(): void {
        this.stateService.go('home.checklists.edit', { uuid: this.stateService.params['uuid'] });
    }

    /**
     * Returns an array of the number of sections in the checklist.
     *
     */
    public getSections(): Array<IItemSection> {
        return this.checklist.sections;
    }

    /**
     * Open Printing options
     *
     */
    public printChecklist(): void {
        window.print();
    }
}
