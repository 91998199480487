import { String } from '@intouch/its.essential/app/essential/utils/String';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IResponse, Response } from './Response';
import { IItemInput, ItemInput } from './ItemInput';
import * as _ from 'lodash';
import {
    IItemInputSettings,
    ItemInputSettings,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import {
    IBaseItemMultipleChoiceTranslation,
    BaseItemMultipleChoiceTranslation,
    IBaseItemMultipleChoiceResponseTranslation,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/translations/BaseItemMultipleChoiceTranslation';
import { IStringMap } from '@intouch/its.essential/app/essential/utils/datatypes/StringMap';
import { IWithAdditionalRequirements } from './WithAdditionalRequirements';
import { IItemAdditionalRequirements, ItemAdditionalRequirements } from './ItemAdditionalRequirements';
import {
    IMultiselectSelectionRequirements,
    MultiselectSelectionRequirements,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/MultiselectSelectionRequirements';

/**
 * The IItemMultipleChoiceSettings interface
 *
 */
export interface IItemMultipleChoiceSettings extends IItemInputSettings {
    responses: Array<IResponse>;
    multipleSelections: boolean;
    daysToResolveProblems: number;
    showResponses: boolean;
    selectionRequirements: IMultiselectSelectionRequirements;
    answerDisplayType: string;

    enableSelectionRequirements(): void;

    disableSelectionRequirements(): void;

    hasSelectionRequirements(): boolean;
}

/**
 * The ItemMultipleChoiceSettings class
 */
export class ItemMultipleChoiceSettings extends ItemInputSettings implements IItemMultipleChoiceSettings {
    public responses: Array<IResponse> = [];
    public multipleSelections: boolean = false;
    public daysToResolveProblems: number = null;
    public showResponses: boolean = false;
    public selectionRequirements: IMultiselectSelectionRequirements = null;
    public answerDisplayType: string = null;

    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemMultipleChoiceSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['responses']) {
            this.responses = EntityBuilder.buildMany<IResponse>(Response, jsonObject['responses'], convertToCamel);
        }

        if (jsonObject['selection_requirements']) {
            this.selectionRequirements = EntityBuilder.buildOne<IMultiselectSelectionRequirements>(
                MultiselectSelectionRequirements,
                jsonObject['selection_requirements'],
                convertToCamel
            );
        }

        return this;
    }

    /**
     * Enables selection requirements
     *
     */
    public enableSelectionRequirements(): void {
        this.selectionRequirements = new MultiselectSelectionRequirements();
    }

    /**
     * Disables selection requirements
     *
     */
    public disableSelectionRequirements(): void {
        this.selectionRequirements = null;
    }

    /**
     * Returns if item has selection requirements
     *
     */
    public hasSelectionRequirements(): boolean {
        return !!this.selectionRequirements;
    }
}

/**
 * The IItemMultipleChoice interface
 *
 */
export interface IItemMultipleChoice extends IItemInput, IWithAdditionalRequirements {
    settings: IItemMultipleChoiceSettings;
    translations: Array<IBaseItemMultipleChoiceTranslation>;

    addAnswer(response: IResponse): IResponse;

    removeAnswer(response: IResponse): void;

    setScored(isScored: boolean): void;

    isScored(): boolean;

    getResponse(uuid: string): IResponse | IBaseItemMultipleChoiceResponseTranslation;

    getTranslation(locale: string): IBaseItemMultipleChoiceTranslation;

    getResponseLabelsConcatenated(delimiter?: string): string;

    hasPoints(): boolean;
}

/**
 * The ItemMultipleChoice class
 */
export class ItemMultipleChoice extends ItemInput implements IItemMultipleChoice {
    public type: string = 'multiplechoice';
    public settings: IItemMultipleChoiceSettings = new ItemMultipleChoiceSettings();
    public translations: Array<IBaseItemMultipleChoiceTranslation> = [];
    public additionalRequirements: IItemAdditionalRequirements = null;

    /**
     * Will make a generic item
     *
     * @param name
     * @param multipleSelections
     * @returns {IItemMultipleChoice}
     */
    public static make(name: string, multipleSelections: boolean = false): IItemMultipleChoice {
        let me: IItemMultipleChoice = new ItemMultipleChoice();
        me.name = name;
        me.uuid = String.uuid();
        me.settings.allowCnaNa = false;
        me.settings.multipleSelections = multipleSelections;

        return me;
    }

    /**
     * Returns if object is allowed as a logic subject
     *
     */
    public allowAsLogicSubject(): boolean {
        return true;
    }

    /**
     * Will build itself from json
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {IItemMultipleChoice}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemMultipleChoice {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['settings']) {
            this.settings = EntityBuilder.buildOne<IItemMultipleChoiceSettings>(
                ItemMultipleChoiceSettings,
                jsonObject['settings'],
                convertToCamel
            );
        }

        if (jsonObject['additional_requirements']) {
            this.additionalRequirements = EntityBuilder.buildOne<IItemAdditionalRequirements>(
                ItemAdditionalRequirements,
                jsonObject['additional_requirements'],
                convertToCamel
            );
        } else if (jsonObject['photo_required']) {
            this.additionalRequirements = new ItemAdditionalRequirements().fromJson(
                {
                    type: 'photo',
                    condition: 'always',
                },
                true
            );
            delete (<any>this).photoRequired;
        }

        if (jsonObject['translations']) {
            this.translations = EntityBuilder.buildMany<IBaseItemMultipleChoiceTranslation>(
                BaseItemMultipleChoiceTranslation,
                jsonObject['translations'],
                convertToCamel
            );
        }

        return this;
    }

    /**
     * Returns a concatenated string of all response name comma separated
     */
    public getResponseLabelsConcatenated(delimiter: string = ', '): string {
        return this.settings.responses.map((response) => response.name).join(delimiter);
    }

    public hasPoints(): boolean {
        return NumberUtils.isNumber(this.points);
    }

    /**
     * Will add a new answer to the list of responses
     *
     * @param response
     * @param index
     * @returns {IResponse}
     */
    public addAnswer(response: IResponse, index: number = null): IResponse {
        if (!response || !(response instanceof Response) || ['response'].indexOf(response.type) === -1) {
            throw new Error('Invalid answer provided to add to this multiple choice item');
        }

        if (index === null) {
            this.settings.responses.push(response);
        } else {
            this.settings.responses.splice(index, 0, response);
        }

        return response;
    }

    /**
     * Will remove the given answer from the list of responses
     *
     * @param response
     */
    public removeAnswer(response: IResponse): void {
        let index: number = _.findIndex(this.settings.responses, { uuid: response.uuid });
        if (index >= 0) {
            this.settings.responses.splice(index, 1);
        }
    }

    /**
     * toggles default scored values of responses for scored or not scored
     *
     * @param {boolean} isScored
     */
    public setScored(isScored: boolean): void {
        if (this.settings.responses && this.settings.responses.length > 0) {
            for (let response of this.settings.responses) {
                response.points = isScored ? 0 : null;
            }
        }

        super.setScored(isScored);
    }

    /**
     * Returns true if any of the responses have a numerical value for points
     *
     * @return {boolean}
     */
    public isScored(): boolean {
        if (this.settings.responses && this.settings.responses.length > 0) {
            for (let response of this.settings.responses) {
                if (NumberUtils.isNumber(response.points)) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Returns the response matching the uuid
     *
     * @param {string} uuid
     * @param {string} locale
     * @return {IResponse | IBaseItemMultipleChoiceResponseTranslation}
     */
    public getResponse(uuid: string, locale?: string): IResponse | IBaseItemMultipleChoiceResponseTranslation {
        let response: IResponse | IBaseItemMultipleChoiceResponseTranslation;
        let translation: IBaseItemMultipleChoiceTranslation = this.getTranslation(locale);
        if (translation) {
            response = translation.getResponse(uuid);
        } else {
            response = _.find(this.settings.responses, (r: IResponse) => {
                return r.uuid === uuid;
            });
        }

        if (response) {
            return response;
        } else {
            return null;
        }
    }

    /**
     * Returns first of array of BaseItemMultipleChoiceTranslation that satisfy the locale
     *
     * @param locale
     */
    public getTranslation(locale: string): IBaseItemMultipleChoiceTranslation {
        let returnable: Array<IBaseItemMultipleChoiceTranslation> = this.translations.filter(
            (translation: IBaseItemMultipleChoiceTranslation) => {
                return translation.locale === locale;
            }
        );

        if (returnable.length > 0) {
            return returnable[0];
        } else {
            let newTranslation: IBaseItemMultipleChoiceTranslation = new BaseItemMultipleChoiceTranslation();
            newTranslation.locale = locale;
            this.translations.push(newTranslation);
            return newTranslation;
        }
    }

    /**
     * Creates a duplicate of this object with null name and a new uuid
     *
     * @param {boolean} preserveTranslations
     * @return {IBaseItem}
     */
    public duplicate(preserveTranslations?: boolean): IItemMultipleChoice {
        let item: IItemMultipleChoice = <IItemMultipleChoice>this.clone();
        let uuidMap: IStringMap = {};
        item.uuid = String.uuid();

        if (item.logic && item.logic.length > 0) {
            for (let logic of item.logic) {
                logic.targetUuid = item.uuid;
            }
        }

        // remap responses
        if (item.settings && item.settings.responses) {
            for (let response of item.settings.responses) {
                uuidMap[response.uuid] = String.uuid();
                response.uuid = uuidMap[response.uuid];
            }
        }

        // remap response translation uuids
        if (item.settings && item.settings.responses && item.translations) {
            for (let translation of item.translations) {
                if (translation.responses) {
                    for (let response of translation.responses) {
                        if (response['uuid']) {
                            response['uuid'] = uuidMap[response['uuid']];
                        }
                    }
                }
            }
        }

        if (!preserveTranslations && item.translations) {
            for (let translation of item.translations) {
                translation.name = null;
            }
        }

        return item;
    }

    /**
     * Return the type of this item
     *
     */
    public getType(): string {
        return this.settings && this.settings.multipleSelections ? 'checkbox' : 'radio';
    }
}
