import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IHierarchyNode } from '@intouch/its.essential/app/essential/domain/access/HierarchyNode';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';
import { ProductNavigationService } from '../services/ProductNavigationService';
import { IListingItem } from '../domain/checklists/ListingItem';
import { ICheckApi } from '../api/CheckApi';
import { IQueryFilter, QueryFilter } from '@intouch/its.essential/app/essential/domain/api/QueryFilter';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import View from './StartAuditModal.html';

export class StartAuditModal extends Modal {
    static $inject: Array<string> = ['$mdDialog', 'itePanelService', 'itcProductNavigationService', 'itcCheckApi'];

    public checklist: IListingItem;
    public location: IHierarchyNode = null;
    public validLocation: boolean = null;
    public validating: boolean = false;

    public static instantiate(config: any): any {
        config = config || { acl: null };
        config.template = View;
        config.controller = StartAuditModal;
        config.controllerAs = 'vm';
        return super.instantiate(config);
    }

    public constructor(
        private dialog: ng.material.IDialogService,
        private panelService: IPanelService,
        private productNavigationService: ProductNavigationService,
        private checkApi: ICheckApi
    ) {
        super();
    }

    public openLocations(event: any): void {
        let config: any = {
            relativeTo: event.target,
            multiSelect: false,
            onSelect: (location: IHierarchyNode) => {
                this.selectLocation(location);
            },
            attachQueryParams: { 'filter[status]': 'open', 'include[]': 'tags' },
        };

        this.panelService.openLocationPickerPanel(config);
    }

    public start(): void {
        if (!!this.location) {
            this.productNavigationService.startAudit(this.checklist.lastPublishedUuid, this.location.uuid);
            this.close();
        }
    }

    public close(): void {
        this.dialog.hide();
    }

    private selectLocation(location: IHierarchyNode): void {
        this.location = location;
        this.validating = true;
        this.validLocation = null;

        this.validateChecklist(this.location)
            .then((result) => {
                this.validLocation = result;
            })
            .catch(() => {
                this.validLocation = false;
            })
            .finally(() => {
                this.validating = false;
            });
    }

    private validateChecklist(location: IHierarchyNode): ng.IPromise<boolean> {
        let filter: IQueryFilter = new QueryFilter();
        filter.addParams({
            'filter[status]': 'published',
            active: true,
            include: 'accessTags,accessLocations',
            'filter[location]': location.uuid,
            'filter[original_uuids]': this.checklist.originalUuid,
        });

        return this.checkApi.findChecklists(undefined, undefined, filter.toString()).then((results: PagedEntities) => {
            if (this.checklist.accessTags.length > 0) {
                for (let locTag of location.tags) {
                    if (this.checklist.accessTags.map((tag) => tag.uuid).includes(locTag.uuid)) {
                        return true;
                    }
                }
                return false;
            }
            return !!results && results.getEntities() && results.getEntities().length > 0;
        });
    }
}
