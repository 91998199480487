import { ItemBaseModal, ItemModalOptions } from './ItemBaseModal';
import { IItemSection } from '../../../domain/checklists/ItemSection';
import View from './ItemSectionModal.html';

/**
 * A class to show the simple check edit/create fields
 */
export class ItemSectionModal extends ItemBaseModal {
    public item: IItemSection;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @param options
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions, options?: ItemModalOptions): any {
        if (options.item == null || options.checklist == null || options.isEdit == null) {
            throw new Error('Invalid information provided to create an item modal dialog');
        }

        config = config || {};
        config.template = View;
        config.controller = ItemSectionModal;
        return super.instantiate(config, options);
    }

    public onInit(): void {
        super.onInit();

        // find the item in the checklist from super (cloned)
        if (this.isEdit) {
            this.item = this.checklist.findSection('uuid', this.item.uuid);
        }
    }

    public save(): void {
        if (this.isEdit) {
            this.checklist.updateSection(<IItemSection>this.item);
        } else {
            this.checklist.addSection(<IItemSection>this.item);
        }

        super.save();
    }
}
