import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ChecklistEditService } from '../../../../../services/ChecklistEditService';

@Component('its.check.module.checklists', ItemTabs.CID, {
    template: require('/app/modules/checklists/components/items/item-tabs/ItemTabs.html'),
    controllerAs: 'vm',
    bindings: {
        tabs: '<',
        onTabSelect: '&',
    },
})
class ItemTabs {
    static IID: string = 'ItemTabs';
    static CID: string = 'itcItemTabs';

    static $inject: Array<string> = ['itcChecklistEditService'];
    public onTabSelect: Function;
    public tabs: string[];

    public activeSettingsTab: string = null;
    public displayBasicTab: boolean = false;
    public displayAdvancedTab: boolean = false;
    public displayTagsTab: boolean = false;
    public displayLogicTab: boolean = false;

    public constructor(private checklistEditService: ChecklistEditService) {}

    $onInit(): void {
        this.displayBasicTab = this.tabs.indexOf('basic') > -1;
        this.displayAdvancedTab = this.tabs.indexOf('advanced') > -1;
        this.displayTagsTab = this.tabs.indexOf('tags') > -1;
        this.displayLogicTab = this.tabs.indexOf('logic') > -1;
        this.activeSettingsTab = this.checklistEditService.getSettingsTab();
    }

    public selectTab(tab: 'basic' | 'advanced' | 'tags' | 'logic'): void {
        this.activeSettingsTab = tab;
        this.onTabSelect({ activeTab: tab });
    }
}
