import { IChecklist } from './Checklist';
import { IStringMap } from '@intouch/its.essential/app/essential/utils/datatypes/StringMap';
import { String } from '@intouch/its.essential/app/essential/utils/String';
import { IItemMultipleChoice } from './ItemMultipleChoice';
import { IItemCalculation } from './ItemCalculation';
import { IItemSection } from './ItemSection';
import { LogicSkipTypes } from '@intouch/its.check.essential/app/check-essential/domain/checklists/logic/LogicConstants';
import * as _ from 'lodash';

/**
 * Class used to duplicate a checklist
 */
export class ChecklistDuplicator {
    /**
     * Duplicates properties from source to target and returns a new checklist with the duplicated properties
     * Does not duplicate checklist uuid, accessOrgUuid, name, dates, and id properties
     *
     * @param {IChecklist} sourceChecklist
     * @param {IChecklist} targetChecklist
     * @return {IChecklist}
     */
    public static duplicate(sourceChecklist: IChecklist, targetChecklist: IChecklist): IChecklist {
        let result: IChecklist = sourceChecklist.clone();

        result.name = targetChecklist.name;
        result.uuid = targetChecklist.uuid;
        result.id = targetChecklist.id;
        result.originalUuid = targetChecklist.uuid;
        result.createdBy = targetChecklist.createdBy;
        result.updatedBy = targetChecklist.updatedBy;
        result.originalCreatedAt = targetChecklist.originalCreatedAt;
        result.createdAt = targetChecklist.createdAt;
        result.updatedAt = targetChecklist.updatedAt;
        result.accessOrgUuid = targetChecklist.accessOrgUuid;
        result.active = false;
        result.outcomeSetId = targetChecklist.outcomeSetId;

        ChecklistDuplicator.replaceUuids(result);

        return result;
    }

    /**
     * Duplicate a section
     *
     * @param {IItemSection} section
     * @param {boolean} clearName
     * @return {IItemSection}
     */
    public static duplicateSection(section: IItemSection, clearName: boolean = true): IItemSection {
        let duplicateSection: IItemSection = <IItemSection>section.clone();
        let uuidMap: IStringMap = {};

        duplicateSection.uuid = String.uuid();
        if (clearName) {
            duplicateSection.name = null;
        }
        duplicateSection.translations = [];

        if (duplicateSection.items) {
            for (let item of duplicateSection.items) {
                uuidMap[item.uuid] = String.uuid();

                if (
                    item.settings &&
                    (<any>item.settings).responses &&
                    (<any>item.settings).responses instanceof Array &&
                    (<any>item.settings).responses.length > 0
                ) {
                    if ((<any>item.settings).responses) {
                        for (let response of (<any>item.settings).responses) {
                            uuidMap[response.uuid] = String.uuid();
                        }
                    }
                }
            }
        }

        ChecklistDuplicator.replaceSectionItemUuids(duplicateSection, uuidMap);

        return duplicateSection;
    }

    /**
     * Replace all uuids in a section's items
     *
     * @param {IItemSection} section
     * @param {IStringMap} uuidMap
     */
    public static replaceSectionItemUuids(section: IItemSection, uuidMap: IStringMap): void {
        for (let item of section.items) {
            item.uuid = uuidMap[item.uuid] ? uuidMap[item.uuid] : item.uuid;
            if (item.logic && item.logic.length > 0) {
                for (let logic of item.logic) {
                    if (uuidMap[logic.targetUuid]) {
                        logic.targetUuid = uuidMap[logic.targetUuid];
                    }
                    if (logic.arguments && logic.arguments.length > 0) {
                        for (let argument of logic.arguments) {
                            if (
                                [
                                    LogicSkipTypes.AUDIT_LOCATION_TAG,
                                    LogicSkipTypes.AUDIT_LOCATION_ATTRIBUTE,
                                    LogicSkipTypes.AUDIT_LOCATION_NODE,
                                ].indexOf(argument.subjectUuid) === -1 &&
                                uuidMap[argument.subjectUuid]
                            ) {
                                argument.subjectUuid = uuidMap[argument.subjectUuid];
                            }
                            if (uuidMap[argument.value]) {
                                argument.value = uuidMap[argument.value];
                            }
                        }
                    }
                }
            }

            if (
                item.settings &&
                (<any>item.settings).responses &&
                (<any>item.settings).responses instanceof Array &&
                (<any>item.settings).responses.length > 0
            ) {
                let multiItem: IItemMultipleChoice = <IItemMultipleChoice>item;
                if (multiItem.settings.responses) {
                    for (let response of multiItem.settings.responses) {
                        let originalUuid: string = response.uuid;
                        response.uuid = uuidMap[response.uuid];
                        // update translation response uuids
                        if (item.translations) {
                            for (let translation of item.translations) {
                                let translationResponse: any = _.find(translation['responses'], { uuid: originalUuid });
                                if (translationResponse) {
                                    translationResponse.uuid = response.uuid;
                                }
                            }
                        }
                    }
                }
            }

            if (
                item.settings &&
                (<any>item.settings).calculations &&
                (<any>item.settings).calculations instanceof Array &&
                (<any>item.settings).calculations.length > 0
            ) {
                let calcItem: IItemCalculation = <any>item;
                if (calcItem.settings.calculations) {
                    for (let calculation of calcItem.settings.calculations) {
                        if (typeof calculation.value === 'string') {
                            calculation.value = uuidMap[calculation.value]
                                ? uuidMap[calculation.value]
                                : calculation.value;
                        }
                    }
                }
            }
        }
    }

    /**
     * Replace all uuids in the checklist
     *
     * @param {IChecklist} checklist
     */
    private static replaceUuids(checklist: IChecklist): void {
        let uuidMap: IStringMap = {};

        // map all section and item uuids to new uuids
        if (checklist.sections) {
            for (let section of checklist.sections) {
                uuidMap[section.uuid] = String.uuid();

                if (section.items) {
                    for (let item of section.items) {
                        uuidMap[item.uuid] = String.uuid();

                        if (
                            item.settings &&
                            (<any>item.settings).responses &&
                            (<any>item.settings).responses instanceof Array &&
                            (<any>item.settings).responses.length > 0
                        ) {
                            if ((<any>item.settings).responses) {
                                for (let response of (<any>item.settings).responses) {
                                    uuidMap[response.uuid] = String.uuid();
                                }
                            }
                        }
                    }
                }
            }

            /**
             * Replace all uuids with new ones from map and create new uuids on sub objects using uuids
             */
            for (let section of checklist.sections) {
                section.uuid = uuidMap[section.uuid] ? uuidMap[section.uuid] : section.uuid;
                if (section.items) {
                    ChecklistDuplicator.replaceSectionItemUuids(section, uuidMap);
                }
            }
        }
    }
}
