import { IBaseGeoData, BaseGeoData } from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseGeoData';
import { IGeoCoordinate } from '@intouch/its.essential/app/essential/services/GeoTracker';

export interface IGeoData extends IBaseGeoData {}

export class GeoData extends BaseGeoData implements IGeoData {
    public static make(properties: { start?: string; finish?: string }): IGeoData {
        let geoData: IGeoData = new GeoData();

        if (properties) {
            if (properties.start) {
                geoData.start = properties.start;
            }
            if (properties.finish) {
                geoData.finish = properties.finish;
            }
        }

        return geoData;
    }

    /**
     * Creates a new GeoData object from a GeoCoordinate object
     *
     * @param {IGeoCoordinate} coords
     * @return {IGeoData}
     */
    public static fromGeoCoordinate(coords: IGeoCoordinate): IGeoData {
        if (!coords) {
            return null;
        }
        let geoData: IGeoData = new GeoData();
        let lat: string = coords.latitude.toString();
        let long: string = coords.longitude.toString();
        geoData.start = lat + ',' + long;
        geoData.finish = geoData.start; // todo: this may need to change - md

        return geoData;
    }
}
