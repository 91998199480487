import { IChecklist } from '../Checklist';
import { IItemInput } from '../ItemInput';
import { ILogic } from '@intouch/its.check.essential/app/check-essential/domain/checklists/logic/Logic';
import { LogicSkipTypes } from '@intouch/its.check.essential/app/check-essential/domain/checklists/logic/LogicConstants';
import { IValidationError } from './ValidationError';
import * as _ from 'lodash';

/**
 * The ISkipError interface
 *
 */
export interface ILogicError extends IValidationError {
    subjectItem: IItemInput;
    itemStack: Array<IItemInput>;
}

export class LogicErrorTypes {
    public static circularReference: string = 'CircularReference';
    public static calcuationCircularReference: string = 'CalculationCircularReference';
}

/**
 * The LogicError class
 */
export class LogicError implements ILogicError {
    public type: string = null;
    public subjectItem: IItemInput = null;
    public itemStack: Array<IItemInput> = [];

    public constructor(params: any) {
        if (params) {
            this.type = params['type'];
            this.subjectItem = params['subjectItem'];
            this.itemStack = params['itemStack'];
        }
    }
}

/**
 * The ILogicValidator interface
 *
 */
export interface ILogicValidator {
    validate(): Array<ILogicError>;

    getErrorTypesFound(): Array<string>;
}

/**
 * The LogicValidator class
 *
 */
export class LogicValidator {
    protected checklist: IChecklist = null;
    protected errors: Array<ILogicError> = [];

    /**
     * Returns all error type that can be returned by skip validation
     *
     * @return {Array<string>}
     */
    public static getAllPossibleErrorTypes(): Array<string> {
        let errorTypes: Array<string> = [];

        for (let key in LogicErrorTypes) {
            if (LogicErrorTypes.hasOwnProperty(key)) {
                errorTypes.push(LogicErrorTypes[key]);
            }
        }

        return errorTypes;
    }

    /**
     * Instantiate the class
     *
     * @param {IChecklist} checklist
     */
    public constructor(checklist: IChecklist) {
        if (!checklist) {
            throw new Error('InvalidChecklist');
        }
        this.checklist = checklist;
    }

    /**
     * Returns true if checklist skip logic is valid among all items and sections in a checklist
     *
     * @return {Array<ILogicError>}
     */
    public validate(): Array<ILogicError> {
        let errors: Array<ILogicError> = [];

        this.errors = errors.concat(this.hasCircularReference());

        return this.errors;
    }

    /**
     * Returns an array of error types found during validation
     *
     * @return {Array<string>}
     */
    public getErrorTypesFound(): Array<string> {
        let errorTypes: Array<string> = [];

        for (let error of this.errors) {
            if (errorTypes.indexOf(error.type) === -1) {
                errorTypes.push(error.type);
            }
        }

        return errorTypes;
    }

    /**
     * Returns an item if it has a circular reference associated to it
     *
     * @return {Array<ILogicError>}
     */
    protected hasCircularReference(): Array<ILogicError> {
        let errors: Array<ILogicError> = [];

        for (let section of this.checklist.sections) {
            for (let item of section.items) {
                if (item.logic) {
                    let result: Array<IItemInput> = this.getCircularReferenceStack(item.logic, item);
                    if (result) {
                        errors.push(
                            new LogicError({
                                type: LogicErrorTypes.circularReference,
                                subjectItem: item,
                                itemStack: result,
                            })
                        );
                    }
                }
            }
        }

        return errors;
    }

    /**
     * Recursively check if item is associated to skip or connected skips.
     *
     * @param {logic:Array<ILogic>} logic
     * @param {IItemInput} item
     * @param {Array<IItemInput>} itemStack
     * @return {Array<IItemInput>}
     */
    protected getCircularReferenceStack(
        logic: Array<ILogic>,
        item: IItemInput,
        itemStack: Array<IItemInput> = []
    ): Array<IItemInput> {
        if (!logic || !item || logic.length === 0) {
            return null;
        }

        for (let logicItem of logic) {
            if (logicItem.hasArguments()) {
                for (let argument of logicItem.arguments) {
                    if (
                        [
                            LogicSkipTypes.AUDIT_LOCATION_TAG,
                            LogicSkipTypes.AUDIT_LOCATION_NODE,
                            LogicSkipTypes.AUDIT_LOCATION_ATTRIBUTE,
                        ].indexOf(argument.type) === -1
                    ) {
                        let subject: IItemInput = this.checklist.findItem('uuid', argument.subjectUuid);

                        if (itemStack.length > 0 && _.find(itemStack, subject)) {
                            return itemStack;
                        }

                        if (subject) {
                            itemStack.push(subject);
                            if (
                                subject.uuid === item.uuid ||
                                (subject.type === 'calculation' && (<any>subject).calculationHasItem(item))
                            ) {
                                return itemStack;
                            } else {
                                return this.getCircularReferenceStack(subject.logic, item, itemStack);
                            }
                        }
                    }
                }
            }
        }

        return null;
    }
}
