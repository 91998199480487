import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IItemSection } from '../../../domain/checklists/ItemSection';
import { IChecklist } from '../../../domain/checklists/Checklist';

/**
 * The UI for rendering a way to add and remove example photos on checklist items
 */
@Component('its.check.module.checklists', MoveSection.CID, {
    template: require('/app/modules/checklists/components/MoveSection.html'),
    controllerAs: 'vm',
    bindings: {
        section: '=',
        item: '=',
        checklist: '<',
        label: '<',
        itemTarget: '=',
        position: '=',
        itemToMoveToIndex: '<',
    },
})
class MoveSection {
    static IID: string = 'MoveSection';
    static CID: string = 'itcMoveSection';

    protected selectedUuid: string = null;
    protected position: string;
    protected itemTargetUuid: string = null;
    protected sectionItems: Array<any> = [];

    // bindings
    protected section: IItemSection;
    protected item: any;
    protected checklist: IChecklist;
    protected label: string;
    protected itemTarget: string;
    protected itemToMoveToIndex: number;

    $onInit(): void {
        if (this.itemToMoveToIndex !== undefined) {
            this.setInitialMoveVariables();
        } else {
            this.position = null;
        }
    }

    $onChanges = (changes: any): any => {
        if (this.section) {
            this.selectedUuid = this.section.uuid;
            this.updateSection();
        }
    };

    /**
     * Sets the initial position and itemTargetUuid to place the item
     */
    public setInitialMoveVariables(): void {
        if (
            this.section.items.length > 0 &&
            this.itemToMoveToIndex >= 0 &&
            this.itemToMoveToIndex <= this.section.items.length
        ) {
            if (this.itemToMoveToIndex === 0) {
                this.position = 'before';
                this.itemTargetUuid = this.section.items[this.itemToMoveToIndex].uuid;
                this.itemTarget = this.section.items[this.itemToMoveToIndex].uuid;
            } else {
                this.position = 'after';
                this.itemTargetUuid = this.section.items[this.itemToMoveToIndex - 1].uuid;
                this.itemTarget = this.section.items[this.itemToMoveToIndex - 1].uuid;
            }
            this.setItemUuidFromIndex();
        }
    }

    /**
     * Updates the section binding with the selected section
     */
    public updateSection(): void {
        this.section = angular.copy(this.checklist.findSection('uuid', this.selectedUuid));
        this.sectionItems = _.filter(this.section.items, (item) => {
            return item.uuid !== this.item.uuid;
        });
        this.itemTargetUuid = null;
    }

    /**
     * Adds the item to the section at an index
     */
    public setItemUuidFromIndex(): void {
        if (this.selectedUuid && this.position && this.itemTargetUuid) {
            if (this.itemToMoveToIndex === undefined) {
                this.itemToMoveToIndex = _.findIndex(this.section.items, { uuid: this.itemTargetUuid });
            }
            if (this.itemToMoveToIndex > -1) {
                if (this.itemToMoveToIndex >= this.section.items.length) {
                    this.itemToMoveToIndex = this.section.items.length - 1;
                }

                if (!this.itemTarget) {
                    this.itemTarget = this.section.items[this.itemToMoveToIndex].uuid;
                }
            }
        }
    }
}
