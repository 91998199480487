import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import * as _ from 'lodash';
import { IBaseChecklist } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseChecklist';
import { IBaseItemSection } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemSection';
import { IBaseItemInput } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { IBaseOutcome } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseOutcome';
import { ITranslationRowData } from '../domain/checklists/translation/TranslationDataRow';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { ItemMultipleChoice } from '../domain/checklists/ItemMultipleChoice';

@Service('its.check.services', ChecklistTranslationExportService.IID, ChecklistTranslationExportService)
export class ChecklistTranslationExportService {
    static IID: string = 'itcChecklistTranslationExportService';
    static $inject: Array<string> = ['$translate', 'iteLogger'];

    private locales: Array<string> = ['en', 'fr_CA', 'es_US'];

    public constructor(private translate: ng.translate.ITranslateService, private logger: ILogger) {}

    public export(checklist: IBaseChecklist): void {
        let csvElement: any = document.createElement('a');
        csvElement.href = encodeURI(this.getExportCSV(checklist));
        csvElement.target = '_blank';
        csvElement.download = checklist.name.replace(/\s/g, '');
        csvElement.click();
    }

    public getExportCSV(checklist: IBaseChecklist): string {
        let rows: Array<Array<string>> = [['"Type"', '"Name"'].concat(this.locales.map((i) => '"' + i + '"'))];

        rows = rows.concat(this.getTranslations(checklist));

        return (
            'data:text/csv;charset=utf-8,' +
            rows
                .map((i: Array<string>) => {
                    return i.join(',');
                })
                .join('\n')
        );
    }

    private getTranslations(checklist: IBaseChecklist): Array<Array<string>> {
        let translations: Array<Array<string>> = this.appendChecklistTranslations([], checklist);

        return translations;
    }

    private addLocaleValues(
        data: ITranslationRowData,
        locales: Array<string>,
        property: string,
        translations: Array<{ locale: string }>
    ): ITranslationRowData {
        for (let locale of locales) {
            data[locale] = this.getTranslationForLocale(locale, property, translations);
        }

        return data;
    }

    private appendChecklistTranslations(
        translations: Array<Array<string>>,
        checklist: IBaseChecklist
    ): Array<Array<string>> {
        let data: ITranslationRowData = this.addLocaleValues(
            {
                type: 'CHECKLISTS.CHECKLIST_TITLE_TRANSLATION',
                name: checklist.name,
            },
            this.locales,
            'name',
            checklist.translations
        );
        translations.push(this.buildRow(data));

        translations = this.appendCustomOutcomeTranslations(translations, checklist.outcomes);
        translations = this.appendSectionTranslations(translations, checklist.sections);

        return translations;
    }

    private appendCustomOutcomeTranslations(
        translations: Array<Array<string>>,
        outcomes: Array<IBaseOutcome> = []
    ): Array<Array<string>> {
        for (let outcome of outcomes) {
            if (outcome.outcomeType === 'custom') {
                let data: ITranslationRowData = this.addLocaleValues(
                    {
                        type: 'CHECKLISTS.CUSTOM_OUTCOME',
                        name: outcome.label,
                    },
                    this.locales,
                    'label',
                    outcome.translations
                );
                translations.push(this.buildRow(data));
            }
        }

        return translations;
    }

    private appendSectionTranslations(
        translations: Array<Array<string>>,
        sections: Array<IBaseItemSection> = []
    ): Array<Array<string>> {
        for (let section of sections || []) {
            let data: ITranslationRowData = this.addLocaleValues(
                {
                    type: 'TRANSLATIONS.SECTION.LABEL',
                    name: section.name,
                },
                this.locales,
                'name',
                section.translations
            );
            translations.push(this.buildRow(data));
            translations = this.appendQuestionTranslations(translations, section.items);
        }

        return translations;
    }

    private appendQuestionTranslations(
        translations: Array<Array<string>>,
        questions: Array<IBaseItemInput>
    ): Array<Array<string>> {
        for (let question of questions || []) {
            let data: ITranslationRowData = this.addLocaleValues(
                {
                    type: this.getQuestionTypeLabel(question),
                    name: question.name,
                },
                this.locales,
                'name',
                question.translations
            );
            translations.push(this.buildRow(data));

            this.appendQuestionExamplePhotoTranslation(translations, question);
            this.appendQuestionDescriptionTranslation(translations, question);
            this.appendQuestionResponseTranslations(translations, question);
        }

        return translations;
    }

    private appendQuestionExamplePhotoTranslation(
        translations: Array<Array<string>>,
        question: IBaseItemInput
    ): Array<Array<string>> {
        let data: ITranslationRowData = this.addLocaleValues(
            {
                type: this.translate.instant('TRANSLATIONS.ITEM.IMAGE') + ' (' + question.name + ')',
                name: !!question.examplePhoto ? (<any>question.examplePhoto).url || '' : '',
            },
            this.locales,
            'examplePhoto.url',
            question.translations
        );
        translations.push(this.buildRow(data));
        return translations;
    }

    private appendQuestionDescriptionTranslation(
        translations: Array<Array<string>>,
        question: IBaseItemInput
    ): Array<Array<string>> {
        let data: ITranslationRowData = this.addLocaleValues(
            {
                type: this.translate.instant('TRANSLATIONS.ITEM.DESCRIPTION') + ' (' + question.name + ')',
                name: question.description,
            },
            this.locales,
            'description',
            question.translations
        );
        translations.push(this.buildRow(data));
        return translations;
    }

    private appendQuestionResponseTranslations(
        translations: Array<Array<string>>,
        question: IBaseItemInput
    ): Array<Array<string>> {
        if (!!question && !!question.settings && !!(<any>question.settings).responses) {
            for (let response of (<any>question.settings).responses || {}) {
                let index: number = _.findIndex((<any>question.settings).responses, { uuid: response.uuid });
                if (index > -1) {
                    let data: ITranslationRowData = this.addLocaleValues(
                        {
                            type:
                                this.translate.instant('TRANSLATIONS.MULTIPLE_CHOICE.RESPONSE_LABEL') +
                                ' (' +
                                question.name +
                                ')',
                            name: response.name,
                        },
                        this.locales,
                        'responses[' + index + '].name',
                        question.translations
                    );
                    translations.push(this.buildRow(data));
                }
            }
        }

        return translations;
    }

    private buildRow(data: ITranslationRowData): Array<string> {
        return [
            this.buildCell(data.type, true),
            this.buildCell(data.name),
            this.buildCell(data['en']),
            this.buildCell(data['fr_CA']),
            this.buildCell(data['es_US']),
        ];
    }

    private buildCell(value: string, translate: boolean = false): string {
        if (translate && !!value) {
            value = this.translate.instant(value);
        }
        return !!value ? '"' + value + '"' : '""';
    }

    private getTranslationForLocale(locale: string, property: string, translations: Array<{ locale: string }>): string {
        if (!translations) {
            return '';
        }

        let translation: Object = _.find(translations, { locale: locale });

        if (!translation) {
            return '';
        }

        return _.get(translation, property, '');
    }

    private getQuestionTypeLabel(question: IBaseItemInput): string {
        switch (question.type) {
            case 'calculation':
                return 'TRANSLATIONS.CALCULATION.LABEL';
            case 'check':
                return 'TRANSLATIONS.SIMPLE_CHECK.LABEL';
            case 'date':
                return 'TRANSLATIONS.DATE_PICKER.LABEL';
            case 'display_text':
                return 'TRANSLATIONS.TEXT.LABEL';
            case 'graphic':
                return 'TRANSLATIONS.GRAPHIC.LABEL';
            case 'dropdown':
                return 'TRANSLATIONS.DROPDOWN.LABEL';
            case 'multiplechoice':
                if ((<ItemMultipleChoice>question).settings.multipleSelections) {
                    return 'TRANSLATIONS.CHECKBOXES.LABEL';
                }
                return 'TRANSLATIONS.MULTIPLE_CHOICE.LABEL';
            case 'number':
                return 'TRANSLATIONS.NUMBER.LABEL';
            case 'text':
                return 'TRANSLATIONS.TEXT.LABEL';
            case 'time':
                return 'TRANSLATIONS.TIME.LABEL';
            default:
                this.logger.error('Invalid question type provided: ' + question.type);
                return '';
        }
    }
}
