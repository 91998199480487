import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

export interface ITranslationImage {}

@Component('its.check.module.checklists', TranslationImage.CID, {
    template: require('/app/modules/checklists/components/translations/TranslationImage.html'),
    controllerAs: 'vm',
    bindings: {
        adminLabel: '<',
        item: '<',
        itemTranslation: '=',
    },
})
class TranslationImage implements ITranslationImage {
    static IID: string = 'TranslationImage';
    static CID: string = 'itsTranslationImage';
}
