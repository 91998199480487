import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { HttpClient, IHttpClient } from '@intouch/its.essential/app/essential/http/HttpClient';
import { IConfigurable } from '@intouch/its.essential/app/essential/services/IConfigurable';

/**
 * The api client configuration interface
 */
export interface IApiClientConfiguration {
    endpoint?: string;
    version?: string;
    trace?: boolean;
}

/**
 * The check API interface
 */
export interface ICheckHttpClient extends IHttpClient {
    getUrl(source: string): string;
}

/**
 * The check API wrapper class
 */
@Service('its.check.api', CheckHttpClient.IID, CheckHttpClient)
export class CheckHttpClient extends HttpClient implements ICheckHttpClient, IConfigurable {
    static IID: string = 'itcCheckHttpClient';

    public endpoint: string;
    private version: string;

    /**
     * Configure the http api client
     *
     * @param data
     */
    public configure(data: IApiClientConfiguration): void {
        this.endpoint = data.endpoint || this.endpoint;
        this.version = data.version || this.version;
        this.trace = data.trace || this.trace;
    }

    /**
     * Get the API client http endpoint
     *
     * @param source - the endpoint source part + params (i.e. /checklists?search=hello)
     */
    public getUrl(source: string): string {
        return this.endpoint + '/' + this.version + source;
    }
}
