import { IItemReference, ItemReference } from './ItemReference';
import { IBaseItemInput } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { IBaseItemSection } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemSection';
import { IItemDate } from '../ItemDate';

export class PrePopulateDateItemReference extends ItemReference implements IItemReference {
    public type: string = 'pre_populate_date';
    public subject: IBaseItemInput = null;
    public source: IItemDate = null;
    public sourceParent: IBaseItemSection = null;

    /**
     * Remove date pre-populate dependencies
     *
     */
    public removeDependency(): void {
        this.source.prePopulateDate = null;
    }
}
