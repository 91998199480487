import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IHierarchyTier } from '@intouch/its.essential/app/essential/domain/access/HierarchyTier';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { IListingItem } from '../../../domain/checklists/ListingItem';
import { ICheckApi } from '../../../api/CheckApi';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';
import { IUserListingItem } from '@intouch/its.essential/app/essential/domain/access/UserListingItem';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import moment_tz = require('moment-timezone'); // tslint:disable-line
import { IUpcomingListingItem } from '../../../domain/audits/UpcomingListItem';
import { IHierarchyNode } from '@intouch/its.essential/app/essential/domain/access/HierarchyNode';
import View from './EditUpcomingAuditModal.html';

export class EditUpcomingAuditModal extends Modal {
    static $inject: Array<string> = ['$mdDialog', 'iteToaster', 'itcCheckApi', 'iteAccessApi', '$translate'];

    public upcomingAudit: IUpcomingListingItem;
    public checklists: Array<IListingItem> = [];
    public selectedChecklist: any;
    public allowChecklistSelection: boolean = false;
    public locations: Array<IHierarchyTier> = [];
    public updating: boolean = false;
    public selectedLocation: IHierarchyNode;
    public selectedUser: IUserListingItem;
    public showDatepicker: boolean = false;

    public upcomingAuditStartByDate: Date = null;
    public upcomingAuditStartByHour: string = null;
    public upcomingAuditStartByMinute: string = null;
    public upcomingAuditStartByMeridiem: string = null;

    public upcomingAuditCompleteByDate: Date = null;
    public upcomingAuditCompleteByHour: string = null;
    public upcomingAuditCompleteByMinute: string = null;
    public upcomingAuditCompleteByMeridiem: string = null;

    public upcomingAuditDateTimeErrorMessage: string = null;

    public minutes: Array<String>;
    public hours: Array<String>;
    public ampm: Array<String>;
    public minDate: Date;

    private translations: any;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = View;
        config.controller = EditUpcomingAuditModal;

        return super.instantiate(config);
    }

    /**
     * Create the modal
     *
     * @param dialog
     * @param toaster
     * @param checkApi
     * @param accessApi
     * @param translate
     */
    constructor(
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private checkApi: ICheckApi,
        private accessApi: IAccessApi,
        private translate: angular.translate.ITranslateService
    ) {
        super();

        this.minDate = new Date();
        this.minutes = [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
            '39',
            '40',
            '41',
            '42',
            '43',
            '44',
            '45',
            '46',
            '47',
            '48',
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '58',
            '59',
        ];
        this.hours = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        this.ampm = ['AM', 'PM'];

        if (this.upcomingAudit) {
            this.selectedChecklist = { name: this.upcomingAudit.checklistName, uuid: this.upcomingAudit.checklistUuid };
            this.loadAuditLocation();
        }

        this.translate([
            'CHECKLISTS.MESSAGES.ASSIGNED_CHECKLIST_UPDATED',
            'CHECKLISTS.ERRORS.UNABLE_TO_UPDATE_ASSIGNED',
            'CHECKLISTS.ERRORS.ASSIGNED_COMPLETE_BY_INVALID',
        ]).then((translations) => {
            this.translations = translations;
        });
    }

    /**
     * Checks if we have all the information needed to start an audit
     *
     * @returns {IChecklist|ILocation|boolean}
     */
    public isValid(): boolean {
        return !!this.selectedChecklist && !!this.selectedLocation && !!this.selectedUser;
    }

    /**
     * Close the check now modal
     *
     */
    public close(): void {
        this.dialog.cancel();
    }

    /**
     * Assigns the checklist
     *
     */
    public update(): void {
        this.updating = true;
        this.upcomingAuditDateTimeErrorMessage = null;

        // if no start_by date was selected; we'll default to today at 12:00 AM
        if (!this.upcomingAuditStartByDate) {
            this.upcomingAuditStartByDate = new Date();
        }

        this.upcomingAuditStartByDate = this.setTime(
            'start',
            this.upcomingAuditStartByDate,
            this.upcomingAuditStartByHour,
            this.upcomingAuditStartByMinute,
            this.upcomingAuditStartByMeridiem
        );

        this.upcomingAuditCompleteByDate = this.setTime(
            'complete',
            this.upcomingAuditCompleteByDate,
            this.upcomingAuditCompleteByHour,
            this.upcomingAuditCompleteByMinute,
            this.upcomingAuditCompleteByMeridiem
        );

        // ensure that the complete by date's time is NOT GT then the start by date's time
        if (
            this.upcomingAuditStartByDate &&
            this.upcomingAuditCompleteByDate &&
            this.upcomingAuditCompleteByHour &&
            this.upcomingAuditCompleteByMeridiem
        ) {
            if (this.upcomingAuditStartByDate.getTime() >= this.upcomingAuditCompleteByDate.getTime()) {
                this.upcomingAuditDateTimeErrorMessage =
                    this.translations['CHECKLISTS.ERRORS.ASSIGNED_COMPLETE_BY_INVALID'];
                this.updating = false;
                return;
            }
        }

        this.upcomingAudit.startBy = this.convertAndFormatDate(
            this.upcomingAuditStartByDate,
            this.upcomingAuditStartByHour,
            this.upcomingAuditStartByMeridiem
        );
        this.upcomingAudit.completeBy = this.convertAndFormatDate(
            this.upcomingAuditCompleteByDate,
            this.upcomingAuditCompleteByHour,
            this.upcomingAuditCompleteByMeridiem
        );
        this.upcomingAudit.auditorUuid = this.selectedUser.uuid;
        this.upcomingAudit.auditorName = this.getUserDisplayText(this.selectedUser);

        this.checkApi
            .updateUpcoming(this.upcomingAudit)
            .then(() => {
                this.toaster.success(this.translations['CHECKLISTS.MESSAGES.ASSIGNED_CHECKLIST_UPDATED']);
                this.close();
            })
            .catch((error: IErrorResponse) => {
                this.toaster.warn(this.translations['CHECKLISTS.ERRORS.UNABLE_TO_UPDATE_ASSIGNED']);
            })
            .finally(() => {
                this.updating = false;
            });
    }

    /**
     * Provides the promise to more users from the access api
     *
     * @param {string} search
     * @param {IPager} pager
     *
     * @return {angular.IPromise<PagedEntities>}
     */
    public findUsers(search: string, pager?: IPager): ng.IPromise<PagedEntities> {
        if (pager) {
            pager.sortBy = 'first_name';
            pager.order = 'asc';
        } else {
            pager = Pager.make(1, 'first_name', 'asc');
        }

        return this.accessApi.findUsers(search, pager);
    }

    /**
     * Returns the text to display in the users listing
     *
     * @param {IUserListingItem} item
     * @return {string}
     */
    public getUserDisplayText(item: IUserListingItem): string {
        return item.firstName && item.lastName ? item.firstName + ' ' + item.lastName : item.email;
    }

    public handleShowDatepicker(): void {
        this.upcomingAuditStartByDate = null;
        this.upcomingAuditStartByHour = null;
        this.upcomingAuditStartByMinute = null;
        this.upcomingAuditStartByMeridiem = null;
        this.upcomingAuditCompleteByDate = null;
        this.upcomingAuditCompleteByHour = null;
        this.upcomingAuditCompleteByMinute = null;
        this.upcomingAuditCompleteByMeridiem = null;
    }

    /**
     * Load  the upcoming audit's location by uuid
     */
    private loadAuditLocation(): void {
        this.updating = true;
        this.accessApi.fetchHierarchyByUuid(this.upcomingAudit.hierarchyUuid).then((result: IHierarchyNode) => {
            this.selectedLocation = result;
            this.updating = false;
            this.initializeDates();
        });
    }

    private initializeDates(): void {
        if (this.upcomingAudit.startBy) {
            let upcomingMomentStartBy: any = moment_tz
                .utc(this.upcomingAudit.startBy)
                .tz(this.selectedLocation.location.timezone);
            let startByHour: number = upcomingMomentStartBy.hours();

            this.upcomingAuditStartByDate = upcomingMomentStartBy.toDate();
            this.upcomingAuditStartByMeridiem = this.getMeridiem(startByHour);
            this.upcomingAuditStartByHour = this.convertHourTo12Hour(
                startByHour,
                this.upcomingAuditStartByMeridiem
            ).toString();
            this.upcomingAuditStartByMinute = this.formatMinute(upcomingMomentStartBy.minutes());

            this.showDatepicker = true;
        }

        if (this.upcomingAudit.completeBy) {
            let upcomingMomentCompleteBy: any = moment_tz
                .utc(this.upcomingAudit.completeBy)
                .tz(this.selectedLocation.location.timezone);
            let completeByHour: number = upcomingMomentCompleteBy.hours();

            this.upcomingAuditCompleteByDate = upcomingMomentCompleteBy.toDate();
            this.upcomingAuditCompleteByMeridiem = this.getMeridiem(completeByHour);
            this.upcomingAuditCompleteByHour = this.convertHourTo12Hour(
                completeByHour,
                this.upcomingAuditCompleteByMeridiem
            ).toString();
            this.upcomingAuditCompleteByMinute = this.formatMinute(upcomingMomentCompleteBy.minutes());

            this.showDatepicker = true;
        }
    }

    /**
     * Figure out what the time's meridiem is based on the 24-hour value
     *
     * @param {number} hour
     *
     * @return string
     */
    private getMeridiem(hour: number): string {
        if (hour >= 12) {
            return 'PM';
        } else {
            return 'AM';
        }
    }

    /**
     * Zero-pad and convert minute to string
     *
     * @param {number} minute
     *
     * @return string
     */
    private formatMinute(minute: number): string {
        if (minute < 10) {
            return '0' + minute.toString();
        } else {
            return minute.toString();
        }
    }

    /**
     * Convert a 12-hour number to a 24-hour number
     *
     * @param {number} hour
     * @param {string} meridiem
     *
     * @return number
     */
    private convertHourTo24Hour(hour: number, meridiem: string): number {
        if (meridiem === 'PM' && hour < 12) {
            hour += 12;
        } else if (meridiem === 'AM' && hour === 12) {
            hour = 0;
        }

        return hour;
    }

    /**
     * Convert a 24-hour number to a 12-hour number
     *
     * @param {number} hour
     * @param {string} meridiem
     *
     * @return number
     */
    private convertHourTo12Hour(hour: number, meridiem: string): number {
        if (meridiem === 'PM' && hour > 12) {
            hour -= 12;
        } else if (meridiem === 'AM' && hour === 0) {
            hour = 12;
        }

        return hour;
    }

    /**
     * Construct the assigned date's time frame
     *
     * @param type
     * @param assignedDate
     * @param assignedHour
     * @param assignedMinute
     * @param assignedMeridiem
     *
     * @return {Date}
     */
    private setTime(
        type: string,
        assignedDate?: Date,
        assignedHour?: string,
        assignedMinute?: string,
        assignedMeridiem?: string
    ): Date {
        if (assignedDate) {
            // default start by time to 12 am and complete by time to 11:59 pm if no times were selected
            if (!assignedHour && !assignedMeridiem) {
                if (type === 'start') {
                    assignedHour = '12';
                    assignedMinute = '00';
                    assignedMeridiem = 'AM';
                } else if (type === 'complete') {
                    assignedHour = '11';
                    assignedMinute = '59';
                    assignedMeridiem = 'PM';
                }
            }

            let minutes: number = 0;
            if (assignedMinute) {
                minutes = this.stringToNumber(assignedMinute);
            }

            assignedDate.setHours(
                this.convertHourTo24Hour(this.stringToNumber(assignedHour), assignedMeridiem),
                minutes
            );
        }

        return assignedDate;
    }

    /**
     * Convert a string to a number
     *
     * @param value
     *
     * @return string
     */
    private stringToNumber(value?: string): number {
        let converted: number = 0;
        let temp: number = parseInt(value, 10);

        if (!isNaN(temp)) {
            converted = temp;
        }

        return converted;
    }

    /**
     * Convert and format an upcoming date
     *
     * @param upcomingDate
     * @param assignedHour
     * @param assignedMeridiem
     */
    private convertAndFormatDate(upcomingDate: Date, assignedHour?: string, assignedMeridiem?: string): any {
        if (upcomingDate) {
            let dateFormat: string = 'YYYY-MM-DD HH:mm:ss';

            let upcomingMoment: any = moment_tz.tz(
                moment_tz(upcomingDate).format(dateFormat),
                this.selectedLocation.location.timezone
            );

            return upcomingMoment.utc().format(dateFormat);
        }

        return null;
    }
}
