import {
    IBaseListingItem,
    BaseListingItem,
} from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseListingItem';
import { IAudit } from './Audit';

export interface IDeletedListingItem extends IBaseListingItem {
    uuid: string;
    status: string;
    hierarchyUuid: string;
    hierarchyName: string;
    accessAuditedById: string;
    accessAuditedByName: string;
    startedAt: string;
    assignedByUuid: string;
    assignedByName: string;
    deletedAt: string;
    percentComplete: number;
    checklistUuid: string;
    checklistName: string;
    hierarchyAddressLine1: string;
    hierarchyAddressCity: string;
}

export class DeletedListingItem extends BaseListingItem implements IDeletedListingItem {
    public uuid: string = null;
    public status: string = null;
    public hierarchyUuid: string = null;
    public hierarchyName: string = null;
    public accessAuditedById: string = null;
    public accessAuditedByName: string = null;
    public startedAt: string = null;
    public assignedByUuid: string = null;
    public assignedByName: string = null;
    public deletedAt: string = null;
    public percentComplete: number = null;
    public checklistUuid: string = null;
    public checklistName: string = null;
    public hierarchyAddressLine1: string = null;
    public hierarchyAddressCity: string = null;

    /**
     * Constructions an instance of IListingItem, if audit is provided, will populate properties from audit
     *
     * @param audit
     */
    public constructor(audit?: IAudit) {
        super();

        if (audit) {
            if (audit.checklist) {
                this.checklistUuid = audit.checklist.uuid;
                this.checklistName = audit.checklist.name;
            }

            if (audit.hierarchy) {
                this.hierarchyUuid = audit.hierarchy.uuid;
                this.hierarchyName = audit.hierarchy.name;
            }
        }
    }

    public displayPercentComplete(): number {
        let percentage: number = this.percentComplete || 0;

        if (percentage > 100) {
            percentage = 100;
        }

        return percentage;
    }
}
