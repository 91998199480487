import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { String } from '@intouch/its.essential/app/essential/utils/String';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';

import {
    IBaseItemSection,
    BaseItemSection,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemSection';

import * as _ from 'lodash';

import { IItemInput } from './ItemInput';
import { ItemInputFactory } from './ItemInputFactory';
import { IAuditPhoto } from '@intouch/its.check.essential/app/check-essential/domain/audits/AuditPhoto';
import {
    IBaseItemSectionTranslation,
    BaseItemSectionTranslation,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/translations/BaseItemSectionTranslation';

/**
 * The checklist IItemSection interface
 *
 */
export interface IItemSection extends IBaseItemSection {
    items: Array<IItemInput>;

    addItem(item: IItemInput, index?: number): void;
    findItem(property: string, value: any): IItemInput;
    getItem(uuid: string): IItemInput;
    getItemIndex(item: IItemInput): number;
    getItemIndexByUuid(uuid: string): number;
    getItems(filter?: Function): Array<IItemInput>;
    moveItem(item: IItemInput, destinationSection: IItemSection): IItemInput;
    removeItem(item: IItemInput | number): void;
    updateItem(item: IItemInput, index?: number): IItemInput;
    getPhotosToUpload(): IAuditPhoto[];
}

/**
 * The checklist ItemSection class
 *
 */
export class ItemSection extends BaseItemSection implements IItemSection {
    public items: Array<IItemInput> = [];

    /**
     * Instantiates the checklist Section class and sets a uuid and optional name
     *
     * @param name
     */
    public constructor(name?: string) {
        super();
        if (name) {
            this.name = name;
        }
        this.uuid = String.uuid();
    }

    /**
     * Builds a checklist Section object with values from a json object
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {ItemSection}
     */
    public fromJson(jsonObject: any, convertToCamel?: boolean): IItemSection {
        super.fromJson(jsonObject, true);

        if (!this.uuid) {
            this.uuid = String.uuid();
        }

        // build items with the factory
        if (jsonObject['items']) {
            this.items = ItemInputFactory.buildMany(jsonObject['items']);
        }

        if (jsonObject['translations']) {
            this.translations = EntityBuilder.buildMany<IBaseItemSectionTranslation>(
                BaseItemSectionTranslation,
                jsonObject['translations'],
                convertToCamel
            );
        }

        return this;
    }

    /**
     * Adds the provided item to the end of the items array
     *
     * @param item
     * @param index
     */
    public addItem(item: IItemInput, index?: number): void {
        if (NumberUtils.isNumber(index)) {
            this.items.splice(index, 0, item);
        } else {
            this.items.push(item);
        }
    }

    /**
     * Search the item list and returns an item with matching value at property.
     * Returns undefined in not found
     *
     * @param property
     * @param value
     * @returns {IItemInput}
     */
    public findItem(property: string, value: any): IItemInput {
        if (this.items && this.items.length > 0) {
            return _.find(this.items, (item: IItemInput) => {
                return item[property] === value;
            });
        } else {
            return undefined;
        }
    }

    /**
     * Get an item via its UUID, null if not found
     *
     * @param uuid
     * @returns {IItemInput}
     */
    public getItem(uuid: string): IItemInput {
        let index: number = this.getItemIndexByUuid(uuid);
        if (index > -1) {
            return this.items[index];
        } else {
            return null;
        }
    }

    /**
     * Returns the index of an item in the items array or -1 if not found
     *
     * @param item
     * @returns {number}
     */
    public getItemIndex(item: IItemInput): number {
        return _.findIndex(this.items, (i: IItemInput) => {
            return i.uuid === item.uuid;
        });
    }

    /**
     * Returns the index of an item from its uuid, -1 if not found
     *
     * @param uuid
     * @returns {number}
     */
    public getItemIndexByUuid(uuid: string): number {
        return _.findIndex(this.items, (i: IItemInput) => {
            return i.uuid === uuid;
        });
    }

    /**
     * Returns an array of items where filter returns true
     *
     * @param filter
     * @returns {T[IItemInput]}
     */
    public getItems(
        filter: Function = () => {
            return true;
        }
    ): Array<IItemInput> {
        return <any>_.filter(this.items, filter);
    }

    /**
     * Will move an item in this checklist to a new section
     *
     * @param item
     * @param destinationSection
     * @returns {IItemInput}
     */
    public moveItem(item: IItemInput, destinationSection: ItemSection): IItemInput {
        let currentIndex: number = this.getItemIndex(item);
        if (currentIndex < 0) {
            throw new Error('Was not able to locate the given item in this checklist');
        }

        destinationSection.addItem(item);
        this.items.splice(currentIndex, 1);

        return item;
    }

    /**
     * Removes an item matching provided item or at provided index
     *
     * @param item - the item(IItemInput) to remove or the index(number) of the item to remove
     */
    public removeItem(item: IItemInput | number): void {
        let index: number = -1;
        if (typeof item === 'number') {
            index = item;
        } else {
            index = this.getItemIndex(item);
        }

        if (this.items && this.items.length > 0 && index > -1 && index < this.items.length) {
            this.items.splice(index, 1);
        }
    }

    /**
     * Will update (replace) the given item with a new copy
     *
     * @param item
     * @param index
     * @returns {IItemInput}
     */
    public updateItem(item: IItemInput, index?: number): IItemInput {
        index = NumberUtils.isNumber(index) ? index : this.getItemIndex(item);

        if (index < 0) {
            // add the item
            this.addItem(item);
        }

        this.items[index] = item;

        this.calculatePoints();

        return item;
    }

    /**
     * Returns an array of photos that require uploading
     *
     * @return {IPhoto[]}
     */
    public getPhotosToUpload(): IAuditPhoto[] {
        let photos: IAuditPhoto[] = [];

        for (let item of this.items) {
            if (item.examplePhoto && !item.examplePhoto.url) {
                photos.push(item.examplePhoto);
            }
        }

        return photos;
    }
}
