import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { Response } from '../../../../../../domain/checklists/Response';
import { ItemMultipleChoice } from '../../../../../../domain/checklists/ItemMultipleChoice';

@Component('its.check.module.checklists', ItemResponsesSetting.CID, {
    template: require('/app/modules/checklists/components/items/shared/item-responses-setting/ItemResponsesSetting.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class ItemResponsesSetting {
    static IID: string = 'ItemResponsesSetting';
    static CID: string = 'itcItemResponsesSetting';

    static $inject: Array<string> = [];
    public item: ItemMultipleChoice;
    public okNotOk: Array<any> = [
        { name: 'CHECKLISTS.MULTIPLE_OK', value: 'ok' },
        { name: 'CHECKLISTS.MULTIPLE_NOT_OK', value: 'not-ok' },
        { name: 'CHECKLISTS.MULTIPLE_NEUTRAL', value: 'neutral' },
    ];

    public $onInit(): void {
        if (this.item.settings.responses.length === 0) {
            this.addAnswer(0);
        }
    }

    public $doCheck(): void {
        if (!!this.item && !this.item.hasPoints()) {
            this.item.setScored(false);
        }
    }

    public addAnswer(index: number): void {
        this.item.addAnswer(Response.make('', 'response'), index);
    }
}
