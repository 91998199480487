import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { ICheckSession } from '../../services/CheckSession';
import { IStateService } from 'angular-ui-router';
import * as IntouchIcon from '../../../assets/images/branding/intouch_icon.svg';

/**
 * The users controller
 *
 * @param UserService
 * @param $state
 * @constructor
 */
@Controller('its.check.module.main', LoginCallbackController.IID, LoginCallbackController)
class LoginCallbackController {
    static IID: string = 'LoginCallbackController';
    static $inject: Array<string> = [
        '$stateParams',
        'iteAccessService',
        'iteLogger',
        'itcCheckSession',
        '$state',
        '$location',
    ];

    public intouchIcon: any = IntouchIcon;

    /**
     * The class constructor
     *
     * @param stateParams
     * @param accessService
     * @param logger
     * @param session
     * @param state
     * @param location
     */
    constructor(
        private stateParams: ng.ui.IStateParamsService,
        private accessService: IAccessService,
        private logger: ILogger,
        private session: ICheckSession,
        private state: IStateService,
        private location: ng.ILocationService
    ) {
        if (!this.stateParams.hasOwnProperty('token')) {
            throw new Error('Was unable to locate a token needed for authentication');
        }

        // flush the session
        this.session.flush();

        let token: IToken = this.accessService.handleLoginSuccess(this.stateParams['token']);

        this.logger.debug('Received a new token from the login callback');
        this.logger.debug('    > Issued time of: ' + token.getDateIssued().toDate());
        this.logger.debug('    > Expire time of: ' + token.getDateExpired().toDate());

        if (this.stateParams['redirect']) {
            this.location.url(this.stateParams['redirect']);
        } else {
            let locationToRemember: string = this.accessService.getRememberLocation();
            // if we have a last location use it and remove it
            if (locationToRemember) {
                this.logger.debug('Detected a last location for this user, redirecting to ' + locationToRemember);

                // clear last location and send them to it
                this.accessService.clearRememberLocation();
                this.location.path(locationToRemember);
                this.location.replace();
            } else {
                this.state.go('home.checklists.list.all');
            }
        }
    }
}
