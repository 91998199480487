import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { ICheckApi } from '../../../api/CheckApi';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { OutcomeSet } from '../../../domain/outcome-sets/OutcomeSet';
import View from './CreateOutcomeSetModal.html';

/**
 * A modal class for collecting the reject comment on a rejected audit
 */
export class CreateOutcomeSetModal extends Modal {
    static $inject: Array<string> = ['$mdDialog', 'iteToaster', 'itcCheckApi'];

    public outcomeSetUuid: string;
    public outcomeSet: OutcomeSet = new OutcomeSet();

    public isCreating: boolean = false;
    public isSaving: boolean = false;
    public isLoading: boolean = false;
    public hasError: boolean = false;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = View;
        config.controller = CreateOutcomeSetModal;

        return super.instantiate(config);
    }

    constructor(
        protected dialog: ng.material.IDialogService,
        protected toaster: IToaster,
        protected checkApi: ICheckApi
    ) {
        super();
        if (!this.outcomeSetUuid) {
            this.isCreating = true;
            (<any>this.outcomeSet).addOutcomeRange(0, 50).color = '#e57567';
            (<any>this.outcomeSet).addOutcomeRange(51, 100).color = '#469f08';
        } else {
            this.loadOutcomeSet(this.outcomeSetUuid);
        }
    }

    public save(): void {
        this.isSaving = true;
        this.hasError = false;
        this.checkApi
            .saveOutcomeSet(this.outcomeSet)
            .then((newOutcomeSet: OutcomeSet) => {
                this.dialog.hide(newOutcomeSet);
            })
            .catch(() => {
                this.hasError = true;
                this.toaster.error('SETTINGS.OUTCOMES.FAILED_SAVE_OUTCOME');
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    public addOutcomeRange(): void {
        (<any>this.outcomeSet).addOutcomeRange().setRandomColor();
    }

    public loadOutcomeSet(outcomeSetUuid: string): void {
        this.isLoading = true;
        this.checkApi
            .findOutcomeByUuidOrId(outcomeSetUuid)
            .then((outcomeLoaded: OutcomeSet) => {
                this.outcomeSet = outcomeLoaded;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public cancel(): void {
        this.dialog.cancel();
    }
}
