import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { OutcomeSet } from '@intouch/its.check.essential/app/check-essential/domain/checklists/outcomes/OutcomeSet';
import { ICheckApi } from '../../../api/CheckApi';
import View from './TranslateOutcomeSetModal.html';

/**
 * A modal class for collecting the reject comment on a rejected audit
 */
export class TranslateOutcomeSetModal extends Modal {
    static $inject: Array<string> = ['$mdDialog', 'itcCheckApi'];

    public outcomeSetUuid: string;
    public outcomeSet: OutcomeSet = new OutcomeSet();

    public isCreating: boolean = false;
    public isSaving: boolean = false;
    public isLoading: boolean = false;
    public hasError: boolean = false;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = View;
        config.controller = TranslateOutcomeSetModal;

        return super.instantiate(config);
    }

    constructor(protected dialog: ng.material.IDialogService, protected checkApi: ICheckApi) {
        super();
        if (this.outcomeSetUuid) {
            this.loadOutcomeSet(this.outcomeSetUuid);
        }
    }

    public save(): void {
        this.isSaving = true;
        this.hasError = false;
        this.checkApi
            .saveOutcomeSet(<any>this.outcomeSet)
            .then((newOutcomeSet: OutcomeSet) => {
                this.dialog.hide(newOutcomeSet);
            })
            .catch(() => {
                this.hasError = true;
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    public loadOutcomeSet(outcomeSetUuid: string): void {
        this.isLoading = true;
        this.checkApi
            .findOutcomeByUuidOrId(outcomeSetUuid)
            .then((outcomeLoaded: OutcomeSet) => {
                this.outcomeSet = outcomeLoaded;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public cancel(): void {
        this.dialog.cancel();
    }
}
