import { IItemReference } from './ItemReference';
import { LogicItemReference } from './LogicItemReference';
import { CalculationItemReference } from './CalculationItemReference';
import { PrePopulateDateItemReference } from './PrePopulateDateItemReference';

export class ItemReferenceFactory {
    public static build(type: string, properties?: Object): IItemReference {
        let reference: IItemReference;
        switch (type) {
            case 'logic':
                reference = new LogicItemReference();
                break;
            case 'calculation':
                reference = new CalculationItemReference();
                break;
            case 'pre_populate_date':
                reference = new PrePopulateDateItemReference();
                break;
            default:
                throw new Error('Invalid type provided to ItemReferenceFactory');
        }

        if (reference && properties) {
            for (let key in properties) {
                if (properties.hasOwnProperty(key) && reference.hasOwnProperty(key)) {
                    reference[key] = properties[key];
                }
            }
        }

        return reference;
    }
}
