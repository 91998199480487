import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ITopNavDropDownMenuItem } from '@intouch/its.essential/app/essential/components/TopNavBar';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { ICheckSession } from '../../services/CheckSession';
import { IOrganization } from '@intouch/its.essential/app/essential/domain/access/Organization';

/**
 * Main Controller for our application
 *
 * @constructor
 */
@Controller('its.check.module.main', AccountProblemController.IID, AccountProblemController)
class AccountProblemController {
    static IID: string = 'AccountProblemController';
    static $inject: Array<string> = ['iteAccessService', 'itcCheckSession', '$translate', '$window', 'APPCONFIG'];

    public topNavMenuItems: Array<ITopNavDropDownMenuItem>;
    public organizationLogoUrl: string;
    public translations: any;
    public subscriptionUrl: string;
    public org: IOrganization;

    /**
     *
     */
    constructor(
        private accessService: IAccessService,
        private session: ICheckSession,
        private translate: ng.translate.ITranslateService,
        private windowService: ng.IWindowService,
        private appConfig: any
    ) {
        this.subscriptionUrl = this.appConfig.access.subscriptionUrl;
        this.org = this.accessService.getToken().getOrganization();
        this.setupSideMenu();
    }

    /**
     * Logout the user from IntouchCheck
     */
    public logout(): void {
        this.accessService.handleLogout();
        this.session.flush();
    }

    public isAdmin(): boolean {
        return this.accessService.getToken().getUser().isAdmin();
    }

    /**
     * Setup the sites sidenav
     */
    private setupSideMenu(): void {
        let token: IToken = this.accessService.getToken();

        this.organizationLogoUrl = token.getOrganization().avatarThumbUrl;

        this.translate(['GENERAL.SWITCH_CLIENTS', 'GENERAL.GUIDE', 'ESSENTIAL.MODALS.WHATS_NEW'])
            .then((translations) => {
                this.translations = translations;
            })
            .finally(() => {
                this.topNavMenuItems = [
                    {
                        icon: 'help',
                        onClick: (): void => {
                            this.windowService.open('https://intouchcheck.zendesk.com/hc/en-us', '_blank');
                        },
                        label: this.translations['GENERAL.GUIDE'],
                    },
                ];
            });
    }
}
