import { ItemInput } from './ItemInput';

export class ItemDetailsProvider {
    private static items: any[] = [
        { group: 'input', type: 'check', name: 'CHECKLISTS.ITEM_TYPE.CHECK', icon: 'check' },
        { group: 'input', type: 'text', name: 'CHECKLISTS.ITEM_TYPE.TEXT_INPUT', icon: 'crop_7_5' },
        { group: 'input', type: 'radio', name: 'CHECKLISTS.ITEM_TYPE.RADIO_2', icon: 'radio_button_checked' },
        { group: 'input', type: 'checkbox', name: 'CHECKLISTS.ITEM_TYPE.CHECKBOX_2', icon: 'check_box' },
        { group: 'input', type: 'number', name: 'CHECKLISTS.ITEM_TYPE.NUMBER', icon: 'looks_one' },
        { group: 'input', type: 'date', name: 'CHECKLISTS.ITEM_TYPE.DATE', icon: 'date_range' },
        { group: 'input', type: 'calculation', name: 'CHECKLISTS.ITEM_TYPE.CALCULATION', icon: 'functions' },
        { group: 'input', type: 'dropdown', name: 'CHECKLISTS.ITEM_TYPE.DROPDOWN', icon: 'arrow_drop_down' },
        { group: 'input', type: 'time', name: 'CHECKLISTS.ITEM_TYPE.TIME', icon: 'access_time' },
        {
            group: 'input',
            type: 'temperature',
            name: 'CHECKLISTS.ITEM_TYPE.TEMPERATURE',
            icon: 'temperature-three-quarters',
        },
        { group: 'display', type: 'display_text', name: 'CHECKLISTS.ITEM_TYPE.DISPLAY_TEXT', icon: 'short_text' },
        { group: 'display', type: 'graphic', name: 'CHECKLISTS.ITEM_TYPE.GRAPHIC', icon: 'image' },
    ];

    public static getItems(): any[] {
        return ItemDetailsProvider.items;
    }

    public static getItemsWithout(exlude?: string): any[] {
        const questions: any[] = [];
        ItemDetailsProvider.items.forEach((value) => {
            if (value.type !== exlude) {
                questions.push(value);
            }
        });

        return questions;
    }

    public static getItemDetails(item: ItemInput): any {
        const type: string = item.getType();
        return _.find(ItemDetailsProvider.items, { type: type });
    }
}
