import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IItemInput, ItemInput } from './ItemInput';
import {
    IItemInputSettings,
    ItemInputSettings,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';

/**
 * The IItemDisplayTextSettings interface
 *
 */
export interface IItemDisplayTextSettings extends IItemInputSettings {
    displayItemLabel: boolean;
}

/**
 * The ItemDisplayTextSettings class
 *
 */
export class ItemDisplayTextSettings extends ItemInputSettings implements IItemDisplayTextSettings {
    public displayItemLabel: boolean = false;
}

/**
 * The IItemDisplayText interface
 *
 */
export interface IItemDisplayText extends IItemInput {
    settings: IItemDisplayTextSettings;
}

/**
 * The ItemText class
 *
 */
export class ItemDisplayText extends ItemInput implements IItemDisplayText {
    public settings: IItemDisplayTextSettings = new ItemDisplayTextSettings();
    public type: string = 'display_text';

    /**
     * Make a generic instance of this object
     *
     * @param name
     * @returns {IItemText}
     */
    public static make(name: string): IItemDisplayText {
        let me: IItemDisplayText = new ItemDisplayText();
        me.name = name;
        me.settings.allowCnaNa = false;

        return me;
    }

    /**
     * Check if the Display Item Label is of type display_text
     *  @returns {boolean}
     */
    public displayItemLabel(): boolean {
        return this.settings.displayItemLabel;
    }

    /**
     * Checks if this item can have a photo added to it
     *
     * @returns {boolean}
     */
    public canAddPhoto(): boolean {
        return false;
    }

    /**
     * Checks if this item can have comments added to it
     *
     * @returns {boolean}
     */
    public canAddComment(): boolean {
        return false;
    }

    /**
     * Checks if this item can be follow-up required
     *
     * @returns {boolean}
     */
    public canFollowUpRequire(): boolean {
        return false;
    }

    /**
     * Builds the object from json
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {ItemText}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemDisplayText {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['settings']) {
            this.settings = EntityBuilder.buildOne<IItemDisplayTextSettings>(
                ItemDisplayTextSettings,
                jsonObject['settings'],
                convertToCamel
            );
        }

        return this;
    }
}
