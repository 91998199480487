import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IItemAdditionalRequirements } from '../../../../../../domain/checklists/ItemAdditionalRequirements';
import { IWithAdditionalRequirements } from '../../../../../../domain/checklists/WithAdditionalRequirements';
import * as _ from 'lodash';

@Component('its.check.module.checklists', PhotoRequiredSetting.CID, {
    template: require('/app/modules/checklists/components/items/shared/photo-required-setting/PhotoRequiredSetting.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class PhotoRequiredSetting {
    static IID: string = 'PhotoRequiredSetting';
    static CID: string = 'itcPhotoRequiredSetting';

    static $inject: Array<string> = [];
    public item: IWithAdditionalRequirements;
    public photoRequired: boolean = false;

    $onInit(): void {
        if (
            this.item.additionalRequirements &&
            this.item.additionalRequirements.type &&
            this.item.additionalRequirements.type === 'photo' &&
            this.item.additionalRequirements.condition &&
            this.item.additionalRequirements.condition === 'always'
        ) {
            this.photoRequired = true;
        }
    }

    public setRequiredInfo(): void {
        this.item.additionalRequirements = this.photoRequired
            ? <IItemAdditionalRequirements>{ type: 'photo', condition: 'always' }
            : null;
    }
}
