import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IItemInput, ItemInput } from './ItemInput';
import {
    IItemInputSettings,
    ItemInputSettings,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import { IWithAdditionalRequirements } from './WithAdditionalRequirements';
import { IItemAdditionalRequirements, ItemAdditionalRequirements } from './ItemAdditionalRequirements';

/**
 * The IItemNumberSettings interface
 *
 */
export interface IItemNumberSettings extends IItemInputSettings {
    min: number;
    max: number;
    roundTo: number;
    displayRange: boolean;
    daysToResolveProblems: number;
}

/**
 * The ItemNumberSettings class
 *
 */
export class ItemNumberSettings extends ItemInputSettings implements IItemNumberSettings {
    public min: number = null;
    public max: number = null;
    public roundTo: number = 0;
    public displayRange: boolean = false;
    public daysToResolveProblems: number = null;

    /**
     * Return a floating point number representing the "step" attribute for the numerical range field.
     *
     * @returns {number}
     */
    public getRangeAccuracy(): number {
        if (this.roundTo === null || this.roundTo === undefined) {
            return 1;
        }

        return 1 / Math.pow(10, this.roundTo);
    }
}

/**
 * The IItemNumber interface
 *
 */
export interface IItemNumber extends IItemInput, IWithAdditionalRequirements {
    settings: IItemNumberSettings;

    isInRange(num: number): boolean;

    hasRangeMin(): boolean;

    hasRangeMax(): boolean;
}

/**
 * The ItemNumber class
 *
 */
export class ItemNumber extends ItemInput implements IItemNumber {
    public type: string = 'number';
    public settings: IItemNumberSettings = new ItemNumberSettings();
    public points: number;
    public additionalRequirements: IItemAdditionalRequirements = null;

    /**
     * Make a generic instance of this object
     *
     * @param name
     * @returns {IItemInput}
     */
    public static make(name: string): IItemInput {
        let me: IItemNumber = new ItemNumber();
        me.name = name;
        me.name = name;

        return me;
    }

    /**
     * Returns if object is allowed as a logic subject
     *
     */
    public allowAsLogicSubject(): boolean {
        return true;
    }

    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemNumber {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['settings']) {
            this.settings = EntityBuilder.buildOne<IItemNumberSettings>(
                ItemNumberSettings,
                jsonObject['settings'],
                convertToCamel
            );
        }

        if (jsonObject['additional_requirements']) {
            this.additionalRequirements = EntityBuilder.buildOne<IItemAdditionalRequirements>(
                ItemAdditionalRequirements,
                jsonObject['additional_requirements'],
                convertToCamel
            );
        } else if (jsonObject['photo_required']) {
            this.additionalRequirements = new ItemAdditionalRequirements().fromJson(
                {
                    type: 'photo',
                    condition: 'always',
                },
                true
            );
            delete (<any>this).photoRequired;
        }

        return this;
    }

    /**
     * Check if a given number is within range (if we have a range)
     *
     * @param num
     * @returns {boolean}
     */
    public isInRange(num: number): boolean {
        if (this.settings.min !== null && this.settings.min !== null) {
            return num >= this.settings.min && num <= this.settings.max;
        } else {
            return true;
        }
    }

    /**
     * Returns true if a minimum range is set
     *
     * @return {boolean}
     */
    public hasRangeMin(): boolean {
        return NumberUtils.isNumber(this.settings.min);
    }

    /**
     * Returns true if a maximum range is set
     *
     * @return {boolean}
     */
    public hasRangeMax(): boolean {
        return NumberUtils.isNumber(this.settings.max);
    }
}
