import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemInput } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';

@Component('its.check.module.checklists', RequireFollowupSetting.CID, {
    template: require('/app/modules/checklists/components/items/shared/require-followup-setting/RequireFollowupSetting.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class RequireFollowupSetting {
    static IID: string = 'RequireFollowupSetting';
    static CID: string = 'itcRequireFollowupSetting';
    static $inject: string[] = [];

    public item: BaseItemInput;
    public hasFollowupDue: boolean = false;

    $onInit(): void {
        if (this.item.settings.daysToResolveProblems) {
            this.hasFollowupDue = true;
        }
    }

    public toggleFollowupDue(): void {
        if (!this.hasFollowupDue) {
            this.item.settings.daysToResolveProblems = null;
        }
    }
}
