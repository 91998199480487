import { IItemInput, ItemInput } from './ItemInput';
import { IItemPrePopulate, ItemPrePopulate } from './ItemPrePopulate';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';

export interface IItemDate extends IItemInput {
    prePopulateDate: IItemPrePopulate;
    addPrePopulateDate(prepopulate: ItemPrePopulate): void;
    clearPrePopulate(): void;
}

export class ItemDate extends ItemInput implements IItemDate {
    public type: string = 'date';
    public prePopulateDate: IItemPrePopulate = null;

    /**
     * Make a generic instance of this object
     *
     * @param name
     * @returns {IItemDate}
     */
    public static make(name: string): IItemDate {
        let me: IItemDate = new ItemDate();
        me.name = name;

        return me;
    }

    public clearPrePopulate(): void {
        this.prePopulateDate = null;
    }

    /**
     * Returns if object is allowed as a logic subject
     *
     */
    public allowAsLogicSubject(): boolean {
        return true;
    }

    /**
     * Adds a prePopulateDate object
     *
     * @param prePopulateDate
     */
    public addPrePopulateDate(prePopulateDate?: IItemPrePopulate): void {
        if (!prePopulateDate) {
            prePopulateDate = new ItemPrePopulate();
        }

        this.prePopulateDate = prePopulateDate;
    }

    /**
     * Builds the object from json
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {ItemPrePopulate}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): ItemDate {
        super.fromJson(jsonObject, convertToCamel);
        if (jsonObject['pre_populate_date']) {
            this.prePopulateDate = EntityBuilder.buildOne<IItemPrePopulate>(
                ItemPrePopulate,
                jsonObject['pre_populate_date'],
                convertToCamel
            );
        }

        return this;
    }
}
