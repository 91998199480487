import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.check.module.checklists', ToggleHeaderComponent.CID, {
    template: require('/app/modules/checklists/components/items/shared/toggle-header/ToggleHeader.html'),
    controllerAs: 'vm',
    bindings: {
        expanded: '=',
        ngDisabled: '<',
        header: '@',
        description: '@',
    },
})
class ToggleHeaderComponent {
    static IID: string = 'ToggleHeaderComponent';
    static CID: string = 'itcToggleHeader';
    static $inject: string[] = [];

    public expanded: boolean;
    public ngDisabled: boolean;
    public header: string;
    public description: string;
}
