import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Checklist, IChecklist } from '../../../domain/checklists/Checklist';
import { ICheckApi } from '../../../api/CheckApi';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { ITemplate } from '@intouch/its.essential/app/essential/domain/template-library/Template';
import { ChecklistDuplicator } from '../../../domain/checklists/ChecklistDuplicator';
import View from './ChecklistCreateModal.html';

export class ChecklistCreateModal extends Modal {
    static $inject: Array<string> = ['$mdDialog', 'itcCheckApi', 'iteToaster', '$scope'];

    public checklist: IChecklist = new Checklist();
    public checklistTemplate: ITemplate;
    public saving: boolean = false;
    public hasError: boolean = false;
    public formName: string = 'checklistCreate';
    public loading: boolean = false;
    public loadingMore: boolean = false;
    public sourceChecklist: IChecklist;

    /**
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = View;
        config.controller = ChecklistCreateModal;
        return super.instantiate(config);
    }

    /**
     * @param dialog
     * @param checkApi
     * @param toaster
     * @param scope
     */
    constructor(
        private dialog: ng.material.IDialogService,
        private checkApi: ICheckApi,
        private toaster: IToaster,
        private scope: ng.IScope
    ) {
        super();

        if (this.checklistTemplate) {
            this.checklist.name = this.checklistTemplate.name;
        }
    }

    /**
     * Allows the user to create a new checklist; will close the modal and redirect to the edit screen
     */
    public create(): void {
        this.saving = true;

        if (this.checklistTemplate) {
            this.createFromTemplate();
        } else {
            this.createFromScratch();
        }
    }

    /**
     * Handler to trigger on checklist name change
     *
     */
    public onNameChange(): void {
        // clear name duplication exception
        if (this.getForm()) {
            this.getForm().name.$setValidity('ChecklistAlreadyExistsException', true);
        }
    }

    /**
     * Returns the form
     *
     * @return {any}
     */
    public getForm(): any {
        return this.scope[this.formName];
    }

    /**
     * Cancel the dialog
     */
    public cancel(): void {
        this.dialog.cancel();
    }

    public isValid(): boolean {
        return !!this.checklist.name;
    }

    /**
     * Handler for error cases on http responses
     *
     * @param {IErrorResponse} error
     * @param {string} defaultMessage
     * @param {string} defaultToast
     */
    private handleHttpResponseErrors(
        error: IErrorResponse,
        defaultMessage: string = null,
        defaultToast: string = null
    ): void {
        if (!error) {
            error.message = defaultMessage + ' (' + error.message + ')';
            this.toaster.warn(defaultToast);
        } else {
            switch (error.type) {
                case 'ChecklistAlreadyExistsException':
                    if (this.getForm()) {
                        this.getForm().name.$setValidity('ChecklistAlreadyExistsException', false);
                    }
                    break;
                default:
                    error.message = defaultMessage + ' (' + error.message + ')';
                    this.toaster.warn(defaultToast);
            }
        }
    }

    private createFromScratch(): void {
        this.saving = true;

        this.checkApi
            .createChecklist(this.checklist.name)
            .then((result: IChecklist) => {
                if (this.sourceChecklist) {
                    // either call duplicate route or create then updated

                    this.checkApi
                        .findChecklist(this.sourceChecklist.uuid)
                        .then((sourceChecklist: IChecklist) => {
                            let duplicateChecklist: IChecklist = ChecklistDuplicator.duplicate(sourceChecklist, result);

                            this.checkApi
                                .updateChecklist(duplicateChecklist)
                                .then((duplicateChecklistResult: IChecklist) => {
                                    this.dialog.hide(duplicateChecklistResult);
                                })
                                .catch((error: IErrorResponse) => {
                                    this.handleHttpResponseErrors(
                                        error,
                                        'Failed to update duplicated checklist',
                                        'CHECKLISTS.ERRORS.UNABLE_TO_DUPLICATE_CHECKLIST'
                                    );
                                });
                        })
                        .catch((error: IErrorResponse) => {
                            this.handleHttpResponseErrors(
                                error,
                                'Failed to download source checklist',
                                'CHECKLISTS.ERRORS.UNABLE_TO_DUPLICATE_CHECKLIST'
                            );
                        });
                } else {
                    this.dialog.hide(result);
                }
            })
            .catch((error: IErrorResponse) => {
                this.handleHttpResponseErrors(
                    error,
                    'Failed to create new checklist',
                    'CHECKLISTS.ERRORS.UNABLE_TO_CREATE_NEW_CHECKLIST'
                );
            })
            .finally(() => {
                this.saving = false;
            });
    }

    private createFromTemplate(): void {
        this.checkApi
            .createChecklistFromTemplate(this.checklist.name, this.checklistTemplate.uuid)
            .then((newChecklist: IChecklist) => {
                this.dialog.hide(newChecklist);
            })
            .catch((error: IErrorResponse) => {
                this.handleHttpResponseErrors(error);
            })
            .finally(() => {
                this.saving = false;
            });
    }
}
