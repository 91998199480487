'use strict';

// if this file does not exist, you must run `gulp build:config`
import config from '../../config';

let module: any = angular.module('its.check.module.main', []);
module.constant('APPCONFIG', config);

import './_configuration';
import './LoginCallbackController';
import './MainController';
import './AccountProblemController';

export default module;
