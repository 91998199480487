import * as _ from 'lodash';

export abstract class BaseTour {
    public steps: any[] = [];

    public constructor(protected translate: ng.translate.ITranslateService) {}

    public abstract getTourLocalStorageId(): string;

    protected getBaseStep(stepOptions: any): any {
        return _.defaultsDeep(stepOptions, {
            popperOptions: {
                modifiers: [{ name: 'offset', options: { offset: [0, 12] } }],
            },
            scrollTo: { behavior: 'smooth', block: 'center' },
        });
    }

    protected getNextButton(classes: Array<string> = []): any {
        const text: string = this.translate.instant('ESSENTIAL.NAV.NEXT');
        return {
            action: function (): void {
                this.next();
            },
            text: text,
            classes: classes.join(' '),
        };
    }

    protected getLetsGoButton(classes: Array<string> = []): any {
        const text: string = this.translate.instant('GENERAL.LETS_GO') + '!';
        return {
            action: function (): void {
                this.next();
            },
            text: text,
            classes: classes.join(' '),
        };
    }

    protected getSkipTourButton(classes: Array<string> = []): any {
        const translatedSkipTour: string = this.translate.instant('GENERAL.SKIP_TOUR');
        const lowerCasedSkipTour: string = translatedSkipTour.toLocaleLowerCase();
        return {
            action: function (): void {
                this.complete();
            },
            text: lowerCasedSkipTour,
            secondary: true,
            classes: classes.join(' '),
        };
    }

    protected getFinishButton(classes: Array<string> = []): any {
        return {
            action: function (): void {
                this.complete();
            },
            text: this.translate.instant('GENERAL.FINISH'),
            classes: classes.join(' '),
        };
    }
}
