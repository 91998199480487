import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { OutcomeRangeTranslation } from '@intouch/its.check.essential/app/check-essential/domain/checklists/outcomes/OutcomeRangeTranslation';
import { OutcomeRange } from '@intouch/its.check.essential/app/check-essential/domain/checklists/outcomes/OutcomeRange';

/**
 * The UI for rendering a way to add and remove simple outcome translations
 */
@Component('its.check.components', OutcomeTranslation.CID, {
    template: require('/app/components/translations/OutcomeTranslation.html'),
    controllerAs: 'vm',
    bindings: {
        outcomeRange: '=',
        locale: '<',
    },
})
class OutcomeTranslation {
    static IID: string = 'OutcomeTranslation';
    static CID: string = 'itcOutcomeTranslation';
    static $inject: Array<string> = [];

    public locale: string;
    public outcomeRange: OutcomeRange;
    public outcomeTranslation: OutcomeRangeTranslation = null;

    public $onInit(): void {
        this.outcomeTranslation = this.outcomeRange.getTranslation(this.locale);
    }
}
