import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ChecklistEditService } from '../../../../services/ChecklistEditService';
import { ItemDetailsProvider } from '../../../../domain/checklists/ItemDetailsProvider';
import { ItemInput } from '../../../../domain/checklists/ItemInput';
import { ChecklistService } from '../../../../services/ChecklistService';
import { Checklist } from '../../../../domain/checklists/Checklist';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import {
    DefaultItemSearchStrategy,
    IItemReferenceFinder,
    ItemReferenceFinder,
} from '../../../../domain/checklists/item-reference/ItemReferenceFinder';
import { IItemReference } from '../../../../domain/checklists/item-reference/ItemReference';
import { ItemReferenceDependencyWarningModal } from '../../modals/ItemReferenceDependencyWarningModal';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';

@Component('its.check.module.checklists', ChecklistItemSettings.CID, {
    template: require('/app/modules/checklists/components/items/ChecklistItemSettings.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
        onSave: '&',
    },
})
class ChecklistItemSettings {
    static IID: string = 'ChecklistItemSettings';
    static CID: string = 'itcChecklistItemSettings';

    static $inject: Array<string> = [
        'itcChecklistEditService',
        'itcChecklistService',
        '$scope',
        '$translate',
        '$mdDialog',
        'iteToaster',
    ];
    public item: ItemInput;
    public onSave: Function;
    public isItemParentTime: boolean = false;
    public editingItemDetails: any = null;
    public itemSettingsForm: ng.IFormController = null;
    public isSaving: boolean = false;
    private checklist: Checklist = null;

    public constructor(
        private checklistEditService: ChecklistEditService,
        private checklistService: ChecklistService,
        private scope: ng.IScope,
        private translate: angular.translate.ITranslateService,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster
    ) {
        const subscription: SubjectSubscription = this.checklistService.checklistObserver.subscribe((checklist) => {
            this.checklist = <Checklist>checklist;
        });
        subscription.unsubscribeOnScopeDestruction(scope);
    }

    public $onInit(): void {
        this.editingItemDetails = ItemDetailsProvider.getItemDetails(this.item);
    }

    public isFormValid(form: ng.IFormController): boolean {
        return (
            form &&
            form.$valid &&
            (this.item.type !== 'calculation' || this.isCalculationAndValid()) &&
            (this.item.type !== 'display_text' || this.isDisplayTextAndValid())
        );
    }

    public isCalculationAndValid(): boolean {
        return this.item.type === 'calculation' && (<any>this.item.settings).calculations.length > 0;
    }

    public isDisplayTextAndValid(): boolean {
        return this.item.type === 'display_text' && this.item.description && this.item.description.length > 0;
    }

    public cancelEdit(): void {
        this.checklistEditService.cancelEdit();
    }

    public saveCallback(): void {
        this.isSaving = true;
        this.onSave({ checklist: this.checklist }).finally(() => {
            this.isSaving = false;
        });
    }

    /**
     * Will delete the question from the checklist
     */
    public deleteQuestion(): void {
        this.translate([
            'CHECKLISTS.MESSAGES.DELETE_QUESTION',
            'GENERAL.DELETE',
            'CHECKLISTS.MESSAGES.CONFIRM_CANCEL',
            'CHECKLISTS.MESSAGES.DELETE_QUESTION_TEXT',
        ]).then((translations) => {
            this.dialog
                .show(
                    Confirm.instantiate({
                        locals: {
                            title: translations['CHECKLISTS.MESSAGES.DELETE_QUESTION'],
                            description: translations['CHECKLISTS.MESSAGES.DELETE_QUESTION_TEXT'],
                            confirmText: translations['GENERAL.DELETE'],
                            cancelText: translations['CHECKLISTS.MESSAGES.CONFIRM_CANCEL'],
                            confirmButtonCssClass: 'its-btn--delete',
                        },
                    })
                )
                .then((result) => {
                    if (result) {
                        let referenceFinder: IItemReferenceFinder = new ItemReferenceFinder(
                            new DefaultItemSearchStrategy(this.checklist, this.item)
                        );
                        let dependencies: Array<IItemReference> = referenceFinder.find();
                        if (dependencies && dependencies.length > 0) {
                            this.dialog
                                .show(
                                    ItemReferenceDependencyWarningModal.instantiate({
                                        locals: {
                                            dependencies: dependencies,
                                            checklist: this.checklist,
                                            localizedTitle: 'CHECKLISTS.DELETE_DEPENDENCIES',
                                        },
                                    })
                                )
                                .then(() => {
                                    this.completeQuestionDelete();
                                });
                        } else {
                            this.completeQuestionDelete();
                        }
                    }
                });
        });
    }

    private completeQuestionDelete(): void {
        this.checklist.removeItem(this.item);
        this.saveCallback();
        this.toaster.success('CHECKLISTS.MESSAGES.QUESTION_DELETED');
    }
}
