'use strict';

import { Configuration } from '@intouch/its.essential/app/essential/decorators/Configuration';

/**
 * Will setup the users routing states
 *
 * @param $stateProvider
 */
@Configuration('its.check.module.checklists', ChecklistsConfiguration)
class ChecklistsConfiguration {
    /**
     * Create the instance of the configuration used for angular binding
     *
     * @returns {any}
     */
    public static instance(): any {
        let config: any = ($stateProvider: ng.ui.IStateProvider) => new ChecklistsConfiguration($stateProvider);
        config.$inject = ['$stateProvider'];
        return config;
    }

    /**
     * Create the user configuration class
     *
     * @param stateProvider
     */
    constructor(private stateProvider: ng.ui.IStateProvider) {
        this.buildStates();
    }

    /**
     * Initialize the user module
     */
    public buildStates(): void {
        this.stateProvider
            .state('home.checklists', {
                abstract: true,
                url: '/checklists',
                template: '<div layout="row" flex><div ui-view layout="column" flex></div></div>',
            })
            .state('home.checklists.create', {
                url: '/create?page&sort_by&order&search&category',
                data: { title: 'CHECKLISTS.NEW_CHECKLIST', translate: true },
                template: require('/app/modules/checklists/views/Create.html'),
                controller: 'its.check.module.checklists.CreateController as vm',
                params: {
                    page: {
                        value: '1',
                        squash: true,
                    },
                    sort_by: {
                        value: 'name',
                        squash: true,
                    },
                    order: {
                        value: 'asc',
                        squash: true,
                    },
                    search: {
                        value: null,
                        squash: true,
                    },
                    category: {
                        value: null,
                        squash: true,
                    },
                },
            })
            .state('home.checklists.list', {
                abstract: true,
                template: require('/app/modules/checklists/views/Layout.html'),
                controller: 'its.check.module.checklists.ChecklistMainController as vm',
            })
            .state('home.checklists.list.all', {
                url: '/list?page&sort_by&order&view',
                template: require('/app/modules/checklists/views/ChecklistsList.html'),
                controller: 'ChecklistsListController as vm',
                data: {
                    title: 'GENERAL.CHECKLISTS',
                    translate: true,
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                    },
                    sort_by: {
                        value: 'updated_at',
                        squash: true,
                    },
                    order: {
                        value: 'desc',
                        squash: true,
                    },
                },
            })
            .state('home.checklists.list.preset', {
                url: '/list/preset/:presetName?page&sort_by&order&view',
                template: require('/app/modules/checklists/views/ChecklistsList.html'),
                controller: 'ChecklistsListController as vm',
                data: {
                    title: 'GENERAL.CHECKLISTS',
                    translate: true,
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                    },
                    sort_by: {
                        value: 'updated_at',
                        squash: true,
                    },
                    order: {
                        value: 'desc',
                        squash: true,
                    },
                },
            })
            .state('home.checklists.edit', {
                url: '/edit/:uuid',
                template: require('/app/modules/checklists/views/ChecklistEdit.html'),
                controller: 'ChecklistEditController as vm',
                params: {
                    uuid: {
                        value: null,
                    },
                    uploaded: null,
                    section: null,
                },
            })
            .state('home.checklists.print', {
                url: '/edit/:uuid/print',
                template: require('/app/modules/checklists/views/ChecklistPrint.html'),
                controller: 'ChecklistPrintController as vm',
                params: {
                    uuid: {
                        value: null,
                    },
                },
            })
            .state('home.checklists.translate', {
                url: '/edit/:uuid/translations',
                template: require('/app/modules/checklists/views/ChecklistTranslations.html'),
                controller: 'ChecklistTranslationsController as vm',
            })
            .state('home.checklists.settings.upload', {
                url: '/edit/:uuid/upload',
                template: require('/app/modules/checklists/upload/views/ChecklistUpload.html'),
                controller: 'ChecklistUploadController as vm',
            })
            .state('home.checklists.settings.upload.step1', {
                url: '/step1',
                template: require('/app/modules/checklists/upload/views/Step1.html'),
                controller: 'ChecklistUploadStep1Controller as vm',
                data: {
                    title: 'GENERAL.SETTINGS',
                    translate: true,
                },
            })
            .state('home.checklists.settings.upload.step2', {
                url: '/step2/:fileUuid',
                template: require('/app/modules/checklists/upload/views/Step2.html'),
                controller: 'ChecklistUploadStep2Controller as vm',
                data: {
                    title: 'GENERAL.SETTINGS',
                    translate: true,
                },
            })
            .state('home.checklists.settings.upload.step3', {
                url: '/step3/:fileUuid',
                template: require('/app/modules/checklists/upload/views/Step3.html'),
                controller: 'ChecklistUploadStep3Controller as vm',
                data: {
                    title: 'GENERAL.SETTINGS',
                    translate: true,
                },
            })
            .state('home.checklists.settings', {
                url: '/edit/:uuid/settings',
                template: require('/app/modules/checklists/settings/views/ChecklistSettings.html'),
                controller: 'ChecklistSettingsController as vm',
                abstract: true,
            })
            .state('home.checklists.settings.general', {
                url: '/general',
                template: require('/app/modules/checklists/settings/views/ChecklistGeneralSettings.html'),
                controller: 'ChecklistGeneralSettingsController as vm',
                data: {
                    title: 'GENERAL.CHECKLIST_SETTINGS',
                    translate: true,
                },
            })
            .state('home.checklists.settings.randomization', {
                url: '/randomization',
                data: {
                    title: 'GENERAL.SETTINGS',
                    translate: true,
                },
                template: require('/app/modules/checklists/settings/views/ChecklistRandomizationSettings.html'),
                controller: 'ChecklistRandomizationSettingsController as vm',
            })
            .state('home.checklists.settings.scheduler', {
                url: '/scheduler',
                data: {
                    title: 'GENERAL.SETTINGS',
                    translate: true,
                },
                template: require('/app/modules/checklists/settings/views/ChecklistSchedulerSettings.html'),
                controller: 'ChecklistSchedulerSettingsController as vm',
            })
            .state('home.checklists.settings.translations', {
                url: '/translations',
                data: {
                    title: 'GENERAL.SETTINGS',
                    translate: true,
                },
                template: require('/app/modules/checklists/settings/views/ChecklistTranslationsSettings.html'),
                controller: 'ChecklistTranslationsSettingsController as vm',
            })
            .state('home.checklists.outcomes', {
                url: '/edit/:uuid/outcomes',
                data: {
                    title: 'CHECKLISTS.CHECKLIST_OUTCOMES.TITLE',
                    translate: true,
                },
                template: require('/app/modules/checklists/outcomes/views/ChecklistOutcomesSettings.html'),
                controller: 'ChecklistOutcomesSettingsController as vm',
            });
    }
}
