import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { ICheckApi } from '../../../api/CheckApi';
import { Checklist, IChecklist } from '../../../domain/checklists/Checklist';
import { IProgramCollectionItem } from '@intouch/its.essential/app/essential/domain/programs/ProgramCollectionItem';
import View from './ChecklistCopyModal.html';

export class ChecklistCopyModal extends Modal {
    static $inject: Array<string> = ['$mdDialog', 'itcCheckApi', 'iteToaster', '$scope'];

    public checklistToCopy: IChecklist;
    public formName: string = 'checklistCopyForm';
    public checklist: IChecklist = new Checklist();
    public checklistToCopyUuid: string = null;
    public creating: boolean = false;
    public selectingChecklistFromModal: boolean = false;
    public checklistProgramCollectionItem: IProgramCollectionItem = null;

    /**
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = View;
        config.controller = ChecklistCopyModal;

        return super.instantiate(config);
    }

    public constructor(
        private dialog: ng.material.IDialogService,
        private checkApi: ICheckApi,
        private toaster: IToaster,
        private scope: ng.IScope
    ) {
        super();

        // if a checklist is not given initially, set a flag for the view to indicate the user needs to pick one.
        if (this.checklistToCopy) {
            this.checklistToCopyUuid = this.checklistToCopy.uuid;
        } else {
            this.selectingChecklistFromModal = true;
        }
    }

    public copy(): void {
        this.creating = true;
        this.checkApi
            .copyChecklist(this.checklistToCopyUuid, this.checklist)
            .then((newChecklist: IChecklist) => {
                this.dialog.hide(newChecklist);
            })
            .catch((error: IErrorResponse) => {
                if (error && error.type && error.type === 'ChecklistAlreadyExistsException') {
                    this.scope[this.formName].name.$setValidity('ChecklistAlreadyExistsException', false);
                } else {
                    this.toaster.error(
                        'CHECKLISTS.ERRORS.UNABLE_TO_CREATE_NEW_CHECKLIST',
                        'Failed to create new checklist: ' + this.checklist.name
                    );
                }
            })
            .finally(() => {
                this.creating = false;
            });
    }

    public cancel(): void {
        this.dialog.cancel();
    }

    public getForm(): any {
        return this.scope[this.formName];
    }

    public onNameChange(): void {
        // clear name duplication exception
        if (this.getForm()) {
            this.getForm().name.$setValidity('ChecklistAlreadyExistsException', true);
        }
    }

    public selectChecklistToCopy(): void {
        this.checklistToCopyUuid = this.checklistProgramCollectionItem.revisionUuid;
    }
}
