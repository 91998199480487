import {
    IOutcomeSet as IBaseOutcomeSet,
    OutcomeSet as BaseOutcomeSet,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/outcomes/OutcomeSet';

export interface IOutcomeSet extends IBaseOutcomeSet {
    id: number;
}

export class OutcomeSet extends BaseOutcomeSet implements IOutcomeSet {
    public id: number = null;
}
