'use strict';

import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IPhotoService, IParsedImage } from '@intouch/its.essential/app/essential/services/PhotoService';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IAuditPhoto, AuditPhoto } from '@intouch/its.check.essential/app/check-essential/domain/audits/AuditPhoto';
import { IItemInput } from '../../../domain/checklists/ItemInput';
import { SecureFileServiceApi } from '@intouch/its.check.essential/app/check-essential/api/SecureFileServiceApi';

/**
 * The UI for rendering a way to add and remove example photos on checklist items
 */
@Component('its.check.module.checklists', ExamplePhoto.CID, {
    template: require('/app/modules/checklists/components/ExamplePhoto.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
        translationLabel: '<',
    },
})
class ExamplePhoto {
    static IID: string = 'ExamplePhoto';
    static CID: string = 'itsExamplePhoto';

    static $inject: Array<string> = ['iteToaster', 'itePhotoService', 'itcSecureFileServiceApi'];

    /**
     * Create the class
     *
     * @param toaster
     * @param photoService
     * @param checkApi
     */
    constructor(
        private toaster: IToaster,
        private photoService: IPhotoService,
        private secureFileServiceApi: SecureFileServiceApi
    ) {}

    /**
     * Will remove the example photo from the item
     *
     * @param item
     */
    public removePhoto(item: IItemInput): void {
        item.examplePhoto = null;
    }

    /**
     * Uploads photo and adds url to item's example photo
     *
     * @param item
     * @param file
     */
    public addPhoto(item: IItemInput, file: File): void {
        let file_label: string = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
        let file_name: string = file.name;
        let type: string = file.type;

        this.photoService
            .parse(file, undefined, undefined, true, undefined, undefined, 0.8, 2000)
            .then((image: IParsedImage) => {
                let photo: IAuditPhoto = new AuditPhoto();
                photo.fromParsedImage(image);
                this.secureFileServiceApi.upload({ type, file_name, file_label }, file).then((result) => {
                    photo.fileUuid = result.uuid;
                    item.examplePhoto = photo;
                    photo.data = null;
                });
            })
            .catch((error) => {
                this.toaster.warn('GENERAL.ERRORS.PHOTO_NOT_ADDED');
            });
    }
}
