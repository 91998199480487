export abstract class BaseItemSettingsComponent {
    public tab: string = 'basic';
    public tabs: string[] = BaseItemSettingsComponent.defaultTabs();

    public advancedSettingsExpanded: boolean = true;
    public additionalInstructionsExpanded: boolean = false;
    public examplePhotoExpanded: boolean = false;

    public static defaultTabs(): string[] {
        return ['basic', 'advanced', 'logic'];
    }

    public static getBindings(): any {
        return {
            item: '<',
        };
    }

    public showBasicSettings(): boolean {
        return this.tab === 'basic';
    }

    public showAdvancedSettings(): boolean {
        return this.tab === 'advanced';
    }

    public showTagsSettings(): boolean {
        return this.tab === 'tags';
    }

    public showLogicSettings(): boolean {
        return this.tab === 'logic';
    }

    public expandSettingsSection(section: string): void {
        switch (section) {
            case 'advanced':
                this.advancedSettingsExpanded = !this.advancedSettingsExpanded;
                this.additionalInstructionsExpanded = false;
                this.examplePhotoExpanded = false;
                break;
            case 'info':
                this.additionalInstructionsExpanded = !this.additionalInstructionsExpanded;
                this.advancedSettingsExpanded = false;
                this.examplePhotoExpanded = false;
                break;
            case 'photo':
                this.examplePhotoExpanded = !this.examplePhotoExpanded;
                this.additionalInstructionsExpanded = false;
                this.advancedSettingsExpanded = false;
                break;
            default:
                break;
        }
    }

    public handleTabChange(activeTab: string): void {
        this.tab = activeTab;
    }
}
