import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { ICheckApi } from '../api/CheckApi';

export interface IAuditCounts {
    approval: number;
    reviewable: number;
    approval_completed: number;
    incomplete: number;
    admin_assigned: number;
    deleted: number;
}

/**
 * The user service interface
 */
export interface IAuditService {
    fetchCounts(): ng.IPromise<any>;

    getCounts(): IAuditCounts;

    deleteMissedAudit(uuid: string): ng.IPromise<any>;

    deleteAudit(uuid: string, force?: boolean): ng.IPromise<any>;

    deleteUpcomingAudit(uuid: string): ng.IPromise<any>;
}

/**
 * The user service class
 */
@Service('its.check.services', AuditService.IID, AuditService)
class AuditService implements IAuditService {
    static IID: string = 'itcAuditService';
    static $inject: Array<string> = ['itcCheckApi', '$q'];

    protected loadingAuditData: boolean = false;
    protected auditCounts: IAuditCounts = {
        approval: 0,
        reviewable: 0,
        approval_completed: 0,
        incomplete: 0,
        admin_assigned: 0,
        deleted: 0,
    };

    /**
     * The  class constructor
     *
     * @param checkApi
     * @param q
     */
    public constructor(private checkApi: ICheckApi, private q: ng.IQService) {}

    /**
     * Get audit counts
     *
     */
    public getCounts(): IAuditCounts {
        return this.auditCounts;
    }

    /**
     * Fetch audit counts
     *
     */
    public fetchCounts(): ng.IPromise<any> {
        let defer: ng.IDeferred<any> = this.q.defer();

        let promises: Array<ng.IPromise<any>> = [
            this.checkApi
                .findReviewableAudits(Pager.make(1, 'submitted_at', 'desc', 1))
                .then((result: PagedEntities) => {
                    this.auditCounts['reviewable'] = result.getPager().total;
                }),
            this.checkApi
                .findApprovableAudits(Pager.make(1, 'submitted_at', 'desc', 1))
                .then((result: PagedEntities) => {
                    this.auditCounts['approval'] = result.getPager().total;
                }),
            this.checkApi
                .findCompletedAudits(Pager.make(1, 'submitted_at', 'desc', 1))
                .then((result: PagedEntities) => {
                    this.auditCounts['approval_completed'] = result.getPager().total;
                }),
            this.checkApi
                .findIncompleteAudits(Pager.make(1, 'should_have_completed_by', 'desc', 1))
                .then((result: PagedEntities) => {
                    this.auditCounts['incomplete'] = result.getPager().total;
                }),
            this.checkApi.findUpcomingAudits(Pager.make(1, 'start_by', 'desc', 1)).then((result: PagedEntities) => {
                this.auditCounts['admin_assigned'] = result.getPager().total;
            }),
            this.checkApi.findInProgressAudits(Pager.make(1, 'updated_at', 'desc', 1)).then((result: PagedEntities) => {
                this.auditCounts['in_progress'] = result.getPager().total;
            }),
            this.checkApi.findTrashedAudits(Pager.make(1, 'deleted_at', 'desc', 1)).then((result: PagedEntities) => {
                this.auditCounts['deleted'] = result.getPager().total;
            }),
        ];

        this.q
            .all(promises)
            .then(() => {
                defer.resolve(this.auditCounts);
            })
            .catch((e) => {
                defer.reject(e);
            });

        return defer.promise;
    }

    /**
     * Deletes the missed audit
     *
     * @param {string} uuid
     * @returns {angular.IPromise<any>}
     */
    public deleteMissedAudit(uuid: string): ng.IPromise<any> {
        return this.checkApi.deleteMissedAudits(uuid).then((result) => {
            return result;
        });
    }

    /**
     * Deletes the audit
     *
     * @param {string} uuid
     * @param force
     * @returns {angular.IPromise<any>}
     */
    public deleteAudit(uuid: string, force?: boolean): ng.IPromise<any> {
        return this.checkApi.deleteAudit(uuid, force).then((result) => {
            return result;
        });
    }

    public deleteUpcomingAudit(uuid: string): ng.IPromise<any> {
        return this.checkApi.deleteUpcomingAudit(uuid).then((result) => {
            return result;
        });
    }
}
