import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import {
    INavigationCollection,
    NavigationCollection,
} from '@intouch/its.essential/app/essential/domain/collections/NavigationCollection';
import { INavigationCollectionItem } from '@intouch/its.essential/app/essential/domain/collections/NavigationCollectionItem';

@Controller('its.check.module.checklists', ChecklistMainController.IID, ChecklistMainController)
class ChecklistMainController {
    static IID: string = 'its.check.module.checklists.ChecklistMainController';
    static $inject: Array<string> = ['$state', '$mdMedia', '$translate'];

    public mobileBreak: string = 'gt-sm';
    public navExpanded: boolean = false;

    public standardLists: Array<INavigationCollectionItem> = [
        {
            label: this.translate.instant('CHECKLISTS.ALL_CHECKLISTS'),
            state: 'home.checklists.list.all',
            icon: 'assignment',
            stateParams: {
                presetName: undefined,
            },
            slug: 'home.checklists.list.all',
        },
        {
            label: this.translate.instant('CHECKLISTS.MY_CHECKLISTS'),
            state: 'home.checklists.list.preset',
            slug: 'home.checklists.list.preset',
            icon: 'assignment_ind',
            stateParams: {
                presetName: 'my-checklists',
            },
        },
        {
            label: this.translate.instant('CHECKLISTS.PENDING'),
            state: 'home.checklists.list.preset',
            slug: 'home.checklists.list.preset',
            icon: 'autorenew',
            stateParams: {
                presetName: 'pending',
            },
        },
        {
            label: this.translate.instant('CHECKLISTS.PUBLISHED'),
            state: 'home.checklists.list.preset',
            slug: 'home.checklists.list.preset',
            icon: 'assignment_turned_in',
            stateParams: {
                presetName: 'published',
            },
        },
        {
            label: this.translate.instant('CHECKLISTS.DISABLED'),
            state: 'home.checklists.list.preset',
            slug: 'home.checklists.list.preset',
            icon: 'not_interested',
            stateParams: {
                presetName: 'disabled',
            },
        },
    ];

    public navCollection: INavigationCollection = new NavigationCollection(this.standardLists);

    constructor(
        public state: ng.ui.IStateService,
        private media: ng.material.IMedia,
        private translate: ng.translate.ITranslateService
    ) {}

    public navIsExpanded(): boolean {
        return this.media('gt-sm') || this.navExpanded;
    }

    public isActive(item: INavigationCollectionItem): boolean {
        if (!item || !item.state || !this.state || !this.state.params) {
            return false;
        }

        if (item.state === 'home.checklists.list.preset') {
            return item.stateParams && item.stateParams['presetName'] === this.state.params['presetName'];
        }

        return this.state.current.name === item.state;
    }
}
