import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IAudit } from '../../domain/audits/Audit';
import { IAuditService } from '../../services/AuditService';
import { IPageService } from '../../services/PageService';
import { IAuditProviderService } from '../../services/AuditProviderService';
import { ICheckApi } from '../../api/CheckApi';
import { AuditRejectCommentModal } from './modals/AuditRejectCommentModal';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { INavigationService } from '../../services/NavigationService';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IIql, Iql } from '@intouch/iql-ts-sdk/src/domain/Iql';
import { FilterGroup } from '@intouch/iql-ts-sdk/src/domain/filters/FilterGroup';
import { DateFilter } from '@intouch/iql-ts-sdk/src/domain/filters/DateFilter';
import { StringFilter } from '@intouch/iql-ts-sdk/src/domain/filters/StringFilter';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';
import { IFeatureFlagService } from '@intouch/its.essential/app/essential/services/FeatureFlagService';

/**
 * The users controller
 *
 * @param UserService
 * @param $state
 * @constructor
 */
@Controller('its.check.module.audits', ViewController.IID, ViewController)
class ViewController {
    static IID: string = 'ViewController';
    static $inject: Array<string> = [
        '$state',
        'itcAuditService',
        'iteToaster',
        'itcPageService',
        '$translate',
        'itcAuditProviderService',
        'itcCheckApi',
        '$mdDialog',
        'iteAccessService',
        'itcNavigationService',
        'iteIqlApi',
        'iteUserLocalTimeService',
        'iteFeatureFlagService',
    ];

    public audit: IAudit;
    public previousAudit: IAudit = null;
    public enableComments: boolean = false;
    public enableAcceptReject: boolean = false;
    public auditUuid: string;
    public saving: boolean = false;
    public loading: boolean = false;
    public errorLoading: boolean = false;
    public sendingRejection: boolean = false;
    public sendingApproval: boolean = false;
    public rejectComment: string = null;
    public showPreviousAudit: boolean = false;

    private translations: any;

    /**
     * The class constructor
     *
     * @param stateService
     * @param auditService
     * @param toaster
     * @param pageService
     * @param translate
     * @param auditProviderService
     * @param checkApi
     * @param dialog
     * @param accessService
     * @param navigationService
     * @param iqlApi
     * @param userLocalTimeService
     */
    constructor(
        private stateService: ng.ui.IStateService,
        private auditService: IAuditService,
        private toaster: IToaster,
        private pageService: IPageService,
        private translate: angular.translate.ITranslateService,
        private auditProviderService: IAuditProviderService,
        private checkApi: ICheckApi,
        private dialog: ng.material.IDialogService,
        private accessService: IAccessService,
        private navigationService: INavigationService,
        private iqlApi: IIqlApi,
        private userLocalTimeService: IUserLocalTimeService,
        private featureFlagService: IFeatureFlagService
    ) {
        this.auditUuid = this.stateService.params['auditUuid'];
        this.enableComments = this.stateService.params['enableComments'] || false;
        this.enableAcceptReject =
            this.stateService.params['enableAcceptReject'] &&
            this.accessService.getToken().getUser().hasAcl('check_approval_assignment');

        // get translations need by controller
        this.translate([
            'AUDITS.ERRORS.COULD_NOT_START',
            'AUDITS.MESSAGES.REJECTED_CHECK',
            'AUDITS.MESSAGES.APPROVED_CHECK',
            'AUDITS.ERRORS.COULD_NOT_REJECT',
            'AUDITS.ERRORS.COULD_NOT_APPROVE',
        ])
            .then((translations) => {
                this.translations = translations;
            })
            .finally(() => {
                this.auditUuid = this.stateService.params['auditUuid'];
                this.loadAuditData();
            });
    }

    /**
     * Load the audit and checklist data
     *
     */
    public loadAuditData(): void {
        this.errorLoading = false;
        if (this.auditUuid) {
            this.pageService.showLoader(true);
            this.auditProviderService
                .getOnlineAuditFromUuid(this.auditUuid)
                .then((result: IAudit) => {
                    this.audit = result;
                    this.loadPreviousAudit();
                    this.audit.updateSummary();
                    this.pageService.setPageTitle(this.audit.checklist.name);
                })
                .catch((error) => {
                    this.errorLoading = true;
                    this.toaster.warn(this.translations['AUDITS.ERRORS.COULD_NOT_START']);
                    this.back();
                })
                .finally(() => {
                    this.pageService.showLoader(false);
                });
        } else {
            this.errorLoading = true;
            this.toaster.warn(this.translations['AUDITS.ERRORS.COULD_NOT_START']);
        }
    }

    /**
     * Go back from where you came demon
     */
    public back(): void {
        this.navigationService.goBack();
    }

    public isLoading(): boolean {
        return this.pageService.loaderVisible;
    }

    public hasError(): boolean {
        return this.errorLoading;
    }

    /**
     * Reject the audit
     *
     */
    public reject(): void {
        this.dialog.show(AuditRejectCommentModal.instantiate({}, this.rejectComment)).then((comment) => {
            this.sendingRejection = true;
            this.checkApi
                .rejectAudit(this.audit.uuid, comment)
                .then(() => {
                    this.toaster.info(this.translations['AUDITS.MESSAGES.REJECTED_CHECK']);
                    this.back();
                })
                .catch((error) => {
                    this.rejectComment = comment; // storing this value on api call fail, then we can re-populate modal on next try
                    this.toaster.warn(this.translations['AUDITS.ERRORS.COULD_NOT_REJECT']);
                })
                .finally(() => {
                    this.sendingRejection = false;
                });
        });
    }

    /**
     * Approve the audit
     *
     */
    public approve(): void {
        this.sendingApproval = true;
        this.checkApi
            .approveAudit(this.audit.uuid)
            .then(() => {
                this.toaster.success(this.translations['AUDITS.MESSAGES.APPROVED_CHECK']);
                this.back();
            })
            .catch((error) => {
                this.toaster.warn(this.translations['AUDITS.ERRORS.COULD_NOT_APPROVE']);
            })
            .finally(() => {
                this.sendingApproval = false;
            });
    }

    /**
     * Load last audit responses
     *
     */
    private loadPreviousAudit(): void {
        if (this.audit && this.audit.checklist && this.audit.checklist.settings.showPreviousAuditResults) {
            let locationUuid: string = this.audit.hierarchy.uuid;
            let checklistUuid: string = this.audit.checklist.originalUuid;

            let iql: IIql = new Iql().filter(
                new FilterGroup(Iql.AND, [
                    new DateFilter(
                        'meta->date_of_document',
                        Iql.LESS_THAN,
                        this.userLocalTimeService.fromUtc(this.audit.finishedAt, 'YYYY-MM-DD HH:mm:ss')
                    ),
                    new StringFilter('meta.hierarchy->uuid', Iql.EQUAL, locationUuid),
                    new StringFilter('meta.reference->program_id', Iql.EQUAL, checklistUuid),
                ])
            );

            // get raw json of last record from this checklist/location
            this.iqlApi
                .execute(iql, Pager.make(1, 'meta.date_of_document', 'desc', 1), null, null)
                .then((results: PagedEntities) => {
                    if (results && results.getEntities() && results.getEntities().length > 0) {
                        this.auditProviderService
                            .getOnlineAuditFromUuid(results.getEntities()[0]['meta']['document_id'])
                            .then((previousAudit: IAudit) => {
                                previousAudit.checklist.settings.showPreviousLocationFailure = false; // don't enabled this feature for prev audit
                                this.previousAudit = previousAudit;
                            });
                    }
                });
        }
    }
}
