import {
    IBaseListingItem,
    BaseListingItem,
} from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseListingItem';

/**
 * The IIncompleteListingItem interface
 *
 */
export interface IIncompleteListingItem extends IBaseListingItem {
    checklistName: string;
    checklistUuid: string;
    displayTimezone: string;
    hierarchyAddressCity: string;
    hierarchyAddressLine1: string;
    hierarchyName: string;
    hierarchyUuid: string;
    shouldHaveCompletedBy: string;
    shouldHaveStartedBy: string;
}

/**
 * The IncompleteListingItem class - holds properties for incomplete audits in a listing
 *
 */
export class IncompleteListingItem extends BaseListingItem implements IIncompleteListingItem {
    public checklistName: string = null;
    public checklistUuid: string = null;
    public displayTimezone: string = null;
    public hierarchyAddressCity: string = null;
    public hierarchyAddressLine1: string = null;
    public hierarchyName: string = null;
    public hierarchyUuid: string = null;
    public shouldHaveCompletedBy: string = null;
    public shouldHaveStartedBy: string = null;
}
