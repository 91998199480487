import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { ICheckApi } from '../../../api/CheckApi';
import { IChecklist } from '../../../domain/checklists/Checklist';
import { IItemSection } from '../../../domain/checklists/ItemSection';
import { IBaseItem } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItem';
import { IAuditPhoto } from '@intouch/its.check.essential/app/check-essential/domain/audits/AuditPhoto';
import { IPhotoService } from '@intouch/its.essential/app/essential/services/PhotoService';
import { ICheckSession } from '../../../services/CheckSession';

export interface ItemModalOptions {
    item: IBaseItem;
    sourceItem?: IBaseItem;
    checklist: IChecklist;
    isEdit: boolean;
    isDuplicate?: boolean;
    addAnother: { value: boolean };
    section?: IItemSection;
    insertAtIndex?: number;
    canAddTags: boolean;
}

export class ItemBaseModal extends Modal {
    static $inject: Array<string> = [
        '$mdDialog',
        'itcCheckApi',
        'iteToaster',
        'iteLogger',
        '$timeout',
        '$scope',
        'itePhotoService',
        'itcCheckSession',
    ];

    public checklist: IChecklist;
    public item: IBaseItem;
    public isEdit: boolean;
    public isDuplicate: boolean;
    public addAnother: boolean;
    public index: number;
    public saving: boolean = false;
    public section: IItemSection;
    public moveToItemUuid: string = null;
    public movePosition: string = null;
    public canAddTags: boolean = false;
    private originalChecklist: IChecklist;

    /**
     * Create the modal with some nice defaults to make me happy
     *
     * @param config
     * @param options
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions, options?: ItemModalOptions): ItemBaseModal {
        // the params are optional to meet the interface requirements for inheritance; but we will check they are provided or give defaults errors otherwise
        if (options.item == null || options.checklist == null || options.isEdit == null) {
            throw new Error('Invalid information provided to create an item modal dialog');
        }

        config = config || {};
        config.disableParentScroll = true;
        config.bindToController = true;
        config.locals = {
            item: options.item,
            checklist: options.checklist,
            isEdit: options.isEdit,
            isDuplicate: options.isDuplicate,
            addAnother: options.addAnother,
            section: options.section,
            sourceItem: options.sourceItem,
            insertAtIndex: options.insertAtIndex,
        };

        return super.instantiate(config);
    }

    /**
     * Create the class
     *
     * @param dialog
     * @param checkApi
     * @param toaster
     * @param logger
     * @param timeout
     * @param scope
     * @param photoService
     * @param session
     */
    constructor(
        protected dialog: ng.material.IDialogService,
        protected checkApi: ICheckApi,
        protected toaster: IToaster,
        protected logger: ILogger,
        protected timeout: ng.ITimeoutService,
        protected scope: ng.IScope,
        public photoService: IPhotoService,
        private session: ICheckSession
    ) {
        super();
        this.onInit();

        this.canAddTags = this.session.getToken().getUser().hasAcl('hierarchy_manager', true);
    }

    /**
     * Executes on initialization of the object
     *
     */
    protected onInit(): void {
        if (!this.checklist || !this.item) {
            throw new Error('Was not provided an item or a checklist');
        }
        this.originalChecklist = this.checklist;
        this.checklist = <IChecklist>this.checklist.clone();

        // execute on ready after on next digest cycle
        this.timeout(() => {
            this.onReady();
        }, 0);
    }

    /**
     * Executes on next digest cycle after modal is initialized. This allows for the dom to populate first.
     *
     */
    protected onReady(): void {
        return;
    }

    /**
     * Save the given checklist item (and parent checklist)
     *
     */
    protected save(): void {
        this.saving = true;
        // now push the new changes to the server
        this.checkApi
            .updateChecklist(this.checklist)
            .then((checklist: IChecklist) => {
                this.dialog.hide(checklist); // return new checklist
            })
            .catch((error: IErrorResponse) => {
                this.checklist = this.originalChecklist.clone(); // revert any changes made to cloned checklist from save
                if (this.section) {
                    this.section = this.checklist.findSection('uuid', this.section.uuid);
                }
                if (error.type === 'ProposedRevisionAlreadyExistsException') {
                    this.toaster.warn('CHECKLISTS.ERRORS.PROPOSED_REVISION_ALREADY_EXISTS');
                } else {
                    this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_SAVE');
                }
            })
            .finally(() => {
                this.saving = false;
            });
    }

    /**
     * Pass item by reference and set the item target + section to move to if we get them
     *
     */
    protected checkIfAddingAtIndex(): number {
        let item: any = null;

        if (this.moveToItemUuid) {
            item = this.section.getItem(this.moveToItemUuid);
        }

        if (item) {
            return this.section.getItemIndex(item) + (this.movePosition === 'after' ? 1 : 0);
        } else {
            return -1;
        }
    }

    /**
     * Cancel the dialog
     */
    protected cancel(): void {
        this.dialog.cancel();
    }
}
