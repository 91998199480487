import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

/*
    Example:
    <itc-item-input-dialog-actions
                in-progress="vm.saving"
                disable-save="vm.saving || itemForm.$invalid"
                add-another="!vm.isEdit && !vm.isDuplicate"
                cancel="vm.cancel()"
                save="vm.save(vm.item)">
        </itc-item-input-dialog-actions>
 */

/**
 * The UI for rendering the dialog actions on ItemInput modals
 *
 */
@Component('its.check.module.checklists', ItemInputDialogActions.CID, {
    template: require('/app/modules/checklists/components/ItemInputDialogActions.html'),
    controllerAs: 'vm',
    bindings: {
        disableSave: '<', // disables the save button
        inProgress: '<', // flag when saving is in progress - disables all buttons
        showAddAnother: '<', // displays add another checkbox
        addAnother: '=', // model binding for add another checkbox, if null checkbox is not inserted into DOM
        save: '&', // expression to execute when save button is clicked
        cancel: '&', // expression to execute when cancel button is clicked
    },
})
class ItemInputDialogActions {
    static IID: string = 'ItemInputDialogActions';
    static CID: string = 'itcItemInputDialogActions';
}
