import { IBaseItemSection } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemSection';
import { IBaseItemInput } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';

/**
 * The interface for a referenced item
 *
 */
export interface IItemReference {
    type: string;
    subject: IBaseItemInput; // the item to look for references to
    source: any; // the location where the subject is referenced
    sourceParent?: IBaseItemSection; // the parent (section) where the source is located

    removeDependency(): void;
}

export abstract class ItemReference implements IItemReference {
    public type: string = null;
    public subject: IBaseItemInput = null;
    public source: IBaseItemInput = null;
    public sourceParent: IBaseItemSection = null;

    /**
     * Removes dependency of subject on source
     *
     */
    public abstract removeDependency(): void;
}
