import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IMainMenuItem } from '@intouch/its.essential/app/essential/components/SideNavBar';
import { IUser } from '@intouch/its.essential/app/essential/domain/access/User';
import { ITopNavDropDownMenuItem } from '@intouch/its.essential/app/essential/components/TopNavBar';
import { ISiteNavigationService } from '@intouch/its.essential/app/essential/services/SiteNavigationService';
import { IOrganization } from '@intouch/its.essential/app/essential/domain/access/Organization';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { IGoogleTagManager } from '@intouch/its.essential/app/essential/services/GoogleTagManager';

import { IPageService } from '../../services/PageService';
import { ICheckSession } from '../../services/CheckSession';

import * as moment from 'moment';

/**
 * Main Controller for our application
 *
 * @constructor
 */
@Controller('its.check.module.main', MainController.IID, MainController)
class MainController {
    static IID: string = 'MainController';
    static $inject: Array<string> = [
        '$state',
        '$mdDialog',
        'itcPageService',
        'itcCheckSession',
        '$translate',
        'iteAccessService',
        'authenticatedUser',
        'APPCONFIG',
        '$window',
        '$rootScope',
        '$location',
        'iteGoogleTagManager',
        'iteSiteNavigationService',
        '$mdMedia',
    ];

    public menuItems: Array<IMainMenuItem>;
    public topNavMenuItems: Array<ITopNavDropDownMenuItem>;
    public actionItems: Array<any>;
    public logoUrl: string;
    public homeState: string;
    public organizationLogoUrl: string;
    public organization: IOrganization;
    public isChecklistManager: boolean = false;

    constructor(
        private stateService: ng.ui.IStateService,
        private dialog: ng.material.IDialogService,
        private pageService: IPageService,
        private session: ICheckSession,
        private translate: angular.translate.ITranslateService,
        public accessService: IAccessService,
        private authenticatedUser: IUser,
        private config: any,
        private window: ng.IWindowService,
        private rootScope: ng.IRootScopeService,
        private location: ng.ILocationService,
        private googleTagManager: IGoogleTagManager,
        private navigationService: ISiteNavigationService,
        private media: ng.material.IMedia
    ) {
        this.isChecklistManager = this.accessService.getToken().getUser().hasAcl('check_admin', true);
        this.setupLocalization();
        this.setupGoogleTagManager();
        this.setupSideMenu();
    }

    /**
     * Show the page loader
     *
     * @returns {boolean}
     */
    public showLoader(): boolean {
        return this.pageService.loaderVisible;
    }

    public showMobileBar(): boolean {
        return !this.media('gt-sm');
    }

    /**
     * Handles mobile navigation clicks
     *
     * @param item
     */
    public handleMobileNavigation(item: IMainMenuItem): void {
        if (item.onClick) {
            item.onClick();
        } else if (item.state) {
            this.stateService.go(item.state);
        }
    }

    /**
     * Determine if we should be showing the navigation item or not; based on:
     *
     * show === false
     * showCallback function given
     *
     * The showCallback function will take priority over show; so ideally only one would be used by
     * the caller
     *
     * @param item
     */
    public showMenuItem(item: IMainMenuItem): boolean {
        // prioritize showCallback
        if (item.hasOwnProperty('showCallback') && typeof item.showCallback === 'function') {
            return item.showCallback();
        } else {
            return item.show !== false;
        }
    }

    /**
     * Setup the site localization we use
     */
    private setupLocalization(): void {
        this.translate.use(this.authenticatedUser.language);

        moment.locale(this.convertToMomentLocaleKey(this.authenticatedUser.language));
    }

    /**
     * Setup integration to GTM on state changes; passes the URL + state name
     */
    private setupGoogleTagManager(): void {
        this.rootScope.$on('$stateChangeSuccess', (event, toState) => {
            this.googleTagManager.pushTag({
                event: 'pageview',
                pagePath: this.location.url(),
                pageTitle: 'admin.' + toState.name,
            });
        });

        // track initial page load which is not caught by $stateChangeSuccess above. Only happens once on initial load.
        this.googleTagManager.pushTag({
            event: 'pageview',
            pagePath: this.location.url(),
            pageTitle: 'admin.' + this.stateService.current.name,
        });
    }

    /**
     * Setup the sites sidenav
     */
    private setupSideMenu(): void {
        let token: IToken = this.accessService.getToken();

        this.organizationLogoUrl = token.getOrganization().avatarThumbUrl;
        this.homeState = 'home.checklists.list.all';

        this.translate([
            'CHECK_ESSENTIAL.GENERAL.SIDENAV.PERFORM_CHECKS',
            'CHECK_ESSENTIAL.GENERAL.SIDENAV.CHECK_MANAGEMENT',
            'CHECK_ESSENTIAL.GENERAL.SIDENAV.MANAGE_CHECKLISTS',
            'CHECK_ESSENTIAL.GENERAL.SIDENAV.DOWNLOAD_CHECKLISTS',
            'CHECK_ESSENTIAL.GENERAL.FOLLOWUPS',
            'GENERAL.USERS',
            'SETTINGS.CHECKLIST.OUTCOMES',
            'GENERAL.ACTIONS',
            'GENERAL.SWITCH_CLIENTS',
            'GENERAL.GUIDE',
            'GENERAL.HOME',
        ]).then((translations) => {
            this.menuItems = [
                {
                    id: 'itc-side-nav-perform-audits',
                    icon: 'assignment_turned_in',
                    onClick: () => {
                        this.navigationService.goToCheckApp('audits/list/in-progress');
                    },
                    title: translations['CHECK_ESSENTIAL.GENERAL.SIDENAV.PERFORM_CHECKS'],
                    position: 'top',
                },
            ];

            if (
                this.pageService.isOnline() &&
                // either has a role of 'user' as well as ACL = 'check_approval_assignment' OR is a role > 'user'
                ((token.getRole() === 1 && token.getUser().hasAcl('check_approval_assignment')) || token.getRole() > 1)
            ) {
                this.menuItems.push({
                    id: 'audit-management',
                    icon: 'find_in_page',
                    activeState: 'home.audits',
                    state: 'home.audits.dashboard.approval',
                    title: translations['CHECK_ESSENTIAL.GENERAL.SIDENAV.CHECK_MANAGEMENT'],
                    position: 'top',
                    showCallback: (): boolean => {
                        return this.pageService.isOnline();
                    },
                });
            }
            // either has a role of 'user' as well as ACL = 'check_approval_assignment' OR is a role > 'user'
            if (this.pageService.isOnline() && this.canSeeChecklistsListing(token)) {
                this.menuItems.push({
                    id: 'checklist-management',
                    icon: 'assignment',
                    activeState: 'home.checklists',
                    state: 'home.checklists.list.all',
                    title: translations['CHECK_ESSENTIAL.GENERAL.SIDENAV.MANAGE_CHECKLISTS'],
                    position: 'top',
                    showCallback: (): boolean => {
                        return this.pageService.isOnline();
                    },
                });
            }

            if (this.pageService.isOnline()) {
                this.menuItems.push({
                    id: 'followups-dashboard',
                    icon: 'flag',
                    onClick: () => {
                        this.navigationService.goToCheckApp('followups/dashboard');
                    },
                    title: translations['CHECK_ESSENTIAL.GENERAL.FOLLOWUPS'],
                    position: 'top',
                    showCallback: (): boolean => {
                        return this.pageService.isOnline();
                    },
                });
            }

            if (token.getUser().hasAcl('check_admin') || token.getUser().hasAcl('checklist_creator')) {
                this.menuItems.push({
                    id: 'outcomes',
                    icon: 'donut_large',
                    activeState: 'home.outcomes',
                    state: 'home.outcomes',
                    title: translations['SETTINGS.CHECKLIST.OUTCOMES'],
                    position: 'top',
                    showCallback: (): boolean => {
                        return this.pageService.isOnline();
                    },
                });
            }

            this.topNavMenuItems = [
                {
                    icon: 'help',
                    onClick: (): void => {
                        this.window.open('https://intouchcheck.zendesk.com/hc/en-us', '_blank');
                    },
                    label: translations['GENERAL.GUIDE'],
                    cssClass: 'its-toolbar--divider-above',
                },
            ];

            this.actionItems = [];

            if (this.authenticatedUser.organizationCount > 1) {
                this.topNavMenuItems.unshift({
                    icon: 'cached',
                    onClick: (): void => {
                        this.accessService.switchOrganization();
                    },
                    label: translations['GENERAL.SWITCH_CLIENTS'],
                    cssClass: '',
                });
            }

            this.orderMenuItems();
        });
    }

    /**
     * Returns the language key formatted for moment
     *
     * @param id
     * @return {any}
     */
    private convertToMomentLocaleKey(id: string): string {
        if (id) {
            return id.toLowerCase().replace('_', '-');
        } else {
            return null;
        }
    }

    /**
     * User can see the "Checklists Listing" (aka "Manage Checklists" view)?
     * - user has a role of "Admin" or greater
     * or
     * - user has a role of "User" and an ACL of "check_admin" or "checklist_creator" or "check_approval_assignment"
     *
     * @param token
     *
     * @return {boolean}
     */
    private canSeeChecklistsListing(token: IToken): boolean {
        return (
            token.getRole() > 1 ||
            (token.getRole() === 1 &&
                (token.getUser().hasAcl('check_admin') ||
                    token.getUser().hasAcl('checklist_creator') ||
                    token.getUser().hasAcl('check_approval_assignment')))
        );
    }

    private orderMenuItems(): void {
        this.menuItems = _.orderBy(
            this.menuItems,
            [
                (item) => {
                    return this.getItemOrder().indexOf(item.id);
                },
            ],
            ['asc']
        );
    }

    private getItemOrder(): Array<string> {
        return this.isChecklistManager ? this.getChecklistManagerItemOrder() : this.getDefaultMenuItemOrder();
    }

    private getDefaultMenuItemOrder(): Array<string> {
        return [
            'home',
            'itc-side-nav-perform-audits',
            'audit-management',
            'checklist-management',
            'followups-dashboard',
            'outcomes',
        ];
    }

    private getChecklistManagerItemOrder(): Array<string> {
        return [
            'checklist-management',
            'audit-management',
            'outcomes',
            'home',
            'itc-side-nav-perform-audits',
            'followups-dashboard',
        ];
    }
}
