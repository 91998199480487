import { IBaseItemInput } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { IBaseItemCalculation } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemCalculation';
import { IChecklist } from '../Checklist';
import { IBaseItemSection } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemSection';
import { IItemReference } from './ItemReference';
import { ItemReferenceFactory } from './ItemReferenceFactory';

export interface IItemReferenceSearchStrategy {
    execute(): Array<IItemReference>;
}

/**
 * A pre-built search strategy to use an IItemReferenceFinder
 */
export class DefaultItemSearchStrategy implements IItemReferenceSearchStrategy {
    private checklist: IChecklist = null;
    private subject: IBaseItemInput = null;

    /**
     * Instantiates the class
     *
     * @param {IChecklist} checklist
     * @param {IBaseItem} subjectItem
     */
    public constructor(checklist: IChecklist, subjectItem: IBaseItemInput) {
        this.checklist = checklist;
        this.subject = subjectItem;
    }

    /**
     * Searches the checklist for references to the item
     *
     * @return {Array<IItemReference>}
     */
    public execute(): Array<IItemReference> {
        let references: Array<IItemReference> = [];

        for (let section of this.checklist.sections) {
            for (let item of section.items) {
                if (item.uuid !== this.subject.uuid) {
                    if (item.logic && item.logic.length > 0) {
                        for (let logic of item.logic) {
                            if (logic.arguments && logic.arguments.length > 0) {
                                for (let argument of logic.arguments) {
                                    if (argument.subjectUuid === this.subject.uuid) {
                                        let reference: IItemReference = ItemReferenceFactory.build('logic', {
                                            subject: this.subject,
                                            source: item,
                                            sourceParent: section,
                                        });
                                        references.push(reference);
                                    }
                                }
                            }
                        }
                    }
                }

                if (item.uuid !== this.subject.uuid && item.type === 'calculation') {
                    let calcItem: IBaseItemCalculation = <any>item;

                    for (let calculation of calcItem.settings.calculations) {
                        if (calculation.type === 'operand' && calculation.value === this.subject.uuid) {
                            let reference: IItemReference = ItemReferenceFactory.build('calculation', {
                                subject: this.subject,
                                source: item,
                                sourceParent: section,
                            });
                            references.push(reference);
                        }
                    }
                }

                if (item.uuid !== this.subject.uuid && item.prePopulateDate) {
                    if (item.prePopulateDate.question === this.subject.uuid) {
                        let reference: IItemReference = ItemReferenceFactory.build('pre_populate_date', {
                            subject: this.subject,
                            source: item,
                            sourceParent: section,
                        });
                        references.push(reference);
                    }
                }
            }
        }

        return references;
    }
}

/**
 * Searches for references to each item in a section
 *
 */
export class SectionItemSearchStrategy implements IItemReferenceSearchStrategy {
    private checklist: IChecklist = null;
    private subject: IBaseItemSection = null;

    /**
     * Instantiates the class
     *
     * @param {IChecklist} checklist
     * @param {IBaseItem} subjectItem
     */
    public constructor(checklist: IChecklist, subjectItem: IBaseItemSection) {
        this.checklist = checklist;
        this.subject = subjectItem;
    }

    /**
     * Searches the checklist for references to the item
     *
     * @return {Array<IItemReference>}
     */
    public execute(): Array<IItemReference> {
        let references: Array<IItemReference> = [];

        for (let sectionItem of this.subject.items) {
            for (let section of this.checklist.sections) {
                for (let item of section.items) {
                    if (item.uuid !== this.subject.uuid) {
                        if (item.logic && item.logic.length > 0) {
                            for (let logic of item.logic) {
                                if (logic.arguments && logic.arguments.length > 0) {
                                    for (let argument of logic.arguments) {
                                        if (argument.subjectUuid === sectionItem.uuid) {
                                            let reference: IItemReference = ItemReferenceFactory.build('logic', {
                                                subject: sectionItem,
                                                source: item,
                                                sourceParent: section,
                                            });
                                            references.push(reference);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (item.uuid !== this.subject.uuid && item.type === 'calculation') {
                        let calcItem: IBaseItemCalculation = <any>item;

                        for (let calculation of calcItem.settings.calculations) {
                            if (calculation.type === 'operand' && calculation.value === sectionItem.uuid) {
                                let reference: IItemReference = ItemReferenceFactory.build('calculation', {
                                    subject: sectionItem,
                                    source: item,
                                    sourceParent: section,
                                });
                                references.push(reference);
                            }
                        }
                    }

                    if (item.uuid !== this.subject.uuid && item.prePopulateDate) {
                        if (item.prePopulateDate.question === sectionItem.uuid) {
                            let reference: IItemReference = ItemReferenceFactory.build('pre_populate_date', {
                                subject: sectionItem,
                                source: item,
                                sourceParent: section,
                            });
                            references.push(reference);
                        }
                    }
                }
            }
        }

        return references;
    }
}

/**
 * The IItemReference finder interface
 *
 */
export interface IItemReferenceFinder {
    find(): Array<IItemReference>;
}

/**
 * The ItemReferenceFinder class - finds references to items
 *
 */
export class ItemReferenceFinder implements IItemReferenceFinder {
    protected searchStrategy: IItemReferenceSearchStrategy;

    /**
     * Instantiates the ItemReferenceFinder
     *
     * @param {IItemReferenceSearchStrategy} searchStrategy
     */
    public constructor(searchStrategy: IItemReferenceSearchStrategy) {
        this.searchStrategy = searchStrategy;
    }

    /**
     * Searches the checklist for other items referencing the subject item and returns them as an array
     *
     * @return {Array<IItemReference>}
     */
    public find(): Array<IItemReference> {
        if (!this.searchStrategy) {
            throw new Error('NoSearchStrategy');
        }

        return this.searchStrategy.execute();
    }
}
