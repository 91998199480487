import { String } from '@intouch/its.essential/app/essential/utils/String';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IResponse, Response } from './Response';
import { IItemInput, ItemInput } from './ItemInput';
import * as _ from 'lodash';
import {
    IItemInputSettings,
    ItemInputSettings,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import {
    IBaseItemDropdownTranslation,
    BaseItemDropdownTranslation,
    IBaseItemDropdownResponseTranslation,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/translations/BaseItemDropdownTranslation';
import { IStringMap } from '@intouch/its.essential/app/essential/utils/datatypes/StringMap';
import { IWithAdditionalRequirements } from './WithAdditionalRequirements';
import { IItemAdditionalRequirements, ItemAdditionalRequirements } from './ItemAdditionalRequirements';
import { ILogicSubject } from './LogicSubject';

/**
 * The IItemDropdownSettings interface
 *
 */
export interface IItemDropdownSettings extends IItemInputSettings {
    responses: Array<IResponse>;
    multipleSelections: boolean;
    daysToResolveProblems: number;
    showResponses: boolean;
    enableSearch: boolean;
}

/**
 * The ItemDropdownSettings class
 */
export class ItemDropdownSettings extends ItemInputSettings implements IItemDropdownSettings {
    public responses: Array<IResponse> = [];
    public multipleSelections: boolean = false;
    public daysToResolveProblems: number = null;
    public showResponses: boolean = false;
    public enableSearch: boolean = false;

    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemDropdownSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['responses']) {
            this.responses = EntityBuilder.buildMany<IResponse>(Response, jsonObject['responses'], convertToCamel);
        }

        return this;
    }
}

/**
 * The IItemDropdown interface
 *
 */
export interface IItemDropdown extends IItemInput, IWithAdditionalRequirements, ILogicSubject {
    settings: IItemDropdownSettings;
    translations: Array<IBaseItemDropdownTranslation>;

    addAnswer(response: IResponse): IResponse;

    removeAnswer(response: IResponse): void;

    setScored(isScored: boolean): void;

    isScored(): boolean;

    getResponse(uuid: string): IResponse | IBaseItemDropdownResponseTranslation;

    getTranslation(locale: string): IBaseItemDropdownTranslation;
}

/**
 * The ItemDropdown class
 */
export class ItemDropdown extends ItemInput implements IItemDropdown {
    public type: string = 'dropdown';
    public settings: IItemDropdownSettings = new ItemDropdownSettings();
    public translations: Array<IBaseItemDropdownTranslation> = [];
    public additionalRequirements: IItemAdditionalRequirements = null;

    /**
     * Will make a generic item
     *
     * @param name
     * @param multipleSelections
     * @returns {IItemDropdown}
     */
    public static make(name: string, multipleSelections: boolean = false): IItemDropdown {
        let me: IItemDropdown = new ItemDropdown();
        me.name = name;
        me.uuid = String.uuid();
        me.settings.allowCnaNa = false;
        me.settings.enableSearch = false;
        me.settings.multipleSelections = multipleSelections;

        return me;
    }

    /**
     * Returns if object is allowed as a logic subject
     *
     */
    public allowAsLogicSubject(): boolean {
        return true;
    }

    /**
     * Will build itself from json
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {IItemDropdown}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemDropdown {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['settings']) {
            this.settings = EntityBuilder.buildOne<IItemDropdownSettings>(
                ItemDropdownSettings,
                jsonObject['settings'],
                convertToCamel
            );
        }

        if (jsonObject['additional_requirements']) {
            this.additionalRequirements = EntityBuilder.buildOne<IItemAdditionalRequirements>(
                ItemAdditionalRequirements,
                jsonObject['additional_requirements'],
                convertToCamel
            );
        } else if (jsonObject['photo_required']) {
            this.additionalRequirements = new ItemAdditionalRequirements().fromJson(
                {
                    type: 'photo',
                    condition: 'always',
                },
                true
            );
            delete (<any>this).photoRequired;
        }

        if (jsonObject['translations']) {
            this.translations = EntityBuilder.buildMany<IBaseItemDropdownTranslation>(
                BaseItemDropdownTranslation,
                jsonObject['translations'],
                convertToCamel
            );
        }

        return this;
    }

    /**
     * Will add a new answer to the list of responses
     *
     * @param response
     * @returns {IResponse}
     */
    public addAnswer(response: IResponse): IResponse {
        if (!response || !(response instanceof Response) || ['response'].indexOf(response.type) === -1) {
            throw new Error('Invalid answer provided to add to this multiple choice item');
        }

        this.settings.responses.push(response);

        return response;
    }

    /**
     * Will remove the given answer from the list of responses
     *
     * @param response
     */
    public removeAnswer(response: IResponse): void {
        let index: number = _.findIndex(this.settings.responses, { uuid: response.uuid });
        if (index >= 0) {
            this.settings.responses.splice(index, 1);
        }
    }

    /**
     * toggles default scored values of responses for scored or not scored
     *
     * @param {boolean} isScored
     */
    public setScored(isScored: boolean): void {
        if (this.settings.responses && this.settings.responses.length > 0) {
            for (let response of this.settings.responses) {
                response.points = isScored ? 0 : null;
            }
        }

        super.setScored(isScored);
    }

    /**
     * Returns true if any of the responses have a numerical value for points
     *
     * @return {boolean}
     */
    public isScored(): boolean {
        if (this.settings.responses && this.settings.responses.length > 0) {
            for (let response of this.settings.responses) {
                if (NumberUtils.isNumber(response.points)) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Returns the response matching the uuid
     *
     * @param {string} uuid
     * @param {string} locale
     * @return {IResponse | IBaseItemDropdownResponseTranslation}
     */
    public getResponse(uuid: string, locale?: string): IResponse | IBaseItemDropdownResponseTranslation {
        let response: IResponse | IBaseItemDropdownResponseTranslation;
        let translation: IBaseItemDropdownTranslation = this.getTranslation(locale);
        if (translation) {
            response = translation.getResponse(uuid);
        } else {
            response = _.find(this.settings.responses, (r: IResponse) => {
                return r.uuid === uuid;
            });
        }

        if (response) {
            return response;
        } else {
            return null;
        }
    }

    /**
     * Returns first of array of BaseItemDropdownTranslation that satisfy the locale
     *
     * @param locale
     */
    public getTranslation(locale: string): IBaseItemDropdownTranslation {
        let returnable: Array<IBaseItemDropdownTranslation> = this.translations.filter(
            (translation: IBaseItemDropdownTranslation) => {
                return translation.locale === locale;
            }
        );

        if (returnable.length > 0) {
            return returnable[0];
        } else {
            let newTranslation: IBaseItemDropdownTranslation = new BaseItemDropdownTranslation();
            newTranslation.locale = locale;
            this.translations.push(newTranslation);
            return newTranslation;
        }
    }

    /**
     * Creates a duplicate of this object with null name and a new uuid
     *
     * @param {boolean} preserveTranslations
     * @return {IBaseItem}
     */
    public duplicate(preserveTranslations?: boolean): IItemDropdown {
        let item: IItemDropdown = <IItemDropdown>this.clone();
        let uuidMap: IStringMap = {};
        item.uuid = String.uuid();

        if (item.logic && item.logic.length > 0) {
            for (let logic of item.logic) {
                logic.targetUuid = item.uuid;
            }
        }

        // remap responses
        if (item.settings && item.settings.responses) {
            for (let response of item.settings.responses) {
                uuidMap[response.uuid] = String.uuid();
                response.uuid = uuidMap[response.uuid];
            }
        }

        // remap response translation uuids
        if (item.settings && item.settings.responses && item.translations) {
            for (let translation of item.translations) {
                if (translation.responses) {
                    for (let response of translation.responses) {
                        if (response['uuid']) {
                            response['uuid'] = uuidMap[response['uuid']];
                        }
                    }
                }
            }
        }

        if (!preserveTranslations && item.translations) {
            for (let translation of item.translations) {
                translation.name = null;
            }
        }

        return item;
    }
}
