import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import View from './DeleteChecklistModal.html';

export class DeleteChecklistModal extends Modal {
    static $inject: Array<string> = ['$mdDialog'];

    public confirmText: string = '';

    /**
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = View;
        config.controller = DeleteChecklistModal;

        return super.instantiate(config);
    }

    constructor(private dialog: ng.material.IDialogService) {
        super();
    }

    public isValid(): boolean {
        return this.confirmText.toLowerCase() === 'check';
    }

    public remove(): void {
        this.dialog.hide(true);
    }

    public cancel(): void {
        this.dialog.cancel();
    }
}
