import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IFileProcessingService } from '@intouch/its.essential/app/essential/services/FileProcessingService';
import { ChecklistImport, IChecklistImport } from '../../../domain/checklists/import/ChecklistImport';
import { ICheckApi } from '../../../api/CheckApi';

@Controller('its.check.module.checklists', ChecklistUploadStep1Controller.IID, ChecklistUploadStep1Controller)
class ChecklistUploadStep1Controller {
    static IID: string = 'ChecklistUploadStep1Controller';
    static $inject: Array<string> = [
        '$state',
        '$stateParams',
        'iteFileProcessingService',
        'itcCheckApi',
        '$translate',
        '$sce',
    ];

    public file: File;
    public upload: IChecklistImport = new ChecklistImport();
    public processing: boolean = false;
    public error: boolean = false;
    public errorMessage: string = null;
    public templateFileUrl: string = '';

    constructor(
        private state: angular.ui.IStateService,
        private stateParams: angular.ui.IStateParamsService,
        private fileProcessingService: IFileProcessingService,
        private checkApi: ICheckApi,
        private translate: angular.translate.ITranslateService,
        private sce: any
    ) {
        this.templateFileUrl = this.sce.trustAsHtml(
            this.translate.instant('CHECKLISTS.UPLOAD.DOWNLOAD_TEMPLATE', {
                link: `/assets/templates/ChecklistImport.csv`,
            })
        );
    }

    /**
     * When the file is selected.
     *
     * @param file
     */
    public onFileSelect(file: any): void {
        this.upload.file = file;
        this.upload.configuration.checklist_id = this.stateParams.uuid;
    }

    /**
     * Proceed to the next step.
     *
     * @param {string} step
     */
    public nextStep(step: string): void {
        this.processing = true;

        this.fileProcessingService
            .upload(this.upload)
            .then((uuid) => {
                if (step) {
                    this.state.go(step, { fileUuid: uuid });
                }
            })
            .catch((error) => {
                this.error = true;
                this.errorMessage = this.translate.instant('CHECKLISTS.UPLOAD.ERROR_UPLOADING');
            })
            .finally(() => {
                this.processing = false;
            });
    }
}
