import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IItemInput } from '../checklists/ItemInput';
import {
    IBaseItemResult,
    BaseItemResult,
} from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseItemResult';
import { IGeoData, GeoData } from './GeoData';
import { IAuditPhoto, AuditPhoto } from '@intouch/its.check.essential/app/check-essential/domain/audits/AuditPhoto';
import { AuditOutcomes } from '@intouch/its.check.essential/app/check-essential/domain/audits/AuditOutcomes';

export interface IItemResult extends IBaseItemResult {
    geodata: Array<IGeoData>;
    checklistItem: IItemInput;
    media: Array<IAuditPhoto>;

    setChecklistItem(item: IItemInput): void;

    hasFollowUpRequired(): boolean;
}

export class ItemResult extends BaseItemResult implements IItemResult {
    geodata: Array<IGeoData> = [];
    checklistItem: IItemInput = null;
    media: Array<IAuditPhoto> = [];

    /**
     * Creates an instance of ItemResult
     *
     * @param checklistItem
     * @returns {IItemResult}
     */
    public static make(checklistItem: IItemInput): IItemResult {
        let item: IItemResult = new ItemResult();

        item.checklistItemUuid = checklistItem.uuid;
        item.type = checklistItem.type;
        item.setChecklistItem(checklistItem);

        return item;
    }

    /**
     * Builds this object from JSON
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {ItemResult}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemResult {
        super.fromJson(jsonObject, true);

        if (jsonObject['geodata']) {
            this.geodata = EntityBuilder.buildMany<IGeoData>(GeoData, jsonObject['geodata'], convertToCamel);
        }

        if (jsonObject['media']) {
            this.media = EntityBuilder.buildMany<IAuditPhoto>(AuditPhoto, jsonObject['media'], convertToCamel);
        }

        if (jsonObject['result'] && (jsonObject['result'] instanceof Object || jsonObject['result'] instanceof Array)) {
            this.result = jsonObject['result'];
        }

        return this;
    }

    /**
     * Sets the underlying checklist item object
     *
     * @param item
     */
    public setChecklistItem(item: IItemInput): void {
        this.checklistItem = item;
    }

    /**
     * Checks if this item has follow-up required
     *
     * @returns {boolean}
     */
    public hasFollowUpRequired(): boolean {
        return this.followUp;
    }

    /**
     * Returns true if item in checklist is set to optional
     *
     * @return {boolean}
     */
    public isOptional(): boolean {
        return (
            this.checklistItem &&
            this.checklistItem.settings.hasOwnProperty('optional') &&
            this.checklistItem.settings['optional']
        );
    }

    /**
     * Returns if the item is skipped. Overrides super.isSkipped()
     *
     * @return {boolean}
     */
    public isSkipped(): boolean {
        return this.outcome === AuditOutcomes.skipped;
    }
}
