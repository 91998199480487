import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { HierarchyTier } from '@intouch/its.essential/app/essential/domain/access/HierarchyTier';
import { IBaseAudit, BaseAudit } from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseAudit';
import { IBaseItemResult } from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseItemResult';
import { GeoData, IGeoData } from './GeoData';
import { ISignature, Signature } from './Signature';
import { IAuditPhoto, AuditPhoto } from '@intouch/its.check.essential/app/check-essential/domain/audits/AuditPhoto';
import {
    IUuidDisplayNamePair,
    UuidDisplayNamePair,
} from '@intouch/its.check.essential/app/check-essential/domain/datatypes/UuidDisplayNamePair';
import { ItemResultBuilder } from './ItemResultBuilder';
import { Outcome } from '../checklists/Outcome';
import { AuditOutcomes } from '@intouch/its.check.essential/app/check-essential/domain/audits/AuditOutcomes';
import { Summary } from './Summary';
import { IChecklist } from '../checklists/Checklist';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';

export interface IAudit extends IBaseAudit {
    checklist: IChecklist;

    findItemResult(comparator: Function): IBaseItemResult;

    updateSummary(): void;
}

export class Audit extends BaseAudit implements IAudit {
    @NotEnumerable public checklist: IChecklist = null;

    /**
     * Populate this object via JSON
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {Group}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IBaseAudit {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['hierarchy']) {
            this.hierarchy = EntityBuilder.buildOne<HierarchyTier>(
                HierarchyTier,
                jsonObject['hierarchy'],
                convertToCamel
            );
        }

        if (jsonObject['geodata']) {
            this.geodata = EntityBuilder.buildMany<IGeoData>(GeoData, jsonObject['geodata'], convertToCamel);
        }

        if (jsonObject['signatures']) {
            this.signatures = EntityBuilder.buildMany<ISignature>(Signature, jsonObject['signatures'], convertToCamel);
        }

        if (jsonObject['media']) {
            this.media = EntityBuilder.buildMany<IAuditPhoto>(AuditPhoto, jsonObject['media'], convertToCamel);
        }

        if (jsonObject['approved_by']) {
            this.approvedBy = EntityBuilder.buildOne<IUuidDisplayNamePair>(
                UuidDisplayNamePair,
                jsonObject['approved_by'],
                convertToCamel
            );
        }

        if (jsonObject['audited_by']) {
            this.auditedBy = EntityBuilder.buildOne<IUuidDisplayNamePair>(
                UuidDisplayNamePair,
                jsonObject['audited_by'],
                convertToCamel
            );
        }

        if (jsonObject['created_by']) {
            this.createdBy = EntityBuilder.buildOne<IUuidDisplayNamePair>(
                UuidDisplayNamePair,
                jsonObject['created_by'],
                convertToCamel
            );
        }

        if (jsonObject['items']) {
            this.items = new ItemResultBuilder().buildMany(jsonObject['items']);
        }

        return this;
    }

    /**
     * Updates skip, calculation, and summary for entire audit
     *
     */
    public refreshAudit(): void {
        this.updateSummary();

        return; // will probably need stuff here.
    }

    /**
     * Cycles through all sections and updates their summaries, then aggregates them into the audit summary
     */
    public updateSummary(): void {
        if (!this.summary) {
            this.summary = new Summary();
        }

        this.summary.reset();

        this.summary.status = this.status;

        for (let section of this.checklist.sections) {
            if (!section.isDisabled()) {
                this.summary.aggregateSummary(section.updateSummary()); // update summary data
            }
        }

        this.score = this.summary.score;

        if (this.checklist) {
            this.summary.outcome = 'ok';

            let percent: number = this.getScorePercentage();
            if (percent < this.checklist.settings.minPercentage) {
                this.summary.outcome = 'not-ok';
            }

            if (this.summary.criticalFailures && this.summary.criticalFailures.length > 0) {
                this.summary.scoreOutcome = Outcome.make(AuditOutcomes.notOkCritical);
            } else {
                this.summary.scoreOutcome = this.checklist.getOutcome(percent);
            }
        }
    }

    /**
     * Returns the item result matching provided comparator
     *
     * @param {Function} comparator
     * @return {IBaseItemResult}
     */
    public findItemResult(comparator: Function): IBaseItemResult {
        return <any>_.find(this.items, comparator);
    }
}
