import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { FroalaModal } from '@intouch/its.essential/app/essential/modals/FroalaModal';
import { Confirm } from '@intouch/its.essential/app/essential/modals/Confirm';
import { IItemInput } from '../../../domain/checklists/ItemInput';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';

/**
 * The UI for rendering Additional Information textfield
 */
@Component('its.check.module.checklists', ItemAdditionalInformation.CID, {
    template: require('/app/modules/checklists/components/ItemAdditionalInformation.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
        translationLabel: '<',
    },
})
class ItemAdditionalInformation {
    static IID: string = 'ItemAdditionalInformation';
    static CID: string = 'itcItemAdditionalInformation';
    static $inject: Array<string> = ['iteLogger', 'APPCONFIG', '$mdDialog', '$translate', 'iteToaster'];

    static toolbarButtons: Array<string> = [
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        'underline',
        'fontSize',
        'fontFamily',
        'color',
        'outdent',
        'indent',
        'align',
        'formatOL',
        'formatUL',
        'insertLink',
        'html',
    ];
    public froalaOptions: any = {
        toolbarButtons: ItemAdditionalInformation.toolbarButtons,
        toolbarButtonsMD: ItemAdditionalInformation.toolbarButtons,
        toolbarButtonsSM: ItemAdditionalInformation.toolbarButtons,
        toolbarButtonsXS: ItemAdditionalInformation.toolbarButtons,
        linkInsertButtons: [],
        height: 250,
        placeholderText: '',
        linkAlwaysBlank: true,
        linkAutoPrefix: 'http://',
        charCounterCount: false,
        quickInsertButtons: [],
        attribution: false,
        fontFamily: {
            'Arial,Helvetica,sans-serif': 'Arial',
            "'Arial Black',Helvetica,sans-serif": 'Arial Black',
            "'Comic Sans MS', cursive, sans-serif": 'Comic Sans MS',
            "'Courier New', Courier, monospace": 'Courier New',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            "'Trebuchet MS', Helvetica, sans-serif": 'Trebuchet MS',
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
        },
        pluginsEnabled: ['url', 'colors', 'fontSize', 'lists', 'link', 'align', 'codeView', 'fontFamily'],
    };

    public item: IItemInput;

    /**
     * Create the controller
     *
     * @param logger
     * @param config
     * @param dialog
     * @param translate
     * @param toaster
     */
    constructor(
        private logger: ILogger,
        private config: any,
        private dialog: ng.material.IDialogService,
        private translate: angular.translate.ITranslateService,
        private toaster: IToaster
    ) {
        // set Froala License Key
        if (this.config.froala && this.config.froala.key) {
            this.froalaOptions.key = this.config.froala.key;
        } else {
            this.logger.warn('Unable to set Froala key');
        }
    }

    public deleteInstructions(): void {
        this.dialog
            .show(
                Confirm.instantiate({
                    locals: {
                        title: this.translate.instant('GENERAL.DELETE_TEXT'),
                        description: this.translate.instant('GENERAL.DELETE_TEXT_DESC'),
                        confirmText: this.translate.instant('GENERAL.DELETE'),
                        confirmButtonCssClass: 'its-btn--delete',
                    },
                })
            )
            .then((result) => {
                if (result) {
                    this.item.description = null;
                    this.toaster.info('AUDITS.MESSAGES.CHECK_DELETED');
                }
            });
    }

    public addInstructions(): void {
        this.dialog
            .show(
                FroalaModal.instantiate({
                    locals: {
                        title:
                            this.item.type === 'display_text'
                                ? this.translate.instant('CHECKLISTS.ITEM_TYPE.DISPLAY_TEXT')
                                : this.translate.instant('CHECKLISTS.ADDITIONAL_INSTRUCTIONS'),
                        froalaOptions: this.froalaOptions,
                        content: this.item.description,
                    },
                })
            )
            .then((froalaText: string) => {
                this.item.description = froalaText;
            });
    }
}
