import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ICheckApi } from '../../../api/CheckApi';
import { IPageService } from '../../../services/PageService';
import { ICheckSession } from '../../../services/CheckSession';
import { IChecklistService } from '../../../services/ChecklistService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { IChecklist } from '../../../domain/checklists/Checklist';
import { ChecklistHistoryModal } from '../modals/ChecklistHistoryModal';

/**
 * The ISettingsMenuItem interface
 *
 */
interface ISettingsMenuItem {
    state: string;
    stateName: string;
    title: string;
    icon?: string;
    children?: ISettingsMenuItem[];
    reload?: boolean;
    params?: {};
}

/**
 * The checklist controller
 *
 * @param UserService
 * @param $state
 * @constructor
 */
@Controller('its.check.module.checklists', ChecklistSettingsController.IID, ChecklistSettingsController)
class ChecklistSettingsController {
    static IID: string = 'ChecklistSettingsController';
    static $inject: Array<string> = [
        'iteToaster',
        '$state',
        '$mdDialog',
        '$translate',
        'itcCheckApi',
        '$timeout',
        'itcPageService',
        'itcCheckSession',
        'itcChecklistService',
        '$mdMedia',
        '$scope',
    ];

    public hasAclAdmin: boolean = false;
    public hasAclCreator: boolean = false;
    public checklistId: string = null;
    public navExpanded: boolean = false;

    public mobileOpen: boolean = false;
    public checklist: IChecklist = null;

    private checklistSubscription: SubjectSubscription;

    /**
     * The class constructor
     *
     * @param toaster
     * @param stateService
     * @param dialog
     * @param translate
     * @param checkApi
     * @param timeout
     * @param pageService
     * @param session
     * @param checklistService
     * @param media
     * @param scope
     */
    constructor(
        private toaster: IToaster,
        private stateService: ng.ui.IStateService,
        private dialog: ng.material.IDialogService,
        private translate: angular.translate.ITranslateService,
        private checkApi: ICheckApi,
        private timeout: ng.ITimeoutService,
        private pageService: IPageService,
        private session: ICheckSession,
        private checklistService: IChecklistService,
        private media: ng.material.IMedia,
        private scope: ng.IScope
    ) {
        this.checklist = this.checklistService.getChecklist();
        this.initSubscription();

        if (!this.stateService.params['uuid']) {
            this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_LOAD');
            return;
        }
    }

    public navIsExpanded(): boolean {
        return this.media('gt-sm') || this.navExpanded;
    }

    public openHistoryModal(): void {
        this.dialog.show(ChecklistHistoryModal.instantiate({ locals: { checklistUuid: this.checklist.originalUuid } }));
    }

    public openHelp(): void {
        window.open('https://intouchcheck.zendesk.com/hc/en-us/categories/360001050733-Manage-Checklists', '_blank');
    }

    public onPublish(publishPromise: ng.IPromise<IChecklist>): void {
        publishPromise
            .then((result: IChecklist) => {
                this.checklist = result;
                this.checklistService.setChecklist(this.checklist);
                this.toaster.success('CHECKLISTS.MESSAGES.NEW_VERSION');
            })
            .catch((error) => {
                this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_PUBLISH', error);
            });
    }

    public goToChecklistBuilder(): void {
        this.stateService.go('home.checklists.edit', { uuid: this.checklistService.getChecklist().uuid });
    }

    public changeChecklistOutcomes(): void {
        this.stateService.go('home.checklists.outcomes', { uuid: this.checklistService.getChecklist().uuid });
    }

    private initSubscription(): void {
        this.checklistSubscription = this.checklistService.checklistObserver.subscribe((checklist) => {
            this.checklist = checklist;
        });
        this.scope.$on('$destroy', () => {
            if (this.checklistSubscription) {
                this.checklistSubscription.unsubscribe();
            }
        });
    }
}
