import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { IChecklist } from '../domain/checklists/Checklist';
import { BehaviorSubject } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { QueryFilter } from '@intouch/its.essential/app/essential/domain/api/QueryFilter';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IIql, Iql } from '@intouch/iql-ts-sdk/src/domain/Iql';
import { Metric } from '@intouch/iql-ts-sdk/src/domain/metrics/Metric';
import { Dimension } from '@intouch/iql-ts-sdk/src/domain/dimensions/Dimension';
import { FilterGroup } from '@intouch/iql-ts-sdk/src/domain/filters/FilterGroup';
import * as _ from 'lodash';
import { IListingItem } from '../domain/checklists/ListingItem';
import { StringFilter } from '@intouch/iql-ts-sdk/src/domain/filters/StringFilter';
import { Filter } from '@intouch/iql-ts-sdk/src/domain/filters/Filter';
import { IqlQueryResult } from '@intouch/iql-ts-sdk/src/domain/IqlQueryResult';
import { IPager } from '@intouch/its.essential/app/essential/domain/Pager';
import { ICheckApi } from '../api/CheckApi';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';

export interface IChecklistService {
    checklistsResult: BehaviorSubject<IChecklistsResult>;
    checklistObserver: BehaviorSubject<IChecklist>;

    setChecklist(checklist: IChecklist): void;
    getChecklist(): IChecklist;
    getChecklistProgramFilter(checklistUuid: string): StringFilter;
    getChecklistProductFilter(): StringFilter;
    getUserHierarchyFilters(): Array<Filter>;
}

export interface IChecklistsResult {
    checklists: IListingItem[];
    pager: IPager;
}

@Service('its.check.services', ChecklistService.IID, ChecklistService)
export class ChecklistService implements IChecklistService {
    static IID: string = 'itcChecklistService';
    static $inject: string[] = ['itcCheckApi', 'iteAccessService', 'iteIqlApi', 'iteToaster'];
    public checklistsResult: BehaviorSubject<IChecklistsResult> = new BehaviorSubject<IChecklistsResult>();
    public checklistObserver: BehaviorSubject<IChecklist> = new BehaviorSubject<IChecklist>();
    public hasError: boolean = false;
    public isLoading: boolean = false;

    private checklist: IChecklist;

    constructor(
        private checkApi: ICheckApi,
        private accessService: IAccessService,
        private iqlApi: IIqlApi,
        private toaster: IToaster
    ) {}

    public setChecklist(checklist: IChecklist): void {
        this.checklist = checklist;
        this.checklistObserver.next(this.checklist);
    }

    public getChecklist(): IChecklist {
        return this.checklist;
    }

    public loadChecklistsByPreset(presetName: string, searchText: string, pager: IPager): ng.IPromise<any> {
        let queryFilter: QueryFilter = this.getQueryFilterForPreset(presetName);
        queryFilter.addPager(pager);
        this.hasError = false;
        this.isLoading = true;

        return this.checkApi
            .findChecklists(pager, searchText, queryFilter.toString(), ['scheduler'])
            .then((results: PagedEntities) => {
                pager = results.getPager();
                let checklists: IListingItem[] = results.getEntities();

                // load the analytics data now if we have checklists
                if (checklists && checklists.length > 0) {
                    let iqlQuery: IIql = this.buildChecklistIql(checklists);

                    // run the query and merge the results with the current checklist listing items
                    this.iqlApi
                        .execute(iqlQuery)
                        .then((result: IqlQueryResult) => {
                            if (result && result.series && result.series.length > 0) {
                                checklists = this.assignChecklistScores(result.series[0].data, checklists);
                            }
                        })
                        .finally(() => {
                            this.checklistsResult.next({
                                checklists: checklists,
                                pager: pager,
                            });
                        });
                } else {
                    this.checklistsResult.next({
                        checklists: checklists,
                        pager: pager,
                    });
                }
            })
            .catch((error) => {
                this.hasError = true;
                this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_LOAD');
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    public getChecklistProgramFilter(checklistUuid: string): StringFilter {
        return new StringFilter('meta.reference->program_id', Iql.EQUAL, checklistUuid);
    }

    public getChecklistProductFilter(): StringFilter {
        return new StringFilter('meta->product', Iql.EQUAL, 'check');
    }

    public getUserHierarchyFilters(): Array<Filter> {
        const userNodeUuids: string[] = this.accessService.getToken().getUser().nodes;
        return userNodeUuids.map((node) => {
            return new Filter('meta.hierarchy->uuid', Iql.EQUAL, node, Iql.HIERARCHY);
        });
    }

    private getQueryFilterForPreset(presetName: string): QueryFilter {
        let queryFilter: QueryFilter = new QueryFilter();

        switch (presetName) {
            case 'pending':
                queryFilter.addParam('filter[active]', 1);
                queryFilter.addParam('filter[status]', 'proposed');
                break;
            case 'published':
                queryFilter.addParam('filter[active]', 1);
                queryFilter.addParam('filter[status]', 'published');
                break;
            case 'disabled':
                queryFilter.addParam('filter[active]', 0);
                break;
            case 'my-checklists':
                queryFilter.addParam('filter[created_by_me]', true);
                break;
            default:
                return queryFilter;
        }

        return queryFilter;
    }

    private buildChecklistIql(checklists: IListingItem[]): IIql {
        let query: IIql = new Iql();
        const synthesisMetric: Metric = new Metric('meta->synthesis_id', Iql.COUNT, Iql.COUNT);
        const scorePercentageMetric: Metric = new Metric('meta->score.percentage', Iql.SCORE, Iql.AVERAGE);
        const programIdDimension: Dimension = new Dimension('meta.reference->program_id', 'string', 'program_name');

        query.metric(synthesisMetric);
        query.metric(scorePercentageMetric);
        query.dimension(programIdDimension);

        // add the checklists that are currently shown as filters, product and users hierarchy
        query.filter(
            new FilterGroup(
                Iql.OR,
                _.map(checklists, (item: IListingItem) => {
                    return this.getChecklistProgramFilter(item.originalUuid);
                })
            )
        );
        query.filter(new FilterGroup(Iql.AND, [this.getChecklistProductFilter()]));
        query.filter(new FilterGroup(Iql.OR, this.getUserHierarchyFilters()));

        return query;
    }

    private assignChecklistScores(scores: any, checklists: IListingItem[]): IListingItem[] {
        for (let scoreItem of scores) {
            let checklist: IListingItem = _.find(checklists, { originalUuid: scoreItem.value });
            if (checklist) {
                checklist.averageScore = scoreItem.metrics['meta_score_average'];
                checklist.recordCount = scoreItem.metrics['meta_count_count'];
            }
        }

        return checklists;
    }
}
