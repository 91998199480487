import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IItemInput, ItemInput } from './ItemInput';
import {
    IItemInputSettings,
    ItemInputSettings,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';

/**
 * The IItemDisplayTextSettings interface
 *
 */
export interface IItemDisplayGraphicSettings extends IItemInputSettings {
    displayItemLabel: boolean;
}

/**
 * The ItemDisplayGraphicSettings class
 *
 */
export class ItemDisplayGraphicSettings extends ItemInputSettings implements IItemDisplayGraphicSettings {
    public displayItemLabel: boolean = false;
}

/**
 * The IItemDisplayGraphic interface
 *
 */
export interface IItemDisplayGraphic extends IItemInput {
    settings: IItemDisplayGraphicSettings;
    photoScale: string;
}

/**
 * The ItemText class
 *
 */
export class ItemDisplayGraphic extends ItemInput implements IItemDisplayGraphic {
    public settings: IItemDisplayGraphicSettings = new ItemDisplayGraphicSettings();
    public type: string = 'graphic';
    public photoScale: string = '100';

    /**
     * Make a generic instance of this object
     *
     * @param name
     * @returns {IItemText}
     */
    public static make(name: string): IItemDisplayGraphic {
        let me: IItemDisplayGraphic = new ItemDisplayGraphic();
        me.name = name;
        me.settings.allowCnaNa = false;

        return me;
    }

    /**
     * Return Display Item Label
     *  @returns {boolean}
     */
    public displayItemLabel(): boolean {
        return this.settings.displayItemLabel;
    }

    /**
     * Checks if this item can have a photo added to it
     *
     * @returns {boolean}
     */
    public canAddPhoto(): boolean {
        return false;
    }

    /**
     * Checks if this item can have comments added to it
     *
     * @returns {boolean}
     */
    public canAddComment(): boolean {
        return false;
    }

    /**
     * Checks if this item can be follow-up required
     *
     * @returns {boolean}
     */
    public canFollowUpRequire(): boolean {
        return false;
    }

    /**
     * Builds the object from json
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {ItemText}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemDisplayGraphic {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['settings']) {
            this.settings = EntityBuilder.buildOne<IItemDisplayGraphicSettings>(
                ItemDisplayGraphicSettings,
                jsonObject['settings'],
                convertToCamel
            );
        }

        return this;
    }
}
