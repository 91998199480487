import { IBaseSummary, BaseSummary } from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseSummary';

/**
 * The ISummary interface
 *
 * Contains the overall results, outcomes, and states of the audit
 */
export interface ISummary extends IBaseSummary {}

/**
 * The Summary class
 *
 * Contains the overall results, outcomes, and states of the audit
 */
export class Summary extends BaseSummary implements ISummary {}
