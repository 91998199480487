// import shepherd.js and fail silently if it fails (IE 11)
try {
    var Shepherd = require('shepherd.js'); // tslint:disable-line
} catch (e) {
    console.warn('Failed to import shepherd.js');
}

/**
 * Shepherd provider to provide a single source to get Shephard class if it was able to load
 *
 */
export class ShepherdProvider {
    public static getShepherd(): any {
        if (!!Shepherd) {
            return Shepherd;
        } else {
            return null;
        }
    }
}
