import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemTranslation } from './BaseItemTranslation';
import { IItemDropdown } from '../../../../domain/checklists/ItemDropdown';

/**
 * The UI for rendering a way to add and remove multiple choice translations
 */
@Component('its.check.module.checklists', DropdownTranslation.CID, {
    template: require('/app/modules/checklists/components/translations/DropdownTranslation.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
        locale: '<',
    },
})
class DropdownTranslation extends BaseItemTranslation {
    static IID: string = 'DropdownTranslation';
    static CID: string = 'itsDropdownTranslation';
    static $inject: Array<string> = ['$translate'];

    public responseLabel: string;
    public item: IItemDropdown;

    /**
     * The class constructor
     *
     * @param translate
     */
    constructor(public translate: angular.translate.ITranslateService) {
        super(translate);

        this.translate(['TRANSLATIONS.DROPDOWN.LABEL', 'TRANSLATIONS.DROPDOWN.RESPONSE_LABEL']).then((translations) => {
            this.itemLabel = translations['TRANSLATIONS.DROPDOWN.LABEL'];
            this.responseLabel = translations['TRANSLATIONS.DROPDOWN.RESPONSE_LABEL'];
        });
    }
}
