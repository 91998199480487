import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IChecklistService } from '../../../services/ChecklistService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';

@Controller('its.check.module.checklists', ChecklistUploadController.IID, ChecklistUploadController)
class ChecklistUploadController {
    static IID: string = 'ChecklistUploadController';
    static $inject: Array<string> = ['$state', 'itcChecklistService', 'iteAccessService', '$window'];

    constructor(
        private state: angular.ui.IStateService,
        private checklistService: IChecklistService,
        private accessService: IAccessService,
        private windowService: ng.IWindowService
    ) {}

    public onStep(stepName: string): boolean {
        return this.state.current.name.indexOf(stepName) > -1;
    }

    /**
     * opens help
     */
    public openHelp(): void {
        this.windowService.open(
            'https://intouchcheck.zendesk.com/hc/en-us/articles/360011450613-Upload-a-Checklist',
            '_blank'
        );
    }
}
