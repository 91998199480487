import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IAdditionalRequirements } from '@intouch/its.check.essential/app/check-essential/domain/datatypes/AdditionalRequirements';
import { ItemTime } from '../../../domain/checklists/ItemTime';

@Component('its.check.module.checklists', AdditionalRequirements.CID, {
    template: require('/app/modules/checklists/components/AdditionalRequirements.html'),
    controllerAs: 'vm',
    bindings: {
        showRequirements: '=',
        item: '=',
        parentItem: '<',
    },
})
class AdditionalRequirements {
    static IID: string = 'AdditionalRequirements';
    static CID: string = 'itcAdditionalRequirements';

    static $inject: Array<string> = [];

    public showRequirements: boolean;
    public item: IAdditionalRequirements;
    public parentItem: any;

    public isItemParentTime: boolean = false;

    $onInit(): void {
        if (this.parentItem instanceof ItemTime) {
            this.isItemParentTime = true;
        }
    }

    /**
     * Toggles additional requirements on/off
     *
     * @param {boolean} state
     */
    public toggleAdditionalRequirements(state: boolean): void {
        if (state) {
            this.item = { type: 'photo', condition: 'always' };
        } else {
            this.item = null;
        }
    }
}
