import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { GroupDatalistModal } from '@intouch/its.essential/app/essential/modals/GroupDatalistModal';
import { IPager } from '@intouch/its.essential/app/essential/domain/Pager';
import { IChecklist } from '../../../domain/checklists/Checklist';
import { ICheckSession } from '../../../services/CheckSession';
import { ICheckApi } from '../../../api/CheckApi';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { IPageService } from '../../../services/PageService';
import { ChecklistService } from '../../../services/ChecklistService';
import { BaseChecklist } from '../BaseChecklist';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';
import { ChecklistEditService } from '../../../services/ChecklistEditService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';


@Controller('its.check.module.checklists', ChecklistGeneralSettingsController.IID, ChecklistGeneralSettingsController)
class ChecklistGeneralSettingsController extends BaseChecklist {
    static IID: string = 'ChecklistGeneralSettingsController';
    static $inject: Array<string> = [
        '$state',
        'itcCheckApi',
        'iteToaster',
        'itcCheckSession',
        'iteAccessApi',
        '$q',
        '$scope',
        'itcPageService',
        'itcChecklistService',
        '$translate',
        '$mdDialog',
        'itePanelService',
        '$window',
        'itcChecklistEditService',
        'iteAccessService',
    ];

    public saving: boolean = false;
    public checklist: IChecklist;
    public formName: string = 'generalSettingsForm';
    public accessType: string = null;
    private checklistSubscription: SubjectSubscription;
    private enableImageOriginalResolutionsSetting: boolean = false;

    /**
     * Creates an instance of the Settings controller
     *
     */

    constructor(
        protected state: angular.ui.IStateService,
        protected checkApi: ICheckApi,
        protected toaster: IToaster,
        protected session: ICheckSession,
        private accessApi: IAccessApi,
        private q: ng.IQService,
        private scope: ng.IScope,
        protected pageService: IPageService,
        protected checklistService: ChecklistService,
        protected translate: ng.translate.ITranslateService,
        private dialogService: ng.material.IDialogService,
        private panelService: IPanelService,
        private windowService: ng.IWindowService,
        protected checklistEditService: ChecklistEditService,
        private accessService: IAccessService
    ) {
        super(state, checkApi, toaster, pageService, translate, session, checklistService, checklistEditService);

        let setAccessType: Function = () => {
            if (this.checklist) {
                if (this.checklist.accessTags && this.checklist.accessTags.length > 0) {
                    this.accessType = 'tag';
                }

                if (this.checklist.accessLocations && this.checklist.accessLocations.length > 0) {
                    this.accessType = 'location';
                }
            }
        };

        let checklist: IChecklist = this.checklistService.getChecklist();
        this.enableImageOriginalResolutionsSetting = this.accessService.hasFeatureEnabled('check', 'original-image-resolution')
        this.initSubscription();

        if (!checklist || checklist.uuid !== this.state.params['uuid']) {
            this.load().then(() => {
                setAccessType();
            });
        } else {
            this.checklist = checklist.clone();
            setAccessType();
        }
    }

    /**
     * Save checklist General Settings
     */
    public saveChecklist(): void {
        this.saving = true;
        this.save(this.checklist, 'GENERAL.MESSAGES.SAVED_CHANGES', null, 'home.checklists.settings.general')
            .catch((error) => {
                this.handleHttpResponseErrors(
                    error,
                    'Unable to save checklist settings',
                    'CHECKLISTS.ERRORS.UNABLE_TO_SAVE_SETTINGS'
                );
            })
            .finally(() => {
                this.saving = false;
            });
    }

    /**
     * Opens the group info modal
     *
     * @param groupUuid
     */
    public openGroupInfoModal(groupUuid: string): void {
        this.dialogService.show(GroupDatalistModal.instantiate({ locals: { groupUuid: groupUuid } }));
    }

    /**
     * Clears the approver group
     *
     */
    public clearApproverGroup(): void {
        this.checklist.approverGroups = [];
        this.clearReviewerGroup();
    }

    /**
     * Clears the reviewer group
     *
     */
    public clearReviewerGroup(): void {
        this.checklist.reviewerGroups = [];
    }

    /**
     * Returns the form
     *
     * @return {any}
     */
    public getForm(): any {
        return this.scope[this.formName];
    }

    /**
     * Handler to trigger on checklist name change
     *
     */
    public onNameChange(): void {
        // clear name duplication exception
        if (this.getForm()) {
            this.getForm().name.$setValidity('ChecklistAlreadyExistsException', true);
        }
    }

    /**
     * Find locations
     *
     * @param {string} search
     * @param {IPager} pager
     * @return {angular.IPromise<PagedEntities>}
     */
    public findLocations(search?: string, pager?: IPager): ng.IPromise<PagedEntities> {
        return this.accessApi.fetchHierarchyNodes(search, pager, { 'filter[type]': 'location' });
    }

    /**
     * Handle change in access type property
     *
     * @param {string} type
     */
    public handleAccessTypeChange(type?: string): void {
        switch (type) {
            case 'location':
                this.checklist.accessTags = [];
                break;
            case 'tag':
                this.checklist.accessLocations = [];
                break;
            default:
                this.accessType = null;
                this.checklist.accessTags = [];
                this.checklist.accessLocations = [];
                break;
        }
    }

    public mapAccessTags(): void {
        this.checklist.accessTags = this.checklist.accessTags.map((tag) => {
            return { uuid: tag.uuid, name: tag.name };
        });
    }

    public mapAccessLocations(): void {
        this.checklist.accessLocations = this.checklist.accessLocations.map((location) => {
            return { uuid: location.uuid, name: location.name };
        });
    }

    public mapAccessGroups(): void {
        this.checklist.accessGroups = this.checklist.accessGroups.map((group) => {
            return { uuid: group.uuid, name: group.name };
        });
    }

    /**
     * opens help
     */
    public openHelp(): void {
        this.windowService.open(
            'https://intouchcheck.zendesk.com/hc/en-us/articles/360011453273-General-Checklist-Settings',
            '_blank'
        );
    }

    /**
     * Handler for error cases on http responses
     *
     * @param {IErrorResponse} error
     * @param {string} defaultMessage
     * @param {string} defaultToast
     */
    private handleHttpResponseErrors(
        error: IErrorResponse,
        defaultMessage: string = null,
        defaultToast: string = null
    ): void {
        if (!error) {
            error.message = defaultMessage + ' (' + error.message + ')';
            this.toaster.warn(defaultToast);
        } else {
            switch (error.type) {
                case 'ChecklistAlreadyExistsException':
                    if (this.getForm()) {
                        this.getForm().name.$setValidity('ChecklistAlreadyExistsException', false);
                    }
                    break;
                default:
                    error.message = defaultMessage + ' (' + error.message + ')';
                    this.toaster.warn(defaultToast);
            }
        }
    }

    private initSubscription(): void {
        this.checklistSubscription = this.checklistService.checklistObserver.subscribe((checklist) => {
            this.checklist = checklist;
        });
        this.scope.$on('$destroy', () => {
            if (this.checklistSubscription) {
                this.checklistSubscription.unsubscribe();
            }
        });
    }
}
