import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ItemMultipleChoice } from '../../../../../../domain/checklists/ItemMultipleChoice';

@Component('its.check.module.checklists', SelectionRequirementsSetting.CID, {
    template:
        require('/app/modules/checklists/components/items/shared/selection-requirements-setting/SelectionRequirementsSetting.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class SelectionRequirementsSetting {
    static IID: string = 'SelectionRequirementsSetting';
    static CID: string = 'itcSelectionRequirementsSetting';

    static $inject: Array<string> = ['$scope'];
    public item: ItemMultipleChoice;
    public selectionRequirementsForm: ng.IFormController;
    public hasRequirements: boolean = false;

    public constructor(private scope: ng.IScope) {
        this.scope.$watch(
            () => {
                return this.item.settings.responses.length;
            },
            () => {
                // length of responses changed so we fire this function to apply view changes to control based on validation
                this.setSelectionRequirementValueValidation();
            }
        );
    }

    public $onInit(): void {
        if (
            !!this.item &&
            this.item.settings &&
            this.item.settings.selectionRequirements &&
            !this.item.settings.selectionRequirements.selectionsOperator
        ) {
            this.item.settings.selectionRequirements.selectionsOperator = 'eq';
        }
        this.setToggleState();
    }

    public setToggleState(): void {
        if (
            this.item.settings.hasSelectionRequirements() &&
            (this.item.settings.selectionRequirements.selectionsOperator ||
                this.item.settings.selectionRequirements.selectionsValue)
        ) {
            this.hasRequirements = true;
        } else {
            this.hasRequirements = false;
        }
        this.setSelectionRequirementValueValidation();
    }

    public toggleSelectionRequirements(newState: boolean): void {
        if (newState) {
            this.item.settings.enableSelectionRequirements();
            this.item.settings.selectionRequirements.selectionsOperator = 'eq';
            this.item.settings.selectionRequirements.selectionsValue = 1;
        } else {
            this.item.settings.disableSelectionRequirements();
        }
        this.setSelectionRequirementValueValidation();
    }

    private setSelectionRequirementValueValidation(): void {
        if (
            this.item &&
            this.item.settings.hasSelectionRequirements() &&
            this.selectionRequirementsForm &&
            this.selectionRequirementsForm['itcCheckboxSelectionRequirementsValue']
        ) {
            this.selectionRequirementsForm['itcCheckboxSelectionRequirementsValue'].$setDirty();
            this.selectionRequirementsForm['itcCheckboxSelectionRequirementsValue'].$setTouched();
        }
    }
}
