import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';

import { LanguageProvider, ILanguage } from '@intouch/its.check.essential/app/check-essential/domain/LanguageProvider';

import { ICheckApi } from '../../../api/CheckApi';
import { IChecklist } from '../../../domain/checklists/Checklist';
import { IPageService } from '../../../services/PageService';
import { BaseChecklist } from '../BaseChecklist';
import { ICheckSession } from '../../../services/CheckSession';
import { ChecklistService } from '../../../services/ChecklistService';
import { ChecklistEditService } from '../../../services/ChecklistEditService';
import { ChecklistTranslationExportService } from '../../../services/ChecklistTranslationExportService';

@Controller(
    'its.check.module.checklists',
    ChecklistTranslationsSettingsController.IID,
    ChecklistTranslationsSettingsController
)
class ChecklistTranslationsSettingsController extends BaseChecklist {
    static IID: string = 'ChecklistTranslationsSettingsController';
    static $inject: Array<string> = [
        '$state',
        'itcCheckApi',
        'iteToaster',
        'itcPageService',
        '$translate',
        'itcCheckSession',
        '$scope',
        'itcChecklistService',
        '$timeout',
        '$window',
        'itcChecklistEditService',
        'itcChecklistTranslationExportService',
    ];

    public saving: boolean = false;
    public noTranslatables: boolean = true;
    public checklist: IChecklist;
    public currentLanguage: ILanguage = null;
    public languages: Array<ILanguage> = [];

    public noCustomOutcomeTranslatables: boolean = true;
    public translations: any;

    public formName: string = 'translationSettingsForm';
    private checklistSubscription: SubjectSubscription;

    constructor(
        protected state: ng.ui.IStateService,
        protected checkApi: ICheckApi,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected translate: angular.translate.ITranslateService,
        protected session: ICheckSession,
        protected scope: ng.IScope,
        protected checklistService: ChecklistService,
        private timeout: ng.ITimeoutService,
        private windowService: ng.IWindowService,
        protected checklistEditService: ChecklistEditService,
        public checklistTranslateService: ChecklistTranslationExportService
    ) {
        super(state, checkApi, toaster, pageService, translate, session, checklistService, checklistEditService);

        let checklist: IChecklist = this.checklistService.getChecklist();
        this.initSubscription();

        if (!checklist || checklist.uuid !== this.state.params['uuid']) {
            this.load().then(() => {
                this.handleSourceLanguageChange();
                this.noTranslatables = !this.hasTranslatables();
                this.noCustomOutcomeTranslatables = !this.hasCustomOutcomeTranslatables();

                this.translate(['CHECKLISTS.CHECKLIST_TITLE_TRANSLATION']).then((translations) => {
                    this.translations = translations;
                });
            });
        } else {
            this.checklist = checklist.clone();
            this.handleSourceLanguageChange();

            this.noTranslatables = !this.hasTranslatables();
            this.noCustomOutcomeTranslatables = !this.hasCustomOutcomeTranslatables();
            this.currentLanguage = this.languages[0];

            this.translate(['CHECKLISTS.CHECKLIST_TITLE_TRANSLATION']).then((translations) => {
                this.translations = translations;
            });
        }
    }

    /**
     * Save checklist General Settings
     */
    public saveChecklist(): void {
        this.checklist.saveTranslations();
        this.saving = true;

        this.save(
            this.checklist,
            'GENERAL.MESSAGES.SAVED_CHANGES',
            'TRANSLATIONS.ERRORS.UNABLE_TO_SAVE_TRANSLATIONS',
            'home.checklists.settings.translations'
        ).finally(() => {
            this.saving = false;
        });
    }

    /**
     * Determines if we are loading data
     *
     * @returns {boolean}
     */
    public isLoading(): boolean {
        return this.pageService.loaderVisible;
    }

    public handleSourceLanguageChange(): void {
        this.languages = _.filter(LanguageProvider.getSupportedLanguages(), (lang: ILanguage) => {
            return lang.locale !== this.checklist.settings.sourceLanguage;
        });

        if (this.currentLanguage === null) {
            this.currentLanguage = this.languages[0];
        } else if (_.indexOf(this.languages, this.currentLanguage) === -1) {
            this.currentLanguage = this.languages[0];
        }
    }

    /**
     * Returns the form
     *
     * @return {any}
     */
    public getForm(): any {
        return this.scope[this.formName];
    }

    /**
     * Returns language labels
     */
    public getLanguageLabel(): string {
        if (this.checklist && this.checklist.settings.sourceLanguage) {
            return LanguageProvider.getLabelByLocale(this.checklist.settings.sourceLanguage);
        } else {
            return 'English';
        }
    }

    /**
     * opens help
     */
    public openHelp(): void {
        this.windowService.open(
            'https://intouchcheck.zendesk.com/hc/en-us/articles/360011453873-Add-Checklist-Translations',
            '_blank'
        );
    }

    /**
     * Checks if checklist has translatable objects (if a section exists)
     *
     * @returns {boolean}
     */
    private hasTranslatables(): boolean {
        return this.checklist && this.checklist.sections && this.checklist.sections.length > 0;
    }

    /**
     * Checks if checklist has translatable custom outcome
     *
     * @returns {boolean}
     */
    private hasCustomOutcomeTranslatables(): boolean {
        return this.checklist && this.checklist.outcomeType === 'custom' && this.checklist.outcomes.length > 0;
    }

    private initSubscription(): void {
        this.checklistSubscription = this.checklistService.checklistObserver.subscribe((checklist) => {
            this.checklist = checklist;
        });
        this.scope.$on('$destroy', () => {
            if (this.checklistSubscription) {
                this.checklistSubscription.unsubscribe();
            }
        });
    }
}
