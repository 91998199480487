import { Configuration } from '@intouch/its.essential/app/essential/decorators/Configuration';

/**
 * Will setup the users routing states
 *
 * @param $stateProvider
 */
@Configuration('its.check.module.outcomes', SettingsConfiguration)
class SettingsConfiguration {
    /**
     * Create the instance of the configuration used for angular binding
     *
     * @returns {function(ng.ui.IStateProvider): its.check.module.users.SettingsConfiguration}
     */
    public static instance(): any {
        let config: any = ($stateProvider: ng.ui.IStateProvider) => new SettingsConfiguration($stateProvider);
        config.$inject = ['$stateProvider'];
        return config;
    }

    /**
     * Create the user configuration class
     *
     * @param stateProvider
     */
    constructor(private stateProvider: ng.ui.IStateProvider) {
        this.buildStates();
    }

    /**
     * Initialize the user module
     */
    public buildStates(): void {
        this.stateProvider.state('home.outcomes', {
            url: '/outcomes?page&sort_by&order&search',
            data: { title: 'SETTINGS.OUTCOMES.OUTCOMES_MENU', translate: true },
            template: require('/app/modules/outcomes/views/Listing.html'),
            controller: 'its.check.module.outcomes.ListingController as vm',
            params: {
                page: {
                    value: '1',
                    squash: true,
                },
                sort_by: {
                    value: 'updated_at',
                    squash: true,
                },
                order: {
                    value: 'desc',
                    squash: true,
                },
                search: {
                    value: null,
                    squash: true,
                },
            },
        });
    }
}
