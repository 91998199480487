import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemTranslation } from './BaseItemTranslation';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';

/**
 * The UI for rendering a way to add and remove simple text translations
 */
@Component('its.check.module.checklists', DisplayTextTranslation.CID, {
    template: require('/app/modules/checklists/components/translations/DisplayTextTranslation.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
        locale: '<',
    },
})
class DisplayTextTranslation extends BaseItemTranslation {
    static IID: string = 'DisplayTextTranslation';
    static CID: string = 'itsDisplayTextTranslation';
    static $inject: Array<string> = ['APPCONFIG', '$translate', '$sce', 'iteLogger'];

    static toolbarButtons: Array<string> = [
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        'underline',
        'fontSize',
        'fontFamily',
        'color',
        'outdent',
        'indent',
        'align',
        'formatOL',
        'formatUL',
        'insertLink',
        'html',
    ];

    public froalaOptions: any = {
        toolbarButtons: DisplayTextTranslation.toolbarButtons,
        toolbarButtonsMD: DisplayTextTranslation.toolbarButtons,
        toolbarButtonsSM: DisplayTextTranslation.toolbarButtons,
        toolbarButtonsXS: DisplayTextTranslation.toolbarButtons,
        linkInsertButtons: [],
        placeholderText: '',
        linkAlwaysBlank: true,
        linkAutoPrefix: 'http://',
        charCounterCount: false,
        quickInsertButtons: [],
        attribution: false,
        fontFamily: {
            'Arial,Helvetica,sans-serif': 'Arial',
            "'Arial Black',Helvetica,sans-serif": 'Arial Black',
            "'Comic Sans MS', cursive, sans-serif": 'Comic Sans MS',
            "'Courier New', Courier, monospace": 'Courier New',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            "'Trebuchet MS', Helvetica, sans-serif": 'Trebuchet MS',
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
        },
        pluginsEnabled: ['url', 'colors', 'fontSize', 'fontFamily', 'lists', 'link', 'align', 'codeView'],
    };

    /**
     *
     * @param config
     * @param {angular.translate.ITranslateService} translate
     * @param {angular.ISCEService} sce
     * @param {ILogger} logger
     */
    constructor(
        private config: any,
        public translate: angular.translate.ITranslateService,
        protected sce: ng.ISCEService,
        private logger: ILogger
    ) {
        super(translate);

        this.translate(['TRANSLATIONS.TEXT.LABEL']).then((translations) => {
            this.itemLabel = translations['TRANSLATIONS.TEXT.LABEL'];
        });

        // set Froala License Key
        if (this.config.froala && this.config.froala.key) {
            this.froalaOptions.key = this.config.froala.key;
        } else {
            this.logger.warn('Unable to set Froala key');
        }
    }

    /**
     * Trust the string as HTML and allow angular to render it
     *
     * @param {string} html
     * @returns {string}
     */
    public trustAsHtml(html: string): string {
        return this.sce.trustAsHtml(html);
    }
}
