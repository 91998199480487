import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItem } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItem';
import { BaseItemSettingsComponent } from '../BaseItemSettingsComponent';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { ChecklistService } from '../../../../../services/ChecklistService';
import { IChecklist } from '../../../../../domain/checklists/Checklist';

@Component('its.check.module.checklists', MultipleChoiceSettings.CID, {
    template: require('/app/modules/checklists/components/items/multiple-choice/MultipleChoiceSettings.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class MultipleChoiceSettings extends BaseItemSettingsComponent {
    static IID: string = 'MultipleChoiceSettings';
    static CID: string = 'itcMultipleChoiceSettings';

    static $inject: Array<string> = ['itcChecklistService', '$scope'];
    public item: BaseItem;
    public checklist: IChecklist = null;

    public constructor(private checklistService: ChecklistService, private scope: ng.IScope) {
        super();
        this.subscribeToChecklist();
    }

    private subscribeToChecklist(): void {
        const checklistSubscription: SubjectSubscription = this.checklistService.checklistObserver.subscribe(
            (checklist) => {
                this.checklist = checklist;
            }
        );
        checklistSubscription.unsubscribeOnScopeDestruction(this.scope);
    }
}
