import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { ICheckHttpClient, CheckHttpClient, IApiClientConfiguration } from './CheckHttpClient';
import { IConfigurable } from '@intouch/its.essential/app/essential/services/IConfigurable';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import {
    IListingItem as IChecklistListingItem,
    ListingItem as ChecklistListingItem,
} from '../domain/checklists/ListingItem';
import { IListingItem as IAuditListingItem, ListingItem as AuditListingItem } from '../domain/audits/ListingItem';
import { IChecklist, Checklist } from '../domain/checklists/Checklist';
import { IAuditPhoto } from '@intouch/its.check.essential/app/check-essential/domain/audits/AuditPhoto';
import { ChecklistAdapter } from '../domain/checklists/ChecklistAdapter';
import { Audit, IAudit } from '../domain/audits/Audit';
import { IAssignment } from '../domain/checklists/Assignment';
import { IIncompleteListingItem, IncompleteListingItem } from '../domain/audits/IncompleteListingItem';
import { IQueryFilter, QueryFilter } from '@intouch/its.essential/app/essential/domain/api/QueryFilter';
import { ChecklistRequirement } from '@intouch/its.check.essential/app/check-essential/domain/ChecklistRequirement';
import { IOutcomeSetListing, OutcomeSetListing } from '../domain/outcome-sets/OutcomeSetListing';
import { IUpcomingListingItem, UpcomingListItem } from '../domain/audits/UpcomingListItem';
import { OutcomeSet } from '../domain/outcome-sets/OutcomeSet';
import { ChecklistUuidType } from '../domain/checklists/enums/ChecklistUuidType';
import { IInProgressListingItem, InProgressListingItem } from '../domain/audits/InProgressListingItem';
import { DeletedListingItem, IDeletedListingItem } from '../domain/audits/DeletedListingItem';
import { IPromise } from 'angular';

export interface ICheckApiClientConfiguration extends IApiClientConfiguration {
    checklistRequirementVersion: number;
}

/**
 * The check API interface
 */
export interface ICheckApi extends ICheckHttpClient {
    // gets
    findApprovableAudits(pager?: IPager, searchTerm?: string): ng.IPromise<PagedEntities>;

    findTrashedAudits(pager?: IPager, searchTerm?: string): ng.IPromise<PagedEntities>;

    findReviewableAudits(pager?: IPager, searchTerm?: string): ng.IPromise<PagedEntities>;

    findIncompleteAudits(pager?: IPager, searchTerm?: string, queryFilter?: IQueryFilter): ng.IPromise<PagedEntities>;

    findCompletedAudits(pager?: IPager, searchTerm?: string): ng.IPromise<PagedEntities>;

    findUpcomingAudits(pager?: IPager, searchTerm?: string, queryFilter?: IQueryFilter): ng.IPromise<PagedEntities>;

    findInProgressAudits(pager?: IPager, queryFilter?: IQueryFilter): ng.IPromise<PagedEntities>;

    updateUpcoming(upcoming: IUpcomingListingItem): ng.IPromise<IUpcomingListingItem>;

    findAuditByUuid(uuid: string, hydrateChecklist?: boolean): ng.IPromise<IAudit>;

    findChecklist(uuid: string, type?: string): ng.IPromise<IChecklist>;

    findChecklists(
        pager?: IPager,
        searchTerm?: string,
        filters?: string,
        includes?: Array<string>
    ): ng.IPromise<PagedEntities>;

    createChecklist(name: string): ng.IPromise<IChecklist>;

    updateChecklist(checklist: IChecklist): ng.IPromise<IChecklist>;

    createChecklistFromTemplate(name: string, templateUuid: string): ng.IPromise<IChecklist>;

    uploadMedia(photo: IAuditPhoto): ng.IPromise<string>;

    uploadMediaItems(photos: IAuditPhoto[]): ng.IPromise<any>;

    assignChecklist(assigment: IAssignment): ng.IPromise<IAudit>;

    disableChecklist(uuid: string): ng.IPromise<IChecklist>;

    enableChecklist(uuid: string): ng.IPromise<IChecklist>;

    publishChecklist(uuid: string): ng.IPromise<IChecklist>;

    copyChecklist(uuid: string, checklist: IChecklist): ng.IPromise<IChecklist>;

    rejectAudit(uuid: string, comment: string): ng.IPromise<any>;

    approveAudit(uuid: string): ng.IPromise<any>;

    findOutcomeByUuidOrId(uuidOrId: string | number): ng.IPromise<OutcomeSet>;

    findOutcomes(search?: string, pager?: Pager): ng.IPromise<PagedEntities>;

    saveOutcomeSet(outcomeSet: OutcomeSet): ng.IPromise<OutcomeSet>;

    // deletes
    deleteChecklist(uuid: string, type?: string): ng.IPromise<any>;

    deleteMissedAudits(uuid: string): ng.IPromise<any>;

    deleteAudit(uuid: string, force?: boolean): ng.IPromise<any>;

    deleteUpcomingAudit(uuid: string): ng.IPromise<any>;

    restoreAuditByUuid(uuid: string): ng.IPromise<any>;
}

/**
 * The check API wrapper class
 */
@Service('its.check.api', CheckApi.IID, CheckApi)
export class CheckApi extends CheckHttpClient implements ICheckApi, IConfigurable {
    static IID: string = 'itcCheckApi';

    private checklistRequirementVersion: number = null;

    /**
     * Configure the http api client
     *
     * @param data
     */
    public configure(data: ICheckApiClientConfiguration): void {
        super.configure(data);
        this.checklistRequirementVersion = data.checklistRequirementVersion || null;
    }

    /**
     * Retrieves a paged list of completed audits for provided state
     *
     * @param pager
     * @param searchTerm
     * @returns {IPromise<PagedEntities>}
     */
    public findCompletedAudits(pager?: IPager, searchTerm?: string): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(this.getUrl('/audits/completed' + this.getPagedParamString(pager, searchTerm)))
            .then((response: { data: { data: any } }) => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IAuditListingItem>(AuditListingItem, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Retrieves a paged list of approvable audits for provided state
     *
     * @param pager
     * @param searchTerm
     * @returns {IPromise<PagedEntities>}
     */
    public findApprovableAudits(pager?: IPager, searchTerm?: string): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(this.getUrl('/audits/approvable' + this.getPagedParamString(pager, searchTerm)))
            .then((response: { data: { data: any } }) => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IAuditListingItem>(AuditListingItem, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Retrieves a paged list of reviewable audits for provided state
     *
     * @param pager
     * @param searchTerm
     * @returns {IPromise<PagedEntities>}
     */
    public findReviewableAudits(pager?: IPager, searchTerm?: string): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(this.getUrl('/audits/reviewable' + this.getPagedParamString(pager, searchTerm)))
            .then((response: { data: { data: any } }) => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IAuditListingItem>(AuditListingItem, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Retrieves a paged list of incomplete audits for provided state
     *
     * @param pager
     * @param searchTerm
     * @param {IQueryFilter} queryFilter
     * @returns {IPromise<PagedEntities>}
     */
    public findIncompleteAudits(
        pager?: IPager,
        searchTerm?: string,
        queryFilter?: IQueryFilter
    ): ng.IPromise<PagedEntities> {
        queryFilter = queryFilter || new QueryFilter();

        if (pager) {
            queryFilter.addPager(pager);
        }

        if (searchTerm) {
            queryFilter.addSearch(searchTerm);
        }

        return this.prepare()
            .get(this.getUrl('/audits/missed'), queryFilter.getParams())
            .then((response: { data: { data: any } }) => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IIncompleteListingItem>(IncompleteListingItem, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Retrieves a list of all upcoming audits
     *
     * @param pager
     * @param searchTerm
     * @param queryFilter
     * @returns {IPromise<PagedEntities>}
     *
     */
    public findUpcomingAudits(
        pager?: IPager,
        searchTerm?: string,
        queryFilter?: IQueryFilter
    ): ng.IPromise<PagedEntities> {
        queryFilter = queryFilter || new QueryFilter();
        queryFilter.addParam('filter[notstatus]', 'cancelled,complete,not-scheduled');
        queryFilter.addParam('filter[type]', 'admin-assigned');

        if (pager) {
            queryFilter.addPager(pager);
        }

        if (searchTerm) {
            queryFilter.addSearch(searchTerm);
        }

        return this.prepare()
            .get(this.getUrl('/audits/upcoming'), queryFilter.getParams())
            .then((response: { data: { data: any } }) => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IUpcomingListingItem>(UpcomingListItem, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    public findInProgressAudits(pager?: IPager, queryFilter?: IQueryFilter): ng.IPromise<PagedEntities> {
        queryFilter = queryFilter || new QueryFilter();
        queryFilter.addParam('status', 'in-progress');

        if (pager) {
            queryFilter.addPager(pager);
        }

        return this.prepare()
            .get(this.getUrl('/audits/admin'), queryFilter.getParams())
            .then((response: { data: { data: any } }) => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IInProgressListingItem>(InProgressListingItem, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Retrieves a paged list of trashed audits for provided state
     *
     * @param pager
     * @param searchTerm
     * @returns {IPromise<PagedEntities>}
     */
    public findTrashedAudits(pager?: IPager, searchTerm?: string): ng.IPromise<PagedEntities> {
        return this.prepare()
            .get(this.getUrl('/audits/trashed' + this.getPagedParamString(pager, searchTerm)))
            .then((response: { data: { data: any } }) => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IDeletedListingItem>(DeletedListingItem, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /**
     * Updates an upcoming audit
     */
    public updateUpcoming(upcoming: IUpcomingListingItem): ng.IPromise<IUpcomingListingItem> {
        return this.prepare()
            .put(this.getUrl('/audits/upcoming/' + upcoming.uuid), upcoming.toJson(true))
            .then((response: { data: any }) => {
                return EntityBuilder.buildOne<IUpcomingListingItem>(UpcomingListItem, response.data, true);
            });
    }

    /**
     * Will find an audit by its uuid
     *
     * @param uuid
     * @param hydrateChecklist
     * @returns {IPromise<IAudit>}
     */
    public findAuditByUuid(uuid: string, hydrateChecklist: boolean = true): ng.IPromise<IAudit> {
        let defer: ng.IDeferred<IAudit> = this.q.defer();

        this.prepare()
            .get(this.getUrl('/audits/' + uuid))
            .then((auditJson: { data: any }): any => {
                let audit: IAudit = auditJson.data;

                if (hydrateChecklist) {
                    this.findChecklist(audit['checklist_uuid'])
                        .then((checklist: IChecklist) => {
                            audit.checklist = checklist;
                            defer.resolve(audit);
                        })
                        .catch((error) => {
                            defer.reject(error);
                        });
                } else {
                    defer.resolve(audit);
                }
            })
            .catch((error) => {
                defer.reject(error);
            });

        return defer.promise;
    }

    /**
     * Will restore an audit by its uuid
     *
     * @param uuid
     * @returns {IPromise<any>}
     */
    public restoreAuditByUuid(uuid: string): ng.IPromise<any> {
        return this.prepare().post(this.getUrl('/audits/' + uuid + '/restore'));
    }

    /**
     * Retrieves a checklist from the api with matching uuid or original uuid
     *
     * @param {string} uuid
     * @param {string} type
     * @returns {IPromise<IChecklist>}
     */
    public findChecklist(uuid: string, type?: string): ng.IPromise<IChecklist> {
        let url: string = '/checklists/' + uuid + '?include=scheduler,outcomes,accessGroups,accessTags,accessLocations';

        if (type) {
            url += '&type=' + type;
        }

        return this.prepare()
            .get(this.getUrl(url))
            .then((response: { data: any }): IChecklist => {
                return EntityBuilder.buildOne<IChecklist>(Checklist, response.data, true);
            });
    }

    /**
     * Retrieves a paged list of checklists from the Api
     *
     * @param pager
     * @param searchTerm
     * @param filters
     * @param includes
     *
     * @returns {IPromise<PagedEntities>}
     */
    public findChecklists(
        pager?: IPager,
        searchTerm?: string,
        filters?: string,
        includes?: Array<string>
    ): ng.IPromise<PagedEntities> {
        let queryString: string = this.getUrl('/checklists' + this.getPagedParamString(pager, searchTerm, filters));

        if (includes) {
            queryString += '&include=' + includes.join(',');
        }

        return this.prepare()
            .get(queryString)
            .then((response: { data: { data: any } }) => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IChecklistListingItem>(ChecklistListingItem, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    /* POSTS */

    /**
     * Posts a new checklist to the Api
     *
     * @param name
     * @returns {IPromise<IChecklist>}
     */
    public createChecklist(name: string): ng.IPromise<IChecklist> {
        return this.prepare()
            .post(this.getUrl('/checklists'), {
                name: name,
            })
            .then((response: { data: any }) => {
                return EntityBuilder.buildOne<IChecklist>(Checklist, response.data, true);
            });
    }

    /**
     * Updates a checklist
     *
     * @param checklist
     * @returns {IPromise<IChecklist>}
     */
    public updateChecklist(checklist: IChecklist): ng.IPromise<IChecklist> {
        checklist = <IChecklist>(
            ChecklistRequirement.applyRequirementVersion(checklist, this.checklistRequirementVersion)
        );
        return this.prepare()
            .put(this.getUrl('/checklists/' + checklist.uuid), ChecklistAdapter.toApiJson(checklist))
            .then((response: { data: any }) => {
                return EntityBuilder.buildOne<IChecklist>(Checklist, response.data, true);
            });
    }

    /**
     * Creates a checklist from a given template
     *
     * @param checklist
     * @returns {IPromise<IChecklist>}
     */
    public createChecklistFromTemplate(name: string, templateUuid: string): ng.IPromise<IChecklist> {
        return this.prepare()
            .post(this.getUrl('/checklists/install/' + templateUuid), { name: name })
            .then((response: { data: any }) => {
                return EntityBuilder.buildOne<IChecklist>(Checklist, response.data, true);
            });
    }

    /**
     * Sends image data to api and returns a string url to the image
     *
     * @param {IAuditPhoto} photo
     * @return {angular.IPromise<string>}
     */
    public uploadMedia(photo: IAuditPhoto): ng.IPromise<string> {
        return this.prepare()
            .post(this.getUrl('/media/photos'), { data: photo.data })
            .then((response: { data: { url: string } }) => {
                return response.data.url;
            });
    }

    /**
     * Sends multiple photos to api and resolves when all are resolved
     *
     * @param {IAuditPhoto[]} photos
     * @return {angular.IPromise<any>}
     */
    public uploadMediaItems(photos: IAuditPhoto[]): ng.IPromise<any> {
        let promises: Array<any> = [];

        for (let photo of photos) {
            promises.push(
                this.uploadMedia(photo).then((url: string) => {
                    photo.url = url;
                    return photo;
                })
            );
        }

        return this.q.all(promises);
    }

    /**
     * Sends a checklist assignment to the api
     *
     * @param {IAssignment} assigment
     * @return {angular.IPromise<IAudit>}
     */
    public assignChecklist(assigment: IAssignment): ng.IPromise<IAudit> {
        return this.prepare()
            .post(this.getUrl('/checklists/' + assigment.checklistUuid + '/assign'), assigment.toJson(true))
            .then((result: { data: any }) => {
                return EntityBuilder.buildOne<IAudit>(Audit, result.data, true);
            });
    }

    /* PUTS */

    /**
     * Sends a disable checklist put request to the Api
     *
     * @param uuid
     * @returns {IPromise<IChecklist>}
     */
    public disableChecklist(uuid: string): ng.IPromise<IChecklist> {
        return this.prepare()
            .put(this.getUrl('/checklists/' + uuid + '/disable'))
            .then((response: { data: any }) => {
                return EntityBuilder.buildOne<IChecklist>(Checklist, response.data, true);
            });
    }

    /**
     * Sends an enable checklist put request to the Api
     *
     * @param uuid
     * @returns {IPromise<IChecklist>}
     */
    public enableChecklist(uuid: string): ng.IPromise<IChecklist> {
        return this.prepare()
            .put(this.getUrl('/checklists/' + uuid + '/enable'))
            .then((response: { data: any }) => {
                return EntityBuilder.buildOne<IChecklist>(Checklist, response.data, true);
            });
    }

    /**
     * Sends a publish checklist put request to the Api
     *
     * @param uuid
     * @returns {IPromise<IChecklist>}
     */
    public publishChecklist(uuid: string): ng.IPromise<IChecklist> {
        return this.prepare()
            .put(this.getUrl('/checklists/' + uuid + '/publish'))
            .then((response: { data: any }) => {
                return EntityBuilder.buildOne<IChecklist>(Checklist, response.data, true);
            });
    }

    /**
     * Copy a checklist
     *
     * @param {string} uuid
     * @param {IChecklist} checklist
     * @returns {angular.IPromise<IChecklist>}
     */
    public copyChecklist(uuid: string, checklist: IChecklist): ng.IPromise<IChecklist> {
        return this.prepare()
            .post(this.getUrl('/checklists/' + uuid + '/copy'), checklist.toJson(true))
            .then((response) => {
                return EntityBuilder.buildOne<IChecklist>(Checklist, response.data, true);
            });
    }

    /**
     * Reject an audit
     *
     * @param {string} uuid
     * @param {string} comment
     * @return {angular.IPromise<any>}
     */
    public rejectAudit(uuid: string, comment: string): ng.IPromise<any> {
        return this.prepare().put(this.getUrl('/audits/' + uuid + '/reject'), { comment: comment });
    }

    /**
     * Approve an audit
     *
     * @param {string} uuid
     * @return {angular.IPromise<any>}
     */
    public approveAudit(uuid: string): ng.IPromise<any> {
        return this.prepare().put(this.getUrl('/audits/' + uuid + '/approve'));
    }

    /* DELETES */

    /**
     * Deletes a checklist by uuid
     *
     * @param {string} uuid
     * @param {string} type
     * @returns {IPromise<any>}
     */
    public deleteChecklist(uuid: string, type: string = ChecklistUuidType.Revision): ng.IPromise<any> {
        return this.prepare()
            .del(this.getUrl('/checklists/' + uuid + '?type=' + type))
            .then((response: any) => {
                return response;
            });
    }

    /**
     * Deletes a missed audit by uuid
     *
     * @param uuid
     * @returns {IPromise<any>}
     */
    public deleteMissedAudits(uuid: string): ng.IPromise<any> {
        return this.prepare()
            .del(this.getUrl('/audits/missed/' + uuid))
            .then((response: any) => {
                return response;
            });
    }

    /**
     * Deletes an audit by uuid
     *
     * @param uuid
     * @param force
     * @returns {IPromise<any>}
     */
    public deleteAudit(uuid: string, force?: boolean): ng.IPromise<any> {
        let query: string = !!force ? '?force=true' : '';
        return this.prepare()
            .del(this.getUrl('/audits/' + uuid + query))
            .then((response: any) => {
                return response;
            });
    }

    /**
     * Delete an upcoming audit by uuid
     * @param uuid
     * @returns {IPromise<any>}
     */
    public deleteUpcomingAudit(uuid: string): IPromise<any> {
        return this.prepare()
            .del(this.getUrl('/audits/upcoming/' + uuid))
            .then((response) => {
                return response;
            });
    }

    public findOutcomeByUuidOrId(uuidOrId: string | number): ng.IPromise<OutcomeSet> {
        if (typeof uuidOrId === 'number') {
            uuidOrId = uuidOrId.toString();
        }
        return this.prepare()
            .get(this.getUrl('/outcome_sets/' + uuidOrId))
            .then((response: any) => {
                return EntityBuilder.buildOne<OutcomeSet>(OutcomeSet, response.data, true);
            });
    }

    public findOutcomes(searchTerm: string = null, pager: Pager = null): ng.IPromise<PagedEntities> {
        let queryString: string = this.getUrl('/outcome_sets' + this.getPagedParamString(pager, searchTerm));
        return this.prepare()
            .get(queryString)
            .then((response: any) => {
                return new PagedEntities(
                    EntityBuilder.buildMany<IOutcomeSetListing>(OutcomeSetListing, response.data.data, true),
                    EntityBuilder.buildOne<IPager>(Pager, response.data, true)
                );
            });
    }

    public saveOutcomeSet(outcomeSet: OutcomeSet): ng.IPromise<OutcomeSet> {
        if (outcomeSet && outcomeSet.uuid) {
            return this.updateOutcomeSet(outcomeSet);
        } else {
            return this.createOutcomeSet(outcomeSet);
        }
    }

    private createOutcomeSet(outcomeSet: OutcomeSet): ng.IPromise<OutcomeSet> {
        return this.prepare()
            .post(this.getUrl('/outcome_sets'), outcomeSet.toJson(true))
            .then((response: any) => {
                return EntityBuilder.buildOne<OutcomeSet>(OutcomeSet, response.data, true);
            });
    }

    private updateOutcomeSet(outcomeSet: OutcomeSet): ng.IPromise<OutcomeSet> {
        return this.prepare()
            .put(this.getUrl('/outcome_sets/' + outcomeSet.uuid), outcomeSet.toJson(true))
            .then((response: any) => {
                return EntityBuilder.buildOne<OutcomeSet>(OutcomeSet, response.data, true);
            });
    }
}
