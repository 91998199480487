import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { IChecklist } from '../../../domain/checklists/Checklist';
import { ICheckApi } from '../../../api/CheckApi';
import { IPageService } from '../../../services/PageService';
import { BaseChecklist } from '../BaseChecklist';
import { ICheckSession } from '../../../services/CheckSession';
import { ChecklistService } from '../../../services/ChecklistService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { OutcomeSetListing } from '../../../domain/outcome-sets/OutcomeSetListing';
import {
    IDefaultOutcomeSet,
    DefaultOutcomeSet,
} from '@intouch/its.essential/app/essential/domain/outcomes/DefaultOutcomeSet';
import { SubscriptionPlanUtils } from '../../../utils/SubscriptionPlanUtils';
import { ICustomAction } from '@intouch/its.essential/app/essential/components/selects/CustomAction';
import { CreateOutcomeSetModal } from '../../outcomes/modals/CreateOutcomeSetModal';
import { IOutcomeSet, OutcomeSet } from '../../../domain/outcome-sets/OutcomeSet';
import { IOutcome } from '../../../domain/checklists/Outcome';
import { IOutcomeTranslation } from '@intouch/its.check.essential/app/check-essential/domain/translations/OutcomeTranslations';
import { ChecklistHistoryModal } from '../modals/ChecklistHistoryModal';
import { ChecklistEditService } from '../../../services/ChecklistEditService';

/**
 * The checklist controller
 *
 * @param UserService
 * @param $state
 * @constructor
 */
@Controller('its.check.module.checklists', ChecklistOutcomesSettingsController.IID, ChecklistOutcomesSettingsController)
class ChecklistOutcomesSettingsController extends BaseChecklist {
    static IID: string = 'ChecklistOutcomesSettingsController';
    static $inject: Array<string> = [
        '$state',
        'itcCheckApi',
        '$mdDialog',
        'iteToaster',
        'itcPageService',
        '$translate',
        'itcCheckSession',
        'itcChecklistService',
        '$scope',
        'iteAccessService',
        'APPCONFIG',
        '$mdMedia',
        'itcChecklistEditService',
    ];

    public loadingOutcomeSet: boolean = false;
    public saving: boolean = false;
    public checklist: IChecklist;
    public formName: string = 'outcomeSettingsForm';
    public subscriptionStatus: string = '';
    public subscriptionPlan: string = '';
    public outcomeSets: Array<OutcomeSet> = [];
    public outcomeToDisplay: OutcomeSet = null;
    public pager: Pager = null;
    public showDefaultOutcome: boolean = false;
    public defaultOutcomeSet: IDefaultOutcomeSet = new DefaultOutcomeSet();
    public customActions: Array<ICustomAction> = [];
    public locale: string = null;
    public mobileBreak: string = 'gt-sm';
    public sideNavExpanded: boolean = this.media(this.mobileBreak);
    public mobileOpen: boolean = false;

    /**
     * Class constructor
     *
     * @param state
     * @param checkApi
     * @param dialog
     * @param toaster
     * @param pageService
     * @param translate
     * @param session
     * @param checklistService
     * @param scope
     * @param accessService
     * @param appConfig
     * @param media
     * @param checklistEditService
     */
    constructor(
        protected state: angular.ui.IStateService,
        protected checkApi: ICheckApi,
        private dialog: ng.material.IDialogService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected translate: ng.translate.ITranslateService,
        protected session: ICheckSession,
        protected checklistService: ChecklistService,
        private scope: ng.IScope,
        private accessService: IAccessService,
        private appConfig: any,
        private media: ng.material.IMedia,
        protected checklistEditService: ChecklistEditService
    ) {
        super(state, checkApi, toaster, pageService, translate, session, checklistService, checklistEditService);
        this.setLocale();
        this.addOutcomeCustomActions();
        this.setSubscriptionInfo();
        this.setChecklist();
    }

    public disableSave(): boolean {
        return (
            this.saving ||
            !this.getForm() ||
            this.getForm().$invalid ||
            (this.checklist &&
                this.checklist.outcomeType === 'custom' &&
                (!this.checklist.outcomes || this.checklist.outcomes.length === 0))
        );
    }

    public loadOutcomes(search: string = null, pager: Pager = null): any {
        return this.checkApi.findOutcomes(search, pager);
    }

    public getCurrentSelectedOutcomeSet(): IOutcomeSet {
        return this.outcomeToDisplay || <any>this.defaultOutcomeSet;
    }

    public selectOutcomeSet(item: OutcomeSetListing | OutcomeSet): void {
        if (!item) {
            this.checklist.outcomeSetId = null;
            this.showDefaultOutcome = true;
        } else {
            this.checklist.outcomeSetId = item.id;
            this.loadOutcomeByUuidOrId(item.uuid);
        }
    }

    public loadOutcomeByUuidOrId(outcomeUuidOrId: string | number): void {
        this.loadingOutcomeSet = true;
        this.showDefaultOutcome = false;
        this.checkApi
            .findOutcomeByUuidOrId(outcomeUuidOrId)
            .then((foundOutcome: OutcomeSet) => {
                this.outcomeToDisplay = foundOutcome;
            })
            .finally(() => {
                this.loadingOutcomeSet = false;
            });
    }

    /**
     * Save checklist General Settings
     */
    public saveChecklist(): void {
        this.saving = true;
        this.save(
            this.checklist,
            'GENERAL.MESSAGES.SAVED_CHANGES',
            'CHECKLISTS.OUTCOME_VALIDATION_FAILED',
            'home.checklists.outcomes'
        ).finally(() => {
            this.saving = false;
        });
    }

    public onPublish(publishPromise: ng.IPromise<IChecklist>): void {
        this.saving = true;
        publishPromise
            .then((result: IChecklist) => {
                this.checklist = result;
                this.checklistService.setChecklist(this.checklist);
                this.toaster.success('CHECKLISTS.MESSAGES.NEW_VERSION');
            })
            .catch((error) => {
                this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_PUBLISH', error);
            })
            .finally(() => {
                this.saving = false;
            });
    }

    /**
     * Returns the form
     *
     * @return {any}
     */
    public getForm(): any {
        return this.scope[this.formName];
    }

    public isTrial(): boolean {
        return this.accessService.isProductTrial();
    }

    /**
     * Determine if user has an active subscription
     *
     */
    public subscriptionActive(): boolean {
        return SubscriptionPlanUtils.isActive(this.subscriptionStatus);
    }

    /**
     * opens help
     */
    public openHelp(): void {
        window.open(
            'https://intouchcheck.zendesk.com/hc/en-us/articles/360011368254-Configure-Audit-Outcomes',
            '_blank'
        );
    }

    public openHistoryModal(): void {
        this.dialog.show(ChecklistHistoryModal.instantiate({ locals: { checklistUuid: this.checklist.originalUuid } }));
    }

    public openChecklistsHelp(): void {
        window.open('https://intouchcheck.zendesk.com/hc/en-us/categories/360001050733-Manage-Checklists', '_blank');
    }

    public toggleSideNav(): void {
        this.sideNavExpanded = !this.sideNavExpanded;
    }

    public closeSideNav(): void {
        this.sideNavExpanded = false;
    }

    public showSideNav(): boolean {
        return this.media(this.mobileBreak) || this.sideNavExpanded;
    }

    public showBackdrop(): boolean {
        return this.showSideNav() && !this.media(this.mobileBreak);
    }

    public closeSideMenu(): void {
        this.mobileOpen = false;
    }

    public goToChecklistBuilder(): void {
        this.state.go('home.checklists.edit', { uuid: this.checklistService.getChecklist().uuid });
    }

    public changeChecklistSettings(): void {
        this.state.go('home.checklists.settings.general', { uuid: this.checklistService.getChecklist().uuid });
    }

    public getOutcomeStyle(outcome: IOutcome): any {
        return {
            'background-color': outcome.color,
        };
    }

    public getOutcomeLabel(outcome: IOutcome): string {
        let translation: IOutcomeTranslation = outcome.getTranslation(this.locale, true);
        return !!translation ? translation.label : outcome.label;
    }

    /**
     * Add available and enabled custom actions
     *
     */
    private addOutcomeCustomActions(): void {
        if (this.accessService.getToken().getUser().hasAcl('check_admin')) {
            this.customActions.push({
                icon: 'add',
                label: 'CHECKLISTS.CHECKLIST_OUTCOMES.CREATE_NEW_OUTCOME_SET',
                onClick: () => {
                    this.dialog.show(CreateOutcomeSetModal.instantiate()).then((outcome: OutcomeSet) => {
                        this.outcomeToDisplay = null;
                        this.selectOutcomeSet(outcome);
                    });
                },
            });
        }
    }

    private setSubscriptionInfo(): void {
        this.subscriptionStatus = this.accessService.getToken().getProductBySlug('check').subStatus;
        this.subscriptionPlan = this.accessService.getToken().getProductBySlug('check').subPlan;
    }

    private setChecklist(): void {
        let checklist: IChecklist = this.checklistService.getChecklist();

        if (!checklist || checklist.uuid !== this.state.params['uuid']) {
            this.load().then(() => {
                checklist = this.checklistService.getChecklist();
                if (checklist.hasOutcomeAssigned()) {
                    this.loadOutcomeByUuidOrId(checklist.outcomeSetId);
                } else {
                    this.showDefaultOutcome = true;
                }
            });
        } else {
            this.checklist = checklist.clone();
            if (checklist.hasOutcomeAssigned()) {
                this.loadOutcomeByUuidOrId(checklist.outcomeSetId);
            } else {
                this.showDefaultOutcome = true;
            }
        }
    }

    private setLocale(): void {
        try {
            this.locale = this.accessService.getUser().language;
        } catch (e) {
            this.locale = this.translate.use();
        }
    }
}
