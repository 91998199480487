import {
    IBaseCalculation,
    BaseCalculation,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseCalculation';

/**
 * The ICalculation interface
 *
 */
export interface ICalculation extends IBaseCalculation {}

/**
 * The Calculation class
 *
 */
export class Calculation extends BaseCalculation implements ICalculation {
    /**
     * Makes a generic version of this class
     *
     * @param type
     * @param value
     * @returns {ICalculation}
     */
    public static make(type: string, value: string | number): ICalculation {
        if (['operator', 'operand', 'constant'].indexOf(type) === -1) {
            throw new Error('Invalid type provided when creating a calculation');
        }

        let me: ICalculation = new Calculation();
        me.type = type;
        me.value = value;

        return me;
    }
}
