import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IItemInput, ItemInput } from './ItemInput';
import {
    IItemInputSettings,
    ItemInputSettings,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';

/**
 * The IItemTextSettings interface
 *
 */
export interface IItemTextSettings extends IItemInputSettings {
    multiline: boolean;
}

/**
 * The ItemTextSettings class
 *
 */
export class ItemTextSettings extends ItemInputSettings implements IItemTextSettings {
    public multiline: boolean = false;
}

/**
 * The IItemText interface
 *
 */
export interface IItemText extends IItemInput {
    settings: IItemTextSettings;
}

/**
 * The ItemText class
 *
 */
export class ItemText extends ItemInput implements IItemText {
    public settings: IItemTextSettings = new ItemTextSettings();
    public type: string = 'text';

    /**
     * Make a generic instance of this object
     *
     * @param name
     * @returns {IItemText}
     */
    public static make(name: string): IItemText {
        let me: IItemText = new ItemText();
        me.name = name;
        me.settings.allowCnaNa = true;

        return me;
    }

    /**
     * Builds the object from json
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {ItemText}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemText {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['settings']) {
            this.settings = EntityBuilder.buildOne<IItemTextSettings>(
                ItemTextSettings,
                jsonObject['settings'],
                convertToCamel
            );
        }

        return this;
    }
}
