import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IItemInput, ItemInput } from './ItemInput';
import {
    IItemInputSettings,
    ItemInputSettings,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import { IWithAdditionalRequirements } from './WithAdditionalRequirements';
import { IItemAdditionalRequirements, ItemAdditionalRequirements } from './ItemAdditionalRequirements';

/**
 * The IItemTemperatureSettings interface
 *
 */
export interface IItemTemperatureSettings extends IItemInputSettings {
    min: number;
    max: number;
    roundTo: number;
    displayRange: boolean;
    daysToResolveProblems: number;
}

/**
 * The ItemTemperatureSettings class
 *
 */
export class ItemTemperatureSettings extends ItemInputSettings implements IItemTemperatureSettings {
    public min: number = null;
    public max: number = null;
    public roundTo: number = 2;
    public displayRange: boolean = false;
    public daysToResolveProblems: number = null;

    /**
     * Return a floating point number representing the "step" attribute for the numerical range field.
     *
     * @returns {number}
     */
    public getRangeAccuracy(): number {
        if (this.roundTo === null || this.roundTo === undefined) {
            return 1;
        }

        return 1 / Math.pow(10, this.roundTo);
    }
}

/**
 * The IItemTemperature interface
 *
 */
export interface IItemTemperature extends IItemInput, IWithAdditionalRequirements {
    settings: IItemTemperatureSettings;

    isInRange(num: number): boolean;

    hasRangeMin(): boolean;

    hasRangeMax(): boolean;

    convertTemperature(temperature: number, unit: string): number;
}

/**
 * The ItemTemperature class
 *
 */
export class ItemTemperature extends ItemInput implements IItemTemperature {
    public type: string = 'temperature';
    public settings: IItemTemperatureSettings = new ItemTemperatureSettings();
    public points: number;
    public additionalRequirements: IItemAdditionalRequirements = null;

    /**
     * Make a generic instance of this object
     *
     * @param name
     * @returns {IItemInput}
     */
    public static make(name: string): IItemInput {
        let me: IItemTemperature = new ItemTemperature();
        me.name = name;
        me.name = name;

        return me;
    }

    /**
     * Returns if object is allowed as a logic subject
     *
     */
    public allowAsLogicSubject(): boolean {
        return true;
    }

    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemTemperature {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['settings']) {
            this.settings = EntityBuilder.buildOne<IItemTemperatureSettings>(
                ItemTemperatureSettings,
                jsonObject['settings'],
                convertToCamel
            );
        }

        if (jsonObject['additional_requirements']) {
            this.additionalRequirements = EntityBuilder.buildOne<IItemAdditionalRequirements>(
                ItemAdditionalRequirements,
                jsonObject['additional_requirements'],
                convertToCamel
            );
        } else if (jsonObject['photo_required']) {
            this.additionalRequirements = new ItemAdditionalRequirements().fromJson(
                {
                    type: 'photo',
                    condition: 'always',
                },
                true
            );
            delete (<any>this).photoRequired;
        }

        return this;
    }

    /**
     * Check if a given number is within range (if we have a range)
     *
     * @param num
     * @returns {boolean}
     */
    public isInRange(num: number): boolean {
        if (this.settings.min !== null && this.settings.min !== null) {
            return num >= this.settings.min && num <= this.settings.max;
        } else {
            return true;
        }
    }

    /**
     * Returns true if a minimum range is set
     *
     * @return {boolean}
     */
    public hasRangeMin(): boolean {
        return NumberUtils.isNumber(this.settings.min);
    }

    /**
     * Returns true if a maximum range is set
     *
     * @return {boolean}
     */
    public hasRangeMax(): boolean {
        return NumberUtils.isNumber(this.settings.max);
    }

    /**
     * Convert a temperate value to the specified unit.
     * @param temperature The temperate value to convert
     * @param unit The temperature unit (C or F) to convert to
     * @returns A temperature value in the desired unit
     */
    public convertTemperature(temperature: number, unit: string): number {
        if (temperature == null) {
            return;
        }

        // round the limits to the desired decimal points
        if (unit === 'F') {
            return parseFloat((temperature * (9 / 5) + 32).toFixed(this.settings.roundTo));
        } else {
            return parseFloat(((temperature - 32) * (5 / 9)).toFixed(this.settings.roundTo));
        }
    }
}
