import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IGeoFull, Geo } from '@intouch/its.essential/app/essential/utils/Geo';
import { IHierarchyTierListingItem } from '@intouch/its.essential/app/essential/domain/access/HierarchyTierListingItem';
import { IHierarchyNode, HierarchyNode } from '@intouch/its.essential/app/essential/domain/access/HierarchyNode';
import { Location } from '@intouch/its.essential/app/essential/domain/access/Location';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import * as _ from 'lodash';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IQueryFilter, QueryFilter } from '@intouch/its.essential/app/essential/domain/api/QueryFilter';
import { String } from '@intouch/its.essential/app/essential/utils/String';
import View from './AddLocationModal.html';

interface ISaveableHierarchyNode extends IHierarchyNode {
    parentId: number;
}

/**
 * Class that extends HierarchyNode with properties required by the save api endpoint
 *
 */
class SaveableHierarchyNode extends HierarchyNode implements ISaveableHierarchyNode {
    public parentId: number = null;
}

export class AddLocationModal extends Modal {
    static $inject: Array<string> = [
        '$mdDialog',
        'iteToaster',
        'iteAccessApi',
        'iteAccessService',
        '$translate',
        'iteToaster',
    ];

    public loading: boolean = false;
    public error: boolean = false;
    public saving: boolean = false;
    public node: ISaveableHierarchyNode = null;
    public nodes: Array<IHierarchyTierListingItem> = [];
    public parent: IHierarchyTierListingItem = null;
    public initialNode: string = null;

    public countryList: Array<IGeoFull> = [];
    public stateList: Array<IGeoFull> = [];
    public provinceList: Array<IGeoFull> = [];

    private translations: any = {};

    public static instantiate(config: any): any {
        config = config || { acl: null };
        config.template = View;
        config.controller = AddLocationModal;
        config.controllerAs = 'vm';
        config.escapeToClose = false;
        return super.instantiate(config);
    }

    /**
     * Instantiate the class
     *
     * @param dialog
     * @param toaster
     * @param accessApi
     * @param accessService
     * @param translate
     */
    public constructor(
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private accessApi: IAccessApi,
        private accessService: IAccessService,
        private translate: ng.translate.ITranslateService
    ) {
        super();
        this.initialNode = this.accessService.getToken().getUser().nodes[0];
        this.translate([
            'CHECKLISTS.LOCATION_INFO.POSTAL_CODE',
            'CHECKLISTS.LOCATION_INFO.ZIP_CODE',
            'CHECKLISTS.LOCATION_INFO.ZIP_POSTAL_CODE',
        ]).then((translations) => {
            this.translations = translations;
        });

        this.countryList = Geo.countriesFull();
        this.stateList = Geo.statesFull();
        this.provinceList = Geo.provincesFull();
        this.initializeNode();
        this.load();
    }

    /**
     * Returns the label for zip/postal based on country
     *
     * @param country
     * @returns {any}
     */
    public getZipPostalText(country: string): string {
        switch (country) {
            case 'CA':
                return this.translate.instant('CHECKLISTS.LOCATION_INFO.POSTAL_CODE');
            case 'US':
                return this.translate.instant('CHECKLISTS.LOCATION_INFO.ZIP_CODE');
            default:
                return this.translate.instant('CHECKLISTS.LOCATION_INFO.ZIP_POSTAL_CODE');
        }
    }

    /**
     * Get province/state list based on country
     *
     * @param country
     * @returns {any}
     */
    public getRegionByCountryCode(country: string): Array<IGeoFull> {
        switch (country) {
            case 'CA':
                return this.provinceList;
            case 'US':
                return this.stateList;
            default:
                return [];
        }
    }

    public onParentSelect(node: IHierarchyTierListingItem): ng.IPromise<ISaveableHierarchyNode> {
        return this.accessApi.fetchHierarchyByUuid(node.uuid).then((result: IHierarchyNode) => {
            this.node.parentId = result.parentId;
            this.node.parentUuid = result.uuid;
            return this.node;
        });
    }

    /**
     * Load required modal data from api
     *
     */
    public load(): void {
        this.loading = true;
        this.error = false;
        this.nodes = [];
        let filter: IQueryFilter = new QueryFilter();
        filter.addParam('filter[type]', 'generic');
        filter.addParam('all', true);

        this.accessApi
            .fetchHierarchyNodes(null, null, filter.getParams())
            .then((results: PagedEntities) => {
                if (results) {
                    this.nodes = results.getEntities();
                    this.parent = _.find(this.nodes, { uuid: this.accessService.getToken().getUser().nodes[0] });
                    this.onParentSelect(this.parent)
                        .catch((error) => {
                            this.error = true;
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            })
            .catch((error) => {
                this.error = true;
            });
    }

    public save(): void {
        this.saving = true;
        this.node.parentUuid = this.parent.uuid;
        this.node.parentId = this.parent.id;
        this.accessApi
            .saveNode(this.node)
            .then(() => {
                this.close();
            })
            .catch((error) => {
                this.toaster.warn('CHECKLISTS.FAILED_LOCATION_SAVE');
            });
    }

    /**
     * Cancel and close modal
     */
    public close(): void {
        this.dialog.hide();
    }

    private initializeNode(): void {
        this.node = new HierarchyNode();
        this.node.location = new Location();
        this.node.type = 'location';
        this.node.slug = String.slug();
    }
}
