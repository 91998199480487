import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IUuidNamePair } from '@intouch/its.check.essential/app/check-essential/domain/datatypes/UuidNamePair';
import { IChecklist } from '../../../domain/checklists/Checklist';
import { IItemCheck } from '../../../domain/checklists/ItemCheck';
import { IItemInput } from '../../../domain/checklists/ItemInput';
import { ICheckApi } from '../../../api/CheckApi';
import { IPageService } from '../../../services/PageService';
import { ICheckSession } from '../../../services/CheckSession';
import * as _ from 'lodash';
import { BaseChecklist } from '../BaseChecklist';
import { ChecklistService } from '../../../services/ChecklistService';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ChecklistEditService } from '../../../services/ChecklistEditService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';

/**
 * A class to show the Randomization settings modal
 */
@Controller(
    'its.check.module.checklists',
    ChecklistRandomizationSettingsController.IID,
    ChecklistRandomizationSettingsController
)
class ChecklistRandomizationSettingsController extends BaseChecklist {
    static IID: string = 'ChecklistRandomizationSettingsController';
    static $inject: Array<string> = [
        '$state',
        'itcCheckApi',
        'iteToaster',
        '$scope',
        'itcPageService',
        '$translate',
        'itcCheckSession',
        'itcChecklistService',
        'iteAccessService',
        '$window',
        'itcChecklistEditService',
    ];

    public saving: boolean = false;
    public checklist: IChecklist;
    public validChecklistQuestions: Array<IItemCheck>;
    public formName: string = 'randomizationSettingsForm';
    public subscriptionStatus: string = '';
    private checklistSubscription: SubjectSubscription;

    /**
     * Constructor
     *
     * @param state
     * @param checkApi
     * @param toaster
     * @param scope
     * @param pageService
     * @param translate
     * @param session
     * @param checklistService
     * @param accessService
     * @param windowService
     * @param checklistEditService
     */
    constructor(
        protected state: angular.ui.IStateService,
        protected checkApi: ICheckApi,
        protected toaster: IToaster,
        protected scope: ng.IScope,
        protected pageService: IPageService,
        protected translate: ng.translate.ITranslateService,
        protected session: ICheckSession,
        protected checklistService: ChecklistService,
        private accessService: IAccessService,
        private windowService: ng.IWindowService,
        protected checklistEditService: ChecklistEditService
    ) {
        super(state, checkApi, toaster, pageService, translate, session, checklistService, checklistEditService);

        this.subscriptionStatus = this.accessService.getToken().getProductBySlug('check').subStatus;

        let checklist: IChecklist = this.checklistService.getChecklist();
        this.initSubscription();

        if (!checklist || checklist.uuid !== this.state.params['uuid']) {
            this.load().then(() => {
                this.setValidRandomQuestions();

                // initialize an empty question if there are no random questions.
                if (this.checklist.settings.randomization.questions.length === 0) {
                    this.addEmptyRandomQuestion();
                }
            });
        } else {
            this.checklist = checklist.clone();

            this.setValidRandomQuestions();

            // initialize an empty question if there are no random questions.
            if (this.checklist.settings.randomization.questions.length === 0) {
                this.addEmptyRandomQuestion();
            }
        }
    }

    /**
     * Save checklist General Settings
     */
    public saveChecklist(): void {
        this.saving = true;

        if (!this.checklist.settings.randomization.enabled) {
            this.checklist.settings.resetRandomizationSettings();
        } else {
            this.pruneUnselectedQuestions();
        }

        this.save(
            this.checklist,
            'GENERAL.MESSAGES.SAVED_CHANGES',
            'CHECKLISTS.ERRORS.UNABLE_TO_SAVE_RANDOMIZATION_SETTINGS',
            'home.checklists.settings.randomization'
        ).finally(() => {
            this.saving = false;
        });
    }

    /**
     * Returns whether the randomization settings are valid.
     */
    public isValid(): boolean {
        if (this.checklist) {
            let selectedQuestions: Array<IUuidNamePair> = this.getSelectedRandomQuestions();

            // if we don't have at least two random questions selected.
            if (selectedQuestions.length < 2) {
                return false;
            }

            // number of questions to show must be less than than number of questions selected, or else they would all show, defeating the purpose.
            if (selectedQuestions.length <= this.checklist.settings.randomization.numberOfQuestions) {
                return false;
            }

            return true;
        }

        return false;
    }

    /**
     * Sets the questions from the checklist that are valid for randomization.
     */
    public setValidRandomQuestions(): void {
        this.validChecklistQuestions = <Array<IItemInput>>this.checklist.getInputItems(
            null,
            (i: IItemInput) => {
                return i.type !== 'calculation';
            },
            true
        );
    }

    /**
     *  Adds a new empty random question to be filled out by the user.
     */
    public addEmptyRandomQuestion(): void {
        this.checklist.settings.randomization.questions.push(<IUuidNamePair>{ name: null, uuid: null });
    }

    /**
     * Sets a random question when a user makes a selection.
     */
    public setRandomQuestion(randomQuestion: IUuidNamePair, checklistQuestion: IItemCheck): void {
        randomQuestion.uuid = checklistQuestion.uuid;
        randomQuestion.name = checklistQuestion.name;
    }

    /**
     * Determine whether it's valid for the user to add another random question.
     */
    public okToAddRandomQuestion(): boolean {
        return this.checklist.settings.randomization.questions.length >= this.validChecklistQuestions.length;
    }

    /**
     * Deletes a random question.
     */
    public deleteRandomQuestion(index: number): void {
        this.checklist.settings.randomization.questions.splice(index, 1);
    }

    /**
     * Gets all of the questions that are valid options for a user to select from.
     */
    public getValidRandomQuestionOptions(currentQuestion?: IUuidNamePair): Array<IItemCheck> {
        let questions: Array<IItemCheck> = [];
        for (let question of this.validChecklistQuestions) {
            // if it's the current selected question from the dropdown, or it's not already in the random question array, it's a valid option.
            if (
                (currentQuestion && currentQuestion.uuid === question.uuid) ||
                !_.find(this.checklist.settings.randomization.questions, { uuid: question.uuid })
            ) {
                questions.push(question);
            }
        }

        return questions;
    }

    /**
     * Return all random questions that have been filled out by the user.
     */
    public getSelectedRandomQuestions(): Array<IUuidNamePair> {
        return <Array<IUuidNamePair>>_.filter(this.checklist.settings.randomization.questions, (question) => {
            return (<IUuidNamePair>question).uuid !== null;
        });
    }

    /**
     * Removes any questions from the random question array that were added, but not selected by the user.
     */
    public pruneUnselectedQuestions(): void {
        this.checklist.settings.randomization.questions = _.reject(this.checklist.settings.randomization.questions, {
            uuid: null,
        });
    }

    /**
     * Returns the form
     *
     * @return {any}
     */
    public getForm(): any {
        return this.scope[this.formName];
    }

    /**
     * opens help
     */
    public openHelp(): void {
        this.windowService.open(
            'https://intouchcheck.zendesk.com/hc/en-us/articles/360011453353-Question-Randomization-Settings',
            '_blank'
        );
    }

    private initSubscription(): void {
        this.checklistSubscription = this.checklistService.checklistObserver.subscribe((checklist) => {
            this.checklist = checklist;
        });
        this.scope.$on('$destroy', () => {
            if (this.checklistSubscription) {
                this.checklistSubscription.unsubscribe();
            }
        });
    }
}
