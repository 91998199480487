import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IItemSection } from '../../../../domain/checklists/ItemSection';

/**
 * The UI for rendering a way to add and remove calculation translations
 */
@Component('its.check.module.checklists', SectionTranslation.CID, {
    template: require('/app/modules/checklists/components/translations/SectionTranslation.html'),
    controllerAs: 'vm',
    bindings: {
        section: '=',
        locale: '<',
    },
})
class SectionTranslation {
    static IID: string = 'SectionTranslation';
    static CID: string = 'itsSectionTranslation';
    static $inject: Array<string> = ['$translate'];

    public sectionLabel: string;

    public locale: string;
    public section: IItemSection;

    /**
     * The class constructor
     *
     * @param translate
     */
    constructor(private translate: angular.translate.ITranslateService) {
        this.translate(['TRANSLATIONS.SECTION.LABEL']).then((translations) => {
            this.sectionLabel = translations['TRANSLATIONS.SECTION.LABEL'];
        });
    }
}
