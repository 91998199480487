import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ItemInput } from '../../../../../../domain/checklists/ItemInput';
import { ItemAdditionalRequirements } from '../../../../../../domain/checklists/ItemAdditionalRequirements';

@Component('its.check.module.checklists', RequireAdditionalInformationSetting.CID, {
    template:
        require('/app/modules/checklists/components/items/shared/require-additional-information-setting/RequireAdditionalInformationSetting.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class RequireAdditionalInformationSetting {
    static IID: string = 'RequireAdditionalInformationSetting';
    static CID: string = 'itcRequireAdditionalInformationSetting';
    static $inject: string[] = [];

    public item: ItemInput;
    public hasRequirements: boolean = false;

    $onInit(): void {
        this.setToggleState();
    }

    public setToggleState(): void {
        if (
            this.item.additionalRequirements &&
            (this.item.additionalRequirements.type || this.item.additionalRequirements.condition)
        ) {
            this.hasRequirements = true;
        } else {
            this.hasRequirements = false;
        }
    }

    public toggleAdditionalRequirements(hasRequirements: boolean): void {
        if (hasRequirements) {
            this.item.additionalRequirements = new ItemAdditionalRequirements();
            this.item.additionalRequirements.type = 'photo';
            this.item.additionalRequirements.condition = 'always';
        } else {
            this.item.additionalRequirements = null;
        }
    }
}
