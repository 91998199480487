import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { ICheckSession } from './CheckSession';
import { BaseTour } from '../domain/tours/BaseTour';
import shepherd from 'shepherd.js';

@Service('its.check.services', ShepherdService.IID, ShepherdService)
export class ShepherdService {
    static IID: string = 'itcShepherdService';
    static $inject: Array<string> = ['$translate', 'itcCheckSession'];

    // all tours are stored under check_tours[my_tour_name]
    private toursLocalStorageKey: string = 'check_tours';

    public constructor(private translate: ng.translate.ITranslateService, private checkSession: ICheckSession) {}

    public createTour(tourToCreate: BaseTour, scope: ng.IScope): any {
        const tour: any = new shepherd.Tour(tourToCreate);
        this.registerTourCloseOnScopeDestroy(scope, tour);
        tour.on('complete', () => {
            this.updateTourLocalStorageAsShown(tourToCreate);
        });
        return tour;
    }

    public hasSeenTour(tourId: string): boolean {
        const tours: any = this.getAllTourSettings();
        return tours.hasOwnProperty(tourId) && tours[tourId];
    }

    private getAllTourSettings(): any {
        return this.checkSession.getValue(this.toursLocalStorageKey) || {};
    }

    private updateTourLocalStorageAsShown(tourToCreate: BaseTour): void {
        let allTourSettings: any = this.checkSession.getValue(this.toursLocalStorageKey) || {};
        const tourLocalStorageId: string = tourToCreate.getTourLocalStorageId();
        allTourSettings[tourLocalStorageId] = true;
        this.checkSession.setValue(this.toursLocalStorageKey, allTourSettings);
    }

    private registerTourCloseOnScopeDestroy(scope: angular.IScope, tour: any): void {
        scope.$on('$destroy', () => {
            if (tour) {
                tour.complete();
            }
        });
    }
}
