import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { OutcomeSet } from '@intouch/its.check.essential/app/check-essential/domain/checklists/outcomes/OutcomeSet';
import { OutcomeRange } from '@intouch/its.check.essential/app/check-essential/domain/checklists/outcomes/OutcomeRange';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';
import { ColorPickerPanel } from '@intouch/its.essential/app/essential/panels/color-picker/ColorPickerPanel';

/**
 * The UI for rendering skip conditions on a checklist item
 */
@Component('its.check.components', ScoringRange.CID, {
    template: require('/app/components/ScoringRange.html'),
    controllerAs: 'vm',
    bindings: {
        outcome: '=',
        form: '<',
        isLoading: '<',
        isDefault: '<',
        emptyMessage: '@?',
        enableEditing: '<',
    },
})
class ScoringRange {
    static IID: string = 'ScoringRange';
    static CID: string = 'itcScoringRange';

    static $inject: Array<string> = ['$translate', 'itePanelService'];

    public outcome: OutcomeSet;
    public form: any;
    public emptyMessage: string;
    public enableEditing: boolean;
    public isLoading: boolean;
    public isDefault: boolean;
    public outcomeHasEmptyRange: boolean = false;
    public outcomeHasInvalidRanges: boolean = false;

    public constructor(private translate: ng.translate.ITranslateService, private panelService: IPanelService) {
        // translate is used in the view - do not remove
        if (this.outcome) {
            this.outcome.sortOutcomeRangesByFrom();
        }

        if (!this.emptyMessage) {
            this.emptyMessage = 'CHECKLISTS.NO_OUTCOMES_MESSAGE';
        }
    }

    public $doCheck(): void {
        this.validateOutcomeRangeColors();
    }

    /**
     * Validates form and returns false if invalid, true if valid
     *
     * @return {boolean}
     */
    public validateRanges(): void {
        if (this.outcome.hasValidRanges()) {
            this.setAllOutcomeRangeValidity(true);
            this.outcomeHasEmptyRange = false;
            this.outcomeHasInvalidRanges = false;
            this.form['incompleteScoringRange'].$setValidity('incompleteScoringRange', true);
        } else {
            let invalidRangeIndexes: number[] = this.outcome.getInvalidRangeIndexes();
            this.setAllOutcomeRangeValidity(true);
            for (let invalidIndex of invalidRangeIndexes) {
                this.setOutcomeRangeValidity(invalidIndex, false);
            }
            this.outcomeHasInvalidRanges = true;
            this.form['incompleteScoringRange'].$setValidity('incompleteScoringRange', false);
            this.outcomeHasEmptyRange = this.outcome.hasEmptyRange();
        }
    }

    public openColorPicker(event: any, outcomeRange: any): void {
        this.panelService.openColorPickerPanel(
            ColorPickerPanel.instantiate({
                relativeTo: event.target,
                onSelect: (color: string) => {
                    outcomeRange.color = color;
                },
            })
        );
    }

    public setOutcomeRangeValidity(index: number, isValid: boolean): void {
        if (!this.form) {
            return;
        }
        this.form['rangeTo' + index].$setValidity('invalidScoringRange', isValid);
        this.form['rangeFrom' + index].$setValidity('invalidScoringRange', isValid);
    }

    public removeOutcomeRange(outcomeRange: OutcomeRange): void {
        this.form.$dirty = true;
        this.outcome.removeOutcomeRange(outcomeRange);
        this.validateRanges();
    }

    public setAllOutcomeRangeValidity(isValid: boolean): void {
        for (let i: number = 0; i < this.outcome.outcomeRanges.length; i++) {
            this.setOutcomeRangeValidity(i, isValid);
        }
    }

    private validateOutcomeRangeColors(): void {
        if (!this.outcome || !this.outcome.outcomeRanges || this.outcome.outcomeRanges.length === 0) {
            return;
        }
        for (let outcomeRange of this.outcome.outcomeRanges) {
            if (outcomeRange.color && outcomeRange.color.indexOf('rgb') === 0) {
                outcomeRange.color = this.rgbToHex(outcomeRange.color);
            }
        }
    }

    private rgbToHex(rgb: string): string {
        let rgbArray: Array<string> = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return rgbArray && rgbArray.length === 4
            ? '#' +
                  ('0' + parseInt(rgbArray[1], 10).toString(16)).slice(-2) +
                  ('0' + parseInt(rgbArray[2], 10).toString(16)).slice(-2) +
                  ('0' + parseInt(rgbArray[3], 10).toString(16)).slice(-2)
            : '';
    }
}
