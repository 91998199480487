import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemTranslation } from './BaseItemTranslation';

/**
 * The UI for rendering a way to add and remove simple text translations
 */
@Component('its.check.module.checklists', TimeTranslation.CID, {
    template: require('/app/modules/checklists/components/translations/BaseItemTranslation.html'),

    controllerAs: 'vm',
    bindings: {
        item: '=',
        locale: '<',
    },
})
class TimeTranslation extends BaseItemTranslation {
    static IID: string = 'TimeTranslation';
    static CID: string = 'itsTimeTranslation';
    static $inject: Array<string> = ['$translate'];

    /**
     * The class constructor
     *
     * @param translate
     */
    constructor(public translate: angular.translate.ITranslateService) {
        super(translate);

        this.translate(['TRANSLATIONS.TIME.LABEL']).then((translations) => {
            this.itemLabel = translations['TRANSLATIONS.TIME.LABEL'];
        });
    }
}
