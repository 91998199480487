import './item-tabs/ItemTabs';

import './shared/label-setting/LabelSetting';
import './shared/hide-from-reporting-setting/HideFromReportingSetting';
import './shared/optional-setting/OptionalSetting';
import './shared/multiline-setting/MultilineSetting';
import './shared/photo-required-setting/PhotoRequiredSetting';
import './shared/allow-cna-na-setting/AllowCnaNaSetting';
import './shared/score-setting/ScoreSetting';
import './shared/critical-setting/CriticalSetting';
import './shared/require-followup-setting/RequireFollowupSetting';
import './shared/require-additional-information-setting/RequireAdditionalInformationSetting';
import './shared/collapse-arrow/CollapseArrow';
import './shared/toggle-header/ToggleHeader';
import './shared/item-responses-setting/ItemResponsesSetting';
import './shared/selection-requirements-setting/SelectionRequirementsSetting';
import './shared/tag-setting/TagSetting';
import './shared/answer-display-type-setting/AnswerDisplayTypeSetting';

import './ChecklistItemSettings';
import './text/TextSettings';
import './simple-check/SimpleCheckSettings';
import './multiple-choice/MultipleChoiceSettings';
import './checkbox/CheckboxSettings';
import './number/NumberSettings';
import './date-picker/DatePickerSettings';
import './dropdown/DropdownSettings';
import './time/TimeSettings';
import './display-text/DisplayTextSettings';
import './graphic/GraphicSettings';
import './calculation/CalculationSettings';
import './temperature/TemperatureSettings';
