import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.check.module.audits', AuditProgressBar.CID, {
    template: require('/app/modules/audits/components/AuditProgressBar.html'),
    controllerAs: 'vm',
    bindings: {
        label: '@',
        value: '<', // number from 0 to 100
    },
})
class AuditProgressBar {
    static IID: string = 'AuditProgressBar';
    static CID: string = 'itcAuditProgressBar';
    static $inject: Array<string> = [];

    public label: string;
    public value: number;

    public onInit(): void {
        this.value = this.value || 0;
        if (this.value > 100) {
            this.value = 100;
        }
    }
}
