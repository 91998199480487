import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { ITemplateLibraryApi } from '@intouch/its.essential/app/essential/api/TemplateLibraryApi';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IQueryFilter, QueryFilter } from '@intouch/its.essential/app/essential/domain/api/QueryFilter';
import { Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import {
    ITemplateCategory,
    TemplateCategory,
} from '@intouch/its.essential/app/essential/domain/template-library/TemplateCategory';
import { DatatableSearch } from '@intouch/its.essential/app/essential/domain/datatable/DatatableSearch';
import { ITemplate } from '@intouch/its.essential/app/essential/domain/template-library/Template';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IChecklist } from '../../domain/checklists/Checklist';
import { ChecklistCreateModal } from './modals/ChecklistCreateModal';
import { PagerTool } from '@intouch/its.essential/app/essential/tools/PagerTool';
import { ChecklistCopyModal } from './modals/ChecklistCopyModal';
import DefaultPreviewImage from '../../../assets/images/default_preview_image.jpg';

@Controller('its.check.module.checklists', CreateController.IID, CreateController)
class CreateController {
    static IID: string = 'its.check.module.checklists.CreateController';
    static $inject: Array<string> = [
        '$state',
        'iteTemplateLibraryApi',
        '$mdMedia',
        '$translate',
        'iteAccessService',
        '$mdDialog',
    ];

    public loading: boolean = false;
    public hasError: boolean = false;
    public navExpanded: boolean = false;

    public categoryNavItems: Array<ITemplateCategory> = [];
    public templates: Array<ITemplate> = [];
    public pagerTool: PagerTool = null;
    public categoryFilter: string;
    public defaultPreviewImage: any = DefaultPreviewImage;

    public search: DatatableSearch = new DatatableSearch();

    constructor(
        private stateService: ng.ui.IStateService,
        private templateLibraryApi: ITemplateLibraryApi,
        private media: any,
        private translate: ng.translate.ITranslateService,
        private accessService: IAccessService,
        private dialog: ng.material.IDialogService
    ) {
        // if they don't have permissions they can't do anything on this screen, and shouldn't be here
        if (
            !this.accessService.getToken().getUser().hasAcl('check_admin') &&
            !this.accessService.getToken().getUser().hasAcl('checklist_creator')
        ) {
            this.stateService.go('home.checklists.list.all');
            return;
        }

        this.categoryFilter = this.stateService.params['category'] || null;
        this.search.term = this.stateService.params['search'] || null;

        let stateParams: any = null;
        if (this.categoryFilter) {
            stateParams = {
                category: this.categoryFilter,
            };
        }

        this.pagerTool = new PagerTool(this.stateService, {
            pagerDefaults: {
                page: 1,
                order: 'asc',
                sortBy: 'name',
            },
            stateParams: stateParams,
            onChange: () => {
                this.loadTemplates();
            },
        });

        this.loadCategories();
        this.loadTemplates();
    }

    public navIsExpanded(): boolean {
        return this.media('gt-sm') || this.navExpanded;
    }

    public isLinkActive(category: ITemplateCategory): boolean {
        return (
            (this.categoryFilter === null && category.uuid === 'all-templates') || this.categoryFilter === category.uuid
        );
    }

    public searchTemplates(): void {
        this.pagerTool.search(this.search.term);
    }

    public back(): void {
        this.stateService.go('home.checklists.list.all');
    }

    /**
     * @param {string} uuid
     */
    public filterByCategory(uuid: string): void {
        this.categoryFilter = uuid;
        this.pagerTool.stateParams = {
            category: this.categoryFilter,
        };

        this.pagerTool.goToSelf();
    }

    public openHelp(): void {
        window.open('https://intouchcheck.zendesk.com/hc/en-us/sections/360002367534-Create-a-Checklist', '_blank');
    }

    public createFromScratch(ev: MouseEvent): void {
        this.dialog
            .show(
                ChecklistCreateModal.instantiate({
                    targetEvent: ev,
                })
            )
            .then((checklist: IChecklist) => {
                this.stateService.go('home.checklists.edit', { uuid: checklist.uuid });
            });
    }

    public createFromTemplate(ev: MouseEvent, template: ITemplate): void {
        this.dialog
            .show(
                ChecklistCreateModal.instantiate({
                    targetEvent: ev,
                    locals: {
                        checklistTemplate: template,
                    },
                })
            )
            .then((checklist: IChecklist) => {
                this.stateService.go('home.checklists.edit', { uuid: checklist.uuid });
            });
    }

    /**
     * Attempt to truncate the descriptions here to a 'natural' length.
     * There is no nice CSS solution for this that we could find.
     *
     * @param {string} description
     * @returns {string}
     */
    public getTemplateRowDescription(description: string): string {
        if (!description) {
            return '';
        }

        if (this.media('gt-md') && description.length > 200) {
            return description.substring(0, 200) + '...';
        }

        if (this.media('gt-xs') && description.length > 150) {
            return description.substring(0, 150) + '...';
        }

        if (description.length > 100) {
            return description.substring(0, 100) + '...';
        }

        return description;
    }

    /**
     * @param {string} description
     * @returns {string}
     */
    public getTemplateCardDescription(description: string): string {
        if (!description) {
            return '';
        }

        if (description.length > 200) {
            return description.substring(0, 200) + '...';
        }

        return description;
    }

    public copyExisting(ev: MouseEvent): void {
        this.dialog
            .show(
                ChecklistCopyModal.instantiate({
                    targetEvent: ev,
                })
            )
            .then((checklist: IChecklist) => {
                this.stateService.go('home.checklists.edit', { uuid: checklist.uuid });
            });
    }

    /**
     * @returns {void}
     */
    private loadTemplates(): void {
        this.loading = true;

        let qf: IQueryFilter = new QueryFilter();
        qf.addPager(this.pagerTool.pager);
        qf.addParam('filter[product]', 'check');

        if (this.categoryFilter) {
            qf.addParam('filter[category_uuids]', [this.categoryFilter]);
        }

        if (this.search.term) {
            qf.addParam('search', this.search.term);
        }

        this.templateLibraryApi
            .getTemplateListing(qf)
            .then((results: PagedEntities) => {
                this.templates = results.getEntities();
                this.pagerTool.setPager(results.getPager());
            })
            .catch((error) => {
                this.hasError = true;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    /**
     * @returns {void}
     */
    private loadCategories(): void {
        let qf: IQueryFilter = new QueryFilter();
        qf.addPager(Pager.make(1, 'name', 'asc'));
        qf.addParam('filter[product]', 'check');

        this.templateLibraryApi
            .getCategoryListing(qf)
            .then((results: PagedEntities) => {
                this.buildTemplateCategoryNavItems(results.getEntities());
            })
            .catch((error) => {
                this.hasError = true;
            });
    }

    /**
     * @returns {void}
     */
    private buildTemplateCategoryNavItems(categories: Array<ITemplateCategory>): void {
        let allTemplates: ITemplateCategory = new TemplateCategory();

        allTemplates.uuid = null;
        allTemplates.name = this.translate.instant('CHECKLISTS.TEMPLATES.ALL_TEMPLATES');

        categories.unshift(allTemplates);

        this.categoryNavItems = categories;
    }
}
