import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

/**
 * The UI for rendering a way to add and remove checklist translations
 */
@Component('its.check.module.checklists', ChecklistTranslations.CID, {
    template: require('/app/modules/checklists/components/translations/ChecklistTranslations.html'),
    controllerAs: 'vm',
    bindings: {
        checklist: '=',
        locale: '<',
    },
})
class ChecklistTranslations {
    static IID: string = 'ChecklistTranslations';
    static CID: string = 'itsChecklistTranslations';
}
