import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ITooltipPanelLocals } from '@intouch/its.essential/app/essential/domain/tooltip-panel/TooltipPanelLocals';
import { TooltipPanel } from '@intouch/its.essential/app/essential/panels/tooltip-panel/TooltipPanel';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';
import { FeatureLockUtils, IFeatureLockCondition } from '@intouch/its.essential/app/essential/utils/FeatureLockUtils';

import * as _ from 'lodash';

export interface IQuestionTooltipConfig {
    contentText: Array<string>;
    imageUrl?: {
        en: string;
        fr_CA: string;
    };
    btnText?: string;
    btnClass?: string;
    btnOnClick?: () => void;
}

export interface IQuestionConfigItem {
    [questionType: string]: IQuestionTooltipConfig;
}

/**
 * The group service class
 */
@Service('its.check.services', QuestionTooltipService.IID, QuestionTooltipService)
export class QuestionTooltipService {
    static IID: string = 'itcQuestionTooltipService';
    static $inject: Array<string> = [
        'iteAccessService',
        '$translate',
        '$timeout',
        'itePanelService',
        'iteSiteNavigationService',
    ];

    public mdPanelRef: any;
    private openTimer: any;
    private noTooltipRequiredList: Array<string> = [];

    private questionTooltipConfigs: IQuestionConfigItem = {
        check: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.CHECK.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/simple_check_en.png',
                fr_CA: 'assets/images/question-tooltips/simple_check_fr.png',
            },
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
        text: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.TEXT_INPUT.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/text_input_en.png',
                fr_CA: 'assets/images/question-tooltips/text_input_fr.png',
            },
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
        radio: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.RADIO_2.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/multiple_choice_en.png',
                fr_CA: 'assets/images/question-tooltips/multiple_choice_fr.png',
            },
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
        checkbox: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.CHECKBOX_2.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/checkbox_en.png',
                fr_CA: 'assets/images/question-tooltips/checkbox_fr.png',
            },
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
        number: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.NUMBER.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/number_en.png',
                fr_CA: 'assets/images/question-tooltips/number_fr.png',
            },
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
        date: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.DATE.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/date_picker_en.png',
                fr_CA: 'assets/images/question-tooltips/date_picker_fr.png',
            },
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
        calculation: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.CALCULATION.PARAGRAPH_1'],
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
        dropdown: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.DROPDOWN.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/dropdown_en.png',
                fr_CA: 'assets/images/question-tooltips/dropdown_fr.png',
            },
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
        time: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.TIME.PARAGRAPH_1'],
            imageUrl: {
                en: 'assets/images/question-tooltips/time_en.png',
                fr_CA: 'assets/images/question-tooltips/time_fr.png',
            },
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
        display_text: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.DISPLAY_TEXT.PARAGRAPH_1'],
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
        graphic: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.GRAPHIC.PARAGRAPH_1'],
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
        temperature: {
            contentText: ['CHECKLISTS.TOOLTIP_TEXT.TEMPERATURE.PATAGRAPH_1'],
            btnText: 'CHECKLISTS.ADD_TO_CHECKLIST',
        },
    };

    public constructor(
        private accessService: IAccessService,
        private translate: ng.translate.ITranslateService,
        private timeout: ng.ITimeoutService,
        private panelService: IPanelService
    ) {}

    public openQuestionTooltip(item: any, event: any, btnOnClick: () => void): void {
        this.cancelExistingQuestionTooltips();

        if (this.shouldShowTooltip(item.type)) {
            this.openTimer = this.timeout(() => {
                if (!this.mdPanelRef) {
                    let questionConfig: ITooltipPanelLocals = this.getQuestionConfig(item);
                    questionConfig.btnOnClick = btnOnClick;
                    this.panelService
                        .openTooltipPanel(TooltipPanel.instantiate(event.target, questionConfig))
                        .then((ref) => {
                            this.mdPanelRef = ref;
                        });
                }
            }, 1500);
        }
    }

    public hideQuestionTooltip(): void {
        this.timeout(() => {
            let hoveredList: NodeListOf<Element> = document.querySelectorAll('.its-tooltip-panel--container:hover');
            if (hoveredList.length === 0) {
                this.closeTooltipPanel();
            }
        }, 300);
    }

    public closeTooltipPanel(): void {
        if (this.mdPanelRef) {
            this.mdPanelRef.close().then(() => {
                this.mdPanelRef.destroy();
                this.mdPanelRef = null;
            });
        }
    }

    public isFeatureLocked(featureLockedConditions?: Array<IFeatureLockCondition>): boolean {
        return !new FeatureLockUtils(this.accessService).checkConditions(featureLockedConditions);
    }

    public getQuestionConfig(item: any): ITooltipPanelLocals {
        if (this.questionTooltipConfigs[item.type]) {
            let questionConfig: IQuestionTooltipConfig = this.questionTooltipConfigs[item.type];
            let tooltipConfigLocals: ITooltipPanelLocals = { contentHtml: null };

            tooltipConfigLocals.titleIcon = item.icon;
            tooltipConfigLocals.title = item.name;

            this.buildHtmlContent(questionConfig, tooltipConfigLocals);
            this.setBtnProperties(questionConfig, tooltipConfigLocals);

            return tooltipConfigLocals;
        } else {
            throw new Error('Could not find question tooltip config for question type.');
        }
    }

    public cancelExistingQuestionTooltips(): void {
        if (this.openTimer) {
            this.timeout.cancel(this.openTimer);
        }

        this.hideQuestionTooltip();
    }

    private shouldShowTooltip(questionType: string): boolean {
        return _.indexOf(this.noTooltipRequiredList, questionType) === -1;
    }

    private setBtnProperties(questionConfig: IQuestionTooltipConfig, tooltipConfigLocals: ITooltipPanelLocals): void {
        tooltipConfigLocals.btnText = this.translate.instant(questionConfig.btnText) || null;
    }

    private buildHtmlContent(questionConfig: IQuestionTooltipConfig, tooltipConfigLocals: ITooltipPanelLocals): void {
        tooltipConfigLocals.contentHtml = '';

        this.addContentTextToContentHtml(questionConfig, tooltipConfigLocals);
        this.addImgToContentHtml(questionConfig, tooltipConfigLocals);
    }

    private addContentTextToContentHtml(
        questionConfig: IQuestionTooltipConfig,
        tooltipConfigLocals: ITooltipPanelLocals
    ): void {
        for (let value of questionConfig.contentText) {
            tooltipConfigLocals.contentHtml +=
                '<div class="its-text--small its-text__muted its-margin-topbot--not-first">' +
                this.translate.instant(value) +
                '</div>';
        }
    }

    private addImgToContentHtml(
        questionConfig: IQuestionTooltipConfig,
        tooltipConfigLocals: ITooltipPanelLocals
    ): void {
        let imageSrc: string = null;

        if (questionConfig.imageUrl) {
            imageSrc = questionConfig.imageUrl[this.translate.use()]
                ? questionConfig.imageUrl[this.translate.use()]
                : questionConfig.imageUrl.en || null;
        }

        if (imageSrc) {
            tooltipConfigLocals.contentHtml += '<p><b>' + this.translate.instant('GENERAL.EXAMPLE') + ':</b></p>';
            tooltipConfigLocals.contentHtml += '<div><img src="' + imageSrc + '" /></div>';
        }
    }
}
