import {
    IBasePrePopulate,
    BasePrePopulate,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BasePrePopulate';

export interface IPrePopulateProperties {
    type?: string;
    question?: string;
    operator?: string;
    count?: number;
    interval?: string;
}

export interface IItemPrePopulate extends IBasePrePopulate {
    type: string;
    question: string;
    operator: string;
    count: number;
    interval: string;
}

export class ItemPrePopulate extends BasePrePopulate implements IItemPrePopulate {
    /**
     * Make a generic instance of this object
     *
     * @returns {IItemPrePopulate}
     */
    public static make(properties?: IPrePopulateProperties): IItemPrePopulate {
        let prePopulate: IItemPrePopulate = new ItemPrePopulate();

        if (properties) {
            prePopulate.type = properties.type || null;
            prePopulate.question = properties.question || null;
            prePopulate.operator = properties.operator || 'add';
            prePopulate.count = properties.count || 0;
            prePopulate.interval = properties.interval || 'days';
        }

        return prePopulate;
    }
}
