import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import View from './AuditRejectCommentModal.html';

/**
 * A modal class for collecting the reject comment on a rejected audit
 */
export class AuditRejectCommentModal extends Modal {
    static $inject: Array<string> = ['$mdDialog'];

    public comment: string;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @param comment
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions, comment?: string): any {
        config = config || {};
        config.template = View;
        config.controller = AuditRejectCommentModal;

        if (comment) {
            config.locals = {
                comment: comment,
            };
        }

        return super.instantiate(config);
    }

    constructor(protected dialog: ng.material.IDialogService) {
        super();
    }

    public save(): void {
        this.dialog.hide(this.comment);
    }

    public close(): void {
        this.dialog.cancel();
    }
}
