import { PagedDatalistModal } from '@intouch/its.essential/app/essential/modals/PagedDatalistModal';
import { ModalDatalist } from '@intouch/its.essential/app/essential/domain/modal-datalist/ModalDatalist';
import {
    IModalDatalistRow,
    ModalDatalistRow,
} from '@intouch/its.essential/app/essential/domain/modal-datalist//ModalDatalistRow';
import { ModalDatalistCell } from '@intouch/its.essential/app/essential/domain/modal-datalist//ModalDatalistCell';
import { ModalDatalistHeader } from '@intouch/its.essential/app/essential/domain/modal-datalist//ModalDatalistHeader';
import { IIqlApi } from '@intouch/its.essential/app/essential/api/IqlApi';
import { IIql, Iql } from '@intouch/iql-ts-sdk/src/domain/Iql';
import { FilterGroup } from '@intouch/iql-ts-sdk/src/domain/filters/FilterGroup';
import { Filter } from '@intouch/iql-ts-sdk/src/domain/filters/Filter';
import { Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { String } from '@intouch/its.essential/app/essential/utils/String';
import { IUserLocalTimeService } from '@intouch/its.essential/app/essential/services/UserLocalTimeService';

export class ChecklistHistoryModal extends PagedDatalistModal {
    static $inject: Array<string> = ['$mdDialog', '$translate', 'iteIqlApi', '$timeout', 'iteUserLocalTimeService'];

    public checklistUuid: string;
    public translations: any;

    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.controller = ChecklistHistoryModal;

        return super.instantiate(config);
    }

    constructor(
        protected dialog: ng.material.IDialogService,
        private translate: ng.translate.ITranslateService,
        private iqlApi: IIqlApi,
        protected timeout: ng.ITimeoutService,
        private userLocalTimeService: IUserLocalTimeService
    ) {
        super(dialog, timeout);

        this.translate(['CHECKLISTS.HISTORY.TITLE', 'GENERAL.USER', 'GENERAL.ACTION', 'GENERAL.DATE'])
            .then((translations) => {
                this.translations = translations;
            })
            .finally(() => {
                this.datalist = ModalDatalist.make(
                    this.translate.instant('CHECKLISTS.HISTORY.TITLE'),
                    [
                        ModalDatalistHeader.make(this.translate.instant('GENERAL.DATE'), 'data.created_at'),
                        ModalDatalistHeader.make(this.translate.instant('GENERAL.ACTION')),
                        ModalDatalistHeader.make(this.translate.instant('GENERAL.USER')),
                    ],
                    [],
                    Pager.make(1, 'data.created_at', 'desc', 10)
                );
            });
    }

    /**
     * Builds the datalist
     */
    public getDatalist(): ng.IPromise<ModalDatalist> {
        let iql: IIql = new Iql().dataSource('trace');
        let rows: Array<IModalDatalistRow> = [];

        this.datalist.loading = true;

        iql.filter(
            new FilterGroup(Iql.AND, [
                new Filter('data.targets.*->type', Iql.EQUAL, 'checklist', Iql.STRING),
                new Filter('data->domain', Iql.EQUAL, 'checklist', Iql.STRING),
                new Filter('data.targets.*->id', Iql.EQUAL, this.checklistUuid, Iql.STRING),
                new FilterGroup(Iql.OR, [
                    new Filter('data.->action', Iql.EQUAL, 'updated', Iql.STRING),
                    new Filter('data.->action', Iql.EQUAL, 'created', Iql.STRING),
                    new Filter('data.->action', Iql.EQUAL, 'published', Iql.STRING),
                    new Filter('data.->action', Iql.EQUAL, 'deleted', Iql.STRING),
                    new Filter('data.->action', Iql.EQUAL, 'disabled', Iql.STRING),
                    new Filter('data.->action', Iql.EQUAL, 'enabled', Iql.STRING),
                ]),
            ])
        );

        return this.iqlApi
            .execute(iql, this.datalist.pager, null, null)
            .then((resp: PagedEntities) => {
                this.datalist.pager = resp.getPager();

                for (let item of resp.getEntities()) {
                    rows.push(
                        ModalDatalistRow.make([
                            ModalDatalistCell.make(
                                this.userLocalTimeService.fromUtc(item.data.created_at, 'YYYY-MM-DD HH:mm:ss')
                            ),
                            ModalDatalistCell.make(String.capitalize(item.data.action)),
                            ModalDatalistCell.make(item.data.actor ? item.data.actor.name : ''),
                        ])
                    );
                }
                this.datalist.rows = rows;
                return this.datalist;
            })
            .finally(() => {
                this.datalist.loading = false;
            });
    }
}
