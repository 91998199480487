import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ICheckSession } from '../../../services/CheckSession';
import { SubscriptionPlanUtils } from '../../../utils/SubscriptionPlanUtils';

/**
 * The UI for rendering skip conditions on a checklist item
 */
@Component('its.check.module.checklists', SubscriptionPlanUpgrade.CID, {
    template: require('/app/modules/checklists/components/SubscriptionPlanUpgrade.html'),
    controllerAs: 'vm',
    bindings: {
        basePage: '@',
    },
})
class SubscriptionPlanUpgrade {
    static IID: string = 'SubscriptionPlanUpgrade';
    static CID: string = 'itsSubscriptionPlanUpgrade';

    static $inject: Array<string> = ['itcCheckSession', 'iteAccessService', 'APPCONFIG', '$window'];

    public subscriptionPlan: string;
    public subscriptionTrialEnd: string;
    public isAdmin: boolean = false;
    public isIntouchUser: boolean = false;
    public trialName: string;
    public basePage: string;
    public translationPage: string;
    public subscriptionStatus: string;

    /**
     * Create the class
     *
     * @param session
     * @param checklistService
     * @param appConfig
     * @param {ng.IWindowService} window
     */
    constructor(
        protected session: ICheckSession,
        private accessService: IAccessService,
        private appConfig: any,
        private window: ng.IWindowService
    ) {
        this.subscriptionPlan = this.accessService.getToken().getProductBySlug('check').subPlan;

        this.subscriptionTrialEnd = this.accessService.getToken().getProductBySlug('check').trialEndsAt;

        this.isAdmin = this.session.getToken().getUser().isAdmin();

        this.isIntouchUser = this.session.getToken().getUser().isIntouchUser();

        this.translationPage = this.basePage.toUpperCase();

        this.subscriptionStatus = this.accessService.getToken().getProductBySlug('check').subStatus;
    }

    /**
     * Determine if user is on Check Trial Standard
     *
     */
    public isCheckTrialStandard(): boolean {
        return SubscriptionPlanUtils.isCheckStandardPlan(this.subscriptionPlan);
    }

    /**
     * Determine if user is on Check Trial Premium
     *
     */
    public isCheckTrialPremium(): boolean {
        return SubscriptionPlanUtils.isCheckPremium(this.subscriptionPlan);
    }

    /**
     * Redirect to subscriptions settings page
     *
     */
    public redirectToSubscriptionSettings(): void {
        this.window.location.href = this.appConfig.products.urls.access.root + 'settings/subscriptions';
    }

    /**
     * Deterimin if trial has expired
     *
     */
    public isTrialExpired(): boolean {
        return this.accessService.isProductTrialExpired();
    }

    /**
     * Determine if user has an active subscription
     *
     */
    public subscriptionActive(): boolean {
        return SubscriptionPlanUtils.isActive(this.subscriptionStatus);
    }

    public shouldDisplayUpgradePanel(): boolean {
        if (
            this.subscriptionActive() &&
            !this.isCheckTrialStandard() &&
            !SubscriptionPlanUtils.isTrial(this.subscriptionStatus)
        ) {
            return false;
        }

        return this.isCheckTrialStandard() || this.isCheckTrialPremium();
    }
}
