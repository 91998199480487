import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemInput } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';

@Component('its.check.module.checklists', ScoreSetting.CID, {
    template: require('/app/modules/checklists/components/items/shared/score-setting/ScoreSetting.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class ScoreSetting {
    static IID: string = 'ScoreSetting';
    static CID: string = 'itcScoreSetting';
    static $inject: string[] = [];

    public item: BaseItemInput;
    public hasPoints: boolean = false;

    public $onInit(): void {
        if (this.item.points) {
            this.hasPoints = true;
        }
    }

    public onPointsChange(): void {
        this.hasPoints = !!this.item && NumberUtils.isNumber(this.item.points);
    }
}
