import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

/**
 * The UI for rendering the dialog title on ItemInput modals
 *
 */
@Component('its.check.module.checklists', ItemInputDialogTitle.CID, {
    template: require('/app/modules/checklists/components/ItemInputDialogTitle.html'),
    controllerAs: 'vm',
    bindings: {
        type: '<',
        name: '<',
        isDuplicate: '<',
    },
})
class ItemInputDialogTitle {
    static IID: string = 'ItemInputDialogTitle';
    static CID: string = 'itcItemInputDialogTitle';

    public type: string;
    public name: string;
    public isDuplicate: boolean;
}
