import { IItemReference, ItemReference } from './ItemReference';
import { IBaseItemInput } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { IBaseItemSection } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemSection';

export class LogicItemReference extends ItemReference implements IItemReference {
    public type: string = 'logic';
    public subject: IBaseItemInput = null;
    public source: IBaseItemInput = null;
    public sourceParent: IBaseItemSection = null;

    /**
     * Remove logic dependencies
     *
     */
    public removeDependency(): void {
        this.source.removeLogicDependencies(this.subject, true);
    }
}
