import { IItemReference, ItemReference } from './ItemReference';
import { IBaseItemInput } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { IBaseCalculation } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseCalculation';
import { IItemCalculation } from '../ItemCalculation';
import { IBaseItemSection } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemSection';

export class CalculationItemReference extends ItemReference implements IItemReference {
    public type: string = 'calculation';
    public subject: IBaseItemInput = null;
    public source: IItemCalculation = null;
    public sourceParent: IBaseItemSection = null;

    /**
     * Remove calculation dependencies
     *
     */
    public removeDependency(): void {
        let calculations: Array<IBaseCalculation> = _.filter(
            this.source.settings.calculations,
            (c: IBaseCalculation) => {
                return c.type === 'operand' && c.value === this.subject.uuid;
            }
        );

        if (calculations) {
            for (let match of calculations) {
                this.source.removeCalculation(match);
            }
        }
    }
}
