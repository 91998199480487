'use strict';

import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { IConnectivity } from '@intouch/its.essential/app/essential/services/Connectivity';
import { ITopNavService } from '@intouch/its.essential/app/essential/services/TopNavService';

/**
 * The group service interface
 */
export interface IPageService {
    loaderVisible: boolean;

    setPageTitle(title: string): void;
    setPageLogo(logoUrl: string): void;
    isOnline(): boolean;
    showLoader(show: boolean): void;
    back(): void;
}

/**
 * The group service class
 */
@Service('its.check.services', PageService.IID, PageService)
export class PageService implements IPageService {
    static IID: string = 'itcPageService';
    static $inject: Array<string> = ['$window', '$mdDialog', 'iteConnectivity', 'iteTopNavService'];

    public loaderVisible: boolean = false;

    /**
     *
     * @param {angular.IWindowService} windowService
     * @param {angular.material.IDialogService} dialog
     * @param {IConnectivity} connectivityService
     * @param {ITopNavService} topNavService
     */
    public constructor(
        private windowService: ng.IWindowService,
        private dialog: ng.material.IDialogService,
        private connectivityService: IConnectivity,
        private topNavService: ITopNavService
    ) {}

    /**
     * Allows for us to dynamically set the pages title (outside of using our routing configuration)
     *
     * @param title
     */
    public setPageTitle(title: string): void {
        this.topNavService.title = title;
    }

    /**
     * Allows for the page logo to be set
     *
     * @param logoUrl
     */
    public setPageLogo(logoUrl: string): void {
        this.topNavService.logoUrl = logoUrl;
    }

    /**
     * Indicates if we are online
     *
     * @returns {boolean}
     */
    public isOnline(): boolean {
        return this.connectivityService.isOnline();
    }

    /**
     * Toggles and gets the current page loading state
     *
     * @param show
     */
    public showLoader(show: boolean): void {
        this.loaderVisible = !!show;
    }

    /**
     * Will navigate to the previous page
     */
    public back(): void {
        this.dialog.cancel(); // to need to close any modals that could be open
        this.windowService.history.back();
    }
}
