import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import View from './TrialUpgradeModal.html';

/**
 * A class to show the simple text edit/create fields
 */
export class TrialUpgradeModal extends Modal {
    static $inject: Array<string> = ['$mdDialog'];

    public description: string;
    public contactAdmin: string;
    public cancelText: string;
    public okText: string;
    public isAdminOrIntouch: boolean;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @param options
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = View;
        config.controller = TrialUpgradeModal;
        config.disableParentScroll = true;
        config.hasBackdrop = true;
        config.clickOutsideToClose = false;
        config.escapeToClose = false;

        return super.instantiate(config);
    }

    /**
     * Create the class
     *
     * @param dialog
     */
    constructor(private dialog: ng.material.IDialogService) {
        super();

        this.isAdminOrIntouch = this.isAdminOrIntouch || false;
        this.cancelText = this.cancelText || '';
        this.okText = this.okText || '';
        this.contactAdmin = this.contactAdmin || '';

        if (!this.description) {
            throw new Error('Description text is required when showing a modal to a user');
        }
    }

    /**
     * Cancel the dialog
     */
    public confirm(): void {
        this.dialog.hide(true);
    }

    /**
     * Cancel the dialog
     */
    public closeDialog(): void {
        this.dialog.cancel();
    }
}
