import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IIql, Iql } from '@intouch/iql-ts-sdk/src/domain/Iql';
import { FilterGroup } from '@intouch/iql-ts-sdk/src/domain/filters/FilterGroup';
import { ObjectUtils } from '@intouch/its.essential/app/essential/utils/ObjectUtils';
import { IChecklistService } from './ChecklistService';

@Service('its.check.services', ProductNavigationService.IID, ProductNavigationService)
export class ProductNavigationService {
    static IID: string = 'itcProductNavigationService';
    static $inject: Array<string> = ['APPCONFIG', 'iteAccessService', 'itcChecklistService'];

    public constructor(
        private appConfig: any,
        private accessService: IAccessService,
        private checklistService: IChecklistService
    ) {}

    public startAudit(checklistUuid: string, locationUuid: string): void {
        const path: string =
            this.appConfig.products.urls.check.app +
            this.accessService.getToken().getRawToken() +
            '?redirect=start-audit';
        const query: string = '?checklistUuid=' + checklistUuid + '&locationUuid=' + locationUuid;
        const url: string = path + encodeURIComponent(query);

        window.open(url, '_blank');
    }

    public viewChecklistRecords(checklistUuid: string): void {
        let iql: IIql = new Iql()
            .filter(
                new FilterGroup(Iql.AND, [
                    this.checklistService.getChecklistProductFilter(),
                    this.checklistService.getChecklistProgramFilter(checklistUuid),
                ])
            )
            .filter(new FilterGroup(Iql.OR, this.checklistService.getUserHierarchyFilters()));

        const path: string =
            this.appConfig.products.urls.intelligence.app +
            this.accessService.getToken().getRawToken() +
            '?redirect=records/iql';
        const query: string =
            encodeURIComponent('?iqlQuery=') + ObjectUtils.jsonToUriQueryString(ObjectUtils.camelToSnake(iql));
        const url: string = path + query;

        window.open(url, '_blank');
    }
}
