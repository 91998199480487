import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { ICalculation, Calculation } from './Calculation';
import * as _ from 'lodash';
import {
    IBaseItemCalculation,
    BaseItemCalculation,
    IItemCalculationSettings,
    ItemCalculationSettings,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemCalculation';
import { String } from '@intouch/its.essential/app/essential/utils/String';
import {
    IBaseItemInputTranslation,
    BaseItemInputTranslation,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/translations/BaseItemInputTranslation';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';
import { ITaggable } from '@intouch/its.essential/app/essential/domain/Taggable';
import { INameUuidPair } from '@intouch/its.essential/app/essential/utils/datatypes/NameUuidPair';
import { ILogicSubject } from './LogicSubject';
import { IItemInput } from './ItemInput';
import { IItemAdditionalRequirements, ItemAdditionalRequirements } from './ItemAdditionalRequirements';
import { IWithAdditionalRequirements } from './WithAdditionalRequirements';

/**
 * The IItemCalculation interface
 *
 */
export interface IItemCalculation extends IBaseItemCalculation, ITaggable, ILogicSubject, IWithAdditionalRequirements {
    settings: IItemCalculationSettings;

    addCalculation(calculation: ICalculation): ICalculation;

    removeCalculation(calculation: ICalculation): void;

    hasRangeMin(): boolean;

    hasRangeMax(): boolean;

    allowAsLogicSubject(): boolean;

    calculationHasItem(item: IItemInput): boolean;

    getType(): string;

    setScored(isScored: boolean): void;
}

/**
 * The ItemCalculation class
 *
 */
export class ItemCalculation extends BaseItemCalculation implements IItemCalculation {
    public settings: IItemCalculationSettings = new ItemCalculationSettings();
    public tags: Array<INameUuidPair> = [];
    public additionalRequirements: IItemAdditionalRequirements = null;

    /**
     * Make a generic instance of this object
     *
     * @param name
     * @returns {IItemCalculation}
     */
    public static make(name: string): IItemCalculation {
        let me: IItemCalculation = new ItemCalculation();
        me.name = name;
        me.settings.allowCnaNa = false;

        return me;
    }

    /**
     * Create a new instance of ItemInput
     * @param {string} name
     */
    public constructor(name?: string) {
        super();
        if (name) {
            this.name = name;
        }
        this.uuid = String.uuid();
        this.settings.roundTo = 0;
    }

    /**
     * Returns a string identifying what type the additional requirement is
     *
     * @return {string}
     */
    public getAdditionalRequirementType(): string {
        if (this.additionalRequirements) {
            return this.additionalRequirements.type;
        }
        return null;
    }

    /**
     * Returns the condition used by additional requirements, if there is one set
     *
     */
    public getAdditionalRequirementCondition(): string {
        if (this.additionalRequirements) {
            return this.additionalRequirements.condition;
        }
        return null;
    }

    public setScored(isScored: boolean): void {
        this.points = isScored ? 0 : null;
    }

    /**
     * Returns the type
     * @returns {string}
     */
    public getType(): string {
        return this.type;
    }

    /**
     * Returns if object is allowed as a logic subject
     *
     */
    public allowAsLogicSubject(): boolean {
        return true;
    }

    /**
     * Build this object from json
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {ItemCalculation}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemCalculation {
        super.fromJson(jsonObject, convertToCamel);

        if (!this.uuid) {
            this.uuid = String.uuid();
        }

        if (jsonObject['access_tags']) {
            this.accessTags = jsonObject['access_tags'];
        }

        if (jsonObject['settings']) {
            this.settings = EntityBuilder.buildOne<IItemCalculationSettings>(
                ItemCalculationSettings,
                jsonObject['settings'],
                convertToCamel
            );
        }

        if (jsonObject['translations']) {
            this.translations = EntityBuilder.buildMany<IBaseItemInputTranslation>(
                BaseItemInputTranslation,
                jsonObject['translations'],
                convertToCamel
            );
        }

        if (jsonObject['tags']) {
            this.tags = jsonObject['tags'];
        }

        if (jsonObject['additional_requirements']) {
            this.additionalRequirements = EntityBuilder.buildOne<IItemAdditionalRequirements>(
                ItemAdditionalRequirements,
                jsonObject['additional_requirements'],
                convertToCamel
            );
        } else if (jsonObject['photo_required']) {
            this.additionalRequirements = new ItemAdditionalRequirements().fromJson(
                {
                    type: 'photo',
                    condition: 'always',
                },
                true
            );
            delete (<any>this).photoRequired;
        }

        return this;
    }

    /**
     * Add a new calculation to this item
     *
     * @param calculation
     * @returns {ICalculation}
     */
    public addCalculation(calculation: ICalculation): ICalculation {
        // add a new operand if this calculation is the first being added
        if (this.settings.calculations.length > 0) {
            this.settings.calculations.push(Calculation.make('operator', '+'));
        }

        this.settings.calculations.push(calculation);

        return calculation;
    }

    /**
     * Will remove a calculation from this item
     *
     * @param calculation
     */
    public removeCalculation(calculation: ICalculation): void {
        // can not remove operators as they are automatically removed for you
        if (calculation.type === 'operator') {
            throw new Error('Not able to remove operators, they are removed for you');
        }

        let index: number = _.findIndex(this.settings.calculations, calculation);

        // remove the operator before the calculation to remove
        if (index > 0) {
            this.settings.calculations.splice(index--, 1);
        } else if (this.settings.calculations.length > 1 && index === 0) {
            this.settings.calculations.splice(index, 1);
        }

        // remove the calculation
        this.settings.calculations.splice(index, 1);
    }

    /**
     * Returns true if a minimum range is set
     *
     * @return {boolean}
     */
    public hasRangeMin(): boolean {
        return NumberUtils.isNumber(this.settings.min);
    }

    /**
     * Returns true if a maximum range is set
     *
     * @return {boolean}
     */
    public hasRangeMax(): boolean {
        return NumberUtils.isNumber(this.settings.max);
    }

    /**
     * Returns if item is part of calculation
     *
     * @param item
     */
    public calculationHasItem(item: IItemInput): boolean {
        return (
            this.settings &&
            this.settings.calculations &&
            this.settings.calculations.length > 0 &&
            !!_.find(this.settings.calculations, { type: 'operand', value: item.uuid })
        );
    }
}
