import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { ISessionService } from '@intouch/its.essential/app/essential/services/SessionService';
import { IGeoCoordinate, GeoCoordinate } from '@intouch/its.essential/app/essential/services/GeoTracker';
import { IUser } from '@intouch/its.essential/app/essential/domain/access/User';
import { IOrganization } from '@intouch/its.essential/app/essential/domain/access/Organization';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { ITokenizer } from '@intouch/its.essential/app/essential/services/JWTokenizer';
import { IConfigurable } from '@intouch/its.essential/app/essential/services/IConfigurable';

/**
 * The check session configuration interface
 */
interface ICheckSessionConfiguration {
    appUrl?: string;
}

/**
 * The session container to hold all session access logic
 */
export interface ICheckSession extends IConfigurable {
    hasUser(): boolean;

    getUser(): IUser;

    getToken(): IToken;

    hasOrganization(): boolean;

    getOrganization(): IOrganization;

    setOrganization(organization: IOrganization): IOrganization;

    hasCoordinates(): boolean;

    getCoordinates(): IGeoCoordinate;

    setCoordinates(coordinates: IGeoCoordinate): IGeoCoordinate;

    getOrganizationCount(): number;

    setOrganizationCount(count: number): number;

    getLanguage(): string;

    setLanguage(language: string): string;

    flush(): void;

    used(): number;

    switchToApp(): void;

    onboardComplete(): boolean;

    setOnboardComplete(value: boolean): boolean;

    getChecklistTourSettings(): any;

    setChecklistTourSettings(value: any): any;

    setValue(key: string, value: any): any;

    getValue(key: string): any;
}

/**
 * The user service class
 */
@Service('its.check.services', CheckSession.IID, CheckSession)
class CheckSession implements ICheckSession {
    static IID: string = 'itcCheckSession';
    static $inject: Array<string> = ['iteSessionService', 'jwTokenizer', 'iteAccessService', '$window'];

    private appUrl: string;

    /**
     * The  class constructor
     *
     * @param session
     * @param jwTokenizer
     * @param accessService
     * @param windowService
     */
    public constructor(
        private session: ISessionService,
        private jwTokenizer: ITokenizer,
        private accessService: IAccessService,
        private windowService: ng.IWindowService
    ) {}

    /**
     * Sets configurable data (such as the app url)
     *
     * @param data
     */
    public configure(data: ICheckSessionConfiguration): void {
        this.appUrl = data.appUrl;
    }

    /**
     * Checks if we have a user in the session
     *
     * @returns {boolean}
     */
    public hasUser(): boolean {
        return this.getUser() !== null;
    }

    /**
     * Retrieves our currently authenticated user
     *
     * @returns {IUser}
     */
    public getUser(): IUser {
        return this.getToken().getUser();
    }

    /**
     * Retrieves our current JWT token class
     *
     * @returns {IToken}
     */
    public getToken(): IToken {
        return this.accessService.getToken();
    }

    /**
     * Checks if we have an organization in the session
     *
     * @returns {boolean}
     */
    public hasOrganization(): boolean {
        return this.getOrganization() !== null;
    }

    /**
     * Gets the current organization from the session
     *
     * @returns {IOrganization}
     */
    public getOrganization(): IOrganization {
        return this.getToken().getOrganization();
    }

    /**
     * Sets the given organization to the session
     *
     * @param organization
     * @returns {IOrganization}
     */
    public setOrganization(organization: IOrganization): IOrganization {
        return this.session.set<IOrganization>('organization', organization);
    }

    /**
     * Determines if we have coordinates set locally
     *
     * @returns {boolean}
     */
    public hasCoordinates(): boolean {
        return this.session.get<IGeoCoordinate>('coordinates', GeoCoordinate) !== null;
    }

    /**
     * Will get the current local coordinates for this session
     *
     * @returns {IGeoCoordinate}
     */
    public getCoordinates(): IGeoCoordinate {
        // todo; need to handle if we have no coordinates; possibly us the users geo coordinates

        return this.session.get<IGeoCoordinate>('coordinates', GeoCoordinate);
    }

    /**
     * Will set the current coordinates locally
     *
     * @param coordinates
     * @returns {IGeoCoordinate}
     */
    public setCoordinates(coordinates: IGeoCoordinate): IGeoCoordinate {
        return this.session.set<IGeoCoordinate>('coordinates', coordinates);
    }

    /**
     * Will get the number of organizations this member is a part of
     *
     * @returns {number}
     */
    public getOrganizationCount(): number {
        return this.session.get<number>('organizationCount');
    }

    /**
     * Will set the number of organizations this member is a part of
     *
     * @param count
     * @returns {number}
     */
    public setOrganizationCount(count: number): number {
        return this.session.set<number>('organizationCount', count);
    }

    /**
     * Gets the current users stored default language
     *
     * @returns {string}
     */
    public getLanguage(): string {
        return this.session.get<string>('language');
    }

    /**
     * Will set the current users default language
     *
     * @param language
     * @returns {string}
     */
    public setLanguage(language: string): string {
        return this.session.set<string>('language', language);
    }

    /**
     * Flushes the current session
     */
    public flush(): void {
        return;
    }

    public used(): number {
        return this.session.used();
    }

    public switchToApp(): void {
        this.windowService.location.href = this.appUrl + this.getToken().getRawToken();
    }

    /**
     * Returns if this is a first time user
     *
     */
    public onboardComplete(): boolean {
        return this.session.get<boolean>('itc_onboard_complete');
    }

    /**
     * Set first time user value
     *
     * @param value
     */
    public setOnboardComplete(value: boolean): boolean {
        return this.session.set<boolean>('itc_onboard_complete', value);
    }

    /**
     * Get checklist tour settings
     *
     */
    public getChecklistTourSettings(): any {
        return this.session.get('checklist_tour') || {};
    }

    /**
     * Set checklist tour settings
     *
     * @param value
     */
    public setChecklistTourSettings(value: any): any {
        return this.session.set('checklist_tour', value);
    }

    /**
     * Set a value in local storage
     *
     * @param key
     * @param value
     */
    public setValue(key: string, value: any): any {
        return this.session.set(key, value);
    }

    /**
     * Get a value from local storage
     *
     * @param key
     */
    public getValue(key: string): any {
        return this.session.get(key);
    }
}
