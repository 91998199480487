let module: any = angular.module('its.check.module.checklists', []);

import './_configuration';
import './ChecklistsListController';
import './ChecklistEditController';
import './ChecklistTranslationsController';
import './ChecklistPrintController';
import './CreateController';

import './components/items/_modules';
import './components/ItemAdditionalInformation';
import './components/ExamplePhoto';
import './components/SkipConditions';
import './components/SubscriptionPlanUpgrade';
import './components/MoveSection';
import './components/ItemInputDialogTitle';
import './components/ItemInputDialogActions';
import './components/translations/TranslationImage';
import './components/translations/ChecklistTranslations';
import './components/translations/BaseItemTranslation';
import './components/translations/TranslationAdditionalInformation';
import './components/translations/SectionTranslation';
import './components/translations/SimpleCheckTranslation';
import './components/translations/TextTranslation';
import './components/translations/TimeTranslation';
import './components/translations/DisplayTextTranslation';
import './components/translations/DisplayGraphicTranslation';
import './components/translations/NumberTranslation';
import './components/translations/DatePickerTranslation';
import './components/translations/CalculationTranslation';
import './components/translations/MultipleChoiceTranslation';
import './components/translations/DropdownTranslation';
import './components/AdditionalRequirements';
import './components/ChecklistPublishButton';

import './upload/ChecklistUploadController';
import './upload/ChecklistUploadStep1Controller';
import './upload/ChecklistUploadStep2Controller';
import './upload/ChecklistUploadStep3Controller';
import './settings/ChecklistSettingsController';
import './settings/ChecklistGeneralSettingsController';
import './outcomes/ChecklistOutcomesSettingsController';
import './settings/ChecklistRandomizationSettingsController';
import './settings/ChecklistSchedulerSettingsController';
import './settings/ChecklistTranslationsSettingsController';
import './ChecklistMainController';

export default module;
