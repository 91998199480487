import { IStringMap } from '@intouch/its.essential/app/essential/utils/datatypes/StringMap';
import { Upload, IUpload } from '@intouch/its.essential/app/essential/domain/file-processing/Upload';

export interface IChecklistImport extends IUpload {
    configuration: IStringMap;
}

export class ChecklistImport extends Upload implements IChecklistImport {
    public configuration: IStringMap = {};

    constructor() {
        super({
            type: 'checklist',
            file: null,
            configuration: {},
        });
    }
}
