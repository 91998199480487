import { ItemBaseModal, ItemModalOptions } from './ItemBaseModal';
import { IItemInput } from '../../../domain/checklists/ItemInput';
import { IItemSection } from '../../../domain/checklists/ItemSection';

/**
 * A base class modal for all input-based modals
 */
export class ItemInputModal extends ItemBaseModal {
    public item: IItemInput;
    public showPoints: boolean;
    public originalSection: IItemSection;
    public insertAtIndex: number;

    public activeTab: string = 'general';

    /**
     * Create the instance of this modal
     *
     * @param config
     * @param options
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions, options?: ItemModalOptions): any {
        config = config || {};

        return super.instantiate(config, options);
    }

    public onInit(): void {
        super.onInit();
        this.section = this.checklist.findSection('uuid', this.section.uuid);
        this.originalSection = this.section;

        if (this.isEdit) {
            this.item = this.section.findItem('uuid', this.item.uuid);
        }

        this.showPoints = this.item.points !== null;
    }

    public save(): void {
        if (this.section) {
            let index: number = this.checkIfAddingAtIndex();
            if (this.isEdit) {
                // remove from original section if moved
                if (this.section !== this.originalSection) {
                    this.originalSection.removeItem(this.item);
                    this.checklist.updateSection(this.originalSection);
                }

                if (index > -1) {
                    this.section.removeItem(this.item);
                    // update index since we may have knocked things out of order
                    index = this.checkIfAddingAtIndex();
                    this.section.items.splice(index, 0, this.item);
                } else {
                    this.section.updateItem(this.item);
                }
            } else {
                if (index > -1) {
                    this.section.items.splice(index, 0, this.item);
                } else {
                    this.section.addItem(this.item, this.insertAtIndex);
                }
            }
            this.checklist.updateSection(this.section);
        } else {
            throw new Error('Could not match section for ' + this.section.uuid);
        }
        super.save();
    }

    /**
     * Updates scoring properties based on showPoints value
     *
     */
    public updateScoringValues(): void {
        if (!this.showPoints) {
            this.item.points = null;
        }
    }

    public mapTags(): void {
        this.item.tags = this.item.tags.map((tag) => {
            return { uuid: tag.uuid, name: tag.name };
        });
    }
}
