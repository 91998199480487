import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { String } from '@intouch/its.essential/app/essential/utils/String';

import {
    IBaseItemInput,
    BaseItemInput,
    IItemInputSettings,
    ItemInputSettings,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';

import { IAuditPhoto } from '@intouch/its.check.essential/app/check-essential/domain/audits/AuditPhoto';
import { IUuidNamePair } from '@intouch/its.check.essential/app/check-essential/domain/datatypes/UuidNamePair';
import { ITaggable } from '@intouch/its.essential/app/essential/domain/Taggable';
import { INameUuidPair } from '@intouch/its.essential/app/essential/utils/datatypes/NameUuidPair';
import { IItemPrePopulate, ItemPrePopulate } from './ItemPrePopulate';
import { IItemAdditionalRequirements, ItemAdditionalRequirements } from './ItemAdditionalRequirements';
import { IWithAdditionalRequirements } from './WithAdditionalRequirements';
import { ILogicSubject } from './LogicSubject';

/**
 * The checklist IItemInput interface
 *
 */
export interface IItemInput extends IBaseItemInput, ITaggable, IWithAdditionalRequirements, ILogicSubject {
    description: string;
    examplePhoto: IAuditPhoto;
    critical: boolean;
    settings: IItemInputSettings;
    type: string;
    prePopulateDate: ItemPrePopulate;
    /**
     * @deprecated
     */
    accessTags: Array<IUuidNamePair>;

    addLogic(): void;

    canFollowUpRequire(): boolean;

    canAddPhoto(): boolean;

    canAddComment(): boolean;

    getType(): string;

    setScored(isScored: boolean): void;
}

/**
 * The checklist ItemInput class
 *
 */
export class ItemInput extends BaseItemInput implements IItemInput {
    public description: string = null;
    public examplePhoto: IAuditPhoto = null;
    public critical: boolean = false;
    public settings: IItemInputSettings = new ItemInputSettings();
    public type: string = null;
    public tags: Array<INameUuidPair> = [];
    public additionalRequirements: IItemAdditionalRequirements = null;
    public prePopulateDate: ItemPrePopulate = null;
    /**
     * @deprecated
     */
    public accessTags: Array<IUuidNamePair> = [];

    /**
     * Create a new instance of ItemInput
     * @param {string} name
     */
    public constructor(name?: string) {
        super();
        if (name) {
            this.name = name;
        }
        this.uuid = String.uuid();
    }

    public setScored(isScored: boolean): void {
        this.points = isScored ? 0 : null;
    }

    /**
     * Returns a string identifying what type the additional requirement is
     *
     * @return {string}
     */
    public getAdditionalRequirementType(): string {
        if (this.additionalRequirements) {
            return this.additionalRequirements.type;
        }
        return null;
    }

    /**
     * Returns the condition used by additional requirements, if there is one set
     *
     */
    public getAdditionalRequirementCondition(): string {
        if (this.additionalRequirements) {
            return this.additionalRequirements.condition;
        }
        return null;
    }

    /**
     * Checks if this item can be follow-up required
     *
     * @returns {boolean}
     */
    public canFollowUpRequire(): boolean {
        return !(['date'].indexOf(this.type) !== -1);
    }

    /**
     * Check to see if Item Label should be displayed. Defailts to true for regular items but overridden by itemDisplayText class.
     * @returns {boolean}
     */
    public displayItemLabel(): boolean {
        return true;
    }

    /**
     * Checks if this item can have a photo added to it
     *
     * @returns {boolean}
     */
    public canAddPhoto(): boolean {
        return !(['date'].indexOf(this.type) !== -1);
    }

    /**
     * Checks if this item can have comments added to it
     *
     * @returns {boolean}
     */
    public canAddComment(): boolean {
        return !(['date', 'text'].indexOf(this.type) !== -1);
    }

    /**
     * Return the type of this item
     *
     */
    public getType(): string {
        return this.type;
    }

    /**
     * Returns if object is allowed as a logic subject
     *
     */
    public allowAsLogicSubject(): boolean {
        return false;
    }

    /**
     * Builds a checklist Section object with values from a json object
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {IItemInput}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemInput {
        super.fromJson(jsonObject, convertToCamel);

        if (!this.uuid) {
            this.uuid = String.uuid();
        }

        if (jsonObject['tags']) {
            this.tags = jsonObject['tags'];
        }

        if (jsonObject['pre_populate_date']) {
            this.prePopulateDate = EntityBuilder.buildOne<IItemPrePopulate>(
                ItemPrePopulate,
                jsonObject['pre_populate_date'],
                convertToCamel
            );
        }

        if (jsonObject['additional_requirements']) {
            this.additionalRequirements = EntityBuilder.buildOne<IItemAdditionalRequirements>(
                ItemAdditionalRequirements,
                jsonObject['additional_requirements'],
                convertToCamel
            );
        }

        return this;
    }

    /**
     * Creates a duplicate of this object with null name and a new uuid
     *
     * @param {boolean} preserveTranslations
     * @return {IBaseItem}
     */
    public duplicate(preserveTranslations?: boolean): IItemInput {
        let item: IItemInput = <IItemInput>this.clone();
        item.uuid = String.uuid();

        if (item.logic && item.logic.length > 0) {
            for (let logic of item.logic) {
                logic.targetUuid = item.uuid;
            }
        }

        if (!preserveTranslations) {
            item.translations = [];
        }

        return item;
    }
}
