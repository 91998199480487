import {
    IBaseListingItem,
    BaseListingItem,
} from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseListingItem';
import { IAudit } from './Audit';

export interface IUpcomingListingItem extends IBaseListingItem {
    uuid: string;
    type: string;
    checklistUuid: string;
    checklistName: string;
    hierarchyUuid: string;
    hierarchyName: string;
    hierarchyAddressCity: string;
    hierarchyAddressLine1: string;
    displayTimezone: string;
    startBy: string;
    completeBy: string;
    status: string;
    accessCreatedById: string;
    accessCreatedByName: string;
    auditorUuid: string;
    auditorName: string;
    createdAt: string;
}

export class UpcomingListItem extends BaseListingItem implements IUpcomingListingItem {
    public uuid: string = null;
    public type: string = null;
    public checklistUuid: string = null;
    public checklistName: string = null;
    public hierarchyUuid: string = null;
    public hierarchyName: string = null;
    public hierarchyAddressCity: string = null;
    public hierarchyAddressLine1: string = null;
    public displayTimezone: string = null;
    public startBy: string = null;
    public completeBy: string = null;
    public status: string = null;
    public accessCreatedById: string = null;
    public accessCreatedByName: string = null;
    public auditorUuid: string = null;
    public auditorName: string = null;
    public createdAt: string = null;

    /**
     * Constructions an instance of IListingItem, if audit is provided, will populate properties from audit
     *
     * @param audit
     */
    public constructor(audit?: IAudit) {
        super();

        if (audit) {
            if (audit.checklist) {
                this.checklistUuid = audit.checklist.uuid;
                this.checklistName = audit.checklist.name;
            }

            if (audit.hierarchy) {
                this.hierarchyUuid = audit.hierarchy.uuid;
                this.hierarchyName = audit.hierarchy.name;
            }
        }
    }
}
