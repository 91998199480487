import { Component } from '@intouch/its.essential/app/essential/decorators/Component';

@Component('its.check.module.checklists', CollapseArrowComponent.CID, {
    template: require('/app/modules/checklists/components/items/shared/collapse-arrow/CollapseArrow.html'),
    controllerAs: 'vm',
    bindings: {
        expanded: '=',
        ngDisabled: '<',
    },
})
class CollapseArrowComponent {
    static IID: string = 'CollapseArrowComponent';
    static CID: string = 'itcCollapseArrow';
    static $inject: string[] = [];

    public expanded: boolean;
    public ngDisabled: boolean;
}
