import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { IListingItem } from '../../../domain/checklists/ListingItem';
import { ICheckApi } from '../../../api/CheckApi';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';
import { Assignment, IAssignment } from '../../../domain/checklists/Assignment';
import { IUserListingItem } from '@intouch/its.essential/app/essential/domain/access/UserListingItem';
import { UuidDisplayNamePair } from '@intouch/its.check.essential/app/check-essential/domain/datatypes/UuidDisplayNamePair';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import moment = require('moment'); // tslint:disable-line
import Moment = moment.Moment;
import { IHierarchyNode } from '@intouch/its.essential/app/essential/domain/access/HierarchyNode';
import { INameUuidPair } from '@intouch/its.essential/app/essential/utils/datatypes/NameUuidPair';
import { IGroupListingItem } from '@intouch/its.essential/app/essential/domain/access/GroupListingItem';
import * as _ from 'lodash';
import { IUuidNamePair } from '@intouch/its.check.essential/app/check-essential/domain/datatypes/UuidNamePair';
import { GroupDatalistModal } from '@intouch/its.essential/app/essential/modals/GroupDatalistModal';
import View from './AssignChecklistModal.html';
import { IQueryFilter, QueryFilter } from '@intouch/its.essential/app/essential/domain/api/QueryFilter';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';

/**
 * A class to show the simple check edit/create fields
 */
export class AssignChecklistModal extends Modal {
    static $inject: Array<string> = [
        '$mdDialog',
        'iteToaster',
        'itcCheckApi',
        'iteAccessApi',
        '$translate',
        '$mdPanel',
        '$mdMedia',
        'itePanelService',
    ];

    public checklists: Array<IListingItem> = [];
    public selectedChecklist: IListingItem;
    public allowChecklistSelection: boolean;
    public assigning: boolean = false;
    public selectedLocations: INameUuidPair[] = [];
    public selectedUser: IUserListingItem;
    public showDatepicker: boolean = false;
    public notes: string = null;

    public upcomingAuditStartByDate: Date = null;
    public upcomingAuditStartByHour: string = null;
    public upcomingAuditStartByMinute: string = null;
    public upcomingAuditStartByMeridiem: string = null;

    public upcomingAuditCompleteByDate: Date = null;
    public upcomingAuditCompleteByHour: string = null;
    public upcomingAuditCompleteByMinute: string = null;
    public upcomingAuditCompleteByMeridiem: string = null;

    public upcomingAuditDateTimeErrorMessage: string = null;

    public minutes: Array<String>;
    public hours: Array<String>;
    public ampm: Array<String>;
    public minDate: Date;
    public notificationGroups: Array<IUuidNamePair> = [];

    private translations: any;

    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = View;
        config.controller = AssignChecklistModal;

        return super.instantiate(config);
    }

    constructor(
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private checkApi: ICheckApi,
        private accessApi: IAccessApi,
        private translate: angular.translate.ITranslateService,
        private mdPanel: any,
        private media: ng.material.IMedia,
        private panelService: IPanelService
    ) {
        super();

        this.minDate = new Date();
        this.minutes = [
            '00',
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
            '39',
            '40',
            '41',
            '42',
            '43',
            '44',
            '45',
            '46',
            '47',
            '48',
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '58',
            '59',
        ];
        this.hours = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        this.ampm = ['AM', 'PM'];

        this.translate([
            'CHECKLISTS.MESSAGES.CHECKLIST_ASSIGNED',
            'CHECKLISTS.ERRORS.UNABLE_TO_ASSIGN',
            'CHECKLISTS.ERRORS.ASSIGNED_COMPLETE_BY_INVALID',
            'CHECKLISTS.MESSAGES.LARGE_AUDIT_SIZE_WARNING',
        ]).then((translations) => {
            this.translations = translations;
        });
    }

    /**
     * Checks if we have all the information needed to start an audit
     *
     * @returns {boolean}
     */
    public isValid(): boolean {
        return !!this.selectedChecklist && this.selectedLocations.length > 0;
    }

    /**
     * Close the check now modal
     *
     */
    public close(): void {
        this.dialog.cancel();
    }

    /**
     * Assigns the checklist
     *
     */
    public assign(): void {
        this.assigning = true;
        this.upcomingAuditDateTimeErrorMessage = null;

        // default to today at 00:00:00
        let startBy: string = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        let completeBy: string = null;

        if (this.upcomingAuditStartByDate) {
            startBy = moment(this.upcomingAuditStartByDate)
                .hour(this.convertHour(parseInt(this.upcomingAuditStartByHour, 10), this.upcomingAuditStartByMeridiem))
                .minute(parseInt(this.upcomingAuditStartByMinute, 10))
                .format('YYYY-MM-DD HH:mm:ss');
        }

        // if no time selected for completeBy - default to 23:59:59
        if (this.upcomingAuditCompleteByDate) {
            const hour: number = this.upcomingAuditCompleteByHour ? parseInt(this.upcomingAuditCompleteByHour, 10) : 23;
            const minute: number =
                this.upcomingAuditCompleteByMinute && this.upcomingAuditCompleteByHour
                    ? parseInt(this.upcomingAuditCompleteByMinute, 10)
                    : 59;
            const second: number = !this.upcomingAuditCompleteByHour && !this.upcomingAuditCompleteByMinute ? 59 : 0;
            const meridiem: string = this.upcomingAuditCompleteByMeridiem;

            completeBy = moment(this.upcomingAuditCompleteByDate)
                .hour(this.convertHour(hour, meridiem))
                .minute(minute)
                .second(second)
                .format('YYYY-MM-DD HH:mm:ss');
        }

        // ensure that the complete by date's time is NOT GT then the start by date's time
        if (completeBy) {
            const momentStartBy: Moment = moment(startBy, 'YYYY-MM-DD HH:mm:ss');
            const momentCompleteBy: Moment = moment(completeBy, 'YYYY-MM-DD HH:mm:ss');

            if (momentStartBy.diff(momentCompleteBy) > 0) {
                this.upcomingAuditDateTimeErrorMessage =
                    this.translations['CHECKLISTS.ERRORS.ASSIGNED_COMPLETE_BY_INVALID'];
                this.assigning = false;
                return;
            }
        }

        if (this.selectedLocations.length === 0) {
            this.toaster.warn(this.translations['CHECKLISTS.ERRORS.UNABLE_TO_ASSIGN']);
            this.assigning = false;
            return;
        }

        let payload: IAssignment = Assignment.make({
            checklistUuid: this.selectedChecklist.lastPublishedUuid,
            locations: this.selectedLocations,
            auditedBy: !this.selectedUser
                ? undefined
                : UuidDisplayNamePair.make({
                      uuid: this.selectedUser.uuid,
                      displayName: this.getUserDisplayText(this.selectedUser),
                  }),
            startBy: startBy,
            completeBy: completeBy,
            notes: this.notes,
            notificationGroup: this.notificationGroups,
        });

        this.checkApi
            .assignChecklist(payload)
            .then(() => {
                this.toaster.success(
                    this.translations['CHECKLISTS.MESSAGES.CHECKLIST_ASSIGNED'] +
                        '.  ' +
                        this.translations['CHECKLISTS.MESSAGES.LARGE_AUDIT_SIZE_WARNING'],
                    undefined,
                    5000
                );
                this.close();
            })
            .catch((error: IErrorResponse) => {
                this.toaster.warn(this.translations['CHECKLISTS.ERRORS.UNABLE_TO_ASSIGN']);
            })
            .finally(() => {
                this.assigning = false;
            });
    }

    /**
     * Provides the promise to more hierarchy locations from the access api
     *
     * @param {string} search
     * @param {IPager} pager
     * @return {ng.IPromise<PagedEntities>}
     */
    public findLocations(search: string, pager?: IPager): ng.IPromise<PagedEntities> {
        if (pager) {
            pager.sortBy = 'name';
            pager.order = 'asc';
        }

        return this.accessApi.fetchHierarchies(search, pager, {
            'filter[type]': 'location',
            include: 'location',
        });
    }

    /**
     * Select a user
     *
     * @param user
     */
    public selectUser(user: IUserListingItem): void {
        this.selectedUser = user;
    }

    /**
     * Provides the promise to more checklists from the check api
     *
     * @param {string} search
     * @param {IPager} pager
     * @return {angular.IPromise<PagedEntities>}
     */
    public findChecklists(search: string, pager?: IPager): ng.IPromise<PagedEntities> {
        if (pager) {
            pager.sortBy = 'name';
            pager.order = 'asc';
        }
        return this.checkApi.findChecklists(pager, search);
    }

    /**
     * Provides the promise to more users from the access api
     *
     * @param {string} search
     * @param {IPager} pager
     * @return {angular.IPromise<PagedEntities>}
     */
    public findUsers(search: string, pager?: IPager): ng.IPromise<PagedEntities> {
        if (pager) {
            pager.sortBy = 'first_name';
            pager.order = 'asc';
        } else {
            pager = Pager.make(1, 'first_name', 'asc');
        }

        return this.accessApi.findUsers(search, pager);
    }

    /**
     * Returns the text to display in the users listing
     *
     * @param {IUserListingItem} item
     * @return {string}
     */
    public getUserDisplayText(item: IUserListingItem): string {
        return item.firstName && item.lastName ? item.firstName + ' ' + item.lastName : item.email;
    }

    public handleShowDatepicker(): void {
        if (!this.showDatepicker) {
            this.upcomingAuditStartByDate = null;
            this.upcomingAuditStartByHour = null;
            this.upcomingAuditStartByMinute = null;
            this.upcomingAuditStartByMeridiem = null;
            this.upcomingAuditCompleteByDate = null;
            this.upcomingAuditCompleteByHour = null;
            this.upcomingAuditCompleteByMinute = null;
            this.upcomingAuditCompleteByMeridiem = null;
        }
    }

    /**
     * Open the location picker panel
     *
     */
    public openLocationsSelect(event: any): void {
        let config: any = {
            relativeTo: event.target,
            panelClass: 'its-filter-panel-child',
            fullscreen: this.media('xs'),
            multiSelect: true,
            onSelect: (location: IHierarchyNode) => {
                this.addLocation(location);
            },
            onDeselect: (location: IHierarchyNode) => {
                this.removeLocation(location);
            },
            showClose: true,
            allowAllOption: true,
            preSelect: this.selectedLocations.map((item) => item.uuid),
            attachQueryParams: { 'filter[status]': 'open' },
        };

        this.panelService.openLocationPickerPanel(config);
    }

    public addSelectedReadyGroup(group: IGroupListingItem): void {
        if (!group) {
            return;
        }

        if (!_.find(this.notificationGroups, { uuid: group.uuid })) {
            this.notificationGroups.push({ uuid: group.uuid, name: group.name });
        }
    }

    /**
     * Opens the group info modal
     *
     * @param groupUuid
     */
    public openGroupInfoModal(groupUuid: string): void {
        this.dialog.show(GroupDatalistModal.instantiate({ locals: { groupUuid: groupUuid } }));
    }

    private addLocation(location: IHierarchyNode): void {
        if (!_.find(this.selectedLocations, { uuid: location.uuid })) {
            this.selectedLocations.push({
                name: location.name,
                uuid: location.uuid,
            });
        }
    }

    private removeLocation(location: IHierarchyNode): void {
        _.remove(this.selectedLocations, { uuid: location.uuid });
    }

    /**
     * Convert a 12-hour number to a 24-hour number
     *
     * @param {number} hour
     * @param {string} meridiem
     *
     * @return number
     */
    private convertHour(hour: number, meridiem: string): number {
        if (meridiem === 'PM' && hour < 12) {
            hour += 12;
        } else if (meridiem === 'AM' && hour === 12) {
            hour = 0;
        }

        return hour;
    }
}
