import { IChecklist } from '../../domain/checklists/Checklist';
import { ICheckApi } from '../../api/CheckApi';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IPageService } from '../../services/PageService';
import { IItemSection } from '../../domain/checklists/ItemSection';
import { ICheckSession } from '../../services/CheckSession';
import { ChecklistService } from '../../services/ChecklistService';
import { ChecklistEditService } from '../../services/ChecklistEditService';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';

/**
 * Base class for checklist editor and settings editor controllers
 *
 */
export class BaseChecklist {
    public saving: boolean = false;
    public checklist: IChecklist;
    public accessType: string = null;
    public hasAclAdmin: boolean = false;
    public hasAclCreator: boolean = false;

    constructor(
        protected state: angular.ui.IStateService,
        protected checkApi: ICheckApi,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected translate: ng.translate.ITranslateService,
        protected session: ICheckSession,
        protected checklistService: ChecklistService,
        protected checklistEditService: ChecklistEditService
    ) {
        this.hasAclAdmin = this.session.getToken().getUser().hasAcl('check_admin');
        this.hasAclCreator = this.session.getToken().getUser().hasAcl('checklist_creator');
    }

    /**
     * Allows a user to save a new version of a checklist to the server
     *
     * @param updatedChecklist - a clone of the current checklist with the new changes to it
     * @param successMessage
     * @param errorMessage
     * @param updateChecklistRoute
     *
     */
    public save(
        updatedChecklist: IChecklist,
        successMessage?: string,
        errorMessage?: string,
        updateChecklistRoute?: string
    ): ng.IPromise<void> {
        return this.checklistEditService
            .saveChecklist(updatedChecklist)
            .then((checklist: IChecklist) => {
                if (successMessage) {
                    this.translate([successMessage]).then((translations) => {
                        this.toaster.success(translations[successMessage]);
                    });
                }
                this.updateChecklist(checklist);
            })
            .catch((error: IErrorResponse) => {
                if (error.type === 'ProposedRevisionAlreadyExistsException') {
                    this.toaster.warn('CHECKLISTS.ERRORS.PROPOSED_REVISION_ALREADY_EXISTS');
                } else if (error.type === 'ChecklistAlreadyExistsException') {
                    this.toaster.warn('CHECKLISTS.ERRORS.CHECKLIST_NAME_ALREADY_EXISTS');
                } else {
                    if (errorMessage) {
                        this.translate([errorMessage]).then((translations) => {
                            this.toaster.warn(translations[errorMessage]);
                        });
                    }
                }
            });
    }

    /**
     * Load checklist
     */
    protected load(): ng.IPromise<void> {
        this.pageService.showLoader(true);
        return this.checkApi
            .findChecklist(this.state.params['uuid'])
            .then((checklist: IChecklist) => {
                this.checklist = checklist.clone();
                this.checklistService.setChecklist(checklist);
            })
            .catch(() => {
                this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_LOAD');
                this.state.go('home.checklists.list.all');
            })
            .finally(() => {
                this.pageService.showLoader(false);
            });
    }

    /**
     * Updates the checklist to a new version
     *
     * @param {IChecklist} checklist
     * @param {IItemSection} selectedSection
     */
    protected updateChecklist(checklist: IChecklist, selectedSection?: IItemSection): void {
        this.checklistEditService.updateToLatestChecklistUrl(checklist, selectedSection);
        this.checklist = checklist.clone();
        this.checklistService.setChecklist(checklist);
    }
}
