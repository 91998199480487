import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItem } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItem';

@Component('its.check.module.checklists', AnswerDisplayTypeSetting.CID, {
    template:
        require('/app/modules/checklists/components/items/shared/answer-display-type-setting/AnswerDisplayTypeSetting.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class AnswerDisplayTypeSetting {
    static IID: string = 'AnswerDisplayTypeSetting';
    static CID: string = 'itcAnswerDisplayTypeSetting';

    static $inject: Array<string> = [];
    public item: BaseItem;
}
