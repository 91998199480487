import { Modal } from '@intouch/its.essential/app/essential/domain/Modal';
import { IItemReference } from '../../../domain/checklists/item-reference/ItemReference';
import { IChecklist } from '../../../domain/checklists/Checklist';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { ICheckApi } from '../../../api/CheckApi';
import View from './ItemReferenceDependencyWarningModal.html';

/**
 * A modal class for warning users of checklist question item reference dependencies
 *
 */
export class ItemReferenceDependencyWarningModal extends Modal {
    static $inject: Array<string> = ['$mdDialog', 'itcCheckApi', 'iteToaster'];

    public dependencies: Array<IItemReference>;
    public checklist: IChecklist;
    public localizedTitle: string; // translation key for the title
    public removingDependencies: boolean = false;

    /**
     * Create the instance of this modal
     *
     * @param config
     * @returns {any}
     */
    public static instantiate(config?: ng.material.IDialogOptions): any {
        config = config || {};
        config.template = View;
        config.controller = ItemReferenceDependencyWarningModal;

        return super.instantiate(config);
    }

    /**
     * Instantiates the class
     *
     * @param {angular.material.IDialogService} dialog
     * @param {ICheckApi} checkApi
     * @param {IToaster} toaster
     */
    constructor(private dialog: ng.material.IDialogService, private checkApi: ICheckApi, private toaster: IToaster) {
        super();
    }

    /**
     * Removes all dependencies from the checklist
     *
     */
    public removeAll(): void {
        this.removingDependencies = true;

        let originalChecklist: IChecklist = this.checklist.clone();
        this.checklist.removeDependencies(this.dependencies);

        this.checkApi
            .updateChecklist(this.checklist)
            .then((checklist: IChecklist) => {
                this.dialog.hide(checklist); // return new checklist
            })
            .catch((error) => {
                this.checklist = originalChecklist.clone(); // revert any changes made to cloned checklist from save
                this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_SAVE');
            })
            .finally(() => {
                this.removingDependencies = false;
            });
    }

    /**
     * Perform no action and close the modal
     *
     */
    public cancel(): void {
        this.dialog.cancel();
    }
}
