import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IPager, Pager } from '@intouch/its.essential/app/essential/domain/Pager';
import { PagedEntities } from '@intouch/its.essential/app/essential/domain/PagedEntities';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ICheckApi } from '../../api/CheckApi';
import { IOutcomeSetListing, OutcomeSetListing } from '../../domain/outcome-sets/OutcomeSetListing';
import { CreateOutcomeSetModal } from './modals/CreateOutcomeSetModal';
import { BaseOutcome } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseOutcome';
import { TranslateOutcomeSetModal } from './modals/TranslateOutcomeSetModal';
import { DatatableSearch } from '@intouch/its.essential/app/essential/domain/datatable/DatatableSearch';

@Controller('its.check.module.outcomes', ListingController.IID, ListingController)
class ListingController {
    static IID: string = 'its.check.module.outcomes.ListingController';
    static $inject: Array<string> = [
        '$translate',
        'itcCheckApi',
        '$state',
        '$mdDialog',
        'iteToaster',
        'iteAccessService',
    ];

    public isLoading: boolean = false;
    public hasError: boolean = false;
    public outcomeSets: Array<IOutcomeSetListing> = [];
    public datatableSearch: DatatableSearch = new DatatableSearch();
    private pager: IPager;

    /**
     * Create the controller
     *
     */
    constructor(
        private translate: ng.translate.ITranslateService,
        private checkApi: ICheckApi,
        private stateService: ng.ui.IStateService,
        private dialog: ng.material.IDialogService,
        private toaster: IToaster,
        private accessService: IAccessService
    ) {
        this.pager = Pager.make(
            this.stateService.params['page'],
            this.stateService.params['sort_by'],
            this.stateService.params['order']
        );
        this.datatableSearch.term = this.stateService.params['search'] || null;
        this.load();
    }

    /**
     * Load the triggers
     */
    public load(): void {
        this.isLoading = true;
        this.checkApi
            .findOutcomes(this.datatableSearch.term, this.pager)
            .then((pagedResponse: PagedEntities) => {
                this.pager = pagedResponse.getPager();
                this.outcomeSets = pagedResponse.getEntities();
            })
            .catch(() => {
                this.hasError = true;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    /**
     * Simple wrapper around access call to check for intouch membership yo
     *
     * @return {boolean}
     */
    public isIntouchUser(): boolean {
        return this.accessService.getToken().isIntouchUser();
    }

    public createOutcomeSet(): void {
        this.dialog.show(CreateOutcomeSetModal.instantiate()).then(() => {
            this.load();
        });
    }

    public openTranslationModal(outcomeSetListing: OutcomeSetListing): void {
        this.dialog.show(
            TranslateOutcomeSetModal.instantiate({
                locals: {
                    outcomeSetUuid: outcomeSetListing.uuid,
                },
            })
        );
    }

    public editOutcomeSet(outcomeToEdit: BaseOutcome): void {
        this.dialog
            .show(
                CreateOutcomeSetModal.instantiate({
                    locals: {
                        outcomeSetUuid: (<any>outcomeToEdit).uuid,
                    },
                })
            )
            .then(() => {
                this.load();
            });
    }

    /**
     * Allow a user to toggle the sort order of a column
     *
     * @param field
     */
    public toggleSort(field: string): void {
        if (this.pager.isCurrentSort(field)) {
            this.pager.toggleSortOrder();
        }
        this.pager.sortBy = field;
        this.goToSelf(this.pager);
    }

    /**
     * Allow a user to go to the next page of submissions
     */
    public next(): void {
        if (this.pager.canGoNext()) {
            this.pager.currentPage++;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Allow a user to go to the previous page of submissions
     */
    public prev(): void {
        if (this.pager.canGoPrevious()) {
            this.pager.currentPage--;
            this.goToSelf(this.pager);
        }
    }

    /**
     * Returns true if user is admin
     *
     * @returns {boolean}
     */
    public isAdmin(): boolean {
        return this.accessService.getToken().isAdmin();
    }

    public search(): void {
        this.pager.currentPage = 1;
        this.goToSelf(this.pager);
    }

    /**
     * @param pager
     */
    private goToSelf(pager: IPager): void {
        this.stateService
            .go(
                'home.outcomes',
                {
                    search: this.datatableSearch.term,
                    page: pager.currentPage,
                    sort_by: pager.sortBy,
                    order: pager.order,
                },
                {
                    notify: false, // prevent the events onStart and onSuccess from firing
                    reload: false, // prevent reload of the current state
                    location: 'replace', // replace the last record when changing the params so you don't hit the back button and get old params
                    inherit: true, // inherit the current params on the url
                }
            )
            .then(() => {
                this.load();
            });
    }
}
