import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { ITag } from '@intouch/its.essential/app/essential/domain/access/Tag';
import { IItemInput } from '../../../domain/checklists/ItemInput';
import { IChecklist } from '../../../domain/checklists/Checklist';
import { ISkipLogicArgument } from '@intouch/its.check.essential/app/check-essential/domain/checklists/logic/arguments/SkipLogicArgument';
import { ILogicValidator, LogicValidator } from '../../../domain/checklists/validation/LogicValidator';
import { SkipLogicArgumentFactory } from '@intouch/its.check.essential/app/check-essential/domain/checklists/logic/arguments/SkipLogicArgumentFactory';
import {
    LogicSkipTypes,
    LogicOperators,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/logic/LogicConstants';
import * as _ from 'lodash';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ICheckSession } from '../../../services/CheckSession';
import { SubscriptionPlanUtils } from '../../../utils/SubscriptionPlanUtils';
import { TimeUtils } from '@intouch/its.check.essential/app/check-essential/domain/utils/TimeUtils';
import { IPanelService } from '@intouch/its.essential/app/essential/services/PanelService';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';
import { currentAuditType } from '@intouch/its.check.essential/app/check-essential/domain/checklists/logic/scopes/CurrentAudit';
import { previousAuditType } from '@intouch/its.check.essential/app/check-essential/domain/checklists/logic/scopes/PreviousAudit';
import { anyAuditInRangeType } from '@intouch/its.check.essential/app/check-essential/domain/checklists/logic/scopes/AnyAuditInRange';
import { ILogic } from '@intouch/its.check.essential/app/check-essential/domain/checklists/logic/Logic';

/**
 * The UI for rendering skip conditions on a checklist item
 */
@Component('its.check.module.checklists', SkipConditions.CID, {
    template: require('/app/modules/checklists/components/SkipConditions.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
        checklist: '=',
        form: '<',
    },
})
class SkipConditions {
    static IID: string = 'SkipConditions';
    static CID: string = 'itsSkipConditions';

    static $inject: Array<string> = [
        'iteAccessService',
        'iteAccessApi',
        'itcCheckSession',
        'itePanelService',
        'APPCONFIG',
        '$window',
    ];

    public item: IItemInput;
    public checklist: IChecklist;
    public comparableItems: Array<IItemInput> = [];
    public selectedOperand: IItemInput = null;
    public form: any;
    public tag: ITag = null;
    public subscriptionPlan: string;
    public isAdmin: boolean = false;
    public isIntouchUser: boolean = false;
    public logicScopes: Array<string> = [currentAuditType, previousAuditType, anyAuditInRangeType];
    public anyAuditInRangeType: string = anyAuditInRangeType;
    public prevScopeType: string = null;

    public skipValues: Array<any> = [
        { name: 'CHECKLISTS.SKIP_VALUES.OK', value: 'ok' },
        { name: 'CHECKLISTS.SKIP_VALUES.NOT_OK', value: 'not-ok' },
    ];
    public skipOperators: Array<any> = [
        { name: 'CHECKLISTS.SKIP_OPERATORS.EQUAL_TO', value: LogicOperators.EQUAL },
        { name: 'CHECKLISTS.SKIP_OPERATORS.NOT_EQUAL_TO', value: LogicOperators.NOT_EQUAL },
        { name: 'CHECKLISTS.SKIP_OPERATORS.GREATER_THAN', value: LogicOperators.GREATER_THAN },
        { name: 'CHECKLISTS.SKIP_OPERATORS.LESS_THAN', value: LogicOperators.LESS_THAN },
        { name: 'CHECKLISTS.SKIP_OPERATORS.GREATER_THAN_EQUAL', value: LogicOperators.GREATER_THAN_EQUAL },
        { name: 'CHECKLISTS.SKIP_OPERATORS.LESS_THAN_EQUAL', value: LogicOperators.LESS_THAN_EQUAL },
    ];
    public skipActions: Array<any> = [
        { name: 'CHECKLISTS.SKIP_ACTIONS.SHOW', value: 'show' },
        { name: 'CHECKLISTS.SKIP_ACTIONS.HIDE', value: 'hide' },
    ];

    public skipVariableGroups: Array<{
        label: string;
        skipVariables: Array<{ value: string; label: string; type: string }>;
    }> = [];
    public hours: Array<number> = TimeUtils.getHours12();
    public minutes: Array<number> = TimeUtils.getMinutes();
    public argumentMeta: Array<Array<any>> = [];
    private availableArgumentValues: string[] = [];

    constructor(
        private accessService: IAccessService,
        private accessApi: IAccessApi,
        private session: ICheckSession,
        private panelService: IPanelService,
        private appConfig: any,
        private window: ng.IWindowService
    ) {
        this.setSkipVariableGroups(true);

        this.initArgumentSources();
        this.initArgumentMeta();
        this.subscriptionPlan = this.accessService.getToken().getProductBySlug('check').subPlan;
        this.isAdmin = this.session.getToken().getUser().isAdmin();
        this.isIntouchUser = this.session.getToken().getUser().isIntouchUser();
    }

    /**
     * Redirect to Access settings page
     *
     */
    public goToSettings(): void {
        this.window.location.href = this.appConfig.products.urls.access.root + 'settings/subscriptions';
    }

    public openTagPickerPanel(event: any, argument: any): void {
        event.stopImmediatePropagation();
        this.panelService.openTagPickerPanel({
            relativeTo: event.target,
            multiSelect: false,
            onSelect: (picked_tag) => {
                argument.label = picked_tag.name;
                this.setArgumentValue(picked_tag.uuid, argument);
            },
        });
    }

    public openAttributePickerPanel(event: any, argument: any): void {
        event.stopImmediatePropagation();
        this.panelService.openHierarchyAttributePickerPanel({
            relativeTo: event.target,
            multiSelect: false,
            onSelect: (picked_attribute) => {
                argument.key = picked_attribute.key;
            },
        });
    }

    /**
     * Updates the selected subject item object (the item to be watched to see if this.item will show or hide)
     *
     * @param logicIndex
     * @param argumentIndex
     * @param fieldName
     */
    public setSelectedSource(logicIndex: number, argumentIndex: number, fieldName: string): void {
        let argument: ISkipLogicArgument = this.item.logic[logicIndex].arguments[argumentIndex];
        let type: string = this.getSkipValueType(argument.subjectUuid);
        if (type) {
            let item: IItemInput = null;
            if (
                [
                    LogicSkipTypes.ITEM_OUTCOME,
                    LogicSkipTypes.ITEM_RESULT,
                    LogicSkipTypes.ITEM_TIME_RESULT,
                    LogicSkipTypes.ITEM_DATE_RESULT,
                    LogicSkipTypes.ITEM_NUMERICAL_RESULT,
                ].indexOf(type) > -1
            ) {
                item = this.checklist.findItem('uuid', argument.subjectUuid);
                argument = SkipLogicArgumentFactory.buildFromItemType(item.type);
                argument.setSubject(item);
                this.isSkipValid(this.form, fieldName);
            } else {
                // we have an audit level argument type (i.e. location tag)
                argument = SkipLogicArgumentFactory.build(type);
                this.clearFormError(this.form, fieldName);
            }
        }

        this.item.logic[logicIndex].arguments[argumentIndex] = argument;
    }

    /**
     * Remove Logic Argument
     *
     * @param logicIndex
     * @param argumentIndex
     */
    public removeLogicArgument(logicIndex: number, argumentIndex: number): void {
        this.item.removeLogicArgument(logicIndex, argumentIndex, true);
        if (this.argumentMeta[logicIndex]) {
            this.argumentMeta[logicIndex].splice(argumentIndex, 1);
        }
    }

    /**
     * Initialize argument sources
     *
     */
    public initArgumentSources(): void {
        if (this.item.logic && this.item.logic.length > 0) {
            for (let logic of this.item.logic) {
                if (logic.arguments && logic.arguments.length > 0) {
                    for (let argument of logic.arguments) {
                        let subject: IItemInput = _.find(this.comparableItems, { uuid: argument.subjectUuid });
                        if (subject) {
                            argument.setSubject(subject);
                        }
                    }
                }
            }
        }
    }

    /**
     * Initialize argument meta
     *
     */
    public initArgumentMeta(): void {
        this.argumentMeta = [];
        if (this.item.logic && this.item.logic.length > 0) {
            let logicIndex: number = 0;
            for (let logic of this.item.logic) {
                this.argumentMeta.push([]);

                if (logic.arguments && logic.arguments.length > 0) {
                    let argumentIndex: number = 0;

                    for (let argument of logic.arguments) {
                        this.setArgumentMeta(logicIndex, argumentIndex, argument);
                        argumentIndex++;
                    }
                }
                logicIndex++;
            }
        }
    }

    /**
     * Set the argument value
     *
     * @param value
     * @param argument
     */
    public setArgumentValue(value: any, argument: ISkipLogicArgument): void {
        argument.value = value;
    }

    public getSkipOperatorLabel(operator: string): Array<any> {
        return _.find(this.skipOperators, { value: operator }).name;
    }

    /**
     * Sets the time argument value
     *
     * @param meta
     * @param amPm
     */
    public setTimeArgumentValue(
        argument: ISkipLogicArgument,
        meta: { hours: number; minutes: number; amPm: string }
    ): void {
        argument.value = TimeUtils.toSeconds12hr(meta.hours, meta.minutes, meta.amPm);
    }

    /**
     * Get formatted minute
     *
     * @param minute
     */
    public getMinuteString(minute: number): string {
        if (minute < 10) {
            return '0' + minute;
        }
        return '' + minute;
    }

    public onScopeChange(logic: ILogic): void {
        if (this.prevScopeType === currentAuditType) {
            logic.action = 'hide';
        }

        logic.setScope(logic.scope.type);
        this.setComparableItems(logic.scope.type);

        logic.arguments.map((a) => {
            if (this.availableArgumentValues.indexOf(a.subjectUuid) === -1) {
                a.subjectUuid = null;
                a.value = null;
            }
        });
    }

    public setComparableItems(type: string): void {
        this.comparableItems = this.findComparableItems(type);
        this.setSkipVariableGroups(type === currentAuditType);
        this.prevScopeType = type;
        this.initArgumentSources();
        this.initArgumentMeta();
    }

    /**
     * Gets a list of items that can be used with skip logic
     *
     * @returns {Array<IItemInput>}
     */
    private findComparableItems(logicScopeType?: string): Array<IItemInput> {
        return <Array<IItemInput>>this.checklist.getInputItems(
            null,
            (i: IItemInput) => {
                return (
                    !i.disabled &&
                    i.allowAsLogicSubject() &&
                    (logicScopeType !== currentAuditType || i.uuid !== this.item.uuid)
                );
            },
            true
        );
    }

    /**
     * Set argument metadata
     *
     * @param logicIndex
     * @param argumentIndex
     * @param argument
     */
    private setArgumentMeta(logicIndex: number, argumentIndex: number, argument: ISkipLogicArgument): void {
        if (argument.type === LogicSkipTypes.ITEM_TIME_RESULT) {
            this.setTimeArgumentMeta(logicIndex, argumentIndex, argument);
        }
    }

    /**
     * Set time argument metadata
     *
     * @param logicIndex
     * @param argumentIndex
     * @param argument
     */
    private setTimeArgumentMeta(logicIndex: number, argumentIndex: number, argument: ISkipLogicArgument): void {
        this.argumentMeta[logicIndex][argumentIndex] = TimeUtils.to12hrTimeData(argument.value);
    }

    /**
     * Returns true if skip rule within the context of the checklist
     *
     * @param form
     * @param fieldName
     * @return {boolean}
     */
    private isSkipValid(form: any, fieldName: string): void {
        if (form && form[fieldName]) {
            let validator: ILogicValidator = new LogicValidator(this.checklist);

            validator.validate();

            let errorTypes: Array<string> = validator.getErrorTypesFound();

            if (errorTypes && errorTypes.length > 0) {
                for (let type of errorTypes) {
                    form[fieldName].$setValidity(type, false);
                }
            } else {
                this.clearFormError(form, fieldName);
            }
        }
    }

    /**
     * Clear validation errors on a form field
     *
     * @param form
     * @param fieldName
     */
    private clearFormError(form: any, fieldName: string): void {
        if (form && form[fieldName]) {
            for (let type of LogicValidator.getAllPossibleErrorTypes()) {
                form[fieldName].$setValidity(type, true);
            }
        }
    }

    /**
     * Set skip variable groups
     *
     */
    private setSkipVariableGroups(includeStatic: boolean): void {
        this.skipVariableGroups = [];
        this.availableArgumentValues = [];

        if (includeStatic) {
            this.skipVariableGroups = [
                {
                    label: 'GENERAL.LOCATION',
                    skipVariables: [
                        {
                            value: 'audit_location_tag',
                            label: 'CHECKLISTS.TAG',
                            type: LogicSkipTypes.AUDIT_LOCATION_TAG,
                        },
                        {
                            value: 'audit_location_node',
                            label: 'CHECKLISTS.NODE',
                            type: LogicSkipTypes.AUDIT_LOCATION_NODE,
                        },
                        {
                            value: 'audit_location_attribute',
                            label: 'CHECKLISTS.SKIP_LOCATION_ATTRIBUTE',
                            type: LogicSkipTypes.AUDIT_LOCATION_ATTRIBUTE,
                        },
                    ],
                },
            ];
            this.availableArgumentValues = this.skipVariableGroups[0].skipVariables.map((i) => i.value);
        }

        for (let section of this.checklist.sections) {
            let items: Array<IItemInput> = this.comparableItems.filter((i) => {
                return !!section.getItem(i.uuid);
            });

            if (items && items.length > 0) {
                this.skipVariableGroups.push({
                    label: section.name,
                    skipVariables: items.map((i) => {
                        this.availableArgumentValues.push(i.uuid);
                        const label: string = i.uuid === this.item.uuid ? this.item.name : i.name;
                        return {
                            value: i.uuid,
                            label: label,
                            type: SkipLogicArgumentFactory.buildFromItemType(i.type).type,
                        };
                    }),
                });
            }
        }
    }

    /**
     * Get skip type from subject uuid
     *
     * @param subjectUuid
     */
    private getSkipValueType(subjectUuid: string): string {
        for (let group of this.skipVariableGroups) {
            let item: { type: string } = _.find(group.skipVariables, { value: subjectUuid });
            if (!!item) {
                return item.type;
            }
        }
        return null;
    }
}
