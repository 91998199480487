import {
    IBaseSignature,
    BaseSignature,
} from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseSignature';

export interface ISignature extends IBaseSignature {}

export class Signature extends BaseSignature implements ISignature {
    /**
     * Can be used to create a generic signature
     *
     * @param name
     * @param image
     * @param type
     * @returns {ISignature}
     */
    public static make(name: string, image: string, type: string): ISignature {
        let me: ISignature = new Signature();
        me.data = image;
        me.name = name;
        me.type = type;
        me.signedAt = new Date().toISOString();

        return me;
    }
}
