import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IErrorResponse } from '@intouch/its.essential/app/essential/domain/ErrorResponse';
import { LanguageProvider, ILanguage } from '@intouch/its.check.essential/app/check-essential/domain/LanguageProvider';
import { ICheckApi } from '../../api/CheckApi';
import { IChecklist } from '../../domain/checklists/Checklist';
import { IPageService } from '../../services/PageService';

@Controller('its.check.module.checklists', ChecklistTranslationsController.IID, ChecklistTranslationsController)
class ChecklistTranslationsController {
    static IID: string = 'ChecklistTranslationsController';
    static $inject: Array<string> = ['$state', 'itcCheckApi', 'iteToaster', 'itcPageService', '$translate'];

    public saving: boolean = false;
    public noTranslatables: boolean = true;
    public checklist: IChecklist;
    public currentLanguage: ILanguage = null;
    public languages: Array<ILanguage> = [];
    public translations: any;

    constructor(
        private stateService: ng.ui.IStateService,
        private checkApi: ICheckApi,
        private toaster: IToaster,
        private pageService: IPageService,
        private translate: angular.translate.ITranslateService
    ) {
        if (!this.stateService.params['uuid']) {
            this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_LOAD');
            this.stateService.go('home.checklists.list.all');
        }

        this.pageService.showLoader(true);

        this.checkApi
            .findChecklist(this.stateService.params['uuid'])
            .then((checklist: IChecklist) => {
                this.languages = LanguageProvider.getSupportedLanguages();
                this.checklist = checklist;
                this.pageService.setPageTitle(
                    this.checklist.name + ' - ' + this.translate.instant('GENERAL.TRANSLATIONS')
                );
                this.noTranslatables = !this.hasTranslatables();
                this.currentLanguage = this.languages[0];
            })
            .catch(() => {
                this.toaster.warn('CHECKLISTS.ERRORS.UNABLE_TO_LOAD');
                this.stateService.go('home.checklists.list.all');
                return;
            })
            .finally(() => {
                this.pageService.showLoader(false);
            });

        this.translate(['CHECKLISTS.CHECKLIST_TITLE_TRANSLATION']).then((translations) => {
            this.translations = translations;
        });
    }

    public save(): void {
        this.checklist.saveTranslations();
        this.saving = true;

        // todo: probably don't need to clone the checklist, since we are not in a modal and don't have two controllers manipulating the same checklist
        this.checkApi
            .updateChecklist(this.checklist.clone())
            .then((checklist: IChecklist) => {
                this.checklist = checklist;

                this.stateService.go('home.checklists.edit', { uuid: this.checklist.uuid });
            })
            .catch((error: IErrorResponse) => {
                if (error.type === 'ProposedRevisionAlreadyExistsException') {
                    this.toaster.warn('CHECKLISTS.ERRORS.PROPOSED_REVISION_ALREADY_EXISTS');
                } else {
                    this.translate(['TRANSLATIONS.ERRORS.UNABLE_TO_SAVE_TRANSLATIONS']).then((translations) => {
                        this.toaster.warn(translations['TRANSLATIONS.ERRORS.UNABLE_TO_SAVE_TRANSLATIONS']);
                    });
                }
            })
            .finally(() => {
                this.saving = false;
            });
    }

    /**
     * Returns user to checklists edit screen
     */
    public cancel(): void {
        this.stateService.go('home.checklists.edit', { uuid: this.checklist.uuid });
    }

    /**
     * Determines if we are loading data
     *
     * @returns {boolean}
     */
    public isLoading(): boolean {
        return this.pageService.loaderVisible;
    }

    /**
     * Checks if checklist has translatable objects (if a section exists)
     *
     * @returns {boolean}
     */
    private hasTranslatables(): boolean {
        return this.checklist.sections && this.checklist.sections.length > 0;
    }
}
