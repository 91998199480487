import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IBaseItemResultBuilder } from '@intouch/its.check.essential/app/check-essential/domain/audits/BaseItemResultBuilder';
import { IItemResult, ItemResult } from './ItemResult';
import { IBaseItemInput } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { IItemInput } from '../checklists/ItemInput';

/**
 * Builder class to build ItemResult objects from json
 *
 */
export class ItemResultBuilder implements IBaseItemResultBuilder {
    /**
     * Build an ItemResult object from json
     *
     * @param jsonObject
     * @return {IItemResult}
     */
    public build(jsonObject: any): IItemResult {
        return EntityBuilder.buildOne<IItemResult>(ItemResult, jsonObject, true);
    }

    /**
     * Builds many IItemResults
     *
     * @param {Array<any>} jsonObject
     * @return {Array<IItemResult>}
     */
    public buildMany(jsonObject: Array<any>): Array<IItemResult> {
        let items: Array<IItemResult> = [];

        for (let item of jsonObject) {
            items.push(this.build(item));
        }

        return items;
    }

    /**
     * Makes an instance of IItemResult
     *
     * @param {IBaseItemInput} input
     * @param {boolean} enableAutoAction
     * @return {IItemResult}
     */
    public make(input: IBaseItemInput, enableAutoAction?: boolean): IItemResult {
        return ItemResult.make(<IItemInput>input);
    }
}
