import * as angular from 'angular';

let module: any = angular.module('its.check.module.audits', []);

import './_configuration';

import './DashboardController';
import './ViewController';
import './components/AuditProgressBar';

export default module;
