import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemSettingsComponent } from '../BaseItemSettingsComponent';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { ChecklistService } from '../../../../../services/ChecklistService';
import { IChecklist } from '../../../../../domain/checklists/Checklist';
import { CalculationType } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemCalculation';
import { ItemCalculation } from '../../../../../domain/checklists/ItemCalculation';
import { ItemNumber } from '../../../../../domain/checklists/ItemNumber';
import { ItemTime } from '../../../../../domain/checklists/ItemTime';
import { IBaseItemInput } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { IItemSection } from '../../../../../domain/checklists/ItemSection';
import { Calculation } from '../../../../../domain/checklists/Calculation';

@Component('its.check.module.checklists', CalculationSettings.CID, {
    template: require('/app/modules/checklists/components/items/calculation/CalculationSettings.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class CalculationSettings extends BaseItemSettingsComponent {
    static IID: string = 'CalculationSettings';
    static CID: string = 'itcCalculationSettings';

    static $inject: Array<string> = ['itcChecklistService', '$scope'];
    public item: ItemCalculation;
    public calculatedItems: Array<ItemCalculation | ItemNumber | ItemTime> = [];
    public checklist: IChecklist = null;
    public calculationTypes: typeof CalculationType = CalculationType;
    public operatorOptions: any = {
        [CalculationType.NUMBER]: ['+', '-', '/', '*'],
        [CalculationType.TIME]: ['+', '-'],
    };

    public constructor(private checklistService: ChecklistService, private scope: ng.IScope) {
        super();
        this.subscribeToChecklist();
    }

    public $onInit(): void {
        this.calculatedItems = this.getCalculatedItems();
    }

    public addCalculation(type: string): void {
        this.item.addCalculation(Calculation.make(type, null));
    }

    private getCalculatedItems(): Array<ItemCalculation | ItemNumber | ItemTime> {
        let items: Array<ItemCalculation | ItemNumber | ItemTime> = <Array<ItemCalculation | ItemNumber | ItemTime>>(
            this.checklist.getInputItems(null, (item: IBaseItemInput) => {
                let filterTypes: Array<string> = [];

                if (
                    !this.item.settings.calculationType ||
                    this.item.settings.calculationType === CalculationType.NUMBER
                ) {
                    filterTypes = ['calculation', 'number'];
                } else {
                    filterTypes = ['time'];
                }

                return filterTypes.indexOf(item.type) !== -1 && item.uuid !== this.item.uuid;
            })
        );

        for (let item of items) {
            let section: IItemSection = this.checklist.getParentSection(item);
            if (section) {
                (<any>item).sectionName = section.name;
            }
        }

        return items;
    }

    private subscribeToChecklist(): void {
        const checklistSubscription: SubjectSubscription = this.checklistService.checklistObserver.subscribe(
            (checklist) => {
                this.checklist = checklist;
            }
        );
        checklistSubscription.unsubscribeOnScopeDestruction(this.scope);
    }
}
