import { IItemInput } from '../../../../domain/checklists/ItemInput';

export class BaseItemTranslation {
    static $inject: Array<string> = ['$translate'];

    public itemLabel: string;
    public descLabel: string;
    public photoLabel: string;

    public locale: string;
    public item: IItemInput;

    /**
     * The class constructor
     *
     * @param translate
     */
    constructor(public translate: angular.translate.ITranslateService) {
        this.translate(['TRANSLATIONS.ITEM.DESCRIPTION', 'TRANSLATIONS.ITEM.IMAGE']).then((translations) => {
            this.descLabel = translations['TRANSLATIONS.ITEM.DESCRIPTION'];
            this.photoLabel = translations['TRANSLATIONS.ITEM.IMAGE'];
        });
    }
}
