import {
    IBaseListingItem,
    BaseListingItem,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseListingItem';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IOutcome, Outcome } from './Outcome';
import { IChecklist } from './Checklist';
import { IScheduleSettings, ScheduleSettings } from './SchedulerSettings';

/**
 * Interface to ListingItem class
 *
 */
export interface IListingItem extends IBaseListingItem {
    publishable: boolean;
    outcomes?: Array<IOutcome>;
    scheduler?: IScheduleSettings;
    outcomeType: string;
    hasSchedule: boolean;
    createdByUuid: string;
    recordCount: number;
    averageScore: number;

    isAssignable(): boolean;

    hasUnpublishedVersion(): boolean;

    canStart(): boolean;

    isDisabledState(): boolean;

    isEmptyState(): boolean;

    isPendingState(): boolean;

    isPublishedState(): boolean;
}

/**
 * The ListingItem class
 * Used for holding data for list items of a checklist list
 *
 */
export class ListingItem extends BaseListingItem implements IListingItem {
    public publishable: boolean = null;
    public outcomes: Array<IOutcome>;
    public scheduler: IScheduleSettings;
    public outcomeType: string = null;
    public hasSchedule: boolean;
    public createdByUuid: string = null;
    public recordCount: number = null;
    public averageScore: number = null;

    public constructor(checklist?: IChecklist) {
        super(checklist);

        if (checklist) {
            this.scheduler = checklist.scheduler;
        }
    }

    /**
     * Builds a listing item from json
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @return {IListingItem}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IListingItem {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['outcomes']) {
            this.outcomes = EntityBuilder.buildMany<IOutcome>(Outcome, jsonObject['outcomes'], convertToCamel);
        }

        if (jsonObject['created_by']) {
            this.createdByUuid = jsonObject['created_by'].uuid;
        }

        if (jsonObject.scheduler) {
            this.hasSchedule = true;
            this.scheduler = EntityBuilder.buildOne<IScheduleSettings>(
                ScheduleSettings,
                jsonObject.scheduler,
                convertToCamel
            );
        }

        // leave commented out until checklist outcome labels are implemented
        // if (jsonObject.outcomes && jsonObject.outcomes.length > 0) {
        //    this.outcomeType = jsonObject.type;
        // }

        return this;
    }

    /**
     * Check for publishable variable along with parent
     *
     * @returns {boolean}
     */
    public isPublishable(): boolean {
        return super.isPublishable() && this.publishable;
    }

    /**
     * Returns true if checklist is in a state where it can be assigned to a user
     *
     * @return {boolean}
     */
    public isAssignable(): boolean {
        return this.lastPublishedUuid !== null;
    }

    public hasUnpublishedVersion(): boolean {
        return !!this.lastPublishedUuid && this.lastPublishedUuid !== this.uuid;
    }

    public canStart(): boolean {
        return this.active && !!this.lastPublishedUuid;
    }

    public isDisabledState(): boolean {
        return !this.active;
    }

    public isEmptyState(): boolean {
        return !this.publishable;
    }

    public isPendingState(): boolean {
        return this.status === 'proposed' && !this.isDisabledState();
    }

    public isPublishedState(): boolean {
        return this.isPublished() && !this.isDisabledState();
    }
}
