import {
    IBaseOutcome,
    BaseOutcome,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseOutcome';
import {
    IOutcomeTranslation,
    OutcomeTranslation,
} from '@intouch/its.check.essential/app/check-essential/domain/translations/OutcomeTranslations';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';

/**
 * The IOutcome interface
 */
export interface IOutcome extends IBaseOutcome {
    uuid: string;

    setOutcomeType(type: string): void;
}

/**
 * The Outcome class
 */
export class Outcome extends BaseOutcome implements IOutcome {
    public uuid: string = null;

    /**
     * Builds a new instance of outcome
     *
     * @param {string} label
     * @return {IBaseOutcome}
     */
    public static make(label: string): IBaseOutcome {
        let outcome: IOutcome = new Outcome();
        outcome.label = label;
        return outcome;
    }

    /**
     * Builds an instance of IOutcome from json
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @return {IOutcome}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IOutcome {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject.translations) {
            if (jsonObject.translations instanceof Array) {
                this.translations = EntityBuilder.buildMany<IOutcomeTranslation>(
                    OutcomeTranslation,
                    jsonObject.translations,
                    convertToCamel
                );
            } else {
                this.translations = [
                    EntityBuilder.buildOne<IOutcomeTranslation>(
                        OutcomeTranslation,
                        jsonObject.translations,
                        convertToCamel
                    ),
                ];
            }
        }

        return this;
    }

    /**
     * Sets the outcome type
     *
     * @param {string} type
     */
    public setOutcomeType(type: string): void {
        this.outcomeType = type;
    }
}
