import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { IItemInput, ItemInput } from './ItemInput';
import {
    IItemInputSettings,
    ItemInputSettings,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { IWithAdditionalRequirements } from './WithAdditionalRequirements';
import { IItemAdditionalRequirements, ItemAdditionalRequirements } from './ItemAdditionalRequirements';

/**
 * The IItemTimeSettings interface
 *
 */
export interface IItemTimeSettings extends IItemInputSettings {
    optional: boolean;
    displayItemLabel: boolean;
}

/**
 * The IItemTime interface
 *
 */
export interface IItemTime extends IItemInput {
    settings: IItemTimeSettings;
}

/**
 * The ItemTimeSettings class
 *
 */
export class ItemTimeSettings extends ItemInputSettings implements IItemTimeSettings {
    public optional: boolean = false;
    public displayItemLabel: boolean = false;
}

/**
 * The ItemText class
 *
 */
export class ItemTime extends ItemInput implements IItemTime, IWithAdditionalRequirements {
    public type: string = 'time';
    public settings: IItemTimeSettings = new ItemTimeSettings();
    public additionalRequirements: IItemAdditionalRequirements = null;

    /**
     * Make a generic instance of this object
     *
     * @param name
     * @returns {IItemText}
     */
    public static make(name: string): IItemTime {
        let me: IItemTime = new ItemTime();
        me.name = name;
        me.settings.allowCnaNa = false;

        return me;
    }

    /**
     * Returns if object is allowed as a logic subject
     *
     */
    public allowAsLogicSubject(): boolean {
        return true;
    }

    /**
     * Check if the Display Item Label is of type display_text
     *  @returns {boolean}
     */
    public displayItemLabel(): boolean {
        return this.settings.displayItemLabel;
    }

    /**
     * Builds the object from json
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {ItemText}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IItemTime {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['settings']) {
            this.settings = EntityBuilder.buildOne<IItemTimeSettings>(
                ItemTimeSettings,
                jsonObject['settings'],
                convertToCamel
            );
        }

        if (jsonObject['additional_requirements']) {
            this.additionalRequirements = EntityBuilder.buildOne<IItemAdditionalRequirements>(
                ItemAdditionalRequirements,
                jsonObject['additional_requirements'],
                convertToCamel
            );
        } else if (jsonObject['photo_required']) {
            this.additionalRequirements = new ItemAdditionalRequirements().fromJson(
                {
                    type: 'photo',
                    condition: 'always',
                },
                true
            );
            delete (<any>this).photoRequired;
        }

        return this;
    }
}
