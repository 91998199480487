import { Service } from '@intouch/its.essential/app/essential/decorators/Service';
import { ISessionService } from '@intouch/its.essential/app/essential/services/SessionService';
import { IConfigurable } from '@intouch/its.essential/app/essential/services/IConfigurable';

export interface INavigationState {
    name: string;
    params: any;
}

/**
 * The navigation service interface
 */
export interface INavigationService extends IConfigurable {
    get(): INavigationState;

    set(state: INavigationState): INavigationState;

    goBack(): void;

    goHome(): void;
}

/**
 * The navigation service class
 */
@Service('its.check.services', NavigationService.IID, NavigationService)
class NavigationService implements INavigationService {
    static IID: string = 'itcNavigationService';
    static $inject: Array<string> = ['iteSessionService', '$rootScope', '$state'];

    private blacklist: Array<string> = ['login-callback', 'trial-expired', 'account-locked'];

    public constructor(
        private session: ISessionService,
        private rootScope: ng.IRootScopeService,
        private stateService: ng.ui.IStateService
    ) {}

    public configure(data: any = null): void {
        this.session.flush('navigation_state');

        this.rootScope.$on('$stateChangeStart', () => {
            this.set({
                name: this.stateService.current.name,
                params: angular.copy(this.stateService.params),
            });
        });
    }

    public get(): INavigationState {
        return this.session.get<INavigationState>('navigation_state');
    }

    public set(state: INavigationState): INavigationState {
        if (this.stateService.current.name && this.blacklist.indexOf(this.stateService.current.name) === -1) {
            return this.session.set<INavigationState>('navigation_state', state);
        } else {
            return null;
        }
    }

    public goBack(): void {
        let state: INavigationState = this.get();
        if (state && state.name) {
            this.stateService.go(state.name, state.params);
        } else {
            this.goHome();
        }
    }

    public goHome(): void {
        this.stateService.go('home.checklists.list.all');
    }
}
