'use strict';
let module: any = angular.module('its.check.services', ['its.essential.http']);

import './PageService';
import './AuditService';
import './CheckSession';
import './AuditProviderService';
import './ChecklistService';
import './NavigationService';
import './QuestionTooltipService';
import './ChecklistEditService';
import './ChecklistTranslationExportService';
import './ProductNavigationService';
import './ShepherdService';

export default module;
