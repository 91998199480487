import * as _ from 'lodash';
import { NotEnumerable } from '@intouch/its.essential/app/essential/decorators/NotEnumerable';
import { EntityBuilder } from '@intouch/its.essential/app/essential/domain/EntityBuilder';
import { NumberUtils } from '@intouch/its.essential/app/essential/utils/NumberUtils';

import { ISettings, Settings } from './Settings';
import { IBaseItem } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItem';
import { IItemSection, ItemSection } from './ItemSection';
import { IItemInput } from './ItemInput';
import { IOutcome, Outcome } from './Outcome';
import {
    IUuidDisplayNamePair,
    UuidDisplayNamePair,
} from '@intouch/its.check.essential/app/check-essential/domain/datatypes/UuidDisplayNamePair';
import { IAuditPhoto } from '@intouch/its.check.essential/app/check-essential/domain/audits/AuditPhoto';
import { IScheduleSettings, ScheduleSettings } from './SchedulerSettings';
import {
    IBaseChecklist,
    BaseChecklist,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseChecklist';
import { IBaseItemInput } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItemInput';
import { IItemReference } from './item-reference/ItemReference';
import {
    IBaseChecklistTranslation,
    BaseChecklistTranslation,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/translations/BaseChecklistTranslation';
import { IUuidNamePair } from '@intouch/its.check.essential/app/check-essential/domain/datatypes/UuidNamePair';
import { ObjectUtils } from '@intouch/its.essential/app/essential/utils/ObjectUtils';
import { ChecklistStatus } from './enums/ChecklistStatus';

/**
 * The checklist interface
 */
export interface IChecklist extends IBaseChecklist {
    settings: ISettings;
    sections: IItemSection[];
    outcomes: IOutcome[];
    scheduler: IScheduleSettings;
    outcomeSetId: number;

    // unit_of_audit
    enableItem(uuid: string): IChecklist;

    disableItem(uuid: string): IChecklist;

    moveItem(item: IItemInput, toIndex: number): IChecklist;

    removeItem(item: IItemInput): IChecklist;

    addSection(section: IItemSection, index?: number): IItemSection;

    disableSection(uuid: string): IChecklist;

    enableSection(uuid: string): IChecklist;

    findItem(property: string, value: any): IItemInput;

    findSection(property: string, value: any): IItemSection;

    getInputItems(
        section?: IItemSection,
        typesFilter?: Array<string> | Function,
        excludeDisabledSections?: boolean
    ): Array<IBaseItemInput>;

    getUnfilteredSections(): Array<IItemSection>;

    getParentSection(item: IBaseItem): IItemSection;

    getSectionIndexByUuid(uuid: string): number;

    isPublishable(): boolean;

    moveSection(section: IItemSection, fromIndex: number, toIndex: number): IChecklist;

    removeSection(section: IItemSection): IChecklist;

    updateSection(section: IItemSection, index?: number): IChecklist;

    getPhotosToUpload(): IAuditPhoto[];

    clone(): IChecklist;

    hasApproverGroups(): boolean;

    removeDependencies(dependencies: Array<IItemReference>): void;

    hasAccessGroup(uuid: string): boolean;

    addAccessGroup(group: IUuidNamePair): IChecklist;

    removeAccessGroup(index: number): IChecklist;

    hasAccessTag(uuid: string): boolean;

    addAccessTag(tag: IUuidNamePair): IChecklist;

    removeAccessTag(index: number): IChecklist;

    hasAccessLocation(uuid: string): boolean;

    addAccessLocation(location: IUuidNamePair): IChecklist;

    removeAccessLocation(index: number): IChecklist;

    hasOutcomeAssigned(): boolean;

    isNewChecklist(): boolean;

    hasUnpublishedVersion(): boolean;
}

/**
 * A user object
 */
export class Checklist extends BaseChecklist implements IChecklist {
    public settings: ISettings = null;
    public sections: IItemSection[] = [];
    public outcomes: IOutcome[] = [];
    public outcomeType: string = null;
    public scheduler: IScheduleSettings = new ScheduleSettings();
    public outcomeSetId: number = null;

    @NotEnumerable protected undoStack: Array<Function> = [];

    public constructor() {
        super();
    }

    /**
     * Build this object from JSON
     *
     * @param jsonObject
     * @param convertToCamel
     * @returns {Checklist}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IChecklist {
        super.fromJson(jsonObject, true);

        if (jsonObject['created_by']) {
            this.createdBy = EntityBuilder.buildOne<IUuidDisplayNamePair>(
                UuidDisplayNamePair,
                jsonObject['created_by'],
                convertToCamel
            );
        }

        if (jsonObject['updated_by']) {
            this.updatedBy = EntityBuilder.buildOne<IUuidDisplayNamePair>(
                UuidDisplayNamePair,
                jsonObject['updated_by'],
                convertToCamel
            );
        }

        if (jsonObject['settings']) {
            this.settings = EntityBuilder.buildOne<ISettings>(Settings, jsonObject['settings'], convertToCamel);
        }

        if (jsonObject['sections']) {
            this.sections = EntityBuilder.buildMany<IItemSection>(ItemSection, jsonObject['sections'], convertToCamel);
        }

        if (jsonObject['outcomes']) {
            this.outcomes = EntityBuilder.buildMany<IOutcome>(Outcome, jsonObject['outcomes'], convertToCamel);
        }

        if (jsonObject['outcome_type']) {
            this.outcomeType = jsonObject['outcome_type'];
        }

        if (jsonObject['notification_group']) {
            this.notificationGroup = jsonObject['notification_group'];
        }

        if (jsonObject['scheduler']) {
            this.scheduler = EntityBuilder.buildOne<IScheduleSettings>(
                ScheduleSettings,
                jsonObject['scheduler'],
                convertToCamel
            );
        }

        if (jsonObject['translations']) {
            this.translations = EntityBuilder.buildMany<IBaseChecklistTranslation>(
                BaseChecklistTranslation,
                jsonObject['translations'],
                convertToCamel
            );
        }

        this.recalculatePoints();

        return this;
    }

    /**
     * Enables the item matching given uuid
     *
     * @param {string} uuid
     * @return {IChecklist}
     */
    public enableItem(uuid: string): IChecklist {
        let updatedItem: IItemInput = this.findItem('uuid', uuid);
        updatedItem.disabled = false;
        return this;
    }

    /**
     * Disables the item matching given uuid
     *
     * @param {string} uuid
     * @return {IChecklist}
     */
    public disableItem(uuid: string): IChecklist {
        let updatedItem: IItemInput = this.findItem('uuid', uuid);
        updatedItem.disabled = true;
        return this;
    }

    /**
     * Removes the item from its section in the checklist
     *
     * @param {IItemInput} item
     * @return {IChecklist}
     */
    public removeItem(item: IItemInput): IChecklist {
        let section: IItemSection = this.getParentSection(item);

        if (section) {
            section.removeItem(item);
        }

        return this;
    }

    /**
     * Moves an item in a section from its current position to the provided new position
     *
     * @param {IItemInput} item
     * @param {number} toIndex
     * @return {IChecklist}
     */
    public moveItem(item: IItemInput, toIndex: number): IChecklist {
        let section: IItemSection = this.getParentSection(item);

        if (section) {
            let fromIndex: number = _.findIndex(section.items, (i: IItemInput) => {
                return i.uuid === item.uuid;
            });
            if (fromIndex > -1 && toIndex > -1 && toIndex <= section.items.length) {
                section.items.splice(fromIndex, 1);
                section.items.splice(toIndex, 0, item); // need to rebuild to remake of object type
            }
        }

        return this;
    }

    /**
     * Adds a new section to this checklist
     *
     * @param section
     * @param index
     * @returns {IItemSection}
     */
    public addSection(section: IItemSection, index?: number): IItemSection {
        if (NumberUtils.isNumber(index)) {
            this.sections.splice(index, 0, section);
        } else {
            this.sections.push(section);
        }

        return section;
    }

    /**
     * Allow for a section and its children to be disabled
     *
     * @param uuid
     * @returns {IChecklist}
     */
    public disableSection(uuid: string): IChecklist {
        let section: IItemSection = this.findSection('uuid', uuid);
        if (section) {
            // disable the section
            section.disabled = true;

            // disable all items in the section
            if (section.items) {
                for (let item of section.items) {
                    item.disabled = true;
                }
            }
        }

        return this;
    }

    /**
     * Allow for a section and its children to be enabled
     *
     * @param uuid
     * @returns {IChecklist}
     */
    public enableSection(uuid: string): IChecklist {
        let section: IItemSection = this.findSection('uuid', uuid);
        if (section) {
            // enable the section
            section.disabled = false;

            // enable all items in the section
            if (section.items) {
                for (let item of section.items) {
                    item.disabled = false;
                }
            }
        }

        return this;
    }

    /**
     * Search the items in the section list and returns an item with matching value at property.
     * Returns undefined in not found
     *
     * @param property
     * @param value
     * @returns {IItemInput}
     */
    public findItem(property: string, value: any): IItemInput {
        if (this.sections && this.sections.length > 0) {
            for (let section of this.sections) {
                let item: IItemInput = section.findItem(property, value);
                if (item) {
                    return item;
                }
            }
        }

        return undefined;
    }

    /**
     * Returns a section with matching property and value
     *
     * @param property
     * @param value
     * @returns {IItemSection}
     */
    public findSection(property: string, value: any): IItemSection {
        return _.find(this.sections, (s: IItemSection) => {
            return s[property] === value;
        });
    }

    /**
     * Will get all input based items (i.e. not sections)
     *
     * @param section
     * @param typesFilter
     * @param excludeDisabledSections
     * @returns {Array<string>|Function}
     */
    public getInputItems(
        section?: IItemSection,
        typesFilter?: Array<string> | Function,
        excludeDisabledSections: boolean = false
    ): Array<IBaseItemInput> {
        let items: Array<IItemInput> = [];

        let filter: Function;
        if (typesFilter && typesFilter instanceof Array) {
            filter = (i: IItemInput) => {
                return !typesFilter || (typesFilter && typesFilter.indexOf(i.type) > -1);
            };
        } else if (typesFilter && typeof typesFilter === 'function') {
            filter = <Function>typesFilter;
        } else {
            filter = null;
        }

        if (section) {
            let sectionItems: Array<IItemInput> = section.getItems(filter);
            if (sectionItems) {
                items = sectionItems;
            }
        } else if (this.sections && this.sections.length) {
            for (let entry of this.sections) {
                if (!excludeDisabledSections || (excludeDisabledSections && !entry.disabled)) {
                    let sectionItems: Array<IItemInput> = entry.getItems(filter);
                    if (sectionItems) {
                        items = _.concat(items, sectionItems);
                    }
                }
            }
        }

        return items;
    }

    /**
     * Returns an array of all enabled sections in the checklist
     *
     * @return {Array<IItemSection>}
     */
    public getSections(): Array<IItemSection> {
        let sections: Array<IItemSection> = [];

        if (this.sections && this.sections.length > 0) {
            for (let section of this.sections) {
                if (!section.isDisabled()) {
                    sections.push(section);
                }
            }
        }

        return sections;
    }

    /**
     * Gets all sections, regardless of disabled or other
     *
     * @return {Array<IItemSection>}
     */
    public getUnfilteredSections(): Array<IItemSection> {
        return this.sections;
    }

    /**
     * Returns the section that an item belongs to or null if not found
     *
     * @param item
     * @returns {any}
     */
    public getParentSection(item: IBaseItem): IItemSection {
        if (this.sections && this.sections.length > 0) {
            for (let section of this.sections) {
                let index: number = section.getItemIndexByUuid(item.uuid);
                if (index > -1) {
                    return section;
                }
            }
        }

        return null;
    }

    /**
     * Returns the index in the sections array of the section matching the uuid, or -1 if not found
     *
     * @param uuid
     */
    public getSectionIndexByUuid(uuid: string): number {
        return _.findIndex(this.sections, (s: IItemSection) => {
            return s.uuid === uuid;
        });
    }

    /**
     * Indicates if this checklist is a proposal of a new version or not
     *
     * @returns {boolean}
     */
    public isPublishable(): boolean {
        return (
            this.status === ChecklistStatus.Proposed &&
            this.getInputItems(null, (item: IItemInput) => {
                return !item.disabled;
            }).length > 0
        );
    }

    /**
     * Moves a sections to a new location in the sections array
     *
     * @param section
     * @param direction
     */
    public moveSection(section: IItemSection, fromIndex: number, toIndex: number): IChecklist {
        if (!this.sections || this.sections.length <= 1) {
            return;
        }
        this.sections.splice(fromIndex, 1);
        this.sections.splice(toIndex, 0, section);
        return this;
    }

    /**
     * Will recalculate all of the points for this checklist -- including sections
     *
     * @returns {number}
     */
    public recalculatePoints(): void {
        this.totalPoints = 0;
        for (let section of this.sections) {
            this.totalPoints += section.calculatePoints();
        }
    }

    /**
     * Will remove a checklist section and all of its child elements
     *
     * @param section
     */
    public removeSection(section: IItemSection): IChecklist {
        let index: number = this.getSectionIndexByUuid(section.uuid);
        if (index > -1) {
            this.sections.splice(index, 1);
            this.recalculatePoints();
        }

        return this;
    }

    /**
     * Will update an existing section in this checklist
     *
     * @param section
     * @param index
     * @returns {IChecklist}
     */
    public updateSection(section: IItemSection, index?: number): IChecklist {
        index = NumberUtils.isNumber(index) ? index : this.getSectionIndexByUuid(section.uuid);

        if (!NumberUtils.isNumber(index) || index < 0) {
            throw new Error('Unable to locate the section to update in this checklist');
        } else {
            this.sections[index] = section;
        }

        return this;
    }

    /**
     * Returns an array of photos that require uploading
     *
     * @return {IAuditPhoto[]}
     */
    public getPhotosToUpload(): IAuditPhoto[] {
        let photos: IAuditPhoto[] = [];

        for (let section of this.sections) {
            photos = photos.concat(section.getPhotosToUpload());
        }

        return photos;
    }

    /**
     * Overrides IEntity clone method to return an IChecklist typed clone
     *
     * @return {IChecklist}
     */
    public clone(): IChecklist {
        return <IChecklist>ObjectUtils.cloneObject(this);
    }

    /**
     * Determines if the checklist has an approver group set.
     *
     * @returns {boolean}
     */
    public hasApproverGroups(): boolean {
        return !!this.approverGroups && this.approverGroups.length > 0;
    }

    /**
     * Removes skip and calculation dependencies from items
     *
     * @param {Array<IItemReference>} dependencies
     */
    public removeDependencies(dependencies: Array<IItemReference>): void {
        for (let dependency of dependencies) {
            dependency.removeDependency();
        }
    }

    /**
     * Returns if the checklist has an access group matching uuid
     *
     * @param {string} uuid
     * @return {boolean}
     */
    public hasAccessGroup(uuid: string): boolean {
        return !!_.find(this.accessGroups || [], { uuid: uuid });
    }

    /**
     * Adds an access group
     *
     * @param {IUuidNamePair} group
     * @return {IChecklist}
     */
    public addAccessGroup(group: IUuidNamePair): IChecklist {
        if (group && !this.hasAccessGroup(group.uuid)) {
            this.accessGroups.push(group);
        }

        return this;
    }

    /**
     * Removes an access group
     *
     * @param {number} index
     * @return {IChecklist}
     */
    public removeAccessGroup(index: number): IChecklist {
        if (this.accessGroups && index > -1 && index < this.accessGroups.length) {
            this.accessGroups.splice(index, 1);
        }

        return this;
    }

    /**
     * Returns if the checklist has an access tag matching uuid
     *
     * @param {string} uuid
     * @return {boolean}
     */
    public hasAccessTag(uuid: string): boolean {
        return !!_.find(this.accessTags || [], { uuid: uuid });
    }

    /**
     * Adds an access group
     *
     * @param {IUuidNamePair} tag
     * @return {IChecklist}
     */
    public addAccessTag(tag: IUuidNamePair): IChecklist {
        if (!this.hasAccessTag(tag.uuid)) {
            this.accessTags.push(tag);
        }

        return this;
    }

    /**
     * Removes an access tag
     *
     * @param {number} index
     * @return {IChecklist}
     */
    public removeAccessTag(index: number): IChecklist {
        if (this.accessTags && index > -1 && index < this.accessTags.length) {
            this.accessTags.splice(index, 1);
        }

        return this;
    }

    /**
     * Returns if the checklist has an access location matching uuid
     *
     * @param {string} uuid
     * @return {boolean}
     */
    public hasAccessLocation(uuid: string): boolean {
        return !!_.find(this.accessLocations || [], { uuid: uuid });
    }

    /**
     * Adds an access location
     *
     * @param {IUuidNamePair} location
     * @return {IChecklist}
     */
    public addAccessLocation(location: IUuidNamePair): IChecklist {
        if (!this.hasAccessLocation(location.uuid)) {
            this.accessLocations.push(location);
        }

        return this;
    }

    /**
     * Returns if this checklist has an outcome assigned
     */
    public hasOutcomeAssigned(): boolean {
        return !!this.outcomeSetId;
    }

    /**
     * Removes an access location
     *
     * @param {number} index
     * @return {IChecklist}
     */
    public removeAccessLocation(index: number): IChecklist {
        if (this.accessLocations && index > -1 && index < this.accessLocations.length) {
            this.accessLocations.splice(index, 1);
        }

        return this;
    }

    /**
     * Returns if this checklist has not yet had any revisions to it
     *
     */
    public isNewChecklist(): boolean {
        return this.revision === 1 && this.createdAt === this.updatedAt;
    }

    public hasUnpublishedVersion(): boolean {
        return !!this.lastPublishedUuid && this.lastPublishedUuid !== this.uuid;
    }
}
