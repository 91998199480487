import {
    IBaseSettings,
    BaseSettings,
} from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseSettings';
import { RandomizationSettings } from './RandomizationSettings';

export interface ISettings extends IBaseSettings {
    showPreviousAuditResults: boolean;
    showPreviousResponsesDuringAudit: boolean;
    showPreviousCommentDuringAudit: boolean;
    resetRandomizationSettings(): void;
}

export class Settings extends BaseSettings implements ISettings {
    public showPreviousAuditResults: boolean = false;
    public showPreviousResponsesDuringAudit: boolean = false;
    public showPreviousCommentDuringAudit: boolean = false;

    /**
     * Resets the randomization settings for a checklist.
     */
    public resetRandomizationSettings(): void {
        this.randomization = new RandomizationSettings();
    }
}
