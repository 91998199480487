import { IChecklist } from './Checklist';

export class ChecklistAdapter {
    /**
     * Returns a json string that the api expects
     *
     * @param {IChecklist} checklist
     * @return {string}
     */
    public static toApiJson(checklist: IChecklist): string {
        let clone: IChecklist = <IChecklist>checklist.clone();
        ChecklistAdapter.adaptItemExamplePhotosToApi(clone);
        ChecklistAdapter.adaptSchedulerToApi(clone);

        return clone.toJson(true);
    }

    /**
     * Adapts scheduler property of a checklist
     *
     * @param {IChecklist} checklist
     * @return {IScheduleSettings}
     */
    protected static adaptSchedulerToApi(checklist: IChecklist): void {
        if (checklist && checklist.scheduler) {
            delete checklist.scheduler.nextRebuildDate;
            delete checklist.scheduler.upcomingDates;
        }
    }

    /**
     * Removes the data property from each example photo in items of a checklist
     *
     * @param {IChecklist} checklist
     */
    protected static adaptItemExamplePhotosToApi(checklist: IChecklist): void {
        if (checklist && checklist.sections) {
            for (let section of checklist.sections) {
                if (section.items) {
                    for (let item of section.items) {
                        if (item.examplePhoto && item.examplePhoto.data) {
                            delete item.examplePhoto.data;
                        }
                    }
                }
            }
        }
    }
}
