import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface IOutcomeSetListing extends IEntity {
    id: number;
    uuid: string;
    name: string;
    type: string;
    active: boolean;
    createdAt: string;
    updatedAt: string;
}

export class OutcomeSetListing extends Entity {
    id: number = null;
    uuid: string = null;
    name: string = null;
    type: string = null;
    active: boolean = true;
    createdAt: string = null;
    updatedAt: string = null;
}
