import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItem } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItem';

@Component('its.check.module.checklists', HideFromReportingSetting.CID, {
    template:
        require('/app/modules/checklists/components/items/shared/hide-from-reporting-setting/HideFromReportingSetting.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class HideFromReportingSetting {
    static IID: string = 'HideFromReportingSetting';
    static CID: string = 'itcHideFromReportingSetting';

    static $inject: Array<string> = [];
    public item: BaseItem;
}
