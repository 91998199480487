import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemTranslation } from './BaseItemTranslation';
import { IItemMultipleChoice } from '../../../../domain/checklists/ItemMultipleChoice';

/**
 * The UI for rendering a way to add and remove multiple choice translations
 */
@Component('its.check.module.checklists', MultipleChoiceTranslation.CID, {
    template: require('/app/modules/checklists/components/translations/MultipleChoiceTranslation.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
        locale: '<',
    },
})
class MultipleChoiceTranslation extends BaseItemTranslation {
    static IID: string = 'MultipleChoiceTranslation';
    static CID: string = 'itsMultipleChoiceTranslation';
    static $inject: Array<string> = ['$translate'];

    public responseLabel: string;
    public item: IItemMultipleChoice;

    /**
     * The class constructor
     *
     * @param translate
     */
    constructor(public translate: angular.translate.ITranslateService) {
        super(translate);

        this.translate([
            'TRANSLATIONS.MULTIPLE_CHOICE.LABEL',
            'TRANSLATIONS.MULTIPLE_CHOICE.RESPONSE_LABEL',
            'TRANSLATIONS.CHECKBOXES.LABEL',
            'TRANSLATIONS.CHECKBOXES.RESPONSE_LABEL',
        ]).then((translations) => {
            if (this.item.settings.multipleSelections) {
                this.itemLabel = translations['TRANSLATIONS.CHECKBOXES.LABEL'];
                this.responseLabel = translations['TRANSLATIONS.CHECKBOXES.RESPONSE_LABEL'];
            } else {
                this.itemLabel = translations['TRANSLATIONS.MULTIPLE_CHOICE.LABEL'];
                this.responseLabel = translations['TRANSLATIONS.MULTIPLE_CHOICE.RESPONSE_LABEL'];
            }
        });
    }
}
