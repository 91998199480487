import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemSettingsComponent } from '../BaseItemSettingsComponent';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { ChecklistService } from '../../../../../services/ChecklistService';
import { IChecklist } from '../../../../../domain/checklists/Checklist';
import { ItemTemperature } from '../../../../../domain/checklists/ItemTemperature';

@Component('its.check.module.checklists', TemperatureSettings.CID, {
    template: require('/app/modules/checklists/components/items/temperature/TemperatureSettings.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class TemperatureSettings extends BaseItemSettingsComponent {
    static IID: string = 'TemperatureSettings';
    static CID: string = 'itcTemperatureSettings';

    static $inject: Array<string> = ['itcChecklistService', '$scope'];
    public item: ItemTemperature;
    public checklist: IChecklist = null;
    public minValue: any = {
        C: null,
        F: null,
    };
    public maxValue: any = {
        C: null,
        F: null,
    };

    public constructor(private checklistService: ChecklistService, private scope: ng.IScope) {
        super();
        this.subscribeToChecklist();
    }

    $onInit() {
        this.rangeChanged('C', this.item.settings.min, 'min');
        this.rangeChanged('C', this.item.settings.max, 'max');
    }

    /**
     * Update the min or max value for a temperature question.
     * @param unit The degree unit we are currently in either F or C
     * @param value The temperature value
     * @param property The property being updated either min or max
     */
    public rangeChanged(unit: 'F' | 'C', value: number, property: 'min' | 'max'): void {
        if (unit === 'F') {
            this.item.settings[property] = this.item.convertTemperature(value, 'C');
        } else {
            this.item.settings[property] = value;
            this[property + 'Value'].F = this.item.convertTemperature(value, 'F');
        }
        this[property + 'Value'].C = this.item.settings[property];
    }

    private subscribeToChecklist(): void {
        const checklistSubscription: SubjectSubscription = this.checklistService.checklistObserver.subscribe(
            (checklist) => {
                this.checklist = checklist;
            }
        );
        checklistSubscription.unsubscribeOnScopeDestruction(this.scope);
    }
}
