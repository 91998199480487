import { Entity, IEntity } from '@intouch/its.essential/app/essential/domain/Entity';
import { IUuidNamePair } from '@intouch/its.check.essential/app/check-essential/domain/datatypes/UuidNamePair';
import * as moment from 'moment';

export interface IScheduleSettings extends IEntity {
    cronMask: string;
    timezone: string;
    secondsBefore: number;
    secondsAfter: number;
    nextRebuildDate?: string;
    readyGroups: Array<IUuidNamePair>;
    overdueGroups: Array<IUuidNamePair>;
    upcomingDates?: Array<string>;
}

export class ScheduleSettings extends Entity implements IScheduleSettings {
    cronMask: string = null;
    timezone: string = null;
    secondsBefore: number = null;
    secondsAfter: number = null;
    nextRebuildDate: string;
    readyGroups: Array<IUuidNamePair>;
    overdueGroups: Array<IUuidNamePair>;
    upcomingDates: Array<string>;

    /**
     * Will convert seconds to a more user friendly format (and return a data structure of what it selected)
     *
     * todo; move this to a generic util class i.e. DateTime.secondsToTimeStructure( value:number )
     *
     * @param value
     * @returns {any}
     */
    public static secondsToTime(value: number): { type: string; value: number } {
        if (value > 24 * 60 * 60) {
            return {
                type: 'days',
                value: value / (24 * 60 * 60),
            };
        } else if (value >= 60 * 60) {
            return {
                type: 'hours',
                value: value / (60 * 60),
            };
        } else {
            return {
                type: 'minutes',
                value: value / 60,
            };
        }
    }

    /**
     * Returns label/value pairs for the hours of the day
     *
     * @return {Array<{label: string; value: string}>}
     */
    public static getTimes(): Array<{ label: string; value: string }> {
        let times: Array<{ label: string; value: string }> = [];

        for (let j: number = 0; j < 24; j++) {
            times.push({ label: j.toString(), value: j.toString() });
        }

        return times;
    }

    /**
     * Returns label/value pairs for the days of the week
     *
     * @return {Array<{label: string; value: string}>}
     */
    public static getWeekDays(): Array<{ label: string; value: string }> {
        let weekDays: Array<string> = moment.weekdaysShort();
        return [
            { label: 'ANY', value: '*' },
            { label: weekDays[0], value: '0' },
            { label: weekDays[1], value: '1' },
            { label: weekDays[2], value: '2' },
            { label: weekDays[3], value: '3' },
            { label: weekDays[4], value: '4' },
            { label: weekDays[5], value: '5' },
            { label: weekDays[6], value: '6' },
        ];
    }

    /**
     * Returns label/value pairs for the days of the month
     *
     * @return {Array<{label: string; value: string}>}
     */
    public static getMonthDays(): Array<{ label: string; value: string }> {
        let monthDays: Array<{ label: string; value: string }> = [];
        monthDays.push({ label: 'ANY', value: '*' });
        for (let j: number = 1; j <= 31; j++) {
            monthDays.push({ label: j.toString(), value: j.toString() });
        }
        monthDays.push({ label: 'LAST', value: 'L' });

        return monthDays;
    }

    /**
     * Returns label/value pairs for each month
     *
     * @return {Array<{label: string; value: string}>}
     */
    public static getMonths(): Array<{ label: string; value: string }> {
        let months: Array<string> = moment.monthsShort();
        return [
            { label: 'ANY', value: '*' },
            { label: months[0], value: '1' },
            { label: months[1], value: '2' },
            { label: months[2], value: '3' },
            { label: months[3], value: '4' },
            { label: months[4], value: '5' },
            { label: months[5], value: '6' },
            { label: months[6], value: '7' },
            { label: months[7], value: '8' },
            { label: months[8], value: '9' },
            { label: months[9], value: '10' },
            { label: months[10], value: '11' },
            { label: months[11], value: '12' },
        ];
    }

    /**
     * Builds the object from json
     *
     * @param jsonObject
     * @param {boolean} convertToCamel
     * @return {IScheduleSettings}
     */
    public fromJson(jsonObject: any, convertToCamel: boolean = true): IScheduleSettings {
        super.fromJson(jsonObject, convertToCamel);

        if (jsonObject['next_rebuild_date']) {
            this.nextRebuildDate = jsonObject['next_rebuild_date'];
        }

        if (jsonObject['upcoming_dates']) {
            this.upcomingDates = jsonObject['upcoming_dates'];
        }

        if (jsonObject['ready_groups']) {
            this.readyGroups = jsonObject['ready_groups'];
        }

        if (jsonObject['overdue_groups']) {
            this.overdueGroups = jsonObject['overdue_groups'];
        }

        return this;
    }
}
