import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { IChecklistService } from '../../../services/ChecklistService';
import { SubjectSubscription } from '@intouch/its.essential/app/essential/utils/BehaviorSubject';
import { IChecklist } from '../../../domain/checklists/Checklist';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { ICheckApi } from '../../../api/CheckApi';
import { ChecklistEditService } from '../../../services/ChecklistEditService';

@Component('its.check.module.checklists', ChecklistPublishButton.CID, {
    template: require('/app/modules/checklists/components/ChecklistPublishButton.html'),
    controllerAs: 'vm',
    bindings: {
        disable: '<?',
        onPublish: '&?',
    },
})
class ChecklistPublishButton {
    static IID: string = 'ChecklistPublishButton';
    static CID: string = 'itcChecklistPublishButton';

    static $inject: Array<string> = [
        'itcChecklistService',
        '$scope',
        '$mdMedia',
        'iteAccessService',
        'itcCheckApi',
        'itcChecklistEditService',
    ];

    public checklist: IChecklist = null;
    public disabled: boolean; // binding
    public hasAclAdmin: boolean = false;
    public publishing: boolean = false;
    private onPublish: (param: { publishPromise: ng.IPromise<IChecklist> }) => void;
    private isEditingItem: boolean = false;

    public constructor(
        private checklistService: IChecklistService,
        private scope: ng.IScope,
        public media: ng.material.IMedia,
        private accessService: IAccessService,
        private checkApi: ICheckApi,
        private checklistEditService: ChecklistEditService
    ) {
        this.checklistService.getChecklist();
        this.hasAclAdmin = this.accessService.getToken().getUser().hasAcl('check_admin');
        this.initSubscription();
        this.subscribeToEditingItem();
    }

    public isDisabled(): boolean {
        return (
            !this.checklist ||
            this.publishing ||
            this.disabled ||
            !this.checklist.isPublishable() ||
            !this.hasAclAdmin ||
            this.isEditingItem
        );
    }

    public publish(): void {
        if (!!this.onPublish) {
            this.onPublish({ publishPromise: this.callPublish() });
        } else {
            this.callPublish();
        }
    }

    public callPublish(): ng.IPromise<IChecklist> {
        this.publishing = true;
        return this.checkApi
            .publishChecklist(this.checklist.uuid)
            .then((result: IChecklist) => {
                return result;
            })
            .finally(() => {
                this.publishing = false;
            });
    }

    private initSubscription(): void {
        const checklistSubscription: SubjectSubscription = this.checklistService.checklistObserver.subscribe(
            (checklist) => {
                this.checklist = checklist;
            }
        );
        checklistSubscription.unsubscribeOnScopeDestruction(this.scope);
    }

    private subscribeToEditingItem(): void {
        const editingItemSubscription: SubjectSubscription = this.checklistEditService.editingItem.subscribe(
            (currentEditingItem) => {
                this.isEditingItem = !!currentEditingItem;
            }
        );
        editingItemSubscription.unsubscribeOnScopeDestruction(this.scope);
    }
}
