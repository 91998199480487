import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IFileProcessingService } from '@intouch/its.essential/app/essential/services/FileProcessingService';
import { IUploadStatus } from '@intouch/its.essential/app/essential/domain/file-processing/UploadStatus';
import { ICheckApi } from '../../../api/CheckApi';

@Controller('its.check.module.checklists', ChecklistUploadStep2Controller.IID, ChecklistUploadStep2Controller)
class ChecklistUploadStep2Controller {
    static IID: string = 'ChecklistUploadStep2Controller';
    static $inject: Array<string> = ['$state', '$stateParams', 'iteFileProcessingService', 'itcCheckApi', '$translate'];
    public errors: Array<string> = [];
    public warnings: Array<string> = [];
    public processing: boolean = true;
    public uploadStatus: IUploadStatus = null;
    public error: boolean = false;
    public errorMessage: string = null;

    constructor(
        private state: angular.ui.IStateService,
        private stateParams: angular.ui.IStateParamsService,
        private fileProcessingService: IFileProcessingService,
        private checkApi: ICheckApi,
        private translate: angular.translate.ITranslateService
    ) {
        this.pollStatus();
    }

    public pollStatus(): void {
        if (this.stateParams['fileUuid']) {
            this.fileProcessingService.pollingStatus(this.stateParams['fileUuid'], 'validation', 7500).then(
                (response) => {
                    this.uploadStatus = <IUploadStatus>response;
                    if (this.uploadStatus.isProcessed()) {
                        this.error = true;
                        this.processing = false;
                        this.errorMessage = this.translate.instant('CHECKLISTS.UPLOAD.ALREADY_PROCESSED');
                    }

                    if (this.uploadStatus.isValidated()) {
                        this.processing = false;
                    }
                },
                () => {
                    this.processing = false;
                    this.error = true;
                    this.errorMessage = this.translate.instant('CHECKLISTS.UPLOAD.ERROR_RETRIEVING_VALIDATION_STATUS');
                }
            );
        } else {
            this.processing = false;
            this.error = true;
            this.errorMessage = this.translate.instant('CHECKLISTS.UPLOAD.INVALID_FILE_ID');
        }
    }

    public nextStep(step: string): void {
        if (step) {
            this.state.go(step, { fileUuid: this.stateParams['fileUuid'] });
        }
    }

    public getStatusIcon(): string {
        if (!this.warnings && !this.errors) {
            return 'check';
        } else if (this.warnings && !this.errors) {
            return 'warning';
        } else if (this.errors) {
            return 'error';
        }

        return null;
    }
}
