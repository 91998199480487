import { String } from '@intouch/its.essential/app/essential/utils/String';
import {
    IBaseResponse,
    BaseResponse,
} from '@intouch/its.check.essential/app/check-essential/domain/datatypes/BaseResponse';

/**
 * The IResponse interface
 *
 */
export interface IResponse extends IBaseResponse {}

/**
 * The Response class
 *
 */
export class Response extends BaseResponse implements IResponse {
    /**
     * Will make a generic response
     *
     * @param name
     * @param type
     * @returns {Response}
     */
    public static make(name: string, type: string): IResponse {
        let me: IResponse = new Response();
        me.name = name;
        me.uuid = String.uuid();
        me.points = null;
        me.type = type;
        me.treatAs = 'ok';

        return me;
    }
}
