import { Configuration } from '@intouch/its.essential/app/essential/decorators/Configuration';
import { ILogger } from '@intouch/its.essential/app/essential/services/Logger';
import { IAccessService } from '@intouch/its.essential/app/essential/services/access/AccessService';
import { IUser } from '@intouch/its.essential/app/essential/domain/access/User';
import { IToken } from '@intouch/its.essential/app/essential/domain/access/Token';
import { ICheckApi } from '../../api/CheckApi';
import { ICheckSession } from '../../services/CheckSession';
import { ICheckEssentialApi } from '@intouch/its.check.essential/app/check-essential/api/CheckEssentialApi';
import { IIntelligenceApi } from '@intouch/its.check.essential/app/check-essential/api/IntelligenceApi';
import { IAccessApi } from '@intouch/its.essential/app/essential/api/AccessApi';
import { ILogHandler } from '@intouch/its.essential/app/essential/services/logger/LogHandler';
import { AuthState } from '@intouch/its.essential/app/essential/services/access/AuthState';
import { datadogRum } from '@datadog/browser-rum';

@Configuration('its.check.module.main', MainConfiguration)
class MainConfiguration {
    /**
     * Build an instance of the configuration for angular binding
     *
     * @returns {any}
     */
    public static instance(): any {
        let config: any = (
            mdThemingProvider: ng.material.IThemingProvider,
            urlRouterProvider: ng.ui.IUrlRouterProvider,
            httpProvider: ng.IHttpProvider,
            stateProvider: ng.ui.IStateProvider
        ) => new MainConfiguration(mdThemingProvider, urlRouterProvider, httpProvider, stateProvider);

        config.$inject = ['$mdThemingProvider', '$urlRouterProvider', '$httpProvider', '$stateProvider'];
        return config;
    }

    /**
     * Build the main sites configuration
     *
     * @param mdThemingProvider
     * @param urlRouterProvider
     * @param httpProvider
     * @param stateProvider
     */
    constructor(
        private mdThemingProvider: ng.material.IThemingProvider,
        private urlRouterProvider: ng.ui.IUrlRouterProvider,
        private httpProvider: ng.IHttpProvider,
        private stateProvider: ng.ui.IStateProvider
    ) {
        this.buildTheme();
        this.buildStates();
        this.setupHttpProvider();
    }

    /**
     * Build the main sites theme
     */
    public buildTheme(): void {
        this.mdThemingProvider.definePalette('IntouchCheck - Primary', {
            '50': '#e1f6ea',
            '100': '#b3e7c9',
            '200': '#81d8a6',
            '300': '#4fc882',
            '400': '#29bc67',
            '500': '#03b04c',
            '600': '#03a945',
            '700': '#02a03c',
            '800': '#029733',
            '900': '#018724',
            A100: '#ffffff',
            A200: '#03b04c',
            A400: '#4dff6e',
            A700: '#34ff59',
            contrastDefaultColor: 'light',
            contrastLightColors: '400 500 600 A400 A700 A200',
            contrastDarkColors: '50 100 200 300 A100',
        });

        this.mdThemingProvider.definePalette('IntouchCheck - Accent', {
            '50': '#e1f6ea',
            '100': '#b3e7c9',
            '200': '#81d8a6',
            '300': '#4fc882',
            '400': '#29bc67',
            '500': '#03b04c',
            '600': '#03a945',
            '700': '#02a03c',
            '800': '#029733',
            '900': '#018724',
            A100: '#ffffff',
            A200: '#03b04c',
            A400: '#4dff6e',
            A700: '#34ff59',
            contrastDefaultColor: 'light',
            contrastLightColors: '400 500 600 A400 A700 A200',
            contrastDarkColors: '50 100 200 300 A100',
        });

        this.mdThemingProvider
            .theme('default')
            .primaryPalette('IntouchCheck - Primary')
            .accentPalette('IntouchCheck - Accent');
    }

    public setupHttpProvider(): void {
        this.httpProvider.defaults.withCredentials = true;

        delete this.httpProvider.defaults.headers.common['X-Requested-With'];
    }

    /**
     * Build the main sites states (in this case default route)
     */
    public buildStates(): void {
        this.stateProvider
            .state('login-callback', {
                url: '/login/callback/:token?redirect',
                controller: 'LoginCallbackController as vm',
                template: require('/app/modules/main/views/LoginCallback.html'),
            })
            .state('trial-expired', {
                url: '/trial-expired',
                controller: 'AccountProblemController as vm',
                template: require('/app/modules/main/views/TrialExpired.html'),
                data: {
                    title: 'ESSENTIAL.TRIAL_EXPIRED.TRIAL_EXPIRED',
                    translate: true,
                },
                resolve: {
                    hasToken: [
                        'iteAccessService',
                        '$state',
                        '$q',
                        '$timeout',
                        (
                            accessService: IAccessService,
                            state: ng.ui.IStateService,
                            q: ng.IQService,
                            timeout: ng.ITimeoutService
                        ) => {
                            let defer: any = q.defer();
                            if (!accessService || !accessService.getToken()) {
                                this.handleLogin(timeout, accessService, false);
                                defer.reject();
                            } else {
                                defer.resolve();
                            }
                            return defer.promise;
                        },
                    ],
                },
            })
            .state('account-locked', {
                url: '/account-locked',
                controller: 'AccountProblemController as vm',
                template: require('/app/modules/main/views/AccountLocked.html'),
                data: {
                    title: 'GENERAL.ACCOUNT_LOCKED.ACCOUNT_LOCKED',
                    translate: true,
                },
                resolve: {
                    hasToken: [
                        'iteAccessService',
                        '$state',
                        '$q',
                        '$timeout',
                        (
                            accessService: IAccessService,
                            state: ng.ui.IStateService,
                            q: ng.IQService,
                            timeout: ng.ITimeoutService
                        ) => {
                            let defer: any = q.defer();
                            if (!accessService || !accessService.getToken()) {
                                this.handleLogin(timeout, accessService, false);
                                defer.reject();
                            } else {
                                defer.resolve();
                            }
                            return defer.promise;
                        },
                    ],
                },
            })
            .state('home', {
                abstract: true,
                template: require('/app/modules/main/views/SiteLayout.html'),
                controller: 'MainController as vm',
                resolve: {
                    authenticatedUser: [
                        'iteAccessService',
                        '$state',
                        '$timeout',
                        '$q',
                        'iteLogger',
                        'itcCheckApi',
                        'itcCheckSession',
                        'itcCheckEssentialApi',
                        'itcIntelligenceApi',
                        'iteAccessApi',
                        (
                            accessService: IAccessService,
                            state: ng.ui.IStateService,
                            timeout: ng.ITimeoutService,
                            q: ng.IQService,
                            logger: ILogger,
                            checkApi: ICheckApi,
                            session: ICheckSession,
                            itcCheckEssentialApi: ICheckEssentialApi,
                            intelligenceApi: IIntelligenceApi,
                            accessApi: IAccessApi
                        ) => {
                            const defer: any = q.defer();
                            const token: IToken = accessService.getToken();

                            const authState: AuthState = new AuthState({
                                timeout: timeout,
                                state: state,
                                q: q,
                                accessService: accessService,
                                authContextProvider: accessApi,
                                logger: logger,
                            });

                            if (!token) {
                                authState.rejectNullToken();
                            } else {
                                // need to check if we are past our token session expiration
                                if (authState.isTokenExpired(token)) {
                                    authState.rejectExpiredToken();
                                } else {
                                    logger.debug(
                                        'User appears to be logged already, session will expire on ' +
                                            token.getDateExpired().toDate()
                                    );

                                    const user: IUser = token.getUser();
                                    authState.addLogHandler(<ILogHandler>logger.getHandler('sentry-handler-v2'), token);
                                    authState.setupDataDogRumContext(datadogRum, token);
                                    intelligenceApi.addTokenReplace(
                                        'org_slug',
                                        accessService.getToken().getOrganization().slug
                                    );

                                    authState
                                        .loadAuthContext()
                                        .then(() => {
                                            q.all([
                                                authState.checkProductTrialExpired('check', {
                                                    name: 'trial-expired',
                                                }),
                                                authState.checkProductSubscriptionCanceled('check', {
                                                    name: 'account-locked',
                                                }),
                                            ]).then(() => {
                                                logger.info('Organization loaded');
                                                defer.resolve(user);
                                            });
                                        })
                                        .catch((error) => {
                                            logger.error(error.message);
                                            authState.rejectNullToken();
                                        });
                                }
                            }

                            return defer.promise;
                        },
                    ],
                },
            });

        this.urlRouterProvider.otherwise('/checklists/list');
    }

    private handleLogin(
        timeout: ng.ITimeoutService,
        accessService: IAccessService,
        rememberLocation: boolean = false
    ): void {
        timeout(() => {
            if (rememberLocation) {
                accessService.rememberLocation();
            }
            accessService.handleLogin();
        });
    }
}
