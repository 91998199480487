import { Configuration } from '@intouch/its.essential/app/essential/decorators/Configuration';

/**
 * Will setup the audit routing states
 *
 * @param $stateProvider
 */
@Configuration('its.check.module.audits', AuditsConfiguration)
class AuditsConfiguration {
    /**
     * Create the instance of the configuration used for angular binding
     *
     * @returns {any}
     */
    public static instance(): any {
        let config: any = ($stateProvider: ng.ui.IStateProvider) => new AuditsConfiguration($stateProvider);
        config.$inject = ['$stateProvider'];
        return config;
    }

    /**
     * Create the audit configuration class
     *
     * @param stateProvider
     */
    constructor(private stateProvider: ng.ui.IStateProvider) {
        this.buildStates();
    }

    /**
     * Initialize the user module
     */
    public buildStates(): void {
        this.stateProvider
            .state('home.audits', {
                abstract: true,
                url: '/audits',
                template: '<div layout="row" flex><div ui-view layout="column" flex></div></div>',
            })
            .state('home.audits.dashboard', {
                abstract: true,
                url: '/dashboard',
                template: '<div ui-view layout="row" flex></div>',
            })
            .state('home.audits.dashboard.approval', {
                url: '/approval?page&sortBy&order',
                data: { title: 'AUDITS.APPROVAL', translate: true, tab: 'approval' },
                template: require('/app/modules/audits/views/Dashboard.html'),
                controller: 'DashboardController as vm',
            })
            .state('home.audits.dashboard.in_progress', {
                url: '/in_progress?page&sortBy&order',
                data: { title: 'AUDITS.IN_PROGRESS', translate: true, tab: 'in_progress' },
                template: require('/app/modules/audits/views/Dashboard.html'),
                controller: 'DashboardController as vm',
            })
            .state('home.audits.dashboard.admin_assigned', {
                url: '/admin_assigned?page&sortBy&order',
                data: { title: 'AUDITS.ADMIN_ASSIGNED', translate: true, tab: 'admin_assigned' },
                template: require('/app/modules/audits/views/Dashboard.html'),
                controller: 'DashboardController as vm',
            })
            .state('home.audits.dashboard.reviewable', {
                url: '/reviewable?page&sortBy&order',
                data: { title: 'AUDITS.REVIEWABLE', translate: true, tab: 'reviewable' },
                template: require('/app/modules/audits/views/Dashboard.html'),
                controller: 'DashboardController as vm',
            })
            .state('home.audits.dashboard.approval_completed', {
                url: '/approval_completed?page&sortBy&order',
                data: { title: 'AUDITS.APPROVAL_COMPLETE', translate: true, tab: 'completed' },
                template: require('/app/modules/audits/views/Dashboard.html'),
                controller: 'DashboardController as vm',
            })
            .state('home.audits.dashboard.incomplete', {
                url: '/incomplete?page&sortBy&order&dateFilter',
                data: { title: 'AUDITS.INCOMPLETE', translate: true, tab: 'incomplete' },
                template: require('/app/modules/audits/views/Dashboard.html'),
                controller: 'DashboardController as vm',
            })
            .state('home.audits.dashboard.deleted', {
                url: '/deleted?page&sortBy&order&dateFilter',
                data: { title: 'AUDITS.DELETED', translate: true, tab: 'deleted' },
                template: require('/app/modules/audits/views/Dashboard.html'),
                controller: 'DashboardController as vm',
            })
            .state('home.audits.dashboard.complete', {
                url: '/complete?page&sortBy&order&dateFilter&to&from',
                data: { title: 'AUDITS.COMPLETE', translate: true, tab: 'all_complete' },
                template: require('/app/modules/audits/views/Dashboard.html'),
                controller: 'DashboardController as vm',
            })
            .state('home.audits.review', {
                url: '/review/:auditUuid',
                template: require('/app/modules/audits/views/View.html'),
                controller: 'ViewController as vm',
                params: {
                    enableComments: {
                        value: true,
                    },
                    enableAcceptReject: {
                        value: false,
                    },
                },
            })
            .state('home.audits.completed', {
                url: '/completed/:auditUuid',
                template: require('/app/modules/audits/views/View.html'),
                controller: 'ViewController as vm',
                params: {
                    enableComments: {
                        value: false,
                    },
                    enableAcceptReject: {
                        value: false,
                    },
                },
            })
            .state('home.audits.all_complete', {
                url: '/all_complete/:auditUuid',
                template: require('/app/modules/audits/views/View.html'),
                controller: 'ViewController as vm',
                params: {
                    enableComments: {
                        value: false,
                    },
                    enableAcceptReject: {
                        value: false,
                    },
                },
            })
            .state('home.audits.approve', {
                url: '/approve/:auditUuid',
                template: require('/app/modules/audits/views/View.html'),
                controller: 'ViewController as vm',
                params: {
                    enableComments: {
                        value: true,
                    },
                    enableAcceptReject: {
                        value: true,
                    },
                },
            });
    }
}
