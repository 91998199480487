import { IAdditionalRequirements } from '@intouch/its.check.essential/app/check-essential/domain/datatypes/AdditionalRequirements';
import { IEntity, Entity } from '@intouch/its.essential/app/essential/domain/Entity';

export interface IItemAdditionalRequirements extends IEntity, IAdditionalRequirements {
    type: string;
    condition: string;
}

export class ItemAdditionalRequirements extends Entity implements IItemAdditionalRequirements {
    public type: string = null;
    public condition: string = null;
}
