import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItemTranslation } from './BaseItemTranslation';

/**
 * The UI for rendering a way to add and remove date picker translations
 */
@Component('its.check.module.checklists', DatePickerTranslation.CID, {
    template: require('/app/modules/checklists/components/translations/BaseItemTranslation.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
        locale: '<',
    },
})
class DatePickerTranslation extends BaseItemTranslation {
    static IID: string = 'DatePickerTranslation';
    static CID: string = 'itsDatePickerTranslation';
    static $inject: Array<string> = ['$translate'];

    /**
     * The class constructor
     *
     * @param translate
     */
    constructor(public translate: angular.translate.ITranslateService) {
        super(translate);

        this.translate(['TRANSLATIONS.DATE_PICKER.LABEL']).then((translations) => {
            this.itemLabel = translations['TRANSLATIONS.DATE_PICKER.LABEL'];
        });
    }
}
