import { Component } from '@intouch/its.essential/app/essential/decorators/Component';
import { BaseItem } from '@intouch/its.check.essential/app/check-essential/domain/checklists/BaseItem';

@Component('its.check.module.checklists', TagSetting.CID, {
    template: require('/app/modules/checklists/components/items/shared/tag-setting/TagSetting.html'),
    controllerAs: 'vm',
    bindings: {
        item: '=',
    },
})
class TagSetting {
    static IID: string = 'TagSetting';
    static CID: string = 'itcTagSetting';

    static $inject: Array<string> = [];
    public item: BaseItem;
}
