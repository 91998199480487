import { Controller } from '@intouch/its.essential/app/essential/decorators/Controller';
import { IFileProcessingService } from '@intouch/its.essential/app/essential/services/FileProcessingService';
import { IUploadStatus } from '@intouch/its.essential/app/essential/domain/file-processing/UploadStatus';
import { ICheckApi } from '../../../api/CheckApi';
import { BaseChecklist } from '../BaseChecklist';
import { IToaster } from '@intouch/its.essential/app/essential/services/Toaster';
import { IPageService } from '../../../services/PageService';
import { ICheckSession } from '../../../services/CheckSession';
import { ChecklistService } from '../../../services/ChecklistService';
import { ChecklistEditService } from '../../../services/ChecklistEditService';

@Controller('its.check.module.checklists', ChecklistUploadStep3Controller.IID, ChecklistUploadStep3Controller)
class ChecklistUploadStep3Controller extends BaseChecklist {
    static IID: string = 'ChecklistUploadStep3Controller';
    static $inject: Array<string> = [
        '$stateParams',
        'iteFileProcessingService',
        '$state',
        'itcCheckApi',
        '$translate',
        'iteToaster',
        'itcPageService',
        'itcCheckSession',
        'itcChecklistService',
        'itcChecklistEditService',
    ];
    public processing: boolean = true;
    public verifying: boolean = true;
    public queued: boolean = false;
    public error: boolean = false;
    public errorMessage: string = null;
    public completedStatus: IUploadStatus = null;

    constructor(
        private stateParams: angular.ui.IStateParamsService,
        private fileProcessingService: IFileProcessingService,
        protected state: ng.ui.IStateService,
        protected checkApi: ICheckApi,
        protected translate: angular.translate.ITranslateService,
        protected toaster: IToaster,
        protected pageService: IPageService,
        protected session: ICheckSession,
        protected checklistService: ChecklistService,
        protected checklistEditService: ChecklistEditService
    ) {
        super(state, checkApi, toaster, pageService, translate, session, checklistService, checklistEditService);

        this.fileProcessingService.status(this.stateParams['fileUuid']).then((uploadStatus: IUploadStatus) => {
            if (uploadStatus.isValid() || uploadStatus.isProcessed()) {
                this.verifying = false;
                if (uploadStatus.isProcessed()) {
                    this.processing = false;
                    this.completedStatus = uploadStatus;
                } else {
                    // send request to process the file
                    this.queued = true;

                    this.fileProcessingService
                        .execute(this.stateParams['fileUuid'])
                        .then(() => {
                            // start polling
                            this.fileProcessingService
                                .pollingStatus(this.stateParams['fileUuid'], 'execution', 7500)
                                .then(
                                    // success
                                    (completedStatus: IUploadStatus) => {
                                        this.completedStatus = completedStatus;
                                        // succeeded in processing
                                        if (completedStatus.isProcessed()) {
                                            this.state.go('home.checklists.edit', {
                                                uuid: this.stateParams['uuid'],
                                                uploaded: true,
                                            });
                                            this.processing = false;
                                            this.error = false;
                                            this.errorMessage = null;
                                        } else {
                                            this.error = true;
                                            this.errorMessage = this.translate.instant(
                                                'CHECKLISTS.UPLOAD.ERROR_PROCESSING'
                                            );
                                        }
                                    },
                                    // error
                                    () => {
                                        this.error = true;
                                        this.errorMessage = this.translate.instant(
                                            'CHECKLISTS.UPLOAD.ERROR_RETRIEVING_STATUS'
                                        );
                                    },
                                    // notification (called on every poll)
                                    (notificationStatus: IUploadStatus) => {
                                        this.completedStatus = notificationStatus;
                                        this.queued = !this.completedStatus.isProcessing();
                                    }
                                );
                        })
                        .catch(() => {
                            this.error = true;
                            this.errorMessage = this.translate.instant('CHECKLISTS.UPLOAD.ERROR_REQUESTING_EXECUTION');
                        });
                }
            } else {
                this.error = true;
                this.errorMessage = this.translate.instant('CHECKLISTS.UPLOAD.ERROR_EXECUTING_INVALID_FILE');
            }
        });
    }

    /**
     * Takes the user back to the checklist that they uploaded to.
     */
    public goToChecklist(): void {
        this.state.go('home.checklists.edit', { uuid: this.completedStatus.configuration.checklist_id });
    }
}
